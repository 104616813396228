import * as yup from "yup";
const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
var fbPattern = /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
var twitterPatter = /^(?:(?:http|https):\/\/)?(?:www.)?twitter.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
var instagramPatten = /^(?:(?:http|https):\/\/)?(?:www.)?instagram.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
var linkedinPatten = /^(?:(?:http|https):\/\/)?(?:www.)?linkedin.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
export const organisationSocialLinksSchema = yup.object().shape({
    website: yup.string().matches(re,'URL is not valid'),
    facebookLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(fbPattern,'Not a facebook url'),
    twitterLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(twitterPatter,'Not a twitter url'),
    instagramLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(instagramPatten,'Not a instagram url'),
    linkedinLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(linkedinPatten,'Not a linkedIn url'),
}).required();