import Modal from 'react-bootstrap/Modal';
import { IDisplayToPostModal } from '../../organisations/createorganisation/interfaces/display-to-post-modal.interface';
import { IGroup } from '../../organisations/createorganisation/interfaces/group.interface';
import { IOrganizationRole } from '../../organisations/createorganisation/interfaces/organization-requested-members.interface';
import {useTranslation} from "react-i18next";


export const PostDisplayToModal: React.FC<IDisplayToPostModal> = (props) => {

    const { t } = useTranslation('', { keyPrefix: 'PostDisplayToModal' });
    const closeDisplayToModal = () => {
        props.closeModal(true)
    }

    return <Modal className="up-thememodal up-modalcreateupdates" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('displayTo')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formdisplayto">
                    <fieldset>
                        <ul className="up-reportoptions">
                            <li>
                                <div className="up-reportoption">
                                    <span>{t('public')}</span>
                                    <div className="up-togglebutton">
                                        <input checked={props.isPublicChecked} onChange={props.publicChangeHandler} type="checkbox" name="advance-option" id="up-displayToPublic" />
                                        <label htmlFor="up-displayToPublic"></label>
                                    </div>
                                </div>
                            </li>
                            {!props.hideRoles && <><div style={{fontWeight: "bold"}}>{t('userRoles')}</div>
                            {props.organizationRoles && props.organizationRoles!.map((role: IOrganizationRole, index: number) => {
                                return <li key={role._id}>
                                <div className="up-advanceoption">
                                <span>{role?.role}</span>
                                <div className="up-togglebutton">
                                <input onChange={(event: any): any => props.handleRoleSelection(event, role, index, 'ROLE')} checked={role.checked!} disabled={props.isPublicChecked} id={role._id} type="checkbox" name="advance-option" />
                                <label htmlFor={role._id}></label>
                                </div>
                                </div>
                                </li>
                            })}</>}
                            <div style={{fontWeight: "bold"}}>{t('userGroups')}</div>
                            {props.organizationGroups && props.organizationGroups.length > 0 && props.organizationGroups!.map((group: IGroup, index: number) => {
                                return <li key={group._id}>
                                    <div className="up-advanceoption">
                                        <span>{group.name}</span>
                                        <div className="up-togglebutton">
                                            <input onChange={(event: any): any => props.handleRoleSelection(event, group, index, 'GROUP')} checked={group.checked!} disabled={props.isPublicChecked} id={group._id} type="checkbox" name="advance-option" />
                                            <label htmlFor={group._id}></label>
                                        </div>
                                    </div>
                                </li>
                            })}
                            {props.organizationGroups && props.organizationGroups.length === 0 && <>{t('noUserGroups')}</>}
                        </ul>
                        <button onClick={closeDisplayToModal} type="button" className="up-btn up-btn-lg">{t('confirm')}</button>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}