import {Link} from "react-router-dom";
import {images} from "../../../assets/images";
import {useTranslation} from "react-i18next";

export const GdprPolicy = () => {
    const {t} = useTranslation('', {keyPrefix: 'GdprPolicy'});
    return (
        <div className={`up-wrapper`}>
            <header id="up-header" style={{'width': '100%'}} className="up-header">
                <div className="up-headercontent">
                    <strong className="up-logo">
                        <Link to="/home">
                            <img src={images.Logo} alt="Logo Image"/>
                        </Link>
                    </strong>
                </div>
            </header>
            <main className="up-main">
                <div className="up-termandconditionpage">
                    <div className="up-themebox">
                        <h1>{t('gdprPolicy')}</h1>
                        <div className="up-description">
                            <p>{t('welcome')}</p>
                            <p>{t('textOne')}</p>
                            <p>{t('textTwo')}</p>
                            <p>{t('textThree')}</p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}