import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { images } from "../../assets/images";
import { IUser } from "../../features/auth/pages/login/interface/user.interface";
import { getUser } from "../../features/auth/slice/user.selector";
import { IChat } from "../../features/chatmessages/interfaces/chat.interface";
import { routes } from "../../router/routes";
import { chatService } from "../../services/chat.service";
import { SideChatWindowComponent } from "./SideChatWindow";

export const SideChatComponent = (props: any) => {
  const [individualChats, setIndividualChats] = useState<IChat[]>([]);
  const authUser = useSelector(getUser);
  const [openChats, setOpenChats] = useState<IChat[]>([]);
  const location = useLocation();
  useEffect(() => {
      setTimeout(()=>{
          getIndividualChats();
      }, 5000)
  }, []);

  const getIndividualChats = async () => {
    const res = await chatService.getIndividualChat({ page: 1, limit: 6 });
    setIndividualChats(res.payload.chats.records);
  };

  const openChat = (chatId: string) => {
    const exist = openChats.some((chat) => chat._id == chatId);
    if (!exist) {
      const index = individualChats.findIndex((chat) => chat._id == chatId);
      setOpenChats([...openChats, individualChats[index]]);
    }
  };

  const closeChat = (chatId: string) => {
    const index = openChats.findIndex((chat) => chat._id == chatId);
    if (index > -1) {
      const oc = [...openChats];
      oc.splice(index, 1);
      setOpenChats([...oc]);
    }
  };

  const renderChatHeads = () => {
    return individualChats.map((chat) => {
      const receiver =
        (chat.participants![0] as IUser)._id == authUser._id
          ? (chat.participants![1] as IUser)
          : (chat.participants![0] as IUser);
      return (
        <li key={chat._id}>
          <figure
            className="up-chatusersimg"
            onClick={() => openChat(chat._id)}
          >
            <img
              src={receiver?.avatar ?? images.UserPlaceholder}
              alt="User Image"
            />
          </figure>
        </li>
      );
    });
  };

  if (location.pathname == routes.allChatPage) return null;
  return (
    <>
      <div className="up-chatwrapper">
        <button
          className="up-btnchat"
          type="button"
          onClick={() => props.handleToggle()}
        >
          <i className="icon-singlechat"></i>
        </button>
        <div className="up-onlinememberholder">
          <ul className="up-chatusers">
            {renderChatHeads()}

            {/* <li>
              <figure className="up-chatusersimg">
                <span className="up-onlinedot up-onlinecolor"></span>
                <img src={images.UserSix} alt="User Image" />
                <button className="up-btnaddtofriendlist" type="button">
                  <i className="icon-plus"></i>
                </button>
              </figure>
            </li> */}
            {/* <li>
              <figure className="up-chatusersimg up-onlinecolor">
                <span className="up-onlinedot"></span>
                <img src={images.UserSeven} alt="User Image" />
              </figure>
            </li> */}
          </ul>
        </div>
      </div>

      {openChats.map((chat) => (
        <SideChatWindowComponent
          key={`chat-${chat._id}`}
          chat={chat}
          closeChat={closeChat}
        />
      ))}
    </>
  );
};
