import { Link } from "react-router-dom";
import { images } from "../../../assets/images";
import {useTranslation} from "react-i18next";

export const TermsAndConditions = () => {
    const {t} = useTranslation('', {keyPrefix: 'TermsAndConditions'});
    return (
        <div className={`up-wrapper`}>
            <header id="up-header" style={{ 'width': '100%' }} className="up-header">
                <div className="up-headercontent">
                    <strong className="up-logo">
                        <Link to="/home">
                            <img src={images.Logo} alt="Logo Image" />
                        </Link>
                    </strong>
                </div>
            </header>
            <main className="up-main">
                <div className="up-termandconditionpage">
                    <div className="up-themebox">
                        <h1>{t('termsAndCondition')}</h1>
                        <div className="up-description">
                            <p>{t('welcome')}</p>
                            <p>{t('text1')}</p>
                            <p>{t('text2')}</p>
                            <h4>{t('text3')}</h4>
                            <p>{t('text4')}</p>
                            <h4>{t('text5')}</h4>
                            <p>{t('text6')}</p>
                            <h4>{t('text7')}</h4>
                            <p>{t('text8')}</p>
                            <h4>{t('text9')}</h4>
                            <ul>
                                <li>{t('text10')}</li>
                                <li>{t('text11')}</li>
                                <li>{t('text12')}</li>
                                <li>{t('text13')}</li>
                                <li>{t('text14')}</li>
                                <li>{t('text15')}</li>
                                <li>{t('text16')}</li>
                                <li>{t('text17')}</li>
                                <li>{t('text18')}</li>
                                <li>{t('text19')}</li>
                                <li>{t('text20')}</li>
                                <li>{t('text21')}</li>
                            </ul>
                            <h4>{t('text22')}</h4>
                            <p>{t('text23')}</p>
                            <h4>{t('text24')}</h4>
                            <p>{t('text25')}</p>
                            <p>{t('text26')}</p>
                            <p>{t('text27')} <h5>{t('text28')}</h5></p>
                            <h4>{t('text29')}</h4>
                            <p>{t('text30')}</p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}