import React, {useEffect, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {useDispatch, useSelector} from "react-redux";
import {images} from "../../../assets/images";
import {AWS_S3_BUCKET, s3} from "../../../config/aws.config";
import {messageService} from "../../../services/message.service";
import {getUser} from "../../auth/slice/user.selector";
import {EChatType} from "../enum/type.enum";
import {IMessage, IReaction} from "../interfaces/message.interface";
import {
  getActiveHead,
  getActiveType,
  getChatAdmins,
  getChatMedias,
  getChatMembers,
  getMessagePagination,
  getMessages,
  getMessagesLoader,
} from "../slice/chat.selector";
import {addMessage, loaderStartStop, addMessages} from "../slice/chat.slice";
import {deleteChat1v1, GetMessages, UpdateChatNotificationStatus,} from "../slice/chat.thunks";
import {S3} from "aws-sdk";
import {EBoolean} from "../../auth/pages/signup/interfaces/drop-down.enums";
import {IUser} from "../../auth/pages/login/interface/user.interface";
import {routes} from "../../../router/routes";
import {useHistory} from "react-router";
import Swal from "sweetalert2";
import Lightbox from "react-image-lightbox";
import {Mention, MentionsInput} from "react-mentions";
import {constructContent, constructMessage,} from "../../../utils/ConstructMessage";
import mentionsInputStyle from "./mentionsInputStyle";
import {useTranslation} from "react-i18next";
import {Toast} from "../../../utils/Toast";
import {ReactionsDetailModal} from "./ReactionsDetailModal";
import {MessageViewsDetailModal} from "./MessageViewDetailModal";
import {URLReplacer} from "../../../utils/url-replacer";

enum ESidebarClasses {
  SHOW_GROUP_INFO = "up-opengroupinfo",
  SHOW_MEMBERS = "up-openmembersidebar",
  SHOW_MEDIA = "up-openmediasidebar",
}
let timer: any;

export const MessageComponent = () => {
  const activeType = useSelector(getActiveType);
  const activeHead: any = useSelector(getActiveHead);
  const messages = useSelector(getMessages);
  const messagePagination = useSelector(getMessagePagination);
  const authUser = useSelector(getUser);
  const messagesLoader = useSelector(getMessagesLoader);
  const chatMembers = useSelector(getChatMembers);
  const chatAdmins: any = useSelector(getChatAdmins);
  const chatMedias = useSelector(getChatMedias);
  const [content, setContent] = useState<string>("");
  const [tags, setTags] = useState<any>([]);
  const [showAddMedia, setShowAddMedia] = useState<boolean>(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [selectedImageVideo, setSelectedImageVideo] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [viewDetailMessage, setViewDetailMessage] = useState<IMessage>();
  const [replyToMessage, setReplyToMessage] = useState<IMessage>();
  const [selectedReactions, setSelectedReactions] = useState<IReaction[]>();
  const [reactionModal, setReactionModal] = useState<boolean>();
  const [scrollToMessage, setScrollToMessage] = useState<IMessage>();
  const imageVideoRef = useRef<HTMLInputElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<ESidebarClasses | string>("");
  const [photoIndex, setPhotoIndex] = useState(0);
  const [tempMedia, setTempMedia] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('', { keyPrefix: 'MessageComponent' });

  const renderChatHeadArea = () => {
    return activeType == EChatType.ONE_TO_ONE
      ? renderIndividualChatHeader()
      : renderGroupChatHeader();
  };

  useEffect(() => {
    (async() => {
      const res: any =  await dispatch(
        GetMessages({ chatId: activeHead!._id, query: { page: 1, limit: 10 } })
      )
      const lastSeen = res.payload.payload.messages.records.reverse().find((item: any) => {
        return item.seenBy.find( (ele: any) => ele._id == authUser._id)
      })
      if (lastSeen && !timer){
        timer = setTimeout(() => {
          handleClickScroll(lastSeen)
        }, 1000);}
    })()
   
  }, [activeHead]);

  useEffect(() => {
    if (scrollToMessage) {
      const element = document.getElementById(`message-${scrollToMessage._id}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setScrollToMessage(undefined)
        if (timer){
          timer = null;
        }
      }
    }
  }, [messages]);

  const renderGroupChatHeader = () => {
    return (
      <div className="up-chatheadarea">
        <div className="up-chatuser">
          <figure className="up-chatusersimg">
            {/* <span className="up-onlinedot up-onlinecolor"></span> */}
            <img
              src={activeHead?.group?.avatar ?? images.RestPlaceholder}
              alt="User Image"
            />
          </figure>
          <div className="up-chatusercontent">
            <div className="up-chatusercontenthead">
              <h3>{activeHead?.group?.name}</h3>
              {/* <span>Fashion Craze</span> */}
            </div>
          </div>
        </div>
        <ul className="up-chatbtns">
          <li>
            <button
              className="up-btninfo"
              onClick={() => setShowSidebar(ESidebarClasses.SHOW_GROUP_INFO)}
              type="button"
            >
              <img src={images.InfoIcon} alt="Info Icon" />
            </button>
          </li>
        </ul>
      </div>
    );
  };

  const renderIndividualChatHeader = () => {
    const user =
      (activeHead?.participants![0] as IUser)._id == authUser._id
        ? (activeHead?.participants![1] as IUser)
        : (activeHead?.participants![0] as IUser);
    return (
      <div className="up-chatheadarea">
        <div className="up-chatuser">
          <figure className="up-chatusersimg">
            {/* <span className="up-onlinedot up-onlinecolor"></span> */}
            <img src={user.avatar ?? images.UserPlaceholder} alt="User Image" />
          </figure>
          <div className="up-chatusercontent">
            <div className="up-chatusercontenthead">
              <h3>{user.username}</h3>
              {/* <span>Fashion Craze</span> */}
            </div>
          </div>
        </div>
        <ul className="up-chatbtns">
          <li>
            <button
              className="up-btninfo"
              onClick={() => setShowSidebar(ESidebarClasses.SHOW_GROUP_INFO)}
              type="button"
            >
              <img src={images.InfoIcon} alt="Info Icon" />
            </button>
          </li>
        </ul>
      </div>
    );
  };

  const messageLinkRedirectionHandler = (link?: string) => {
    if (link && window) {
      (window as any).open(`post-detail/${link}`, "_blank").focus();
    }
  };
  const openImages = (index: number, mediaArray: any) => {
    setTempMedia(mediaArray);
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const openProfile = (sender: IUser) => {
    history.push(`/memberprofilepage/${sender._id}`);
  };
  const handleClickScroll = async (item: IMessage ) => {
    const element = document.getElementById(`message-${item._id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      if (timer){
        timer = null;
      }
    }else{
      dispatch(loaderStartStop(true));
      setScrollToMessage(item)
      let messages: any[] = [];
      let currentPagination = {...messagePagination}
      let found = false;
      do {
        const res = await messageService.getMessages(activeHead!._id, { page: currentPagination.currentPage + 1, limit: 10 });
        if (res.statusCode == 200){
          messages = [...res.payload.messages.records,...messages];
          currentPagination = res.payload.messages.paginationInfo;
          const init = res.payload.messages.records.find(mes => mes._id == item._id);
          if (init){
            dispatch(addMessages({messages, currentPagination}));
            found = true;
          }
        }else {
          dispatch(loaderStartStop(false));
          Toast.fire({
            icon: 'warning',
            title: res.message
          })
        }
      } while (!found)
    }
  };

  const addReaction = (event: any, message: IMessage, reaction: string) => {
    dispatch(loaderStartStop(true));
    if (event.target.className.includes('disabled')) {
      const option = message.reactions.find(item => item.reaction === reaction && item.sender._id === authUser._id)
      option && messageService.removeReaction(option._id)
    } else {
      messageService.addReaction({
        message: message._id,
        reaction
      });
    }
  }

  const openReactionDetail = (reactions: Array<IReaction>) => {
    setSelectedReactions(reactions);
    setReactionModal(true);
  }
  const closeReactionModal = () => {
    setSelectedReactions(undefined);
    setReactionModal(false);
  }
  const closeViewDetailModal = () => {
    setViewDetailMessage(undefined);
  }
  const renderMessages = () => {
    return messages?.map((message: IMessage) => (
      <div
        key={`message-${message._id}`}
        id={`message-${message._id}`}
        className={`${
          message?.sender?._id == authUser._id
            ? "up-message up-recievermessage"
            : "up-message up-sendermessage"
        }`}
        onClick={() => messageLinkRedirectionHandler(message.link)}
      >
        <figure
          style={{ cursor: "pointer" }}
          className="up-userimg"
          onClick={() => openProfile(message.sender)}
        >
          <img
            src={message.sender.avatar ?? images.UserPlaceholder}
            alt="User Image"
          />
        </figure>
        <div className="up-messagecontentholder">
            <div className="up-messagereplyinfoiconsholder"><div className="ct-messagedataholder up-messagereactionhover">
            {message?.replyTo && <div className="up-messagereply">
              <span onClick={() => handleClickScroll(message?.replyTo!)} >{constructMessage(message?.replyTo?.content ?? '', message?.replyTo.tags) === '' ? (message?.replyTo.mediaType === 'FILE' ? (message?.replyTo?.medias?.length && message?.replyTo?.medias[0]!.split('postFile')[1]?.replace('-','')?.replace(/%20/g, " ")) : t('media')) : constructMessage(message?.replyTo?.content ?? '', message?.replyTo.tags)}</span>
              {message?.replyTo.mediaType === 'IMAGE' && message?.replyTo.medias && message?.replyTo.medias?.length > 0 && <div className="up-mediareplaygrid">
                {message?.replyTo.medias[0].toString().includes("postVideo") &&
                    <figure className="up-chatfileimage up-videofile">
                      <video key={message?.replyTo.medias[0]} controls>
                        <source src={message?.replyTo.medias[0]}/>
                        {" "}
                      </video>
                    </figure>}
                {message?.replyTo.medias[0].toString().includes("postImage") && <figure
                    onClick={() => openImages(0, message?.replyTo!.medias)}
                    className="up-chatfileimage"
                >
                  <img src={message?.replyTo.medias[0]} alt="Chat FIle"/>
                </figure>}</div>}
              {message?.replyTo.mediaType == "FILE" && message?.replyTo.medias && message?.replyTo.medias?.length > 0 && <div className="up-mediareplaygrid">
                {message?.replyTo.medias[0].toString().includes("postFile") && <figure className="up-chatfileimage">
                  <i className="icon-file"/>
                </figure>}
              </div>}
            </div>}
            <div className="up-chatfilesholder">
              {message.medias?.map((media: string, index: number) => (
                  <div
                      key={media}
                      className={`up-chatfile ${
                          message.mediaType == "IMAGE" ? "" : "up-chatfileicon"
                      }`}
                  >
                    {message.mediaType == "FILE" &&
                        media.toString().includes("postFile") && <figure className="up-chatfileimage">
                          <a href={media} target="_blank" className="up-fileiconwithname">
                            <span>{media.split('postFile')[1]?.replace('-','')?.replace(/%20/g, " ")}</span>
                            <i className="icon-file"/>
                          </a>
                        </figure>}
                    {message.mediaType == "IMAGE" &&
                        media.toString().includes("postVideo") && <figure className="up-chatfileimage up-videofile">
                          <video key={media} controls>
                            <source src={media}/>
                            {" "}
                          </video>
                        </figure>}
                    {message.mediaType == "IMAGE" &&
                        !media.toString().includes("postVideo") && <figure
                            onClick={() => openImages(index, message.medias)}
                            className="up-chatfileimage"
                        >
                          <img src={media} alt="Chat FIle"/>
                        </figure>}
                    {isOpen && (
                        <Lightbox
                            mainSrc={tempMedia[photoIndex]}
                            enableZoom={false}
                            nextSrc={tempMedia[(photoIndex + 1) % tempMedia.length]}
                            prevSrc={
                              tempMedia[
                              (photoIndex + tempMedia.length - 1) % tempMedia.length
                                  ]
                            }
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() =>
                                setPhotoIndex(
                                    (photoIndex + tempMedia.length - 1) % tempMedia.length
                                )
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % tempMedia.length)
                            }
                        />
                    )}
                  </div>
              ))}
            </div>
            <p dangerouslySetInnerHTML={{ __html: constructMessage(message?.content || '', message.tags).replace(/(?:\r\n|\r|\n)/g, '<br>')}}>
            </p>
            <ul className="up-replyhover">
              <li>
                <span style={{ cursor: 'pointer' }} className={message.reactions.find(item => item.reaction === '👍' && item.sender._id === authUser._id) ? 'disabled': ''} onClick={(e) => addReaction(e,message,'👍')}>👍</span>
              </li>
              <li>
                <span style={{ cursor: 'pointer' }} className={message.reactions.find(item => item.reaction === '👎' && item.sender._id === authUser._id) ? 'disabled': ''} onClick={(e) => addReaction(e,message,'👎')}>👎</span>
              </li>
              <li>
                <span style={{ cursor: 'pointer' }} className={message.reactions.find(item => item.reaction === '❤️️' && item.sender._id === authUser._id) ? 'disabled': ''} onClick={(e) => addReaction(e,message,'❤️️')}>❤️️️</span>
              </li>
              <li>
                <span style={{ cursor: 'pointer' }} className={message.reactions.find(item => item.reaction === '😂' && item.sender._id === authUser._id) ? 'disabled': ''} onClick={(e) => addReaction(e,message,'😂')}>😂</span>
              </li>
            </ul>
            </div>
            <ul className="up-replyinfoicon">
              <li>
                <span onClick={() => setReplyToMessage(message)} className="up-iconreplymessage">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M205 34.8c11.5 5.1 19 16.6 19 29.2v64H336c97.2 0 176 78.8 176 176c0 113.3-81.5 163.9-100.2 174.1c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-7.5 4.3-14.4 9.8-19.5c9.4-8.8 22.2-26.4 22.2-56.7c0-53-43-96-96-96H224v64c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4z"/></svg>
                </span>
              </li>
              {message.sender._id === authUser._id && <li>
                <button onClick={() => setViewDetailMessage(message)} className="up-btninfoicon"><i
                    className="icon-info"></i></button>
              </li>}
            </ul>
              </div>
            {message.reactions.length > 0 && <span className="up-reactiononmessgae"><span onClick={() => openReactionDetail(message.reactions)}>{message.reactions.map(item => item.reaction)}</span></span>}
          <time dateTime="2022-12-12">
            {new Intl.DateTimeFormat("en-GB", {
              dateStyle: "medium",
              timeStyle: "short",
            }).format(new Date(message.createdAt!))}
          </time>
        </div>
        {/* IN CHAT FILE AREA START */}
        <div className="up-chatfilesarea">
            <div className="up-messagereplyinfoiconsholder">
              <div className="up-messagereactionhover up-fileswidthcalc">
                <ul className="up-replyhover">
                  <li>
                    <span style={{cursor: 'pointer'}} className={message.reactions.find(item => item.reaction === '👍' && item.sender._id === authUser._id) ? 'disabled' : ''} onClick={(e) => addReaction(e, message, '👍')}>👍</span>
                  </li>
                  <li>
                    <span style={{cursor: 'pointer'}} className={message.reactions.find(item => item.reaction === '👎' && item.sender._id === authUser._id) ? 'disabled' : ''} onClick={(e) => addReaction(e, message, '👎')}>👎</span>
                  </li>
                  <li>
                    <span style={{cursor: 'pointer'}} className={message.reactions.find(item => item.reaction === '❤️️' && item.sender._id === authUser._id) ? 'disabled' : ''} onClick={(e) => addReaction(e, message, '❤️️')}>❤️️️</span>
                  </li>
                  <li>
                    <span style={{cursor: 'pointer'}} className={message.reactions.find(item => item.reaction === '😂' && item.sender._id === authUser._id) ? 'disabled' : ''} onClick={(e) => addReaction(e, message, '😂')}>😂</span>
                  </li>
                </ul>
              </div>
              <ul className="up-replyinfoicon">
                <li>
                  <span onClick={() => setReplyToMessage(message)} className="up-iconreplymessage">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M205 34.8c11.5 5.1 19 16.6 19 29.2v64H336c97.2 0 176 78.8 176 176c0 113.3-81.5 163.9-100.2 174.1c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-7.5 4.3-14.4 9.8-19.5c9.4-8.8 22.2-26.4 22.2-56.7c0-53-43-96-96-96H224v64c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4z"/></svg>
                  </span>
                </li>
                {message.sender._id === authUser._id && <li>
                  <button onClick={() => setViewDetailMessage(message)} className="up-btninfoicon"><i
                      className="icon-info"></i></button>
                </li>}
              </ul>
            </div>
        </div>
        {/* IN CHAT FILE AREA End */}
      </div>
    ));
  };

  const msgStructure = (
    content: string,
    medias?: string[],
    mediaType?: string
  ) => {
    return {
      _id: Math.random().toString(),
      chat: activeHead!._id,
      sender: authUser,
      content: constructContent(content),
      medias: medias,
      mediaType: mediaType,
      createdAt: new Date().toString(),
      updatedAt: new Date().toString(),
      tags: tags,
      reactions: [],
      replyTo: replyToMessage ? replyToMessage : undefined
    };
  };

  const sendMessage = async () => {
    setIsSubmit(true);
    dispatch(loaderStartStop(true));
    const uploadedMedia: string[] = [];
    let mediaType: string = "";
    if (selectedImageVideo) {
      for (const item of selectedImageVideo) {
        let params: any = {};
        if (item.type.split("/")[0] === "video") {
          params = {
            Body: item,
            Bucket: AWS_S3_BUCKET!,
            Key: `${parseInt(
                (new Date().getTime() / 1000).toFixed(0)
            )}-postVideo-${item.name}`,
            ContentType: item.type,
            ACL: "public-read",
          };
        } else {
          params = {
            Body: item,
            Bucket: AWS_S3_BUCKET!,
            Key: `${parseInt(
                (new Date().getTime() / 1000).toFixed(0)
            )}-postImage-${item.name}`,
            ContentType: item.type,
            ACL: "public-read",
          };
        }
        const uploadResult: any = await s3.upload(params).promise();
        uploadedMedia.push(uploadResult.Location);
      }
      mediaType = "IMAGE";
    }
    if (selectedFile) {
      for (const item of selectedFile) {
        const params: S3.PutObjectRequest = {
          Body: item,
          Bucket: AWS_S3_BUCKET!,
          Key: `${parseInt(
              (new Date().getTime() / 1000).toFixed(0)
          )}-postFile-${item.name}`,
          ContentType: item.type,
          ACL: "public-read",
        };
        const uploadResult: any = await s3.upload(params).promise();
        uploadedMedia.push(uploadResult.Location);
      }
      mediaType = "FILE";
    }
    const message: IMessage = msgStructure(content, uploadedMedia, mediaType);
    dispatch(addMessage(message));
    setContent("");
    const params: any = {
      content: constructContent(content),
      tags: tags.map((tag: any) => tag._id),
      chat: activeHead!._id,
      replyTo: replyToMessage ? replyToMessage._id : undefined
    }
    if (uploadedMedia.length > 0 ){
      params.mediaType = mediaType;
      params.medias = uploadedMedia;
    }
    await messageService.createMessage(params);
    setReplyToMessage(undefined)
    setTags([]);
    setIsSubmit(false);
    dispatch(loaderStartStop(false));
    closeMediaModal()
    scrollToBottom()
  };

  const imageVideoChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsFileSelected(true);
      const tempFiles: any = [];
      for (const file of e.target.files) {
        tempFiles.push(file);
      }
      if (selectedImageVideo) {
        setSelectedImageVideo([...selectedImageVideo, ...tempFiles]);
      } else {
        setSelectedImageVideo([...tempFiles]);
      }
    }
  };

  const fileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsFileSelected(true);
      const tempFiles: any = [];
      for (const file of e.target.files) {
        tempFiles.push(file);
      }
      if (selectedFile) {
        setSelectedFile([...selectedFile, ...tempFiles]);
      } else {
        setSelectedFile([...tempFiles]);
      }
    }
  };

  const onImageVideoClick = () => {
    if (imageVideoRef.current) {
      imageVideoRef.current.click();
    }
  };

  const onFileClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const removeSelectedFile = (index: number) => {
    selectedFile.splice(index, 1);
    if (selectedFile.length == 0) {
      setIsFileSelected(false);
      setSelectedFile(undefined);
    } else {
      setSelectedFile([...selectedFile]);
    }
  };
  const removeSelectedImageVideo = (index: number) => {
    selectedImageVideo.splice(index, 1);
    if (selectedImageVideo.length == 0) {
      setIsFileSelected(false);
      setSelectedImageVideo(undefined);
    } else {
      setSelectedImageVideo([...selectedImageVideo]);
    }
  };

  const closeMediaModal = () => {
    setShowAddMedia(false);
    setIsFileSelected(false);
    setSelectedImageVideo(null);
    setSelectedFile(null);
  };
  const scrollToBottom = () => {
    const messageHolder = document.getElementById("messageFolder");
      messageHolder!.scrollTo({
        top: messageHolder!.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
  }

  const renderAdmins = () => {
    return chatAdmins?.map((a: any) => (
      <div className="up-member" key={a.member._id}>
        <figure className="up-memberimg">
          <img
            src={a.member.avatar ?? images.UserPlaceholder}
            alt="Member Image"
          />
        </figure>
        <div className="up-membername">
          <h4>{a.member.name}</h4>
          <div className="up-countadmin">
            <h5>{t('admin')}</h5>
            {/* <h6>23</h6> */}
          </div>
        </div>
      </div>
    ));
  };

  const renderMembers = () => {
    return chatMembers?.map((m: any) => (
      <div className="up-member" key={m.member._id}>
        <figure className="up-memberimg">
          <img
            src={m.member.avatar ?? images.UserPlaceholder}
            alt="Member Image"
          />
        </figure>
        <div className="up-membername">
          <h4>{m.member.name}</h4>
        </div>
      </div>
    ));
  };

  const renderFiles = () => {
    return chatMedias!.map((m: IMessage) => {
      if (m.mediaType == "File") {
        return m.medias?.map((url: string) => (
          <a target={"_blank"} href={url}>
            {t('url')}
          </a>
        ));
      } else {
        return null;
      }
    });
  };

  const renderImages = () => {
    return chatMedias!.map((m: IMessage) => {
      if (m.mediaType == "IMAGE") {
        return m.medias?.map((url: string, index: number) => {
          if (url.toString().includes("postVideo")) {
            return (
              <figure className="up-chatfileimage up-videofile">
                <video key={url} controls>
                  <source src={url} />{" "}
                </video>
              </figure>
            );
          }
          if (!url.toString().includes("postVideo")) {
            return (
              <figure
                onClick={() => openImages(index, m.medias)}
                className="up-chatmediaimage"
              >
                <img src={url} alt="Chat Image" />
              </figure>
            );
          }
        });
      } else {
        return null;
      }
    });
  };

  const deleteChat = async () => {
    Swal.fire({
      title: t('deleteChat'),
      text: t('areYouSure'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: t('delete'),
      cancelButtonText: t('cancel'),
    }).then(async (result: any) => {
      if (result) {
        if (result.isConfirmed) {
          await dispatch(deleteChat1v1(activeHead!._id));
          history.push(`${routes.allChatPage}?tab=${EChatType.ONE_TO_ONE}`);
        }
      }
    });
  };

  const viewMentionDataHandler = (e: string) => {
    let data: any = [];
    if (activeType == EChatType.ONE_TO_ONE) {
      data = [
        (activeHead?.participants![0] as IUser)._id == authUser._id
          ? {
              id: (activeHead?.participants![1] as IUser)._id,
              display: (activeHead?.participants![1] as IUser).name,
            }
          : {
              id: (activeHead?.participants![0] as IUser)._id,
              display: (activeHead?.participants![0] as IUser).name,
            },
      ];
    } else {
      if (chatAdmins.length > 0) {
        data = [
          ...chatAdmins?.map((admin: any) => ({
            id: admin.member._id,
            display: admin.member.name,
          })),
        ];
      }
      if (chatMembers && chatMembers?.length > 0) {
        data = [
          ...data,
          ...chatMembers?.map((member: any) => ({
            id: member.member._id,
            display: member.member.name,
          })),
        ];
      }
    }
    return data;
  };
  const messageOnChangeHandler = (e: any) => {
    const value = e.target.value;
    const regex = /\@\[([^\]]+)\]\(([^)]+)\)/g;
    const matches = [...value.matchAll(regex)];
    const mentions = matches.map((match) => {
      const [_, display, _id] = match;
      return { _id, name: display };
    });
    let uniqueTags: any = new Map();
    [...mentions].forEach((item) => {
      uniqueTags.set(item._id, item);
    });
    setTags([...uniqueTags.values()]);

    setContent(e.target.value);
  };
  const renderLoadMoreBtn = () => {
    if (messagePagination.currentPage == messagePagination.pages || messagePagination.pages <= 1) return null

    return (<div className="up-btncontentholder"><button className="up-btn" onClick={() => {
      if (messages) {
        setScrollToMessage(messages[0]);
      }
      dispatch(
        GetMessages({ chatId: activeHead!._id, query: { page: messagePagination.currentPage + 1, limit: 10 } })
      );
    }}>{t('loadMore')}</button></div>)
  }
  
  // if (messagesLoader) return null;
  return (
    <>
      {/* No Chat Selected End */}
      <div className={`up-chatmessagearea ${showSidebar}`}>
        {/* Loader for chat Start  For Message full screen*/}
        {messagesLoader && (
          <div className="up-fullloader up-smallloader">
            <div className="lds-dual-ring"></div>
          </div>
        )}

        {/* Loader for chat End */}
        {renderChatHeadArea()}
        <div className="up-chatmessagesholder" id="messageFolder">
          {renderLoadMoreBtn()}
          {renderMessages()}
        </div>
        {(chatAdmins!.filter((item: any) => item.member._id == authUser._id)
          .length > 0 || activeHead?.group?.canMemberChat) !== EBoolean.NO && (
          <div className="up-writemessagearea">
            <form className="up-formtheme up-formwritemessages">
              <fieldset>
                <div className="up-previewimagesarea">
                    {selectedImageVideo && (
                      <>
                        {selectedImageVideo.map((item: any, index: number) => {
                          return (
                            <div key={index}>
                              {item.type.split("/")[0] === "image" && (
                                <img
                                  src={URL.createObjectURL(item)}
                                  alt="Post Image"
                                />
                              )}
                              {item.type.split("/")[0] === "video" && (
                                <video width="400" controls>
                                  <source src={URL.createObjectURL(item)} />{" "}
                                </video>
                              )}
                              <button
                                className="up-btndelete"
                                disabled={isSubmit}
                                onClick={() => removeSelectedImageVideo(index)}
                                type="button"
                              >
                                <i className="icon-bin2" />
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}
                    {selectedFile && (
                      <>
                        {selectedFile.map((item: any, index: number) => {
                          return (
                            <div className="up-filepreiview" key={index}>
                              <i className="icon-file" />
                              <span>{item.name}</span>
                              <button
                                className="up-btndelete"
                                disabled={isSubmit}
                                onClick={() => removeSelectedFile(index)}
                                type="button"
                              >
                                <i className="icon-bin2" />
                              </button>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                <div className="form-group">

                  {replyToMessage && <>
                    <div className="up-replymessageholder up-replayimageholder">
                    <div className="up-replymessagecrossicon">
                      <span>{constructMessage(replyToMessage?.content ?? '', replyToMessage?.tags) === '' ? (replyToMessage?.mediaType === 'FILE' ? (replyToMessage?.medias?.length && replyToMessage?.medias[0]!.split('postFile')[1]?.replace('-','')?.replace(/%20/g, " ")) : t('media')) : constructMessage(replyToMessage?.content ?? '', replyToMessage?.tags)}</span>
                      <button className="up-btncrossreply" onClick={() => setReplyToMessage(undefined)}><i
                          className="icon-cross"></i></button>
                    
                      {replyToMessage.mediaType === 'IMAGE' && replyToMessage.medias && replyToMessage.medias?.length > 0 && <div className="up-mediareplaygrid">
                        {replyToMessage.medias[0].toString().includes("postVideo") &&
                            <figure className="up-chatfileimage up-videofile">
                              <video key={replyToMessage.medias[0]} controls>
                                <source src={replyToMessage.medias[0]}/>
                                {" "}
                              </video>
                            </figure>}
                        {replyToMessage.medias[0].toString().includes("postImage") && <figure
                            onClick={() => openImages(0, replyToMessage.medias)}
                            className="up-chatfileimage"
                        >
                          <img src={replyToMessage.medias[0]} alt="Chat FIle"/>
                        </figure>}</div>}
                      {replyToMessage.mediaType == "FILE" && replyToMessage.medias && replyToMessage.medias?.length > 0 && <div className="up-mediareplaygrid">
                        {replyToMessage.medias[0].toString().includes("postFile") && <figure className="up-chatfileimage">
                          <i className="icon-file"/>
                          </figure>}
                      </div>}
                        </div>
                    </div>
                  </>}
                  {!selectedImageVideo && <div className="up-uploadfiles">
                    <input
                        accept=".doc,.docx,.xml,.pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        onChange={fileChange}
                        ref={fileRef}
                        type="file"
                        name="select-files"
                        id="up-upload-file"
                        multiple
                    />
                    <label htmlFor="up-upload-file" className="up-addbutton up-addattacmentbutton">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path
                            d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"/>
                      </svg>
                    </label>
                  </div>}
                  {!selectedFile && <div className="up-uploadfiles">
                    <input
                        accept="image/*, video/*"
                        onChange={imageVideoChange}
                        type="file"
                        name="select-files"
                        id="up-upload-img-video"
                        multiple
                    />
                    <label htmlFor="up-upload-img-video"
                           className="up-addbutton up-addattacmentbutton up-addfileicononchat">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path
                            d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/>
                      </svg>
                    </label>
                  </div>}
                    
                  <MentionsInput
                    placeholder={t('writeHere')}
                    value={content}
                    className="up-testclass"
                    onChange={messageOnChangeHandler}
                    style={mentionsInputStyle}
                  >
                    <Mention
                      trigger="@"
                      className="up-testclasscontent"
                      data={viewMentionDataHandler}
                    />
                  </MentionsInput>
                  <button
                    className="up-btnsendmessage up-btnsendchat"
                    type="button"
                    disabled={messagesLoader || isSubmit}
                    onClick={sendMessage}
                  >
                    {t('send')}
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        )}

        <div className="up-messageinfosidebar up-gourpinfisidebar">
          <div className="up-messageinfosidebarhead">
            <button
              className="up-closesidebarinfo"
              type="button"
              onClick={() => setShowSidebar("")}
            >
              <i className="icon-cross" />
            </button>
            <div className="up-infosidebarheadcontent">
              <h3>
                {activeType == EChatType.ONE_TO_ONE
                  ? t('chatInformation')
                  : t('groupInformation')}
              </h3>
              <span>
                {t('createdOn')}
                {new Intl.DateTimeFormat("en-GB", {
                  dateStyle: "medium",
                  timeStyle: "short",
                }).format(new Date(activeHead!.createdAt!))}
              </span>
            </div>
          </div>
          <div className="up-chatsidebarcontent">
            <ul>
              {activeType == EChatType.GROUP && (
                <li>
                  <button
                    className="up-btnsidebar"
                    type="button"
                    onClick={() => setShowSidebar(ESidebarClasses.SHOW_MEMBERS)}
                  >
                    {t('members')}
                  </button>
                  <span className="up-goupcount">
                    {chatMembers!.length + chatAdmins!.length}
                  </span>
                </li>
              )}

              <li>
                <button
                  className="up-btnsidebar"
                  type="button"
                  onClick={() => setShowSidebar(ESidebarClasses.SHOW_MEDIA)}
                >
                  {t('media')}
                </button>
                <span className="up-goupcount">{chatMedias?.length}</span>
              </li>
              <li>
                <button className="up-btnsidebar" type="button">
                  {t('notification')}
                </button>
                <div className="up-notificationwsitch">
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="up-notificationswitch"
                    value={EBoolean.YES}
                    checked={
                      !(activeHead!.muteNotification as string[]).includes(
                        authUser._id
                      )
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(
                        UpdateChatNotificationStatus({
                          chatId: activeHead!._id,
                          body: {
                            status: e.target.checked
                              ? EBoolean.YES
                              : EBoolean.NO,
                          },
                          userId: authUser._id,
                        })
                      );
                    }}
                  />
                  <label htmlFor="up-notificationswitch"></label>
                </div>
              </li>
              {activeType == EChatType.ONE_TO_ONE && (
                <li>
                  <button
                    className="up-btnsidebar"
                    type="button"
                    onClick={deleteChat}
                  >
                    {t('deleteChat')}
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className="up-messageinfosidebar up-memberssidebar">
          <div className="up-messageinfosidebarhead">
            <button
              className="up-closesidebarinfo"
              type="button"
              onClick={() => setShowSidebar(ESidebarClasses.SHOW_GROUP_INFO)}
            >
              <i className="icon-arrow" />
            </button>
            <div className="up-infosidebarheadcontent">
              <h3>{t('members')}</h3>
              <span className="up-goupcount">
                {chatMembers!.length + chatAdmins!.length}
              </span>
            </div>
          </div>
          <div className="up-chatsidebarcontent">
            <div className="up-membersarea">
              {renderAdmins()}
              {renderMembers()}
            </div>
          </div>
        </div>

        <div className="up-messageinfosidebar up-mediatabssidebar">
          <div className="up-messageinfosidebarhead">
            <button
              className="up-closesidebarinfo"
              type="button"
              onClick={() => setShowSidebar(ESidebarClasses.SHOW_GROUP_INFO)}
            >
              <i className="icon-arrow" />
            </button>
            <div className="up-infosidebarheadcontent">
              <h3>{t('media')}</h3>
              <span className="up-goupcount">{chatMedias?.length}</span>
            </div>
          </div>
          <div className="up-chatsidebarcontent">
            <Tabs
              defaultActiveKey="media"
              id="uncontrolled-tab-example"
              className="up-mediasidebartabs"
            >
              <Tab eventKey="media" title={t('media')}>
                <div className="up-allmediaholder">
                  <div className="up-allmedia">
                    <div className="up-chatmedias">{renderImages()}</div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="docs" title={t('docs')}>
                {renderFiles()}
              </Tab>
            </Tabs>
          </div>
        </div>
        {reactionModal && <ReactionsDetailModal reactions={selectedReactions || []} closeModal={closeReactionModal} />}
        {viewDetailMessage && <MessageViewsDetailModal message={viewDetailMessage} closeModal={closeViewDetailModal} />}
      </div>
    </>
  );
};
