import * as yup from "yup";

export const postCommentSchema = yup.object().shape({
    content: yup.string().required('Comment cannot be empty'),
}).required();

export const textSurveySchema = yup.object().shape({
    optionValue: yup.string().required(),
}).required();
export const dateSurveySchema = yup.object().shape({
    date: yup.string().required(),
    startTime: yup.string().required(),
    endTime: yup.string().required(),
}).required();