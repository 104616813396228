import { IResponseInterface } from "../interfaces/api-response.interface";
import { HttpService } from "./base.service";

class OrganisationShortcutService extends HttpService {
  private readonly prefix: string = "organization-shortcut";

  getShortcuts = (id: string): Promise<IResponseInterface<{ }>> => this.get(`${this.prefix}/organization/${id}?page=1&limit=1000`)

  getMyShortcuts = (): Promise<IResponseInterface<{ }>> => this.get(`${this.prefix}/my?page=1&limit=1000`)

  createShortcut = (data: any): Promise<IResponseInterface<{ }>> => this.post(`${this.prefix}`, data)

  updateShortcut = (id: string, data: any): Promise<IResponseInterface<{ }>> => this.put(`${this.prefix}/${id}`, data)

  deleteShortcut = (id: string): Promise<IResponseInterface<{ }>> => this.delete(`${this.prefix}/${id}`)

}
export const shortcutService = new OrganisationShortcutService();