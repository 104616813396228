import { IMessage } from "../features/chatmessages/interfaces/message.interface";
import { IResponseInterface } from "../interfaces/api-response.interface";
import { IPagination } from "../interfaces/pagination-info.interface";
import { IQuery } from "../interfaces/query.interface";
import { HttpService } from "./base.service";

class MessageService extends HttpService {
  private readonly prefix: string = "message";

  /**
   * @description get chat messages
   * @param chatId 
   * @param query 
   * @returns 
   */
  getMessages = (chatId: string, query: IQuery): Promise<IResponseInterface<{ messages: IPagination<IMessage[]> }>> => this.get(`${this.prefix}/chat/${chatId}`, query);

  createMessage = (data: any): Promise<IResponseInterface<{ message: IMessage }>> => this.post(`${this.prefix}`, data)
  addReaction = (data: any): Promise<IResponseInterface<{}>> => this.post(`message-reaction`,data);

  removeReaction = (messageId: string): Promise<IResponseInterface<{}>> => this.delete(`message-reaction/${messageId}`);
}
export const messageService = new MessageService();