import React, {useState} from "react";
import {images} from "../../assets/images";
import {NavLink, useHistory} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {useDispatch} from "react-redux";

import DashboardIcon from "../../assets/images/d-icon/dashboard.svg";

import Logo from "../../assets/images/d-icon/logo.svg";
import {setLogout} from "../../features/auth/slice/user.slice";
import {useTranslation} from "react-i18next";

export const AdminDashboardLayout: React.FC = (props) => {
  const [sideBarToggle, setSideBarToggle] = useState(false);
  const [rightSideChatToggle, setRightSideChatToggle] = useState(false);
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const dispatch = useDispatch()
  const { t } = useTranslation('', { keyPrefix: 'AdminDashboardLayout' });

  return (
    <div
      className={`up-wrapper up-wrappertwo ${sideBarToggle ? "up-openclose-sidebar-menu" : ""
        } ${rightSideChatToggle ? "up-opensidebarchat" : ""} `}
    >
      <header id="up-header" className="up-header up-headerdashboard">
        {/* <div className="up-headercontent">
          <h2>{props?.orgName?.replace(/%20/g, " ") || ''}</h2>
        </div> */}
        <span className="up-menuiconholder">
          <button
            onClick={() => setSideBarToggle(!sideBarToggle)}
            className="up-btnmenuicon"
            type="button"
          >
            <img src={images.MenuIcon} alt="Menu Icon" />
          </button>
        </span>
      </header>
      <div className="up-sidebarwrapper up-sidebardashboard">
        <span className="up-menuiconholder">
          <button
            onClick={() => setSideBarToggle(!sideBarToggle)}
            className="up-btnmenuicon"
            type="button"
          >
            <img src={images.MenuIcon} alt="Menu Icon" />
          </button>
        </span>
        <strong className="up-logo">
          {/* <Link to={`/organization-profile/${props.id}`}> */}
            <img src={Logo} alt="Logo Image" />
          {/* </Link> */}
        </strong>
        <nav className="navigation">
          <ul className="nav_list">
            
            <li className="list-item" data-tooltip="User Data">
              <NavLink className={isActive =>
                "nav-link" + (isActive ? " up-active" : "")
              } to={{ pathname: `/admin/dashboard`}}>
                <img src={DashboardIcon} alt="user icon" />
                <em>{t('organizations')}</em>
              </NavLink>
            </li>
            <li className="list-item" data-tooltip="User Data">
              <a className="nav-link" type="button" onClick={() => {
                dispatch(setLogout());
                history.push('/');
              }}>
                <em>{t('Logout')}</em>
                </a>
            </li>
          </ul>
        </nav>
      </div>
      <main className="up-main">{props.children}</main>


      <Modal
        className="up-thememodal up-modalreport"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="up-modalheader" closeButton>
          <Modal.Title>Change Language</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
          <div className="up-modalcontentholder">
            <form className="up-formtheme up-formreport">
              <fieldset>
                <ul className="up-reportoptions">
                  <li>
                    <div className="up-reportoption">
                      <span>English</span>
                      <div className="up-radio">
                        <input
                          type="radio"
                          name="report-option"
                          id="up-optionone"
                        />
                        <label htmlFor="up-optionone"></label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="up-reportoption">
                      <span>Dutch</span>
                      <div className="up-radio">
                        <input
                          type="radio"
                          name="report-option"
                          id="up-optiontwo"
                        />
                        <label htmlFor="up-optiontwo"></label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="up-reportoption">
                      <span>French</span>
                      <div className="up-radio">
                        <input
                          type="radio"
                          name="report-option"
                          id="up-optionthree"
                        />
                        <label htmlFor="up-optionthree"></label>
                      </div>
                    </div>
                  </li>
                </ul>
              </fieldset>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
