import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm, useFieldArray } from 'react-hook-form';
import { externalSurveySchema, internalSurveySchema } from '../validations/external-survey.validation';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { EPostCategory } from '../../../interfaces/post-category.interface';
import { EBoolean } from '../../auth/pages/signup/interfaces/drop-down.enums';
import { EPollResultType, EPollType } from '../../../interfaces/post-type.interface';
import moment from 'moment';
import {useTranslation} from "react-i18next";

export const CreatePollModal: React.FC<any> = (props) => {

    const [startDate, setStartDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState('Select Survey Type');
    const [internalOption, setInternalOption] = useState('Select Internal Survey Type');
    const { register: externalRegister, handleSubmit: externalSubmit, formState: { errors: externalErrors } } = useForm({
        resolver: yupResolver(externalSurveySchema)
    });
    const { control, register: internalRegister, handleSubmit: internalSubmit, formState: { errors: internalErrors } } = useForm({
        resolver: yupResolver(internalSurveySchema)
    });
    const { fields: textFields, append: textAppend, remove: textRemove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "option", // unique name for your Field Array
    });
    const { fields: dateFields, append: dateAppend, remove: dateRemove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'date', // unique name for your Field Array
    });
    const { t } = useTranslation('', { keyPrefix: 'CreatePollModal' });
    const closePollModal = () => {
        props.closeModal(true)
    }

    const changeHandler = (event: any) => {
        setSelectedOption(event.target.value);
    }
    const changeInternalHandler = (event: any) => {
        if (event.target.value === "Date/Time Survey") {
            dateAppend({ date: new Date() });
        } else if (event.target.value === "Text Survey") {
            textAppend({ option: '' });
        }
        setInternalOption(event.target.value);
    }

    const onSubmit = async (data: any) => {
        props.closeModal({ type: EPostCategory.EXTERNAL, ...data })
    };
    const internalOnSubmit = async (data: any) => {
        data.pollEndsAt = startDate.toISOString();
        let options = [];
        if (internalOption === "Text Survey") {
            data.pollType = EPollType.TEXT;
            for (const key of data.option) {
                options.push(key.value);
            }
        } else if (internalOption === "Date/Time Survey") {
            data.pollType = EPollType.DATE_TIME;
            for (const iterator of data.date) {
                options.push(moment(iterator.value).format('DD/MM/YYYY') + " " + iterator.startTime + " - " + iterator.endTime)
            }
        }
        data.pollOptions = options;
        delete data.option;
        delete data.date;
        props.closeModal({ type: EPostCategory.INTERNAL, ...data })
    };
    const handleDateChange = (data: any) => {
        setStartDate(data)
    };
    const addOption = () => {
        if (internalOption === "Date/Time Survey") {
            dateAppend({ date: new Date() });
        } else if (internalOption === "Text Survey") {
            textAppend({ option: 'test' });
        }
    };
    const deleteOption = (index: number) => {
        if (internalOption === "Date/Time Survey") {
            dateRemove(index)
        } else if (internalOption === "Text Survey") {
            textRemove(index);
        }
    };
    return <Modal className="up-thememodal up-modalcreateupdates" show={true} onHide={closePollModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('pollSurvey')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder up-surveymodalholder">
                <div className="up-formtheme up-formdisplayto">
                    <ul className="up-reportoptions">
                        <div className='form-group'>
                            <div className='up-select'>
                                <select className="up-selecttheme" onChange={(e) => changeHandler(e)}>
                                    <option>{t('selectSurveyType')}</option>
                                    <option value='Internal' >{t('internal')}</option>
                                    <option value='External' >{t('external')}</option>
                                </select>
                            </div>
                        </div>
                        {selectedOption === 'External' && <form onSubmit={externalSubmit(onSubmit)}>
                            <fieldset>
                                <div className="form-group">
                                    <label>{t('urlLabel')}</label>
                                    <input
                                        {...externalRegister("label")} name="label" className="form-control" placeholder={t('enterLabel')} />
                                    <small id="emailHelp" className="form-text text-muted">
                                        {externalErrors.label?.message}
                                    </small>
                                </div>
                                <div className="form-group">
                                    <label>{t('url')}</label>
                                    <input
                                        {...externalRegister("url")} name="url"
                                        className="form-control" placeholder={t('enterURL')} />
                                    <small id="emailHelp" className="form-text text-muted">
                                        {externalErrors.url?.message}
                                    </small>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="up-btn up-btnwithloader">{t('confirm')}</button>
                                </div>
                            </fieldset>
                        </form>}
                        {selectedOption === 'Internal' && <div >
                            <fieldset>
                                <div className='form-group'>
                                    <div className='up-select'>
                                        {/* <label></label> */}
                                        <select className="up-selecttheme" onChange={(e) => changeInternalHandler(e)}>
                                            <option>{t('selectInternalSurveyType')}</option>
                                            <option value='Date/Time Survey' >{t('dateTimeSurvey')}</option>
                                            <option value='Text Survey' >{t('textSurvey')}</option>
                                        </select>
                                    </div>
                                </div>
                                {internalOption === "Date/Time Survey" && dateFields.map((date, index) => (
                                    <div key={date.id}>
                                        <div className="form-group up-setaligndatetime">
                                            <input id="value" type="date" {...internalRegister(`date.${index}.value`)} className="form-control" placeholder={t('enterOption')} />
                                            <input id="startTime" {...internalRegister(`date.${index}.startTime`)} type="time" className="form-control" placeholder={t('startTime')} />
                                            <input id="endTime" {...internalRegister(`date.${index}.endTime`)} type="time" className="form-control" placeholder={t('endTime')} />
                                            <i className='icon-cross' onClick={() => deleteOption(index)}></i>
                                            {/* <button type="button"  className="up-btn">Delete option</button> */}
                                        </div>
                                    </div>
                                ))
                                }
                                {internalOption === "Text Survey" && textFields.map((option, index) => (
                                    <div>
                                        <div className="form-group up-inputwithcrosicon up-inputwithicon">
                                            <input type="text" key={option.id} {...internalRegister(`option.${index}.value`)} className="form-control" placeholder={t('enterOption')} />
                                            <i className='icon-cross' onClick={() => deleteOption(index)}></i>
                                        </div>
                                        {/* <button type="button"  className="up-btn">Delete option</button> */}
                                    </div>
                                ))
                                }
                                <div className='form-group'>
                                    {(internalOption === "Date/Time Survey" || internalOption === "Text Survey") && <button type="button" onClick={addOption} className="up-btn up-btnwithloader">{t('addOption')}</button>}
                                </div>
                                <form onSubmit={internalSubmit(internalOnSubmit)}>
                                    <div className='up-inputwidthfifty'>
                                        <div className="form-group">
                                            <label>{t('showResult')}</label>
                                            <div className='up-select'>
                                                <select {...internalRegister("showPollResults")}>
                                                    <option value={EBoolean.YES}>{t('yes')}</option>
                                                    <option value={EBoolean.NO}>{t('no')}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>{t('multipleAnswers')}</label>
                                            <div className='up-select'>
                                                <select {...internalRegister("isPollMultiSelect")}>
                                                    <option value={EBoolean.YES}>{t('yes')}</option>
                                                    <option value={EBoolean.NO} selected>{t('no')}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='up-inputwidthfifty'>
                                        <div className="form-group">
                                            <label>{t('allowUsersToAdOptions')}</label>
                                            <div className='up-select'>
                                                <select {...internalRegister("allowCustomPollOption")}>
                                                    <option value={EBoolean.YES}>{t('yes')}</option>
                                                    <option value={EBoolean.NO}>{t('no')}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>{t('showResultsIn')}</label>
                                            <div className='up-select'>
                                                <select {...internalRegister("pollResultType")}>
                                                    <option value={EPollResultType.NUMBER}>{t('number')}</option>
                                                    <option value={EPollResultType.PERCENTAGE}>{t('percentage')}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                    <label>{t('dateTimeForVote')}</label>
                                        <DatePicker
                                            {...internalRegister("pollEndsAt")}
                                            selected={startDate}
                                            onChange={handleDateChange}
                                            showTimeSelect
                                            minDate={moment().toDate()}
                                            dateFormat="yyyy-MM-dd hh:mm"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="up-btn up-btnwithloader">{t('confirm')}</button>
                                    </div>
                                </form>
                            </fieldset>
                        </div>}
                    </ul>
                    {/* <button onClick={closePollModal} type="button" className="up-btn up-btn-lg">Confirm</button> */}
                </div>
            </div>
        </Modal.Body>
    </Modal>
}