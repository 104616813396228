import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap"
import { postService } from "../../services/post.service";
import NoDataFound from "../NoData/NoDataFound";
import { images } from "../../assets/images";
import {IPaginationInfo} from "../../interfaces/pagination-info.interface";
import {DynamicLoader} from "../../utils/DynamicLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from "react-i18next";

export const UserDetailModal: React.FC<any> = (props: any) => {

    const [isLoading, setIsloading] = useState(false);
    const [likeDetailData, setLikeDetailData] = useState<any[]>([]);
    const [viewDetailData, setViewDetailData] = useState<any[]>([]);
    const [likesPaginationInfo, setLikesPaginationInfo] = useState<IPaginationInfo>({
        currentPage: 1,
        pages: 0,
        perPage: 0,
        totalRecords: 0,
    })
    const [viewsPaginationInfo, setViewsPaginationInfo] = useState<IPaginationInfo>({
        currentPage: 1,
        pages: 0,
        perPage: 0,
        totalRecords: 0,
    })
    const { t } = useTranslation('', { keyPrefix: 'UserDetailModal' });
    const {showLike} = props
    useEffect(() => {
        if (showLike)
        {
            getLikeDetails(likesPaginationInfo.currentPage);
        }else{
            getViewDetails(viewsPaginationInfo.currentPage);
        }
    }, [])
    const closeDisplayToModal = () => {
        props.closeModal(false)
    }
    const getLikeDetails = async (page: number) => {
        setIsloading(true);
        const res: any = await postService.getLikeDetails(props.id,page)
        setIsloading(false);
        if (res.statusCode === 200) {
            if (likesPaginationInfo?.currentPage === 1)
            {
                setLikeDetailData(res.payload.users.records)
            }else{
                setLikeDetailData([...likeDetailData,...res.payload.users.records])
            }
            setLikesPaginationInfo(res.payload.users.paginationInfo)
        }
    }
    const getViewDetails = async (page: number) => {
        setIsloading(true);
        const res: any = await postService.getViewDetails(props.id,page)
        setIsloading(false);
        if (res.statusCode === 200) {
            if (viewsPaginationInfo?.currentPage === 1)
            {
                setViewDetailData(res.payload.users.records)
            }else{
                setViewDetailData([...likeDetailData,...res.payload.users.records])
            }
            setViewsPaginationInfo(res.payload.users.paginationInfo)
        }
    }

    return <Modal className="up-thememodal up-invitemembersmodal" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{showLike ? t('likeDetails') : t('viewDetails')}</Modal.Title>
        </Modal.Header>
        {isLoading && <DynamicLoader />}
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <div></div>
                <div className="up-userstable">
                    <div className="up-usertableholder">
                        {showLike && <>
                            {likeDetailData && likeDetailData.length > 0 && (
                                <InfiniteScroll
                                    dataLength={likeDetailData!.length} //This is important field to render the next data
                                    next={() => getLikeDetails(likesPaginationInfo?.currentPage! + 1)}
                                    hasMore={likeDetailData!.length !== likesPaginationInfo?.totalRecords!}
                                    loader={<h4>{t('loading')}</h4>}
                                    endMessage={
                                        <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                            <b>{t('noMoreUsers')}</b>
                                        </p>
                                    }>
                                    <table className="up-tabletheme">
                                        <thead>
                                        <tr>
                                            <th>{t('user')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {likeDetailData.map((option, index) => (<tr>
                                            <td>
                                                <div className="up-usertabledetail" style={{ marginBottom: '12px' }}>
                                                    <figure>
                                                        <img src={option.avatar || images.UserPlaceholder} alt="user image" />
                                                    </figure>
                                                    <h4>{option.name}</h4>
                                                </div>
                                            </td>
                                        </tr>))}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            )}
                            {likeDetailData && likeDetailData.length === 0 && (
                                <NoDataFound />
                            )}
                        </>}
                        {!showLike && <>
                            {viewDetailData && viewDetailData.length > 0 && (
                                <InfiniteScroll
                                    dataLength={viewDetailData!.length} //This is important field to render the next data
                                    next={() => getViewDetails(viewsPaginationInfo?.currentPage! + 1)}
                                    hasMore={viewDetailData!.length !== viewsPaginationInfo?.totalRecords!}
                                    loader={<h4>{t('loading')}</h4>}
                                    endMessage={
                                        <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                            <b>{t('noMoreUsers')}</b>
                                        </p>
                                    }>
                                    <table className="up-tabletheme">
                                        <thead>
                                        <tr>
                                            <th>{t('user')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {viewDetailData.map((option, index) => (<tr>
                                            <td>
                                                <div className="up-usertabledetail" style={{ marginBottom: '12px' }}>
                                                    <figure>
                                                        <img src={option.avatar || images.UserPlaceholder} alt="user image" />
                                                    </figure>
                                                    <h4>{option.name}</h4>
                                                </div>
                                            </td>
                                        </tr>))}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            )}
                            {viewDetailData && viewDetailData.length === 0 && (
                                <NoDataFound />
                            )}
                        </>}
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}