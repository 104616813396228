import {Modal} from "react-bootstrap";
import {images} from "../../../assets/images";
import NoDataFound from "../../../components/NoData/NoDataFound";
import {useTranslation} from "react-i18next";
import {IMessage, IReaction} from "../interfaces/message.interface";
import {useSelector} from "react-redux";
import {getUser} from "../../auth/slice/user.selector";
import {useState} from "react";
import {DynamicLoader} from "../../../utils/DynamicLoader";
import {messageService} from "../../../services/message.service";
import {IUser} from "../../auth/pages/login/interface/user.interface";


interface IMessageViewsDetailModalProps {
    message: IMessage;
    closeModal: Function
}

export const MessageViewsDetailModal: React.FC<IMessageViewsDetailModalProps> = (props) => {
    const {t} = useTranslation('', {keyPrefix: 'MessageViewsDetailModal'});
    const currentUser = useSelector(getUser);
    const [seenBy] = useState<IUser[]>(JSON.parse(JSON.stringify(props.message?.seenBy?.filter(item => item._id !== currentUser._id) || [])))

    const closeDisplayToModal = () => {
        props.closeModal()
    }

    return <Modal className="up-thememodal up-invitemembersmodal" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('viewDetail')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <div></div>
                <div className="up-userstable">
                    <div className="up-usertableholder">
                        {seenBy.length > 0 && <table className="up-tabletheme">
                            <thead>
                            <tr>
                                <th>{t('user')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {seenBy.map((option, index) => (
                                <tr>
                                    <td>
                                        <div className="up-usertabledetail" style={{marginBottom: '12px'}}>
                                            <figure>
                                                <img src={option.avatar || images.UserPlaceholder} alt="user image"/>
                                            </figure>
                                            <h4>{option.name}</h4>
                                        </div>
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>}
                        {seenBy && seenBy.length === 0 && (
                            <h2 className="text-center">
                                {t('noData')}
                            </h2>
                        )}
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}