import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { userService } from "../../../services/user.service";
import { Toast } from "../../../utils/Toast";
import { setUpdatedUser } from "../../auth/slice/user.slice";
import { verifyEmailCodeSchema, verifyPhoneSchema } from "../validations/email-verify.validation";
import {useTranslation} from "react-i18next";

export const ChangePhoneModal: React.FC<any> = (props) => {
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [newPhone, setNewPhone] = useState<number>();
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(verifyPhoneSchema)
    });
    const { register: codeRegister, handleSubmit: codeHandleSubmit, formState: { errors: codeErrors } } = useForm({
        resolver: yupResolver(verifyEmailCodeSchema)
    });
    const { t } = useTranslation('', { keyPrefix: 'ChangePhoneModal' });

    const closeDisplayToModal = () => {
        props.closeModal(true)
    }

    const sendVerifyCode = async (data: any) => {
        const res: any = await userService.sendPhoneOTP(data)
        if (res.statusCode === 200) {
            setNewPhone(data.phone)
            setShowCodeInput(true)
            Toast.fire({
                icon: 'success',
                title: res.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const sendVerifyPhone = async (data: any) => {
        data.phone = newPhone;
        const res: any = await userService.verifyPhoneOTP(data)
        if (res.statusCode === 200) {
            await dispatch(setUpdatedUser(res.payload.user))
            props.closeModal(true)
            Toast.fire({
                icon: 'success',
                title: res.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    return <Modal className="up-thememodal up-modalcreateupdates" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('changePhone')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formdisplayto" onSubmit={handleSubmit(sendVerifyCode)}>
                    <fieldset>
                        <div className="form-group">
                            <label>{t('phone')}</label>
                            <input type="number" {...register("phone")} name="phone" className="form-control" placeholder={t('phone')} />
                            <small id="emailHelp" className="form-text text-muted">
                                {errors.phone?.message}
                            </small>
                            <button type="submit" className="up-btn">{t('sendCode')}</button>
                        </div>
                    </fieldset>
                </form>
                {showCodeInput && <form className="up-formtheme up-formdisplayto" onSubmit={codeHandleSubmit(sendVerifyPhone)}>
                    <fieldset>
                        <div className="form-group">
                            <label>{t('enterCode')}</label>
                            <input type="text" {...codeRegister("otpCode")} name="otpCode" className="form-control" placeholder={t('enterCode')} />
                            <small id="emailHelp" className="form-text text-muted">
                                {codeErrors.otpCode?.message}
                            </small>
                            <button type="submit" className="up-btn">{t('verifyCode')}</button>
                        </div>
                    </fieldset>
                </form>}
            </div>
        </Modal.Body>
    </Modal>
}