import {yupResolver} from "@hookform/resolvers/yup";
import {useState} from "react";
import {Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {postService} from "../../../services/post.service";
import {Toast} from "../../../utils/Toast";
import {
    ISharePostInUserGroupsModal
} from "../../organisations/createorganisation/interfaces/share-post-modal.interface";
import {sharePostSchema} from "../../organisations/createorganisation/validations/share-post.validation";
import {useTranslation} from "react-i18next";

export const SharePostInUserGroupModal: React.FC<ISharePostInUserGroupsModal> = (props) => {
    const [isLoading, setIsloading] = useState(false);
    const { t } = useTranslation('', { keyPrefix: 'SharePostInUserGroupModal' });


    const { register: shareRegister, handleSubmit: shareSubmit, formState: { errors: shareErrors }, reset: shareReset } = useForm({
        resolver: yupResolver(sharePostSchema)
    });
    const sharePostSubmit = async (data: any) => {
        if (data.organization == '0') {
            Toast.fire({
                icon: 'warning',
                title: t('pleaseSelectUserGroup')
            })
            return;
        }
        setIsloading(true)
        const res: any = await postService.sharePostInUserGroup({
            "postId": props.postToBeShared?._id,
            "groupId": data.organization
        })
        if (res.statusCode === 201) {
            shareReset({ organization: '' });
            props.closeModal(true)
            setIsloading(false)
            Toast.fire({
                icon: 'success',
                title: res.message
            })
        } else {
            setIsloading(false)
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const closeShareModal = () => {
        props.closeModal(true)
    }
    return <Modal className="up-thememodal up-modalcreateupdates" show={true} onHide={closeShareModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('sharePostInUserGroup')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formcreategroup" onSubmit={shareSubmit(sharePostSubmit)}>
                    <fieldset>
                        <div className="up-creategroupcontentholder" style={{ paddingTop: '0px' }}>
                            <div className="up-sharemodalcontent">
                                <div className="form-group">
                                    <label>{t('selectUserGroup')}</label>
                                    <span className="up-select">
                                        <select {...shareRegister('organization')} name="organization" className="form-control">
                                            {props.userGroups && props.userGroups.map((e, key) => {
                                                return <option key={key} value={e._id}>{e.name}</option>;
                                            })}
                                        </select>
                                    </span>
                                    <small id="emailHelp" className="form-text text-muted">
                                        {shareErrors.organization?.message}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <button disabled={isLoading} type="submit" className="up-btn up-btn-lg">{t('share')} {isLoading && <div className="lds-dual-ring"></div>}</button>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}