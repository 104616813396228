import {ICreateURLModalProps} from "../../organisations/createorganisation/interfaces/create-new-role-modal.interface"
import Modal from 'react-bootstrap/Modal';
import {useEffect, useState} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {createURLSchema} from "../../organisations/createorganisation/validations/create-role.validation";
import {images} from "../../../assets/images";
import {PostDisplayToModal} from "./PostDisplayToModal";
import {IGroup} from "../../organisations/createorganisation/interfaces/group.interface";
import {
    IOrganizationRole
} from "../../organisations/createorganisation/interfaces/organization-requested-members.interface";
import {groupService} from "../../../services/group.service";
import {Toast} from "../../../utils/Toast";
import {DynamicFieldsModal} from "./DynamicFieldsModal";
import {IUserField} from "../../../interfaces/user-fields.interface";
import {shortcutService} from "../../../services/shortcut.service";
import {EWhichDisplayTo} from "../../organisations/createorganisation/interfaces/display-to-enum";
import S3 from "aws-sdk/clients/s3";
import {AWS_S3_BUCKET, s3} from "../../../config/aws.config";
import {organisationService} from "../../../services/organisation.service";
import {UrlConst} from "../../../utils/url.-consts";
import {useTranslation} from "react-i18next";

export const CreateURLModal: React.FC<ICreateURLModalProps> = (props) => {

    const [selectedOption, setSelectedOption] = useState('');
    const [displayToModal, setDisplayToModal] = useState(false);
    const [dynamicFieldsModal, setDynamicFieldsModal] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([])
    const [selectedGroups, setSelectedGroups] = useState<string[]>([])
    const [selectedUserFields, setSelectedUserFields] = useState<IUserField[]>([])
    const [groupsForPostCreation, setGroupsForPostCreation] = useState<IGroup[]>([]);
    const [organizationRoles, setOrganizationRoles] = useState<IOrganizationRole[]>(props.organizationRoles)
    const [isPublicChecked, setIsPublicChecked] = useState(true)
    const [showUrlModal, setShowUrlModal] = useState(true)
    const [userFields, setUserFields] = useState<IUserField[]>([]);
    const [selectedUrlIcon, setSelectedUrlIcon] = useState<any>()
    const [urlIconPreview, setUrlIconPreview] = useState<any>()
    const { t } = useTranslation('', { keyPrefix: 'CreateURLModal' });

    const {register, setValue, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(createURLSchema)
    });
    useEffect(() => {
        getUserFields()
        getGroupsForPostCreation();
        setData()
        return () => {
            reset();
            setSelectedRoles([])
            setSelectedGroups([])
            setSelectedUserFields([])
        }
    }, [])

    useEffect(() => {
        if (!selectedUrlIcon) {
            setUrlIconPreview(undefined)
            return
        }
        const objectUrl: any = URL.createObjectURL(selectedUrlIcon)
        setUrlIconPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedUrlIcon])
    const getUserFields = async () => {
        const res: any = await organisationService.getOrganizationFields(props.organizationId);
        if (res.statusCode == 200) {
            if (props.isEdit) {
                if (props.selectedEditURL.urlType == 'DYNAMIC') {
                    const temp = [];
                    for (const iterator of props.selectedEditURL.dynamicFields) {
                        const objIndex = res.payload.fields.findIndex((role: any) => role._id == iterator._id)
                        if (objIndex != -1) {
                            res.payload.fields[objIndex].checked = true;
                            temp.push(res.payload.fields[objIndex])
                        }
                    }
                    setSelectedUserFields(temp)
                    setUserFields([...res.payload.fields])
                }
            }else{
                setUserFields(res.payload.fields)
            }
        }
    }
    const setData = async () => {
        if (props.isEdit) {
            const {selectedEditURL} = props
            setValue('url', selectedEditURL.url);
            setValue('label', selectedEditURL.label);
            setValue('icon', selectedEditURL.icon);
            setSelectedOption(selectedEditURL.urlType)
            setValue('urlType', selectedEditURL.urlType);
            if (selectedEditURL.displayTo.length == 0) {
                setSelectedRoles([]);
                setSelectedGroups([]);
                setIsPublicChecked(true);
            } else {
                const tempRoles: string[] = []
                if (selectedEditURL.eWhichDisplayTo === EWhichDisplayTo.OrganizationRole) {
                    for (const iterator of selectedEditURL.displayTo) {
                        const objIndex = organizationRoles.findIndex(role => role._id == iterator)
                        if (objIndex != -1) {
                            organizationRoles[objIndex].checked = true;
                            tempRoles.push(iterator);
                        }
                    }
                    setSelectedRoles(tempRoles);
                } else if (selectedEditURL.eWhichDisplayTo === EWhichDisplayTo.Group) {
                    const tempData: any = await getGroupsForPostCreation()
                    for (const iterator of selectedEditURL.displayTo) {
                        const objIndex = tempData.findIndex((role: any) => role._id == iterator)
                        if (objIndex != -1) {
                            tempData[objIndex].checked = true;
                            tempRoles.push(iterator);
                        }
                    }
                    setGroupsForPostCreation([...tempData])
                    setSelectedGroups(tempRoles);
                }
                setIsPublicChecked(false)
            }
        }
    }
    const getGroupsForPostCreation = async () => {
        const res: any = await groupService.getOrganizationGroups(props.organizationId, 1, ' ', 1000);
        if (res.statusCode == 200) {
            for (const group of res.payload.groups.records) {
                group.childGroups = [];
                group.checked = false;
            }
            setGroupsForPostCreation([...res.payload.groups.records])
            return [...res.payload.groups.records]
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const closeDisplayToModal = () => {
        setShowUrlModal(true)
        setDisplayToModal(false)
    }
    const closeDynamicFieldModal = () => {
        setShowUrlModal(true)
        setDynamicFieldsModal(false)
    }
    const closeURLModal = () => {
        props.closeModal(false)
    }
    const createURLSubmit = async (data: any) => {
        data.organization = props.organizationId
        if (isPublicChecked) {
            data.displayTo = []
        } else if (selectedRoles.length > 0) {
            data.displayTo = [...selectedRoles]
            data.eWhichDisplayTo = EWhichDisplayTo.OrganizationRole
        } else if (selectedGroups.length > 0) {
            data.displayTo = [...selectedGroups]
            data.eWhichDisplayTo = EWhichDisplayTo.Group
        }
        if (data.urlType === 'DYNAMIC' && selectedUserFields) {
            data.dynamicFields = selectedUserFields.map(field => field._id)
        }
        if (selectedUrlIcon) {
            const params: S3.PutObjectRequest = {
                Body: selectedUrlIcon,
                Bucket: AWS_S3_BUCKET!,
                Key: `${parseInt(
                    (new Date().getTime() / 1000).toFixed(0)
                )}-${selectedUrlIcon.name}`,
                ContentType: selectedUrlIcon.type,
                ACL: "public-read",
            };
            const uploadResult: any = await s3.upload(params).promise();
            data.icon = uploadResult.Location
        }
        if (props.isEdit) {
            const res: any = await shortcutService.updateShortcut(props.selectedEditURL._id, data)
            if (res.statusCode == 200) {
                props.closeModal(true, res.payload.shortcut)
                Toast.fire({
                    icon: 'success',
                    title: res.message
                })
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        } else {
            const res: any = await shortcutService.createShortcut(data)
            if (res.statusCode == 201) {
                props.closeModal(true, res.payload.shortcut)
                Toast.fire({
                    icon: 'success',
                    title: res.message
                })
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }
    }

    const changeHandler = (event: any) => {
        setSelectedOption(event.target.value);
    }

    const showDisplayToModal = () => {
        setShowUrlModal(false)
        setDisplayToModal(!displayToModal);
    }
    const publicChangeHandler = (event: any) => {
        if (event.target.checked) {
            setSelectedRoles([]);
            setSelectedGroups([]);
            for (const iterator of organizationRoles) {
                iterator.checked = false;
            }
            for (const iterator of groupsForPostCreation) {
                iterator.checked = false;
            }
            setOrganizationRoles([...organizationRoles])
            setGroupsForPostCreation([...groupsForPostCreation])
        }
        setIsPublicChecked(!isPublicChecked)
    }
    const handleRoleSelection = (event: any, role: any, index: number, type: string) => {
        role.checked = event.target.checked
        switch (type) {
            case 'ROLE':
                if (event.target.checked) {
                    const temp: string[] = []
                    temp.push(event.target.id)
                    if (selectedRoles) {
                        setSelectedRoles([...selectedRoles, ...temp])
                    } else {
                        setSelectedRoles([...temp])
                    }
                } else {
                    const temp = selectedRoles!.findIndex((role) => role === event.target.id)
                    if (temp != -1) {
                        selectedRoles!.splice(temp, 1)
                        setSelectedRoles([...selectedRoles!])
                    }
                }
                for (const iterator of groupsForPostCreation) {
                    iterator.checked = false;
                }
                setGroupsForPostCreation([...groupsForPostCreation])
                setSelectedGroups([]);
                break;
            case 'GROUP':
                if (event.target.checked) {
                    const temp: string[] = []
                    temp.push(event.target.id)
                    if (selectedGroups) {
                        setSelectedGroups([...selectedGroups, ...temp])
                    } else {
                        setSelectedGroups([...temp])
                    }
                } else {
                    const temp = selectedGroups!.findIndex((role) => role === event.target.id)
                    if (temp != -1) {
                        selectedGroups!.splice(temp, 1)
                        setSelectedGroups([...selectedGroups!])
                    }
                }
                for (const iterator of organizationRoles) {
                    iterator.checked = false;
                }
                setOrganizationRoles([...organizationRoles])
                setSelectedRoles([])
                break;
            default:
                break;
        }
    }
    const handleFieldSelection = (event: any, field: any, index: number) => {
        field.checked = event.target.checked
        if (event.target.checked) {
            const temp = []
            temp.push(field)
            if (selectedUserFields) {
                setSelectedUserFields([...selectedUserFields, ...temp])
            } else {
                setSelectedUserFields([...temp])
            }
        } else {
            const temp = selectedUserFields!.findIndex((field) => field._id === event.target.id)
            if (temp != -1) {
                selectedUserFields!.splice(temp, 1)
                setSelectedUserFields([...selectedUserFields!])
            }
        }
    }
    const urlIconChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedUrlIcon(e.target.files[0]);
        }
    };

    const getFieldNames = ()=>{
        if (selectedUserFields)
        {
            return selectedUserFields.map((item) => item.label).toString()
        }
    }

    const openDynamicFieldModal = () => {
        setShowUrlModal(false)
        setDynamicFieldsModal(true)
    }
    return <>
        <Modal className="up-thememodal up-modalcreateupdates up-assignnewrolemodal" show={showUrlModal}
               onHide={closeURLModal}>
            <Modal.Header className="up-modalheader" closeButton>
                <Modal.Title>{t('createURLForward')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="up-modalbody">
                <div className="up-modalcontentholder">
                    <form className="up-formtheme up-formdisplayto" onSubmit={handleSubmit(createURLSubmit)}>
                        <fieldset>
                            <div className="up-gourpprofileimage">
                                <figure className="up-groupprofileimg">
                                    <div className="up-uploadgroupimg">
                                        <input accept="image/*" onChange={urlIconChange} type="file" name="upload-profile-img" id="up-uploadgroupprofileimg" />
                                        <label htmlFor="up-uploadgroupprofileimg"><i className="icon-camera"></i></label>
                                    </div>
                                    {urlIconPreview && <img src={urlIconPreview} alt="Group Profile Image" />}
                                    {!urlIconPreview && <img src={props.selectedEditURL?.icon ?? images.RestPlaceholder} alt="Group Profile Image" />}
                                </figure>
                            </div>
                            <div className="form-group">
                                <label>{t('labelName')}</label>
                                <input  {...register("label")} type="text" name="label" className="form-control"
                                        placeholder={t('label')}/>
                                <small id="content" className="form-text text-muted">
                                    {errors.label?.message}
                                </small>
                            </div>
                            <div className="form-group">
                                <label>{t('URL')}</label>
                                <input  {...register("url")} type="text" name="url" className="form-control"
                                        placeholder={t('URL')}/>
                                <small id="content" className="form-text text-muted">
                                    {errors.url?.message}
                                </small>
                            </div>
                            <div className='form-group'>
                                <label>{t('URLType')}</label>
                                <div className='up-select'>
                                    <select {...register("urlType")} className="up-selecttheme"
                                            onChange={(e) => changeHandler(e)}>
                                        <option disabled selected value=''>{t('selectURLType')}</option>
                                        <option value={'DYNAMIC'}>{t('dynamic')}</option>
                                        <option value={'FIXED'}>{t('fixed')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className="up-displayto">
                                    <em>{t('displayTo')}</em>
                                    <span
                                        onClick={showDisplayToModal}>{(selectedRoles.length > 0 || selectedGroups.length > 0) ? t('custom') : t('public')}<img
                                        src={images.ArrowDownIcon} alt="Arrow Icon"/></span>
                                </div>
                            </div>
                            {selectedOption === 'DYNAMIC' && <>
                                {selectedUserFields?.length > 0 && selectedUserFields.map((item, index) => (
                                    <div className='form-group'>
                                    <div className="up-displayto">
                                        <em>{UrlConst[index].replaceAll('$','')}</em>
                                        <span onClick={openDynamicFieldModal}>{item.label}
                                            <img src={images.ArrowDownIcon} alt="Arrow Icon"/></span>
                                    </div>
                                    </div>
                                ))}
                                {selectedUserFields?.length == 0 && <div className="up-displayto">
                                    <em>{t('dynamicFields')}</em>
                                    <span onClick={openDynamicFieldModal}>{(selectedUserFields?.length > 0) ? t('selected') : t('none')}
                                        <img src={images.ArrowDownIcon} alt="Arrow Icon"/></span>
                                </div>}
                            </>}
                            <div className="form-group">
                                <button type="submit"
                                        className="up-btn up-btnwithloader">{props.isEdit ? t('update') : t('create')}</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
        {displayToModal &&
            <PostDisplayToModal hideRoles={false} organizationGroups={groupsForPostCreation} organizationRoles={organizationRoles}
                                publicChangeHandler={publicChangeHandler} isPublicChecked={isPublicChecked}
                                closeModal={closeDisplayToModal} handleRoleSelection={handleRoleSelection}/>}
        {dynamicFieldsModal &&
            <DynamicFieldsModal fields={userFields} handleFieldSelection={handleFieldSelection}
                                closeModal={closeDynamicFieldModal}/>}

    </>
}