import UserIcon from "../../assets/images/d-icon/icon.svg";
import UsersIcon from "../../assets/images/d-icon/users.svg";
import UpdatesIcon from "../../assets/images/d-icon/updates.svg";
import EventIcon from "../../assets/images/d-icon/event.svg";
import { IStatsProps } from "../../interfaces/dashboard-stats.interface";
import {useTranslation} from "react-i18next";

export const Stat: React.FC<IStatsProps> = (props) => {
    const { t } = useTranslation('', { keyPrefix: 'Stat' });
    return (
        <div className="up-statsgrid">
            <div className="up-stat">
                <span className="up-staticon">
                    <img src={UserIcon} alt='statsicon' />
                </span>
                <div className="up-statcontent">
                    <ul>
                        <li>
                            <span>{t('users')}</span>
                            <h3>{props.data.activeMemberCount}</h3>
                        </li>
                        <li>
                            <a href={`/dashboard-users/${props.id}`}>{t('viewAll')}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="up-stat">
                <span className="up-staticon">
                    <img src={UsersIcon} alt='statsicon' />
                </span>
                <div className="up-statcontent">
                    <ul>
                        <li>
                            <span>{t('usersGroups')}</span>
                            <h3>{props.data.groupCount}</h3>
                        </li>
                        <li>
                            <a href={`/dashboard-user-groups/${props.id}`}>{t('viewAll')}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="up-stat">
                <span className="up-staticon">
                    <img src={UpdatesIcon} alt='statsicon' />
                </span>
                <div className="up-statcontent">
                    <ul>
                        <li>
                            <span>{t('updates')}</span>
                            <h3>{props.data.postCount}</h3>
                        </li>
                        <li>
                            <a href={`/dashboard-updates/${props.id}`}>{t('viewAll')}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="up-stat">
                <span className="up-staticon">
                    <img src={EventIcon} alt='statsicon' />
                </span>
                <div className="up-statcontent">
                    <ul>
                        <li>
                            <span>{t('events')}</span>
                            <h3>{props.data.activeEventCount}</h3>
                        </li>
                        <li>
                            <a href={`/dashboard-events/${props.id}`}>{t('viewAll')}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}