import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { userService } from '../../../services/user.service';
import { FullPageLoader } from '../../../utils/FullPageLoader';
import { Toast } from '../../../utils/Toast';
import { changePasswardSchema } from '../validations/change-passward.validation';
import {useTranslation} from "react-i18next";


export const ChangePasswardModal: React.FC<any> = (props) => {
    const [isLoading, setIsloading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(changePasswardSchema)
    });
    const { t } = useTranslation('', { keyPrefix: 'ChangePasswardModal' });
    const closeDisplayToModal = () => {
        props.closeModal(false)
    }

    const passwordSubmit = async (data: any) => {
        setIsloading(true)
        try {
            const res: any = await userService.changePassword(data);
            setIsloading(false)
            if (res.statusCode == 200) {
                props.closeModal(true)
                Toast.fire({
                    icon: "success",
                    title: t('passwordUpdated')
                })
            }
        } catch (e: any) {
            setIsloading(false)
            Toast.fire({
                icon: 'warning',
                title: e.response.data.message
            })
        }
    }
    return <Modal className="up-thememodal up-modalcreateupdates" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('changePassword')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            {isLoading && <FullPageLoader />}
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formdisplayto" onSubmit={handleSubmit(passwordSubmit)}>
                    <fieldset>
                        <div className="form-group">
                            <label>{t('oldPassword')}</label>
                            <input type="password"
                                {...register("oldPassword")} className="form-control" placeholder={t('enterOldPassword')} />
                            <small id="emailHelp" className="form-text text-muted">
                                {errors.oldPassword?.message}
                            </small>
                        </div>
                        <div className="form-group">
                            <label>{t('newPassword')}</label>
                            <input type="password"
                                {...register("newPassword")} className="form-control" placeholder={t('enterNewPassword')} />
                            <small id="emailHelp" className="form-text text-muted">
                                {errors.newPassword?.message}
                            </small>
                        </div>
                        <div className="form-group">
                            <label>{t('confirmNewPassword')}</label>
                            <input type="password"
                                {...register("passwordConfirmation")} className="form-control" placeholder={t('enterConfirmPassword')} />
                            <small id="emailHelp" className="form-text text-muted">
                                {errors.passwordConfirmation?.message}
                            </small>
                        </div>
                        <button type="submit" className="up-btn up-btn-lg">{t('updatePassword')}</button>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}