import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit'
import { IUserState } from './types';

const selectDomain = (state: any) => state.userReducer;

export const getIsLoading = createDraftSafeSelector(selectDomain, (user: IUserState) => user.isLoading);

export const getToken = createDraftSafeSelector(selectDomain, (user: IUserState) => user.token);

export const getUser = createDraftSafeSelector(selectDomain, (user: IUserState) => user.user);
