import {useEffect, useState} from "react";
import {FullPageLoader} from "../../../utils/FullPageLoader";
import {IScheduledPostTab} from "../../organisations/createorganisation/interfaces/scheduled-post-tab.inerface";
import {scheduledPostService} from "../../../services/schedule-post.service";
import InfiniteScroll from "react-infinite-scroll-component";
import {IPost} from "../../organisations/createorganisation/interfaces/post.interface";
import {IPaginationInfo} from "../../../interfaces/pagination-info.interface";
import {Toast} from "../../../utils/Toast";
import {organisationService} from "../../../services/organisation.service";
import {groupService} from "../../../services/group.service";
import Swal from "sweetalert2";
import {IOrganization} from "../../organisations/createorganisation/interfaces/organisation.interface";
import {useSelector} from "react-redux";
import {getUser} from "../../auth/slice/user.selector";
import {IGroup} from "../../organisations/createorganisation/interfaces/group.interface";
import {SharePostOrganizationModal} from "./SharePostModal";
import {SharePostInUserGroupModal} from "./SharePostInUserGroupsModal";
import {ScheduledPost} from "../../../components/ScheduledPost/ScheduledPost";
import {requestedPostService} from "../../../services/requested-post.service";
import {RejectReasonModal} from "./RejectReasonModal";
import {CreateScheduledPostModal} from "./CreateScheduledPostModal";
import {useTranslation} from "react-i18next";


export const RequestedPostsTab: React.FC<IScheduledPostTab> = (props) => {
    const [isLoading, setIsloading] = useState(true);
    const [timelinePosts, setTimelinePosts] = useState<IPost[]>([]);
    const [paginationInfoPosts, setPaginationInfoPosts] = useState<IPaginationInfo>();
    const [postToBeShared, setPostToBeShared] = useState<IPost>();
    const [selectedEditPost, setSelectedEditPost] = useState<IPost>();
    const [isEditPost, setIsEditPost] = useState(false);
    const [showReportPostModal, setShowReportPostModal] = useState(false)
    const [createPostModal, setCreatePostModal] = useState(false);
    const [userOrganizations, setUserOrganization] = useState<IOrganization[]>();
    const [sharePostModal, setSharePostModal] = useState(false);
    const [sharePostInUserGroupsModal, setSharePostInUserGroupsModal] = useState(false);
    const [rejectReasonModal, setRejectReasonModal] = useState(false);
    const [postToBeSharedInOrganization, setPostToBeSharedInOrganization] = useState<IPost>();
    const [userGroups, setUserGroups] = useState<IGroup[]>([]);
    const { t } = useTranslation('', { keyPrefix: 'RequestedPostsTab' });

    const currentUser = useSelector(getUser);


    useEffect(() => {

        getPosts(1)
    }, [])

    const getPosts = async (page: number) => {

        const res: any = await requestedPostService.getRequestedPosts(props.organizationId, page)
        setIsloading(false)
        if (res.statusCode == 200) {
            setPaginationInfoPosts(res.payload.posts.paginationInfo)
            if (page == 1) {
                setTimelinePosts(res.payload.posts.records)
            } else {
                setTimelinePosts([...timelinePosts, ...res.payload.posts.records])
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const editPost = (selectedPost: IPost) => {
        setSelectedEditPost(selectedPost);
        setIsEditPost(true);
        setCreatePostModal(true);
    }
    const sharePost = async (selectedPost: IPost) => {
        setPostToBeShared(selectedPost);
        const res: any = await organisationService.getUserOrganizations(currentUser._id)
        if (res.statusCode === 200) {
            const temp = [{_id: 0, name: 'Select Organisation.'}, ...res.payload.organizations]
            setUserOrganization(temp)
            setSharePostModal(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const shareInUserGroupsPost = async (selectedPost: IPost) => {
        setPostToBeSharedInOrganization(selectedPost);
        const res: any = await groupService.getUserGroups(1, '', selectedPost.organization?._id)
        if (res.statusCode === 200) {
            const temp = [{_id: 0, name: 'Select User Group'}, ...res.payload.groups.records]
            setUserGroups(temp)
            setSharePostInUserGroupsModal(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const deletePost = async (selectedPost: IPost) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await scheduledPostService.deleteSchedulePost(selectedPost._id);
                    if (res.statusCode = 200) {
                        const tempIndex = timelinePosts!.findIndex((post: any) => post._id === selectedPost._id)
                        timelinePosts!.splice(tempIndex, 1);
                        setTimelinePosts([...timelinePosts!])
                        setPaginationInfoPosts({
                            currentPage: paginationInfoPosts?.currentPage || 0,
                            pages: paginationInfoPosts?.pages || 0,
                            perPage: paginationInfoPosts?.perPage || 0,
                            totalRecords: (paginationInfoPosts?.totalRecords ? paginationInfoPosts.totalRecords : 1) - 1
                        })
                        if (props.updateCount){
                            props.updateCount();
                        }
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }
    const closeSharePostModal = (data: boolean) => {
        setSharePostModal(false);
        setSharePostInUserGroupsModal(false);
    }
    const handleClosePostModal = () => {
        setCreatePostModal(false);
        setIsEditPost(false);
        setSelectedEditPost(undefined)
    }
    const setPosts = (data: IPost[]) => {
        setTimelinePosts(data);
        setPaginationInfoPosts({
            currentPage: paginationInfoPosts?.currentPage || 0,
            pages: paginationInfoPosts?.pages || 0,
            perPage: paginationInfoPosts?.perPage || 0,
            totalRecords: (paginationInfoPosts?.totalRecords ? paginationInfoPosts.totalRecords : 1) - 1
        })
        if (props.updateCount){
            props.updateCount();
        }
        setCreatePostModal(false);
    }
    const reportPost = (selectedPost: IPost) => {
        setSelectedEditPost(selectedPost);
        setShowReportPostModal(true);
    }
    const setLikePost = (postId: string, userId: string, status: string) => {

        const postIndex = timelinePosts?.findIndex((post) => post._id === postId);
        if (postIndex !== -1) {
            if (status === 'YES') {
                timelinePosts[postIndex].likes.push(currentUser._id);
            } else {
                const index = timelinePosts[postIndex].likes.findIndex((lik) => lik === userId);
                if (index !== -1) {
                    timelinePosts[postIndex].likes.splice(index, 1);
                }
            }
            setTimelinePosts([...timelinePosts])
        }
    }
    const closeReasonModal = (data: boolean) => {
        if (data) {
            const postIndex = timelinePosts.findIndex(post => post._id == selectedEditPost?._id!)
            const tempPosts = [...timelinePosts!]
            tempPosts.splice(postIndex, 1)
            setTimelinePosts([...tempPosts])
            setPaginationInfoPosts({
                currentPage: paginationInfoPosts?.currentPage || 0,
                pages: paginationInfoPosts?.pages || 0,
                perPage: paginationInfoPosts?.perPage || 0,
                totalRecords: (paginationInfoPosts?.totalRecords ? paginationInfoPosts.totalRecords : 1) - 1
            })
            if (props.updateCount){
                props.updateCount();
            }
            setSelectedEditPost(undefined)
        }
        setRejectReasonModal(false);
    }
    const openRejectionModal = (selectedPost: IPost) => {
        setSelectedEditPost(selectedPost);
        setRejectReasonModal(true);
    }
    const removeFromList = (id: string) => {
        const index = timelinePosts.findIndex(item => item._id === id)
        timelinePosts.splice(index, 1)
        setTimelinePosts([...timelinePosts])
        setPaginationInfoPosts({
            currentPage: paginationInfoPosts?.currentPage || 0,
            pages: paginationInfoPosts?.pages || 0,
            perPage: paginationInfoPosts?.perPage || 0,
            totalRecords: (paginationInfoPosts?.totalRecords ? paginationInfoPosts.totalRecords : 1) - 1
        })
        if (props.updateCount){
            props.updateCount();
        }
    }

    return <>
        <div className="up-organisationsettings">
            <div className="up-themeboxhead">
                <h3>{t('requested')}</h3>
                {isLoading && <FullPageLoader/>}
            </div>
            <div className="up-themebox">
                {timelinePosts && <InfiniteScroll
                    dataLength={timelinePosts!.length} //This is important field to render the next data
                    next={() => getPosts((paginationInfoPosts?.currentPage! + 1))}
                    hasMore={timelinePosts!.length !== paginationInfoPosts?.totalRecords!}
                    loader={<h4>{t('loading')}</h4>}
                    endMessage={
                        <p className="up-allpostseen" style={{textAlign: 'center'}}>
                            <b>{t('noMoreRequestedPosts')}</b>
                        </p>
                    }>
                    {timelinePosts && timelinePosts.map((item, index) => {
                        return <ScheduledPost removeFromList={removeFromList} fromAdmin={false} public={false}
                                              fromDashboard={true} setLikePost={setLikePost} reportPost={reportPost}
                                              fromTimeline={false} key={item._id + index} post={item}
                                              showInOrganization={true} setEditPost={editPost}
                                              setShareOrganizationPost={sharePost}
                                              setShareInUserGroupsPost={shareInUserGroupsPost}
                                              rejectRequestedPost={openRejectionModal}
                                              setDeletePost={deletePost}/>
                    })}
                    {createPostModal &&
                        <CreateScheduledPostModal showSelectionButtons={false} id={selectedEditPost?.organization?._id!} organizationPosts={timelinePosts!}
                                          selectedEditPost={selectedEditPost!} isEditPost={isEditPost}
                                          setOrganizationPosts={setPosts} closeModal={handleClosePostModal}/>}

                    {sharePostModal && <SharePostOrganizationModal postToBeShared={postToBeShared!}
                                                                   userOrganizations={userOrganizations!}
                                                                   closeModal={closeSharePostModal}/>}
                    {sharePostInUserGroupsModal &&
                        <SharePostInUserGroupModal postToBeShared={postToBeSharedInOrganization!}
                                                   userGroups={userGroups!} closeModal={closeSharePostModal}/>}
                    {rejectReasonModal &&
                        <RejectReasonModal post={selectedEditPost} closeModal={closeReasonModal}/>}
                </InfiniteScroll>}
            </div>
        </div>
    </>
}