export enum EPostCategory {
    DEFAULT = 'DEFAULT',
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
  }

export enum EEmailFrom {
    USER = 'USER',
    ORGANIZATION = 'ORGANIZATION',
    NO_REPLY = 'NO_REPLY',
}