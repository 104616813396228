import {getCurrentDayEvents, getEventsCount, getEventsLastFetched} from "./cache.selector";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {FOUR_HOURS} from "./usePixeeImageData";
import {FetchCurrentDayEvents, FetchEventCounts} from "./cache.thunk";
import moment from "moment/moment";


export const useEventsData = (refresh = false) => {
    const dispatch = useDispatch();
    const eventsCounts = useSelector(getEventsCount);
    const secondaryData = useSelector(getCurrentDayEvents);
    const lastFetched = useSelector(getEventsLastFetched);


    useEffect(() => {
        const currentTime = Date.now();
        const currentDay = new Date().getDay();

        const hasExpired = !lastFetched ||
            (currentTime - lastFetched > FOUR_HOURS) ||
            (new Date(lastFetched).getDay() !== currentDay);

        if (hasExpired) {
            // Pass initialParams to fetchInitialData
            (async () => {
                await dispatch(FetchEventCounts())
                await dispatch(FetchCurrentDayEvents(new Date()));
            })();
        }
    }, [dispatch, lastFetched]);

    return {eventsCounts, secondaryData, lastFetched};
}