export const constructMessage = (message: string, tags: any) => {
  // Use regular expression to match the IDs within #{}
  const regex =/#{(\w+)}/g;

  // Use the replace() function with a callback to update the IDs with names
  const updatedMessage = message.replace(regex, (_, id) => {
    // Find the corresponding object from the tags array based on the ID
    const tag = tags.find((tag: any) => tag._id === id);
    // Return the name if the object is found, otherwise return the original ID
    return tag ? `@${tag.name}` : id;
  });

  return updatedMessage;
};

export const constructContent = (content: string) => content.replace(/\@\[.*?\]\((.*?)\)/g, '#{$1}');


export function makeArrayUniqueByKey(arr: any, key: any) {
  const uniqueMap = new Map();

  // Use reduce to create a Map with unique values based on the specified key
  const uniqueArray = arr.reduce((result: any, obj: any) => {
    const keyValue = obj[key];

    if (!uniqueMap.has(keyValue)) {
      uniqueMap.set(keyValue, obj);
      result.push(obj);
    }

    return result;
  }, []);

  return uniqueArray;
}