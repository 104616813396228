import Modal from 'react-bootstrap/Modal';
import {IDynamicFieldsModal} from '../../organisations/createorganisation/interfaces/display-to-post-modal.interface';
import {IUserField} from "../../../interfaces/user-fields.interface";
import {useTranslation} from "react-i18next";


export const DynamicFieldsModal: React.FC<IDynamicFieldsModal> = (props) => {
    const { t } = useTranslation('', { keyPrefix: 'DynamicFieldsModal' });

    const closeDynamicFieldModal = () => {
        props.closeModal(true)
    }

    return <Modal className="up-thememodal up-modalcreateupdates" show={true} onHide={closeDynamicFieldModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('displayTo')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formdisplayto">
                    <fieldset>
                        <ul className="up-reportoptions">
                            <div style={{fontWeight: "bold"}}>{t('dynamicFields')}</div>
                            {props.fields && props.fields!.map((userField: IUserField, index: number) => {
                                return <li key={userField._id}>
                                    <div className="up-advanceoption">
                                        <span>{userField?.label}</span>
                                        <div className="up-togglebutton">
                                            <input
                                                onChange={(event: any): any => props.handleFieldSelection(event, userField, index)}
                                                checked={userField.checked!} id={userField._id}
                                                type="checkbox" name="advance-option"/>
                                            <label htmlFor={userField._id}></label>
                                        </div>
                                    </div>
                                </li>
                            })}
                        </ul>
                        <button onClick={closeDynamicFieldModal} type="button" className="up-btn up-btn-lg">{t('confirm')}
                        </button>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}