export enum EPolicy {
    CAN_UPDATE_ORGANIZATION_SETTINGS = 'CAN_UPDATE_ORGANIZATION_SETTINGS',
    CAN_VIEW_GROUPS = 'CAN_VIEW_GROUPS',
    CAN_CREATE_GROUPS = 'CAN_CREATE_GROUPS',
    CAN_UPDATE_GROUPS = 'CAN_UPDATE_GROUPS',
    CAN_DELETE_GROUPS = 'CAN_DELETE_GROUPS',
    CAN_VIEW_FILES = 'CAN_VIEW_FILES',
    CAN_UPLOAD_FILES = 'CAN_UPLOAD_FILES',
    CAN_DELETE_FILES = 'CAN_DELETE_FILES',
    CAN_VIEW_POSTS = 'CAN_VIEW_POSTS',
    CAN_ADD_POSTS = 'CAN_ADD_POSTS',
    CAN_DELETE_POSTS = 'CAN_DELETE_POSTS',
    CAN_SHARE_POSTS = 'CAN_SHARE_POSTS',
    CAN_LIKE_POSTS = 'CAN_LIKE_POSTS',
    CAN_VIEW_COMMENTS = 'CAN_VIEW_COMMENTS',
    CAN_ADD_COMMENTS = 'CAN_ADD_COMMENTS',
    CAN_DELETE_COMMENTS = 'CAN_DELETE_COMMENTS',
    CAN_ADD_EVENTS = 'CAN_ADD_EVENTS',
    CAN_DELETE_EVENTS = 'CAN_DELETE_EVENTS',
  }