import { useForm } from "react-hook-form";
import { ILogInInterface } from "../interface/login.interface";
import { loginSchema } from "../validations/login.validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { HttpService } from "../../../../../services/base.service";
import { getIsLoading, getToken, getUser } from "../../../slice/user.selector";
import { useEffect } from "react";
import { routes } from "../../../../../router/routes";
import { Toast } from "../../../../../utils/Toast";
import { LoginUser } from "../../../slice/auth.thunks";
import { useState } from "react";
import { userService } from "../../../../../services/user.service";
import { setLogout } from "../../../slice/user.slice";

export const AdminLoginForm = () => {
    const [isLoading, setIsloading] = useState(false);
    const dispatch = useDispatch();
    let history = useHistory();
    const token = useSelector(getToken);
    const {role} = useSelector(getUser)

    useEffect(() => {
        if (token) {
            HttpService.setToken(token);
            if(role == "USER"){
                history.push(routes.home);
            }else {
                history.push(routes.adminDashboard);
            }
        }
    }, [])
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(loginSchema)
    });

    const onSubmit = async (data: ILogInInterface) => {
        setIsloading(true)
        data.email = data.email.toLowerCase();
        const res: any = await dispatch(LoginUser(data));
        setIsloading(false)
        if (res.payload.statusCode == 200 && res.payload.payload.user.role == "ADMIN") {
            Toast.fire({
                icon: "success",
                title: 'Welcome back'
            })
            history.push(routes.adminDashboard);
        } else{
            dispatch(setLogout());
        }

    };
    return (
        <form className="up-formtheme up-formsignin" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <div className="form-group">
                    <label>Email address</label>
                    <input type="email"
                        {...register("email")} className="form-control" placeholder="Enter Email" />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.email?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input type="password"
                        {...register("password")}
                        className="form-control" placeholder="Enter Password" />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.password?.message}
                    </small>
                </div>
                <div className="form-group">
                    {/* <div className="up-remebermearea">
                        <input type="checkbox" name="checkbox" id="up-rememberme" />
                        <label htmlFor="up-rememberme">Remember me</label>
                    </div> */}
                    <button type="submit" className="up-btn up-btnwithloader">Sign in {isLoading && <div className="lds-dual-ring"></div>}</button>
                </div>
            </fieldset>
        </form>
    )
};