import { IUser } from "../features/auth/pages/login/interface/user.interface";
import { EBoolean } from "../features/auth/pages/signup/interfaces/drop-down.enums";
import { IChat } from "../features/chatmessages/interfaces/chat.interface";
import { IMessage } from "../features/chatmessages/interfaces/message.interface";
import { ICreateEvent } from "../features/organisations/createorganisation/interfaces/create-event.interface";
import { IGroup } from "../features/organisations/createorganisation/interfaces/group.interface";
import { IResponseInterface } from "../interfaces/api-response.interface";
import { IPagination } from "../interfaces/pagination-info.interface";
import { IQuery } from "../interfaces/query.interface";
import { HttpService } from "./base.service";

class ChatService extends HttpService {
  private readonly prefix: string = "chat";

  getGroupChat = (query: IQuery): Promise<IResponseInterface<{ chats: IPagination<IChat[]> }>> => this.get(`${this.prefix}/group`, query);

  getGroupChatDetail = (groupId: string): Promise<IResponseInterface<{ chat: IChat }>> => this.get(`${this.prefix}/group/${groupId}`);

  getIndividualChat = (query: IQuery): Promise<IResponseInterface<{ chats: IPagination<IChat[]> }>> => this.get(`${this.prefix}/individual`, query);

  updateNotificationStatus = (chatId: string, body: { status: EBoolean }): Promise<IResponseInterface<{}>> => this.put(`${this.prefix}/update-notification-status/${chatId}`, body);

  chatMembers = (chatId: string): Promise<IResponseInterface<{ members: IUser[] }>> => this.get(`${this.prefix}/members/${chatId}`);

  chatMedia = (chatId: string): Promise<IResponseInterface<{ medias: IMessage[] }>> => this.get(`${this.prefix}/media/${chatId}`);

  checkIndividualChat = (userId: string): Promise<IResponseInterface<{ chat: IChat }>> => this.post(`${this.prefix}/individual`, { userId });

  readMessage = (messageId: string): Promise<IResponseInterface<{}>> => this.get(`message/mark-read/${messageId}`);

  deleteChat1v1 = (id: string): Promise<IResponseInterface<{ message: IMessage }>> => this.delete(`${this.prefix}/${id}`)
}
export const chatService = new ChatService();