import {useEffect, useRef, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router";
import {IIdFromUrl} from "../../interfaces/id-from-url.interface";
import {dashboardService} from "../../services/dashboard.service";
import {Toast} from "../../utils/Toast";
import {DashboardLayout} from "../layouts/DashboardLayout";
import Image from "../../assets/images/d-icon/icon.svg";
import {useDebounce} from "../../hooks/use-debounce.hook";
import {FullPageLoader} from "../../utils/FullPageLoader";
import {groupService} from "../../services/group.service";
import {IGroup} from "../../features/organisations/createorganisation/interfaces/group.interface";
import "react-datepicker/dist/react-datepicker.css";
import NoDataFound from "../NoData/NoDataFound";
import moment from "moment";
import {IPaginationInfo} from "../../interfaces/pagination-info.interface";
import {CSVLink} from "react-csv";
import {EPrivacy} from "../../features/auth/pages/signup/interfaces/drop-down.enums";
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from "react-i18next";


export default function DashboardUsers() {
    const [memberVisit, setMemberVisit] = useState<any[]>([]);
    const [search, setSearch] = useState("");
    const [organizationGroups, setOrganizationGroups] = useState<IGroup[]>([]);
    const [isLoading, setIsloading] = useState(true);
    const [paginationInfoViews, setPaginationInfoViews] = useState<IPaginationInfo>();
    const [selectedOption, setSelectedOption] = useState(-69);
    const [csvUsers, setCSVUsers] = useState<any[]>([]);
    const [currentDataCSV, setCurrentDataCSV] = useState<any[]>([]);
    const [debounceSearch] = useDebounce(search, 300);
    let { id } = useParams<IIdFromUrl>();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const { t } = useTranslation('', { keyPrefix: 'DashboardUsers' });
    const csvLink: any = useRef()
    const currentDataLink: any = useRef()
    const location = useLocation();
    const [organizationName, setOrganizationName] = useState(location?.search.replace('?', ''));
    const history = useHistory();
    useEffect(() => {
        getDashboardVisits(1, '', 20)
        getOrganizationGroups(1, '')
    }, [])
    useEffect(() => {
        if (csvUsers && csvUsers.length > 0) {
            csvLink?.current?.link?.click();
            setCSVUsers([]);
        }
    }, [csvUsers])
    useEffect(() => {
        if (currentDataCSV && currentDataCSV.length > 0) {
            currentDataLink?.current?.link?.click();
            setCurrentDataCSV([]);
        }
    }, [currentDataCSV])
    useEffect(() => {
        if (selectedOption == -69) {
            getDashboardVisits(1, debounceSearch, 20);
        } else {
            getMembersByGroup(selectedOption.toString(), 1, debounceSearch);
        }
    }, [debounceSearch]);

    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const getOrganizationGroups = async (page: number, query: string) => {
        setIsloading(true);
        const res: any = await groupService.getOrganizationGroups(id, page, query, 1000);
        setIsloading(false);
        if (res.statusCode == 200) {
            for (const group of res.payload.groups.records) {
                group.childGroups = [];
            }

            setOrganizationGroups([{
                "_id": "-69",
                "name": "Select UserGroup",
                "organization": "6169e2ecdfd86492cd5a1cbf",
                "childGroups": [],
                "creator": "615e155dbbb6280303f1bf38",
                "members": [
                    {
                        "_id": "629d1d97217e486375406ae7",
                        "member": {
                            "_id": "620020738250b70f5c757b65",
                            "name": "Sergino Dest"
                        },
                        "role": "6169e2ecdfd86492cd5a1cc3"
                    }
                ],
                "admins": [
                    {
                        "_id": "6169e2ecdfd86492cd5a1cc6",
                        "member": {
                            "_id": "615e155dbbb6280303f1bf38",
                            "name": "Waleed Ahmad",
                            "avatar": "https://uptod8.s3.amazonaws.com/1344538800-pp.jpeg"
                        },
                        "role": "6169e2ecdfd86492cd5a1cc1"
                    }
                ],
                "startDate": "2022-06-01T19:00:00.000Z",
                "endDate": null,
                "canMemberChat": "YES",
                "createdAt": "2022-06-02T22:05:03.872Z",
                "updatedAt": "2022-06-05T21:18:15.494Z",
            }, ...res.payload.groups.records])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getDashboardVisits = async (page: number, query: string, limit: number, downloadCSV = false) => {
        setIsloading(true);
        const res: any = await dashboardService.getDashboardMemberVisitStats(id, page, limit, query);
        setIsloading(false);
        if (res.statusCode == 200) {
            if (downloadCSV) {
                const csvData = [['User', 'Username', 'Email', 'App Activity Count', 'Last Activity At', 'Org Role', 'Org Policies', 'Groups', 'Joining Date', 'Organization Fields','Assignment Key']];
                for (const iterator of res.payload.organizationMembers.records) {
                    const groupNames = iterator.groups.map(function (item: any) {
                        return item['name'];
                    });
                    const fieldValues: any = []
                    iterator.organizationFieldValues.map((item: any) => {
                        fieldValues.push("{"+item.organizationField.label);
                        fieldValues.push(item.value+"}");
                    })
                    let item = [iterator?.member?.name,
                    iterator?.member?.username,
                    iterator?.member?.emailPrivacy === EPrivacy.EVERY_ONE ? iterator?.member?.email : '',
                    iterator?.member?.appActivityCount || 0,
                    moment(iterator?.member?.appActivityAt).format('YYYY-MM-DD hh:mm'),
                    iterator?.role?.role,
                    iterator?.role?.policies?.toString(),
                    groupNames?.toString(),
                    moment(iterator?.createdAt).format('YYYY-MM-DD hh:mm'),
                    fieldValues.toString(),
                    iterator?.comment ?? ''];
                    csvData.push(item);
                }
                setCSVUsers(csvData)
            } else {
                setPaginationInfoViews(res.payload.organizationMembers.paginationInfo);
                if (page == 1) {
                    setMemberVisit([...res.payload.organizationMembers.records])
                } else {
                    setMemberVisit([...memberVisit, ...res.payload.organizationMembers.records])
                }
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const changeHandler = (event: any) => {
        setSelectedOption(event.target.value);
        if (event.target.value == -69) {
            getDashboardVisits(1, search, 20);
        } else {
            getMembersByGroup(event.target.value, 1, search);
        }
    }
    const getMembersByGroup = async (groupId: string, page: number, query: string) => {
        setIsloading(true);
        const res: any = await dashboardService.getDashboardMemberByGroups(groupId, page, query);
        setIsloading(false);
        if (res.statusCode == 200) {
            // setPaginationInfoVisits(res.payload.organizationMembers.paginationInfo);
            if (page == 1) {
                setMemberVisit([...res.payload.topOrganizationMembers.records])
            } else {
                setMemberVisit([...memberVisit, res.payload.topOrganizationMembers.records])
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const downloadCSV = () => {
        if (search.length === 0) {
            getDashboardVisits(1, search, paginationInfoViews?.totalRecords!, true);
        } else {
            const csvData = [['User', 'Username', 'Email', 'App Activity Count', 'Last Activity At', 'Org Role', 'Org Policies', 'Groups', 'Joining Date', 'Organization Fields','Assignment Key']];
            for (const iterator of memberVisit) {
                const groupNames = iterator.groups.map(function (item: any) {
                    return item['name'];
                });
                const fieldValues: any = []
                iterator.organizationFieldValues.map((item: any) => {
                    fieldValues.push("{"+item.organizationField.label);
                    fieldValues.push(item.value+"}");
                })
                let item = [iterator?.member?.name,
                iterator?.member?.username,
                iterator?.member?.emailPrivacy === EPrivacy.EVERY_ONE ? iterator?.member?.email : '',
                iterator?.member?.appActivityCount || 0,
                moment(iterator?.member?.appActivityAt).format('YYYY-MM-DD hh:mm'),
                iterator?.role?.role,
                iterator?.role?.policies?.toString(),
                groupNames?.toString(),
                moment(iterator?.createdAt).format('YYYY-MM-DD hh:mm'),
                fieldValues.toString(),
                iterator?.comment?? ''];
                csvData.push(item);
            }
            setCurrentDataCSV(csvData)
        }
    }
    const openProfile = (id: string) => {
        history.push(`/memberprofilepage/${id}`)
    }
    return (
        <DashboardLayout id={id} orgName={organizationName}>
            {isLoading && <FullPageLoader />}
            <div className="up-usersholer">
                <div className="up-userfilterholder">
                    <div className="up-userspagetitle">
                        <h2>{t('userData')}</h2>
                    </div>
                    <form className="up-formtheme">
                        <fieldset>
                            <CSVLink
                                data={csvUsers}
                                filename='users.csv'
                                className='hidden'
                                ref={csvLink}
                                target='_blank'
                            />
                            <CSVLink
                                data={currentDataCSV}
                                filename='users.csv'
                                className='hidden'
                                ref={currentDataLink}
                                target='_blank'
                            />
                            <button type='button' onClick={downloadCSV} className="up-btn">{t('downloadCSV')}</button>
                            <div className="up-select">
                                <select className="up-selecttheme" onChange={(e) => changeHandler(e)}>
                                    {organizationGroups && organizationGroups.map((group, index) => {
                                        return <option key={index} value={group._id}>{group.name}</option>;
                                    })}
                                </select>
                            </div>
                            <div className="form-group">
                                <div className="up-inputwithicon">
                                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} name="search" placeholder={t('searchUsers')} />
                                    <i className="icon-search"></i>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="up-userstable">
                    <div className="up-usertableholder">
                        {memberVisit && memberVisit.length > 0 && (
                            <InfiniteScroll
                                dataLength={memberVisit!.length} //This is important field to render the next data
                                next={() => getDashboardVisits(paginationInfoViews?.currentPage! + 1, debounceSearch, 20)}
                                hasMore={memberVisit!.length !== paginationInfoViews?.totalRecords!}
                                loader={<h4>{t('loading')}</h4>}
                                endMessage={
                                    <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                        <b>{t('noMoreUsers')}</b>
                                    </p>
                                }>
                                <table className="up-tabletheme">
                                    <thead>
                                        <tr>
                                            <th>{t('name')}</th>
                                            <th>{t('role')}</th>
                                            <th>{t('visit')}</th>
                                            <th>{t('lastVisit')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {memberVisit.map((user, index) => (<tr>
                                            <td>
                                                <div className="up-usertabledetail" onClick={() => openProfile(user.member._id)}>
                                                    <figure>
                                                        <img style={{ cursor: 'pointer' }} src={user?.member?.avatar || Image} alt="user image" />
                                                    </figure>
                                                    <h4 style={{ cursor: 'pointer' }}>{user?.member?.name}</h4>
                                                </div>
                                            </td>
                                            <td>{user?.role?.role}</td>
                                            <td>{user?.member?.appActivityCount || 0}</td>
                                            <td>{moment(user?.member?.appActivityAt).format('YYYY-MM-DD HH:mm')}</td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        )}
                        {memberVisit && memberVisit.length === 0 && (
                            <NoDataFound />
                        )}

                    </div>
                    {/* <div className="up-paginationholder">
                        <ul className="up-pagination">
                            <li>
                                <p>1-8 of 24</p>
                            </li>
                            <li>
                                <button className="up-btn up-btnpag">
                                    <i className="icon-arrow-left2 "></i>
                                </button>
                            </li>
                            <li>
                                <button className="up-btn up-btnpag">
                                    <i className="icon-arrow-right2"></i>
                                </button>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </DashboardLayout>
    )
}