import { Dropdown } from "react-bootstrap";
import moment from "moment";
import { EEventJoiningStatus } from "../../../interfaces/event-join-status.interface";
import { images } from "../../../assets/images";
import { EPolicy } from "../../../interfaces/organization-policies.enums";
import { URLReplacer } from "../../../utils/url-replacer";
import { IEventProps } from "../../organisations/createorganisation/interfaces/event.interface";

import GoogleMapReact from 'google-map-react';
import Global from '../../../assets/images/2.jpeg';
import Ticket from '../../../assets/images/1.jpeg';
import { useSelector } from "react-redux";
import { getUser } from "../../auth/slice/user.selector";
import { useState } from "react";
import Lightbox from "react-image-lightbox";
import {EBoolean} from "../../auth/pages/signup/interfaces/drop-down.enums";
import {useTranslation} from "react-i18next";

const API_KEY = process.env.GOOGLE_API_KEY;

const AnyReactComponent = (props: any) => {
    return <div><svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="location"><path fill="#e3e2e1" d="M54.01 58.74C54.01 61.65 44.15 64 32 64c-12.15 0-22.01-2.35-22.01-5.26 0-2.6 7.9-4.74 18.26-5.18h7.5c10.37.44 18.26 2.58 18.26 5.18z"></path><path fill="#e82327" d="M32 0C20.7 0 11.54 9.15 11.54 20.45 11.54 31.75 32 58.74 32 58.74s20.45-26.99 20.45-38.29S43.3 0 32 0zm0 33.99c-7.48 0-13.54-6.06-13.54-13.54S24.52 6.91 32 6.91c7.48 0 13.54 6.06 13.54 13.54S39.48 33.99 32 33.99z"></path></svg></div>
};

export const Event: React.FC<IEventProps> = (props) => {
    const { event, index } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [postImages] = useState<any[]>([event?.avatar])
    const [photoIndex, setPhotoIndex] = useState(0);

    const format2 = "YYYY-MM-DD HH:mm"
    const timeFormat = "HH:mm"
    const options = { scrollwheel: false, zoomControl: true };
    const currentUser = useSelector(getUser);
    const { t } = useTranslation('', { keyPrefix: 'ShowEventModal' });

    return <div key={event._id} className="up-event">
        <div className="up-eventhead">
            <div className="up-eventnameimgdate up-eventaddeduserdetail">
                <figure className="up-eventlogoimg">
                    <img src={event?.avatar ?? images.RestPlaceholder} alt="Event Logo Image" />
                </figure>
                <div className="up-eventnamedate">
                    <h3>{event.name}</h3>
                    <span>{event.location}</span>
                    {!props.fromTab && <div>{event.organization.name}</div>}
                    {moment(new Date(event.startDate)).isSame(new Date(event.endDate), 'day') ? <time dateTime="">{moment(event.startDate).format(format2)} {' - '} {moment(event.endDate).format(timeFormat)}</time> : <time dateTime="">{moment(event.startDate).format(format2)} {' - '} {moment(event.endDate).format(format2)}</time>}
                </div>
            </div>
            <ul className="up-eventbuttons">
                {!props.fromAdminDashboard && event.isSubscriptionNeeded === EBoolean.YES && <li>
                    {(moment().isBefore(moment(new Date(event.joiningEndDate))) || event?.joiningStatus) && <select value={event.joiningStatus || ''} onChange={(e) => props.updateJoiningStatus(event, e.target.value, index)}>
                        <option value="" disabled selected>{t('yourAnswer')}</option>
                        <option value={EEventJoiningStatus.JOINING}>{t('joined')}</option>
                        <option value={EEventJoiningStatus.NOT_JOINING}>{t('notJoined')}</option>
                        <option value={EEventJoiningStatus.INTERESTED}>{t('interested')}</option>
                    </select>}
                </li>}
                <li>
                    {event.isExpanded && <button onClick={() => props?.showDetail && props?.showDetail(event._id, index, false)} className="up-btn up-btnclose" type="button">{t('close')}</button>}
                    {!event.isExpanded && <button className="up-btn up-btnviewdetails" onClick={() => props?.showDetail && props?.showDetail(event._id, index, true)} type="button">{t('viewDetail')}</button>}
                </li>

                {props.fromTab && <Dropdown className="up-themedropdown">
                    <Dropdown.Toggle id="dropdown-basic">
                        <i className="icon-menu-icon" style={{color:'black'}}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="up-themedropdownmenu up-eventsdropdownmenu">
                        {(((props.event.creator._id || props.event.creator) == currentUser._id) || props.fromAdminDashboard) && <Dropdown.Item onClick={() => props?.editEvent && props?.editEvent(event, index)}>{t('editEvent')}</Dropdown.Item>}
                        {(props.memberId.role.policies.includes(EPolicy.CAN_DELETE_EVENTS) || props.fromAdminDashboard) && <Dropdown.Item onClick={() => props?.deleteEvent && props?.deleteEvent(event, index)} >{t('deleteEvent')}</Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>}
            </ul>
        </div>
        {isOpen && (
            <Lightbox
                mainSrc={postImages[photoIndex]}
                enableZoom={false}
                nextSrc={postImages[(photoIndex + 1) % postImages.length]}
                prevSrc={postImages[(photoIndex + postImages.length - 1) % postImages.length]}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + postImages.length - 1) % postImages.length)
                }
                onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % postImages.length)
                }
            />
        )}
        {event.isExpanded && <div className="up-eventsdetailsinfo">
            <div className="up-eventdescriptions">
                {event?.avatar && <figure onClick={() => setIsOpen(true)} className="up-evenetdetailimg">
                    <img src={event?.avatar} alt="Event Logo Image" />
                </figure>}
                <h4>{t('invitedGroups')}</h4>
                <button className="up-btnshowallmembers" onClick={() => {
                    props.setSelectedEditEvent && props?.setSelectedEditEvent(event);
                    props.setShowDetailModal(true);
                }}>{t('showGuestsAnswers')}</button>
                {/* <div > */}
                {event.displayTo.length > 0 && <div className="up-hashtag">{event.displayTo.map((item => <span>{item?.name || item?.role}</span>))}</div>}
                {event.displayTo.length == 0 && <div className="up-hashtag"><span>{t('public')}</span></div>}
                {/* </div> */}
                <div className="up-description">
                    <div className="up-descriptiontitle">
                        <h4>{t('description')}</h4>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: URLReplacer(event.description) }}></p>
                    {event.lat && event.long && event.location && <div style={{ height: '40vh', width: '80%', margin: '50px auto 0' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: API_KEY! }}
                            defaultCenter={{ lat: +event.lat, lng: +event.long }}
                            defaultZoom={15}
                            options={options}
                        >
                            <AnyReactComponent
                                lat={+event.lat}
                                lng={+event.long}
                                text={event.location}
                            />
                        </GoogleMapReact>
                    </div>}
                    <ul className="up-ticketgloballinkholder">
                        {props?.event?.url && <li onClick={() => {
                            window.open(props?.event?.url, "_blank")
                        }}>
                            <figure>
                                <img src={Global} alt="" />
                            </figure>
                            <span style={{ color: '#0d6efd', cursor: 'pointer' }} onClick={() => {
                                window.open(props?.event?.url, "_blank")
                            }} title={props?.event?.url}>{props?.event?.url}</span>
                        </li>}
                        {props?.event?.ticket && <li onClick={() => {
                            window.open(props?.event?.ticket, "_blank")
                        }}>
                            <figure>
                                <img src={Ticket} alt="" />
                            </figure>
                            <span style={{ color: '#0d6efd', cursor: 'pointer' }} onClick={() => {
                                window.open(props?.event?.ticket, "_blank")
                            }} title={props?.event?.ticket}>{props?.event?.ticket}</span>
                        </li>}
                    </ul>
                </div>
            </div>
        </div>}
    </div>

}