export enum ENotificationType {
    ORGANIZATION_FOLLOW_REQUEST = 'ORGANIZATION_FOLLOW_REQUEST',
    ORGANIZATION_FOLLOW_REQUEST_RESPONSE = 'ORGANIZATION_FOLLOW_REQUEST_RESPONSE',
    ORGANIZATION_FOLLOW_REQUEST_REJECTED = 'ORGANIZATION_FOLLOW_REQUEST_REJECTED',
    COMMENT_ADDED = 'COMMENT_ADDED',
    TAGGED_ON_COMMENT = 'TAGGED_ON_COMMENT',
    GROUP_MEMBER_ADDED = 'GROUP_MEMBER_ADDED',
    EVENT_REMAINDER = 'EVENT_REMAINDER',
    ORGANIZATION_MEMBER_INVITE = 'ORGANIZATION_MEMBER_INVITE',
    NEW_POST_ADDED = 'NEW_POST_ADDED',
    NEW_POST_REQUESTED_ADDED = 'NEW_POST_REQUESTED_ADDED',
    POST_REQUEST_APPROVED = 'POST_REQUEST_APPROVED',
    POST_REQUEST_REJECTED = 'POST_REQUEST_REJECTED',
    NEW_EVENT_ADDED = 'NEW_EVENT_ADDED',
    POST_UPDATED = 'POST_UPDATED',
    SHARE_POST_IN_ORGANIZATION = 'SHARE_POST_IN_ORGANIZATION',
    POST_LIKED = 'POST_LIKED',
    EVENT_UPDATED = 'EVENT_UPDATED',
}

  export enum EWhich {
    Organization = 'Organization',
    Post = 'Post',
    Group = 'Group',
    Event = 'Event',
  }

  export enum EEventNotificationType {
    IN_APP_NOTIFICATION = 'IN_APP_NOTIFICATION',
    EMAIL = 'EMAIL',
  }