import ProfileImage from "../../assets/images/user-profile.jpg";

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { DashboardLayout } from "../layouts/DashboardLayout";


export default function Profile() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        // <DashboardLayout>
            <div className="up-profileholder">
                <div className="up-profilecontent">
                    <form className="up-formtheme">
                        <fieldset>
                            <div className="up-profileimageupdate">
                                <figure>
                                    <img src={ProfileImage} alt="user image" />
                                    <figcaption>
                                        <input type="file" name="file" id="up-changeprofileimage" />
                                        <label htmlFor="up-changeprofileimage">
                                            <i className="icon-pencil"></i>
                                        </label>
                                    </figcaption>
                                </figure>
                                <h3>Lina Osborn</h3>
                            </div>
                            <div className="up-inputwidthfifty">
                                <div className="form-group">
                                    <label>First Name </label>
                                    <input type="text" placeholder="Lina" />
                                </div>
                                <div className="form-group">
                                    <label>Last Name </label>
                                    <input type="text" placeholder="Osborn" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" placeholder="account@mail.com" />
                            </div>
                            <div className="form-group up-inputwithpassword">
                                <label>Password</label>
                                <input type="password" placeholder="● ● ● ● ● ● ● ● ● ●" />
                                <Button variant=" up-btnmodalchangepassword" onClick={handleShow}>
                                    Change Password
                                </Button>
                                <Modal show={show} onHide={handleClose} className="up-changepasswordmodal">
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Want to Change Password?
                                            <span>Changes are successfully saved</span>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <form className="up-formtheme">
                                            <fieldset>
                                                <div className="form-group">
                                                    <input type="text" placeholder="Current Password" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" placeholder="New Password" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" placeholder="Confirm Password" />
                                                </div>
                                                <Button variant=" up-btn up-btn-lg" onClick={handleClose}>
                                                    Change Password
                                                </Button>
                                            </fieldset>
                                        </form>
                                    </Modal.Body>
                                </Modal>
                            </div>
                            <div className="up-btnholder">
                                <a href="javascript: void(0);" className="up-btn up-btncancel">Discard</a>
                                <a href="javascript: void(0);" className="up-btn">Save Changes</a>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        // </DashboardLayout>
    )
}