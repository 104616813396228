import { useState, useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap"
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
export const ImageCropper: React.FC<any> = (props) => {
    const [image, setImage] = useState(props.image);
    const [croppedArea, setCroppedArea] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedImage, setCroppedImage] = useState(null)


    useEffect(() => {
        const reader = new FileReader();
        reader.readAsDataURL(props.image);
        reader.addEventListener("load", () => {
            setImage(reader.result);
        });
    }, [])
    const createImage = (url: any) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", (error) => reject(error));
            image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
            image.src = url;
        });
    function getRadianAngle(degreeValue: any) {
        return (degreeValue * Math.PI) / 180;
    }
    const getCroppedImg = async (imageSrc: any, pixelCrop: any, rotation = 0) => {
        const image: any = await createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx: any = canvas.getContext("2d");

        const maxSize = Math.max(image.width, image.height);
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea;
        canvas.height = safeArea;

        // translate canvas context to a central location on image to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.rotate(getRadianAngle(rotation));
        ctx.translate(-safeArea / 2, -safeArea / 2);

        // draw rotated image and store data.
        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        );

        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
            data,
            0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
            0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
        );

        // As Base64 string
        // return canvas.toDataURL("image/jpeg");
        return canvas;
    }
    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
        setCroppedArea(croppedAreaPixels);
    }, [])
    const dataURLtoFile = (dataurl: any, filename: any) => {
        const arr = dataurl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) u8arr[n] = bstr.charCodeAt(n);

        return new File([u8arr], filename, { type: mime });
    };
    const getImage = async () => {

        const canvas = await getCroppedImg(image, croppedArea);
        canvas.toBlob(
            (blob: any) => {
                const previewUrl = window.URL.createObjectURL(blob);
                props.croppedImage(previewUrl,blob);
            },
            "image/jpeg",
            0.95
        );
    }

    const closeDisplayToModal = () => {
        props.closeModal(true)
    }
    return <Modal className="up-thememodal up-modalcreateupdates" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>Crop Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <div className='cropper'>
                    <Cropper
                        image={image!}
                        crop={crop}
                        zoom={zoom}
                        aspect={16 / 9}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </div>

                <div className='slider'>
                    <Slider
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onChange={(e: any, zoom: any) => setZoom(zoom)}
                    />
                </div>
                <button style={{display: 'block', margin: '0 auto', clear: 'both', width: '100px'}} onClick={() => getImage()} className="up-btn">Upload</button>
            </div>
        </Modal.Body>
    </Modal>
}