// import { styles } from "./"

import { images } from "../../../../assets/images";
import { SignUpForm } from "./components/SignUpForm";
import {useTranslation} from "react-i18next";


export const SignUpPage = () => {
    const { t } = useTranslation('', { keyPrefix: 'SignUp' });
  return (
    <div className="up-loginarea up-signuparea">
      <figure className="up-circlimages">
        <img src={images.LoginCircles} alt="Circles"/>
      </figure>
      <div className="up-logincontentarea">
        <h3>{t('secureSocial')}<span>{t('networkIsHere')}</span></h3>
        <div className="up-description">
          <p>{t('description')}</p>
        </div>
      </div>
      <div className="up-loginformholder">
        <div className="up-loginformcontentholder">
          <strong className="up-logo">
            <img src={images.Logo} alt="Logo Image"/>
          </strong>
          <div className="up-formHead">
            <h1>{t('signUp')}</h1>
            <span>{t('enterEmailText')}</span>
          </div>
          <SignUpForm/>
        </div>
      </div>
    </div>
  )
};
