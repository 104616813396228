import React, {useState, useEffect, useRef} from "react";
import { Modal } from "react-bootstrap"
import { postService } from "../../services/post.service";
import NoDataFound from "../NoData/NoDataFound";
import { images } from "../../assets/images";
import moment from "moment";
import {CSVLink} from "react-csv";
import {IPost} from "../../features/organisations/createorganisation/interfaces/post.interface";
import {useTranslation} from "react-i18next";

interface IProps {
    closeModal: () => void;
    id: string;
    post: IPost;
}
export const VotingDetailModal: React.FC<any> = (props: IProps) => {

    const [options, setOptions] = useState<any[]>([]);
    const [csvPost, setCsvPost] = useState<any[]>([]);
    const csvLink: any = useRef()
    const { t } = useTranslation('', { keyPrefix: 'VotingDetailModal' });
    useEffect(() => {
        getVotingDetails();
    }, [])
    useEffect(() => {
        if (csvPost && csvPost.length > 0) {
            csvLink?.current?.link?.click();
            setCsvPost([]);
        }
    }, [csvPost])
    const closeDisplayToModal = () => {
        props.closeModal()
    }
    const getVotingDetails = async () => {
        const res: any = await postService.getVotingDetail(props.id)
        if (res.statusCode === 200) {
            setOptions(res.payload.pollVoters)
        }
    }
    const downLoadCSV = ()=> {
        const {post} = props
        const csvData = [['Poll Organizer', 'Poll Closing Date', 'Voter Name', 'Voter Username', 'Voter Answer','Voted On Timestamp']];
        for (let item of options) {
            csvData.push([post.creator.name,post.pollEndsAt,item.votedBy.name,item.votedBy.username,item.pollOption.option,item.updatedAt])
        }
        setCsvPost(csvData)
    }
    return <Modal className="up-thememodal up-invitemembersmodal" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('votingDetails')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder up-modalheightset">
                <CSVLink
                    data={csvPost}
                    filename={`Votes for Poll.csv`}
                    className='hidden'
                    ref={csvLink}
                    target='_blank'
                />
                
                <div className="up-userstable">
                    <div className="up-usertableholder">
                        {options && options.length > 0 && (
                            <table className="up-tabletheme">
                                <thead>
                                    <tr>
                                        <th>{t('user')}</th>
                                        <th>{t('vote')}</th>
                                        <th>{t('votedOn')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {options.map((option, index) => (<tr>
                                        <td>
                                            <div className="up-usertabledetail" style={{ marginBottom: '12px' }}>
                                                <figure>
                                                    <img src={option.votedBy?.avatar || images.UserPlaceholder} alt="user image" />
                                                </figure>
                                                <h4>{option.votedBy?.name}</h4>
                                            </div>
                                        </td>
                                        <td>{option.pollOption.option}</td>
                                        <td>{moment(option.updatedAt).format('YYYY-MM-DD HH:mm')}</td>

                                    </tr>))}
                                </tbody>
                            </table>
                        )}
                        {options && options.length === 0 && (
                            <NoDataFound />
                        )}
                    </div>
                </div>
            </div>
            <button className="up-btn mb-3 mx-4" style={{float: 'right'}} onClick={downLoadCSV}>{t('downloadCSV')}</button>
        </Modal.Body>
    </Modal>
}
