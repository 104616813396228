// import react form 'react';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { routes } from '../../../../../router/routes';
import { userService } from '../../../../../services/user.service';
import { FullPageLoader } from '../../../../../utils/FullPageLoader';
import { Toast } from '../../../../../utils/Toast';
import { setLogin } from '../../../slice/user.slice';
import {useTranslation} from "react-i18next";

export const EmailVerifyForm: React.FC<any> = (props) => {
    const [isLoading, setIsloading] = useState(false);
    const [otp, setOtp] = useState('');
    let history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation('', { keyPrefix: 'VerifyEmail' });
    const handleSubmit = async () => {
        if (otp.length == 5) {
            setIsloading(true)
            try {
                const res: any = await userService.verifyEmail2FA({
                    email: props.email,
                    otpCode: otp
                });
                setIsloading(false)
                if (res.statusCode == 200) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Email verified!'
                    })
                    await dispatch(setLogin({
                        token: res.payload.token,
                        user: res.payload.user
                    }))
                    history.push(routes.profileSetup);
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: res.message
                    })
                }
            } catch (error: any) {
                setIsloading(false)
                setOtp('')
                Toast.fire({
                    icon: 'warning',
                    title: error.response.data.message
                })
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: 'Please enter full code'
            })
        }
    }

    const resendCode = async () => {
        setIsloading(true)
        const res: any = await userService.resendEmail2FA({
            email: props.email
        });
        setIsloading(false)
        if (res.statusCode == 200) {
            Toast.fire({
                icon: 'success',
                title: 'Verification email resent!'
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    return (
        <form className="up-formtheme up-formsignup up-formverifyemail ">
            <fieldset>
                {isLoading && <FullPageLoader />}
                <div className='up-flexcenter'>
                    <OtpInput
                        inputStyle={{
                            width: '3rem',
                            padding: '0',
                        }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={5}
                        separator={<span>-</span>}
                    />
                </div>
                <div className="form-group">
                    <button onClick={handleSubmit} type="button" className="up-btn up-btnwithloader">{t('verify')}</button>
                </div>
                <div className="up-alreadyahveaccount">
                    <h2> <span className="d-block">{t('textOne')}</span>{t('textTwo')}<a onClick={resendCode} href="javascript:void(0);">{t('resendEmail')}</a></h2>
                </div>
            </fieldset>
        </form>
    )
};
export default EmailVerifyForm;