import { ICreateEvent } from "../features/organisations/createorganisation/interfaces/create-event.interface";
import { IGroup } from "../features/organisations/createorganisation/interfaces/group.interface";
import { IResponseInterface } from "../interfaces/api-response.interface";
import { HttpService } from "./base.service";

class EventService extends HttpService {
    private readonly eventPrefix: string = "event";
  
  createEvent = (data: ICreateEvent): Promise<IResponseInterface<{}>> => this.post(`${this.eventPrefix}`, data);

  getOrganizationEvents = (id: string,page:number): Promise<IResponseInterface<{}>> => this.get(`${this.eventPrefix}/organization/${id}?page=${page}&limit=15`)

  joinEvent = (eventId:string,data:any): Promise<IResponseInterface<{}>> => this.put(`${this.eventPrefix}/join/${eventId}`,data)
 
  leaveEvent = (data:any): Promise<IResponseInterface<{}>> => this.put(`${this.eventPrefix}/leave`,data)

  deleteEvent = (eventId:string): Promise<IResponseInterface<{}>> => this.delete(`${this.eventPrefix}/${eventId}`);

  updateEvent = (eventId:string,data:any): Promise<IResponseInterface<{}>> => this.put(`${this.eventPrefix}/${eventId}`,data)

  getUserEvents = (page:number, query:string, organizationId? : string): Promise<IResponseInterface<{groups: IGroup[]}>> => this.get(`${this.eventPrefix}/my?page=${page}&limit=10${organizationId?'&organizationId='+organizationId: ''}&query=${query}`)

  joinEventOrganization = (data:any): Promise<IResponseInterface<{}>> => this.put(`${this.eventPrefix}/join/organization`,data)

  getDateEvents = (date: string): Promise<IResponseInterface<{}>> => this.get(`${this.eventPrefix}/on/${date}`)

  getEventCounts = (startDate: string,endDate: string ): Promise<IResponseInterface<{}>> => this.get(`event/counts?startDate=${startDate}&endDate=${endDate}`)

  getEventDetail = (id: string): Promise<IResponseInterface<{}>> => this.get(`${this.eventPrefix}/${id}`);

}
  export const eventService = new EventService();