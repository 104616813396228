import * as yup from "yup";

var fbPattern = /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
var twitterPatter = /^(?:(?:http|https):\/\/)?(?:www.)?twitter.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
var instagramPatten = /^(?:(?:http|https):\/\/)?(?:www.)?instagram.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
var linkedinPatten = /^(?:(?:http|https):\/\/)?(?:www.)?linkedin.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;

export const editProfileSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  username: yup.string().required('Username is required'),
  // dob: yup.date().required('Date of birth is required'),
  postalCode: yup.string().required('PostalCode is required'),
  address: yup.string().required('address is required'),
  bio: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr),
  facebookLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(fbPattern,'Not a facebook url'),
  twitterLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(twitterPatter,'Not a twitter url'),
  instagramLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(instagramPatten,'Not a instagram url'),
  linkedinLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(linkedinPatten,'Not a linkedIn url'),
}).required();