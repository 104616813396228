export const routes = {
  login: "/login",
  signup: "/signup",
  verifyemail: "/verify-email/:email",
  profileSetup: "/profile-setup",
  privacySettings: "/privacy-settings",
  followOrganizations: "/follow-organizations",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  home: "/home",
  dashboardHome: "/dashboard/:id",
  dashboardUsers: "/dashboard-users/:id",
  dashboardEvents: "/dashboard-events/:id",
  dashboardUpdates: "/dashboard-updates/:id",
  dashboardProfile: "/dashboard-profile/:id",
  dashboardUserGroups: "/dashboard-user-groups/:id",
  dashboardUserGroupDetail: "/dashboard-user-groups/:id/group-detail/:groupId",
  dashboardUserData: "/dashboard-user-data/:id",
  allOrganizations: "/all-organizations",
  organisationprofile: "/organisation-profile",
  createogranisation: "/create-organisation",
  allfiles: "/all-files",
  eventspage: "/events",
  shortCutsPage: "/shortcuts",
  groups: "/users-groups",
  profilepage: "/profile-page",
  allnotifications: "/all-notifications",
  allChatPage: "/all-chat",
  termsandconditions: "/termsandconditions",
  gdprPolicy: "/gdpr-policy",
  memberprofilepage: "/memberprofilepage/:id",
  organizationprofile: "/organization-profile/:id",
  publicOrganizationProfile: "/public-organization-profile/:id",
  postDetails: "/post-detail/:id",
  postSearch: "/post-search",
  organisationsetup: "/organisation-setup/:id",
  organisationsociallinks: "/organisation-links/:id",
  default: "/",
  adminLogin: "/admin/login",
  adminDashboard: "/admin/dashboard"
};
