import { createSlice } from "@reduxjs/toolkit";
import { CreateOrganisation, GetOrganisationTypes } from "./organisation.thunks";
import { IOrganisationState } from "./types";

const initialState: IOrganisationState = {
  isLoading: false
};

const organisationReducer = createSlice({
  name: "organisationReducer",
  initialState,
  reducers: {

  },
  extraReducers: {
    [CreateOrganisation.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [CreateOrganisation.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
    },
    [CreateOrganisation.rejected.toString()]: (state, action) => {
      state.isLoading = false;
    },
    [GetOrganisationTypes.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetOrganisationTypes.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
    },
    [GetOrganisationTypes.rejected.toString()]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { } = organisationReducer.actions;

export default organisationReducer.reducer;
