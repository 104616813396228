import { useState } from "react";
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { Dropdown } from "react-bootstrap";
import { images } from "../../../assets/images";
import { ThemeCalendar } from "../../themecomponents/ThemeCalendar";

export const OrganisationProfileTabs = () => {
    const [show, setShow] = useState(false);
    const [openEventDetail, setOpenEventDetail] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="up-organiserprofiletabarea">
            <Tabs defaultActiveKey="timeline" id="uncontrolled-tab-example" className="up-profiletabs">
                <Tab eventKey="timeline" title="Timeline">
                    <div className="up-timlines">
                        <div className="up-twocolumns">
                            <div className="up-postsholder">
                                <div className="up-postsarea">
                                    <div className="up-post">
                                        <div className="up-posthead">
                                            <div className="up-postnameuserimage">
                                                <figure className="up-userimage">
                                                    <img src={images.UserOne} alt="User Image" />
                                                </figure>
                                                <div className="up-postheadcontent">
                                                    <h3><a href="javascript:void(0);">Laur­a AJ </a>Posted in the Organization <a href="javascript:void(0);">Fashion Craze</a></h3>
                                                    <span>Public . 2 min ago</span>
                                                </div>
                                                <Dropdown className="up-themedropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i className="icon-menu-icon"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="up-themedropdownmenu">
                                                        <Dropdown.Item href="javascript:void(0);" >Edit</Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);" >Delete Post</Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);" >Pin to top</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="up-postdescription">
                                            <p>Ethically sourced skulls from animals that have already died. Beautiful art to cherish life, even after death.</p>
                                        </div>
                                        <div className="up-postsimagearea">
                                            <figure className="up-postimage">
                                                <img src={images.PostPlaceHolder} alt="up-postimage" />
                                            </figure>
                                        </div>
                                        <div className="up-postreactionsharearea">
                                            <div className="up-reactpostholder">
                                                <Dropdown className="up-themedropdown up-reactdropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        {/* <i className="icon-menu-icon"></i> */}
                                                        <img src={images.ReactIcon} alt="Rect Icon" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="up-themedropdownmenu">
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.LikeIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.HappyIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.SurpriseIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.LoveIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.SadIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className="up-sharecommentholder">
                                                <a href="javascript:void(0);" className="up-btncomments">853 Comments</a>
                                                <Dropdown className="up-themedropdown up-reactdropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        {/* <i className="icon-menu-icon"></i> */}
                                                        <img src={images.ShareIcon} alt="Share Icon" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="up-themedropdownmenu">
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.TwitterIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.PinterestIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.FacebookIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.InstagramIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="up-commentsarea">
                                            <div className="up-sectiontitle">
                                                <h3>Comments</h3>
                                            </div>
                                            <ul className="up-comments">
                                                <li>
                                                    <div className="up-comment">
                                                        <figure className="up-avatar">
                                                            <img src={images.UserOne} alt="Avatar Image" />
                                                        </figure>
                                                        <div className="up-commentscontent">
                                                            <h4>Ajay Nagar <time dateTime="2022-12-12">May 12 16:40</time></h4>
                                                            <div className="up-description">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eleifend lectus ligula. Fusce mi ligula, consequat sit amet enim quis, finibus varius arcu.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="up-comment">
                                                        <figure className="up-avatar">
                                                            <img src={images.UserTwo} alt="Avatar Image" />
                                                        </figure>
                                                        <div className="up-commentscontent">
                                                            <h4>Ajay Nagar <time dateTime="2022-12-12">May 12 16:40</time></h4>
                                                            <div className="up-description">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eleifend lectus ligula. Fusce mi ligula, consequat sit amet enim quis, finibus varius arcu.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <form className="up-formtheme up-formwritemessage">
                                                <fieldset>
                                                    <div className="form-group">
                                                        <figure className="up-ownimage">
                                                            <img src={images.UserFour} alt="Own Image" />
                                                        </figure>
                                                        <textarea placeholder="Write your text here..." />
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="up-post up-addpost">
                                        <h4>ad</h4>
                                    </div>
                                    <div className="up-post">
                                        <div className="up-posthead">
                                            <div className="up-postnameuserimage">
                                                <figure className="up-userimage">
                                                    <img src={images.UserFive} alt="User Image" />
                                                </figure>
                                                <div className="up-postheadcontent">
                                                    <h3><a href="javascript:void(0);">Laur­a AJ </a>Posted in the Organization <a href="javascript:void(0);">Fashion Craze</a></h3>
                                                    <span>Public . 2 min ago</span>
                                                </div>
                                                <Dropdown className="up-themedropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i className="icon-menu-icon"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="up-themedropdownmenu">
                                                        <Dropdown.Item href="javascript:void(0);" >Edit</Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);" >Delete Post</Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);" >Pin to top</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="up-postdescription">
                                            <p>Ethically sourced skulls from animals that have already died. Beautiful art to cherish life, even after death.</p>
                                        </div>
                                        <div className="up-postsimagearea">
                                            <figure className="up-postimage">
                                                <img src={images.PostPlaceHolder} alt="up-postimage" />
                                            </figure>
                                        </div>
                                        <div className="up-postreactionsharearea">
                                            <div className="up-reactpostholder">
                                                <Dropdown className="up-themedropdown up-reactdropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        {/* <i className="icon-menu-icon"></i> */}
                                                        <img src={images.ReactIcon} alt="Rect Icon" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="up-themedropdownmenu">
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.LikeIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.HappyIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.SurpriseIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.LoveIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.SadIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className="up-sharecommentholder">
                                                <a href="javascript:void(0);" className="up-btncomments">853 Comments</a>
                                                <Dropdown className="up-themedropdown up-reactdropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        {/* <i className="icon-menu-icon"></i> */}
                                                        <img src={images.ShareIcon} alt="Share Icon" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="up-themedropdownmenu">
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.TwitterIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.PinterestIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.FacebookIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.InstagramIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="up-commentsarea">
                                            <div className="up-sectiontitle">
                                                <h3>Comments</h3>
                                            </div>
                                            <ul className="up-comments">
                                                <li>
                                                    <div className="up-comment">
                                                        <figure className="up-avatar">
                                                            <img src={images.UserOne} alt="Avatar Image" />
                                                        </figure>
                                                        <div className="up-commentscontent">
                                                            <h4>Ajay Nagar <time dateTime="2022-12-12">May 12 16:40</time></h4>
                                                            <div className="up-description">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eleifend lectus ligula. Fusce mi ligula, consequat sit amet enim quis, finibus varius arcu.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="up-comment">
                                                        <figure className="up-avatar">
                                                            <img src={images.UserTwo} alt="Avatar Image" />
                                                        </figure>
                                                        <div className="up-commentscontent">
                                                            <h4>Ajay Nagar <time dateTime="2022-12-12">May 12 16:40</time></h4>
                                                            <div className="up-description">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eleifend lectus ligula. Fusce mi ligula, consequat sit amet enim quis, finibus varius arcu.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <form className="up-formtheme up-formwritemessage">
                                                <fieldset>
                                                    <div className="form-group">
                                                        <figure className="up-ownimage">
                                                            <img src={images.UserFour} alt="Own Image" />
                                                        </figure>
                                                        <textarea placeholder="Write your text here..." />
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="up-post">
                                        <div className="up-posthead">
                                            <div className="up-postnameuserimage">
                                                <figure className="up-userimage">
                                                    <img src={images.UserOne} alt="User Image" />
                                                </figure>
                                                <div className="up-postheadcontent">
                                                    <h3><a href="javascript:void(0);">Laur­a AJ </a>Posted in the Organization <a href="javascript:void(0);">Fashion Craze</a></h3>
                                                    <span>Public . 2 min ago</span>
                                                </div>
                                                <Dropdown className="up-themedropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i className="icon-menu-icon"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="up-themedropdownmenu">
                                                        <Dropdown.Item href="javascript:void(0);" >Edit</Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);" >Delete Post</Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);" >Pin to top</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="up-postdescription">
                                            <p>Ethically sourced skulls from animals that have already died. Beautiful art to cherish life, even after death.</p>
                                        </div>
                                        <div className="up-postsimagearea">
                                            <figure className="up-postimage">
                                                <img src={images.PostPlaceHolder} alt="up-postimage" />
                                            </figure>
                                        </div>
                                        <div className="up-postreactionsharearea">
                                            <div className="up-reactpostholder">
                                                <Dropdown className="up-themedropdown up-reactdropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        {/* <i className="icon-menu-icon"></i> */}
                                                        <img src={images.ReactIcon} alt="Rect Icon" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="up-themedropdownmenu">
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.LikeIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.HappyIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.SurpriseIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.LoveIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.SadIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className="up-sharecommentholder">
                                                <a href="javascript:void(0);" className="up-btncomments">853 Comments</a>
                                                <Dropdown className="up-themedropdown up-reactdropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        {/* <i className="icon-menu-icon"></i> */}
                                                        <img src={images.ShareIcon} alt="Share Icon" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="up-themedropdownmenu">
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.TwitterIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.PinterestIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.FacebookIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="javascript:void(0);">
                                                            <img src={images.InstagramIcon} alt="Like Icon" />
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="up-commentsarea">
                                            <div className="up-sectiontitle">
                                                <h3>Comments</h3>
                                            </div>
                                            <ul className="up-comments">
                                                <li>
                                                    <div className="up-comment">
                                                        <figure className="up-avatar">
                                                            <img src={images.UserOne} alt="Avatar Image" />
                                                        </figure>
                                                        <div className="up-commentscontent">
                                                            <h4>Ajay Nagar <time dateTime="2022-12-12">May 12 16:40</time></h4>
                                                            <div className="up-description">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eleifend lectus ligula. Fusce mi ligula, consequat sit amet enim quis, finibus varius arcu.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="up-comment">
                                                        <figure className="up-avatar">
                                                            <img src={images.UserTwo} alt="Avatar Image" />
                                                        </figure>
                                                        <div className="up-commentscontent">
                                                            <h4>Ajay Nagar <time dateTime="2022-12-12">May 12 16:40</time></h4>
                                                            <div className="up-description">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eleifend lectus ligula. Fusce mi ligula, consequat sit amet enim quis, finibus varius arcu.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <form className="up-formtheme up-formwritemessage">
                                                <fieldset>
                                                    <div className="form-group">
                                                        <figure className="up-ownimage">
                                                            <img src={images.UserFour} alt="Own Image" />
                                                        </figure>
                                                        <textarea placeholder="Write your text here..." />
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <aside className="up-sidebar">
                                <div className="up-widget up-widgetaboutorganisation">
                                    <div className="up-widgethead up-textalignleft">
                                        <h3>Organisation Info</h3>
                                    </div>
                                    <div className="up-organisationcontent">
                                        <div className="up-description">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rhoncus odio quam, interdum aliquet enim varius vel. Nullam ac eleifend lectus, et sollicitudin risus.</p>
                                        </div>
                                        <ul className="up-organisationinfo">
                                            <li>
                                                <span>E-mail: </span>
                                                <span>info@gmail.com</span>
                                            </li>
                                            <li>
                                                <span>Address: </span>
                                                <span>59 Street Neworkcity</span>
                                            </li>
                                            <li>
                                                <span>Phone: </span>
                                                <span>+123 9856836</span>
                                            </li>
                                            <li className="up-websitelink">
                                                <span>Web: </span>
                                                <span><a href="javascript:void(0);">www.fash­­zioncraze.com</a></span>
                                            </li>
                                            <li>
                                                <span>Social: </span>
                                                <div className="up-socialicons">
                                                    <a className="up-twittersocial" href="javascript:void(0);">
                                                        <i className="icon-twitter"></i>
                                                    </a>
                                                    <a className="up-pinterestsocial" href="javascript:void(0);">
                                                        <i className="icon-pinterest"></i>
                                                    </a>
                                                    <a className="up-facebooksocial" href="javascript:void(0);">
                                                        <i className="icon-facebook"></i>
                                                    </a>
                                                    <a className="up-instagramsocial" href="javascript:void(0);">
                                                        <i className="icon-instagram"></i>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="up-widget up-widgetadmin">
                                    <div className="up-widgethead up-textalignleft">
                                        <h3>Organisation Admin</h3>
                                    </div>
                                    <div className="up-membersarea">
                                        <div className="up-member">
                                            <figure className="up-memberimg">
                                                <img src={images.MemberOne} alt="Member Image" />
                                            </figure>
                                            <div className="up-membername">
                                                <h4>Aahat Akter</h4>
                                                <span>@Aahat</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="up-widget up-widgetallmembers">
                                    <div className="up-widgethead up-textalignleft">
                                        <h3>Members</h3>
                                    </div>
                                    <div className="up-membersarea">
                                        <div className="up-member">
                                            <figure className="up-memberimg">
                                                <img src={images.MemberOne} alt="Member Image" />
                                            </figure>
                                            <div className="up-membername">
                                                <h4>Aahat Akter</h4>
                                                <span>@Aahat</span>
                                            </div>
                                        </div>
                                        <div className="up-member">
                                            <figure className="up-memberimg">
                                                <img src={images.MemberTwo} alt="Member Image" />
                                            </figure>
                                            <div className="up-membername">
                                                <h4>Kazi Rahman</h4>
                                                <span>@Kazi</span>
                                            </div>
                                        </div>
                                        <div className="up-member">
                                            <figure className="up-memberimg">
                                                <img src={images.MemberThree} alt="Member Image" />
                                            </figure>
                                            <div className="up-membername">
                                                <h4>Alia Karon</h4>
                                                <span>@Alia</span>
                                            </div>
                                        </div>
                                        <div className="up-member">
                                            <figure className="up-memberimg">
                                                <img src={images.MemberFour} alt="Member Image" />
                                            </figure>
                                            <div className="up-membername">
                                                <h4>Masterero</h4>
                                                <span>@Masterero</span>
                                            </div>
                                        </div>
                                        <div className="up-member">
                                            <figure className="up-memberimg">
                                                <img src={images.MemberFive} alt="Member Image" />
                                            </figure>
                                            <div className="up-membername">
                                                <h4>Michelle Jones</h4>
                                                <span>@Michelle</span>
                                            </div>
                                        </div>
                                    </div>
                                    <a className="up-btn" href="javascript:void(0);">See All Members</a>
                                </div>
                                <div className="up-widget up-widgetadd">
                                    <h5>ad</h5>
                                </div>
                                <div className="up-widget up-widgetcreateddate">
                                    <div className="up-widgethead up-textalignleft">
                                        <h3>Created Date</h3>
                                    </div>
                                    <div className="up-organisationcreateddate">
                                        <span>May 03</span>
                                        <h4>2021</h4>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="about" title="About">
                    <div className="up-organisationaboutinfo">
                        <div className="up-themebox">
                            <div className="up-organisationcontent">
                                <div className="up-themeboxhead">
                                    <h3>Description</h3>
                                </div>
                                <div className="up-description">
                                    <p>Aenean ornare diam congue ullamcorper sodales. Etiam malesuada neque et sollicitudin euismod. Praesent ac rutrum tellus. Nunc porta mauris non nibh volutpat, ac lobortis ex pharetra. Integer luctus sed erat sed euismod. Ut blandit pretium elit, vel facilisis tellus mollis sed. Nam congue pretium velit, sit amet tempus lorem ultrices in.</p>
                                </div>
                                <div className="up-contactorganisation">
                                    <div className="up-themeboxhead">
                                        <h3>Contact</h3>
                                    </div>
                                    <ul className="up-organisationinfo">
                                        <li>
                                            <span>E-mail: </span>
                                            <span>info@gmail.com</span>
                                        </li>
                                        <li>
                                            <span>Address: </span>
                                            <span>59 Street Neworkcity</span>
                                        </li>
                                        <li>
                                            <span>Phone: </span>
                                            <span>+123 9856836</span>
                                        </li>
                                        <li className="up-websitelink">
                                            <span>Web: </span>
                                            <span><a href="javascript:void(0);">www.fash­­zioncraze.com</a></span>
                                        </li>
                                        <li>
                                            <span>Social: </span>
                                            <div className="up-socialicons">
                                                <a className="up-twittersocial" href="javascript:void(0);">
                                                    <i className="icon-twitter"></i>
                                                </a>
                                                <a className="up-pinterestsocial" href="javascript:void(0);">
                                                    <i className="icon-pinterest"></i>
                                                </a>
                                                <a className="up-facebooksocial" href="javascript:void(0);">
                                                    <i className="icon-facebook"></i>
                                                </a>
                                                <a className="up-instagramsocial" href="javascript:void(0);">
                                                    <i className="icon-instagram"></i>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="up-organisationhistory">
                                    <div className="up-themeboxhead">
                                        <h3>History</h3>
                                    </div>
                                    <ul className="up-organisationinfo">
                                        <li>
                                            <span>Created Date: </span>
                                            <span>03 May 2021</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="members" title="Members">
                    <div className="up-allmemberstab">
                        <div className="up-themebox">
                            <div className="up-themeboxhead">
                                <h3>Members <span>(230)</span></h3>
                            </div>
                            <form className="up-formtheme up-formallmembers">
                                <fieldset>
                                    <div className="form-group">
                                        <i className="icon-search"></i>
                                        <input type="search" name="search-members" className="form-control" placeholder="Search for members" />
                                    </div>
                                </fieldset>
                            </form>
                            <div className="up-membersarea">
                                <div className="up-memberhead">
                                    <h4>Admin</h4>
                                </div>
                                <div className="up-member">
                                    <figure className="up-memberimg">
                                        <img src={images.MemberOne} alt="Member Image" />
                                    </figure>
                                    <div className="up-membername">
                                        <h4>Aahat Akter</h4>
                                        <span>@Aahat</span>
                                    </div>
                                </div>
                            </div>
                            <div className="up-membersarea">
                                <div className="up-memberhead">
                                    <h4>Members</h4>
                                </div>
                                <div className="up-member">
                                    <figure className="up-memberimg">
                                        <img src={images.MemberOne} alt="Member Image" />
                                    </figure>
                                    <div className="up-membername">
                                        <h4>Aahat Akter</h4>
                                        <span>@Aahat</span>
                                    </div>
                                </div>
                                <div className="up-member">
                                    <figure className="up-memberimg">
                                        <img src={images.MemberTwo} alt="Member Image" />
                                    </figure>
                                    <div className="up-membername">
                                        <h4>Kazi Rahman</h4>
                                        <span>@Kazi</span>
                                    </div>
                                </div>
                                <div className="up-member">
                                    <figure className="up-memberimg">
                                        <img src={images.MemberThree} alt="Member Image" />
                                    </figure>
                                    <div className="up-membername">
                                        <h4>Alia Karon</h4>
                                        <span>@Alia</span>
                                    </div>
                                </div>
                                <div className="up-member">
                                    <figure className="up-memberimg">
                                        <img src={images.MemberFour} alt="Member Image" />
                                    </figure>
                                    <div className="up-membername">
                                        <h4>Masterero</h4>
                                        <span>@Masterero</span>
                                    </div>
                                </div>
                                <div className="up-member">
                                    <figure className="up-memberimg">
                                        <img src={images.MemberFive} alt="Member Image" />
                                    </figure>
                                    <div className="up-membername">
                                        <h4>Michelle Jones</h4>
                                        <span>@Michelle</span>
                                    </div>
                                </div>
                            </div>
                            <a className="up-btn" href="javascript:void(0);">See All Members</a>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="groups" title="Groups">
                    <div className="up-groupsarea">
                        <div className="up-themebox">
                            <div className="up-themeboxhead">
                                <h3>User Groups<span> (03)</span></h3>
                            </div>
                            <form className="up-formtheme up-formallmembers">
                                <fieldset>
                                    <div className="form-group">
                                        <i className="icon-search"></i>
                                        <input type="search" name="search-members" className="form-control" placeholder="Search for group" />
                                    </div>
                                </fieldset>
                            </form>
                            <div className="up-allgroups">
                                <div className="up-group">
                                    <div className="up-gourpimagename">
                                        <figure className="up-groupimg">
                                            <img src={images.OrganiserCompanyLogo} alt="Group Image" />
                                        </figure>
                                        <div className="up-groupname">
                                            <h3>Tha Fashion Art</h3>
                                        </div>
                                    </div>
                                    <a href="javascript:void(0);" className="up-btn up-btnwithicon">
                                        <span>Open Group chat</span>
                                        <i className="icon-chat"></i>
                                    </a>
                                </div>
                                <div className="up-group">
                                    <div className="up-gourpimagename">
                                        <figure className="up-groupimg">
                                            <img src={images.OrganiserCompanyLogo} alt="Group Image" />
                                        </figure>
                                        <div className="up-groupname">
                                            <h3>Only Manager Group</h3>
                                        </div>
                                    </div>
                                    <a href="javascript:void(0);" className="up-btn up-btnwithicon">
                                        <span>Open Group chat</span>
                                        <i className="icon-chat"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="media" title="Media">
                    <div className="up-allmediaarea">
                        <div className="up-themebox">
                            <div className="up-themeboxhead">
                                <h3>Media</h3>
                            </div>
                            <div className="up-mediatabsarea">
                                <Tabs defaultActiveKey="photos" id="uncontrolled-tab-example" className="up-mediatabs">
                                    <Tab eventKey="photos" title="Photos (23)">
                                        <div className="up-allmediaimages">
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgOne} alt="Media Image" />
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgTwo} alt="Media Image" />
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgThree} alt="Media Image" />
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgOne} alt="Media Image" />
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgTwo} alt="Media Image" />
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgThree} alt="Media Image" />
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgOne} alt="Media Image" />
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgTwo} alt="Media Image" />
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgThree} alt="Media Image" />
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgOne} alt="Media Image" />
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgTwo} alt="Media Image" />
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgThree} alt="Media Image" />
                                                </figure>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="videos" title="Videos(1)">
                                        <div className="up-allmediaimages">
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgOne} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgTwo} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgThree} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgOne} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgTwo} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgThree} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgOne} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgTwo} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgThree} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgOne} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgTwo} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="up-mediafile">
                                                <figure className="up-mediaimg">
                                                    <img src={images.MediaImgThree} alt="Media Image" />
                                                    <figcaption>
                                                        <a href="javascript:void(0);" className="up-btnvideoplay">
                                                            <i className="icon-play3"></i>
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="events" title="Events">
                    <div className="up-eventstabsarea">
                        <div className="up-themebox">
                            <div className="up-themeboxhead">
                                <h3>Event List</h3>
                            </div>
                            <div className="up-alleventstwocols">
                                <div className="up-eventsholder">
                                    <form className="up-formtheme up-formallmembers">
                                        <fieldset>
                                            <div className="form-group">
                                                <i className="icon-search"></i>
                                                <input type="search" name="search-members" className="form-control" placeholder="Search for Events" />
                                            </div>
                                        </fieldset>
                                    </form>
                                    <div className="up-alleventsholder">
                                        <div className="up-event">
                                            <div className="up-eventhead">
                                                <div className="up-eventnameimgdate">
                                                    <figure className="up-eventlogoimg">
                                                        <img src={images.EventsImageOne} alt="Event Logo Image" />
                                                    </figure>
                                                    <div className="up-eventnamedate">
                                                        <h3>Boutique Opening</h3>
                                                        <time dateTime="2022-12-12">May 14, Friday 18:00 - 19:00</time>
                                                    </div>
                                                </div>
                                                <ul className="up-eventbuttons">
                                                    <li>
                                                        <button className="up-btn" type="button">Join</button>
                                                        {/* <button className="up-btn up-btnjoined" type="button">Joined</button> */}
                                                    </li>
                                                    <li>
                                                        <button className="up-btn up-btnclose" type="button">Close</button>
                                                        {/* <button className="up-btn up-btnviewdetails" type="button">View Detail</button> */}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="up-eventsdetailsinfo">
                                                <div className="up-eventdescriptions">
                                                    <h4>Description</h4>
                                                    <div className="up-description">
                                                        <p>Aenean ornare diam congue ullamcorper sodales. Etiam malesuada neque et sollicitudin euismod. Praesent ac rutrum tellus. Nunc porta mauris non nibh volutpat, ac lobortis ex pharetra. Integer luctus sed erat sed euismod. Ut blandit pretium elit, vel facilisis tellus mollis sed. Nam congue pretium velit, sit amet tempus lorem ultrices in.</p>
                                                    </div>
                                                    <div className="up-eventotherinfo">
                                                        <h5>𝗠𝗲𝗲𝘁 𝗢𝘂𝗿 𝗦𝗽𝗲𝗮𝗸𝗲𝗿</h5>
                                                        <div className="up-description">
                                                            <p>Aenean ornare diam congue ullamcorper sodales. Etiam malesuada neque et sollicitudin euismod. Praesent ac rutrum tellus.</p>
                                                        </div>
                                                    </div>
                                                    <div className="up-eventotherinfo">
                                                        <h5>𝗠𝗲𝗲𝘁 𝗢𝘂𝗿 𝗛𝗼𝘀𝘁</h5>
                                                        <div className="up-description">
                                                            <p>Aenean ornare diam congue ullamcorper sodales. Etiam malesuada neque et sollicitudin euismod. Praesent ac rutrum tellus.</p>
                                                        </div>
                                                    </div>
                                                    <div className="up-eventlocation">
                                                        <span><i className="icon-location"></i> Aenean ornare diam congue ullamcorper sodales. </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="up-event">
                                            <div className="up-eventhead">
                                                <div className="up-eventnameimgdate">
                                                    <figure className="up-eventlogoimg">
                                                        <img src={images.EventsImageTwo} alt="Event Logo Image" />
                                                    </figure>
                                                    <div className="up-eventnamedate">
                                                        <h3>Basket ball Competitation</h3>
                                                        <time dateTime="2022-12-12">May 14, Friday 18:00 - 19:00</time>
                                                    </div>
                                                </div>
                                                <ul className="up-eventbuttons">
                                                    <li>
                                                        {/* <button className="up-btn" type="button">Join</button> */}
                                                        <button className="up-btn up-btnjoined" type="button">Joined</button>
                                                    </li>
                                                    <li>
                                                        {/* <button className="up-btn up-btnclose" type="button">Close</button> */}
                                                        <button className="up-btn up-btnviewdetails" type="button">View Detail</button>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <div className="up-eventsdetailsinfo">
                                                <div className="up-eventdescriptions">
                                                    <h4>Description</h4>
                                                    <div className="up-description">
                                                        <p>Aenean ornare diam congue ullamcorper sodales. Etiam malesuada neque et sollicitudin euismod. Praesent ac rutrum tellus. Nunc porta mauris non nibh volutpat, ac lobortis ex pharetra. Integer luctus sed erat sed euismod. Ut blandit pretium elit, vel facilisis tellus mollis sed. Nam congue pretium velit, sit amet tempus lorem ultrices in.</p>
                                                    </div>
                                                    <div className="up-eventotherinfo">
                                                        <h5>𝗠𝗲𝗲𝘁 𝗢𝘂𝗿 𝗦𝗽𝗲𝗮𝗸𝗲𝗿</h5>
                                                        <div className="up-description">
                                                            <p>Aenean ornare diam congue ullamcorper sodales. Etiam malesuada neque et sollicitudin euismod. Praesent ac rutrum tellus.</p>
                                                        </div>
                                                    </div>
                                                    <div className="up-eventotherinfo">
                                                        <h5>𝗠𝗲𝗲𝘁 𝗢𝘂𝗿 𝗛𝗼𝘀𝘁</h5>
                                                        <div className="up-description">
                                                            <p>Aenean ornare diam congue ullamcorper sodales. Etiam malesuada neque et sollicitudin euismod. Praesent ac rutrum tellus.</p>
                                                        </div>
                                                    </div>
                                                    <div className="up-eventlocation">
                                                        <span><i className="icon-location"></i> Aenean ornare diam congue ullamcorper sodales. </span>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className={`up-event ${openEventDetail ? "up-open-event-detail" : ""}`}>
                                            <div className="up-eventhead">
                                                <div className="up-eventnameimgdate">
                                                    <figure className="up-eventlogoimg">
                                                        <img src={images.EventsImageThree} alt="Event Logo Image" />
                                                    </figure>
                                                    <div className="up-eventnamedate">
                                                        <h3>NYC vs BLC</h3>
                                                        <time dateTime="2022-12-12">May 14, Friday 18:00 - 19:00</time>
                                                    </div>
                                                </div>
                                                <ul className="up-eventbuttons">
                                                    <li>
                                                        <button className="up-btn" type="button">Join</button>
                                                        {/* <button className="up-btn up-btnjoined" type="button">Joined</button> */}
                                                    </li>
                                                    <li>
                                                        {openEventDetail ?
                                                            <button className="up-btn up-btnclose" onClick={()=> setOpenEventDetail(!openEventDetail)} type="button">Close</button>
                                                            :
                                                            <button className="up-btn up-btnviewdetails" onClick={()=> setOpenEventDetail(!openEventDetail)} type="button">View Detail</button>
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <div className="up-eventsdetailsinfo">
                                                <div className="up-eventdescriptions">
                                                    <h4>Description</h4>
                                                    <div className="up-description">
                                                        <p>Aenean ornare diam congue ullamcorper sodales. Etiam malesuada neque et sollicitudin euismod. Praesent ac rutrum tellus. Nunc porta mauris non nibh volutpat, ac lobortis ex pharetra. Integer luctus sed erat sed euismod. Ut blandit pretium elit, vel facilisis tellus mollis sed. Nam congue pretium velit, sit amet tempus lorem ultrices in.</p>
                                                    </div>
                                                    <div className="up-eventotherinfo">
                                                        <h5>𝗠𝗲𝗲𝘁 𝗢𝘂𝗿 𝗦𝗽𝗲𝗮𝗸𝗲𝗿</h5>
                                                        <div className="up-description">
                                                            <p>Aenean ornare diam congue ullamcorper sodales. Etiam malesuada neque et sollicitudin euismod. Praesent ac rutrum tellus.</p>
                                                        </div>
                                                    </div>
                                                    <div className="up-eventotherinfo">
                                                        <h5>𝗠𝗲𝗲𝘁 𝗢𝘂𝗿 𝗛𝗼𝘀𝘁</h5>
                                                        <div className="up-description">
                                                            <p>Aenean ornare diam congue ullamcorper sodales. Etiam malesuada neque et sollicitudin euismod. Praesent ac rutrum tellus.</p>
                                                        </div>
                                                    </div>
                                                    <div className="up-eventlocation">
                                                        <span><i className="icon-location"></i> Aenean ornare diam congue ullamcorper sodales. </span>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="up-eventcalendarholder">
                                    <div className="up-themebox">
                                        <ThemeCalendar />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    )
}