import moment from "moment";
import react from "react"
import { useHistory } from "react-router-dom";
import Image from "../../assets/images/d-icon/icon.svg";
import { EPrivacy } from "../../features/auth/pages/signup/interfaces/drop-down.enums";
import { IGroup } from "../../features/organisations/createorganisation/interfaces/group.interface";
import { IOrganizationRequestedMembers } from "../../features/organisations/createorganisation/interfaces/organization-requested-members.interface";
import { IUserProps } from "../../interfaces/dashboard-stats.interface";
export const User: React.FC<IUserProps> = (props) => {
    const history = useHistory();

    const openProfile = (id: string) => {
        history.push(`/memberprofilepage/${id}`)
    }

    const openGroupProfile = (orgId: string, groupId: string) => {
        // history.replace({ pathname: `/dashboard-user-groups/${orgId}/group-detail/${groupId}`, state: { isActive: true } });
        history.replace(`/dashboard-user-groups/${orgId}/group-detail/${groupId}`)
        window.location.reload();
    }
    return (
        <>
            {props.data && props.data.length > 0 && props.data.map((data: IOrganizationRequestedMembers, index: number) => (<div key={index + data._v} className="up-user">
                <figure onClick={() => openProfile(data.member._id)}>
                    <img style={{cursor: 'pointer' }} src={data?.member?.avatar || Image} alt="user image" />
                </figure>
                <div>
                    <h4 style={{cursor: 'pointer' }} onClick={() => openProfile(data.member._id)}>{data.member.name} {(data?.postCount && data?.postCount > 0) ? <span>({data?.postCount})</span> : ''}{(props?.showActivityPoints && data?.activityPoints && data?.activityPoints > 0) ? <span>({data?.activityPoints})</span> : ''}</h4>
                    {data?.member?.emailPrivacy === EPrivacy.EVERY_ONE && <a href="javascript: void(0);">{data.member.email}</a>}
                </div>
                {props?.showDate && <span style={{lineHeight: '17px', fontSize: '14px'}}>{moment(data?.createdAt).format('YYYY-MM-DD HH:mm')}</span>}
            </div>))}
            {props.childGroups && props.childGroups.length > 0 && props.childGroups.map((data: IGroup, index: number) => (<div key={index + data._id} className="up-user">
                <figure onClick={() => openGroupProfile(data.organization, data._id)}>
                    <img src={data?.avatar || Image} alt="user image" />
                </figure>
                <div>
                    <h4 onClick={() => openGroupProfile(data.organization, data._id)}>{data?.name}</h4>
                    {/* <a href="javascript: void(0);">{data.}</a> */}
                </div>
            </div>))}
        </>
    )
}