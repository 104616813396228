// import openSocket from 'socket.io-client';
const Config = process.env.REACT_APP_SOCKET_URL;

// export const _io = openSocket(Config!)

export const _io = (window as any).io(Config)
_io.on('connect', () => {
    console.log('Connection Established')
});
// _io.on('listen', console.log)

