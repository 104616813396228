import { ICreateNewRoleModalProps } from "../../organisations/createorganisation/interfaces/create-new-role-modal.interface"
import Modal from 'react-bootstrap/Modal';
import { EPolicy } from "../../../interfaces/organization-policies.enums";
import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { createRoleSchema } from "../../organisations/createorganisation/validations/create-role.validation";
import { Toast } from "../../../utils/Toast";
import { organisationService } from "../../../services/organisation.service";
import {useTranslation} from "react-i18next";

export const CreateNewRoleModal: React.FC<ICreateNewRoleModalProps> = (props) => {

    const [policies, setPolicies] = useState<any[]>([]);
    const [isLoading, setIsloading] = useState(false)
    const { t } = useTranslation('', { keyPrefix: 'CreateNewRoleModal' });

    const defaultRoles = ['Admin', 'Follower', 'Member']
    const { register, setValue, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(createRoleSchema)
    });
    useEffect(() => {
        const roles: any = Object.values(EPolicy);
        const tempPolicies: any[] = [];
        for (let i = 0; i < roles.length; i++) {
            tempPolicies.push({ policy: roles[i], isChecked: false });
        }
        if (props.isEdit) {
            for (const temp of tempPolicies) {
                if (props.selectedEditRole.policies.includes(temp.policy)) {
                    temp.isChecked = true;
                }
            }
            setValue('role', props.selectedEditRole?.role)
        }
        setPolicies(tempPolicies);
    }, [])

    const closeDisplayToModal = () => {
        props.closeModal(false)
    }
    const handlePolicySelection = (index: number) => {
        policies[index].isChecked = !policies[index].isChecked;
        setPolicies([...policies])
    }
    const createRoleSubmit = async (data: any) => {
        setIsloading(true)
        const tempPolicies = policies.filter(pol => pol.isChecked == true)
        if (tempPolicies.length > 0) {
            const policy: string[] = [];
            for (const iterator of tempPolicies) {
                policy.push(iterator.policy)
            }
            data.policies = policy;
            if (props.isEdit) {
                const res: any = await organisationService.updateOrganizationRole(data, props.selectedEditRole._id);
                if (res.statusCode === 201) {
                    Toast.fire({
                        icon: 'success',
                        title: res.message
                    })
                    props.closeModal(false, res.payload.organizationRole)
                    setIsloading(false)
                } else {
                    setIsloading(false)
                    Toast.fire({
                        icon: 'warning',
                        title: res.message
                    })
                }
            } else {
                data.organization = props.organizationId;
                const res: any = await organisationService.createOrganizationRole(data);
                if (res.statusCode === 201) {
                    Toast.fire({
                        icon: 'success',
                        title: res.message
                    })
                    props.closeModal(true, res.payload.organizationRole)
                    setIsloading(false)
                } else {
                    setIsloading(false)
                    Toast.fire({
                        icon: 'warning',
                        title: res.message
                    })
                }
            }

        } else {
            setIsloading(false)
            Toast.fire({
                icon: 'warning',
                title: t('pleaseSelectOnePermission')
            })
        }

    }
    return <Modal className="up-thememodal up-modalcreateupdates up-assignnewrolemodal" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('assignNewOrganisationRole')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formdisplayto" onSubmit={handleSubmit(createRoleSubmit)}>
                    <fieldset>
                        {!defaultRoles.includes(props?.selectedEditRole?.role) && <div className="form-group">
                            <input  {...register("role")} type="text" name="role" className="form-control"
                                    placeholder={t('typeRoleName')}/>
                            <small id="content" className="form-text text-muted">
                                {errors.role?.message}
                            </small>
                        </div>}
                        <ul>
                            {policies && policies.map((pol: any, index: number) => {
                                return <li key={pol.policy + index}>
                                    <div className="up-advanceoption">
                                        <span>{pol.policy.split('_').join(' ')}</span>
                                        <div className="up-togglebutton">
                                            <input checked={pol.isChecked} onChange={(event: any): any => handlePolicySelection(index)} id={index.toString()} type="checkbox" name="advance-option" />
                                            <label htmlFor={index.toString()}></label>
                                        </div>
                                    </div>
                                </li>
                            })}
                        </ul>
                        <button style={{display: 'block', margin: '0 auto', clear: 'both', width: '100px'}} type="submit" className="up-btn">{props.isEdit ? t('update') : t('create')}{isLoading && <div className="lds-dual-ring"></div>}</button>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}