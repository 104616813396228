import {getImagesData, getImagesLastFetched, getImagesPagination} from "./cache.selector";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {FetchPixeeImages} from "./cache.thunk";
export const FOUR_HOURS = 4 * 60 * 60 * 1000;

export const usePixeeImageData = () => {
    const dispatch = useDispatch();
    const pixeeImages: any = useSelector(getImagesData);
    const dataPagination = useSelector(getImagesPagination);
    const lastFetched = useSelector(getImagesLastFetched);

    useEffect(() => {
        const currentTime = Date.now();
        const shouldFetch = !lastFetched || currentTime - lastFetched > FOUR_HOURS;

        if (shouldFetch) {
            dispatch(FetchPixeeImages());
        }
    }, [dispatch, lastFetched]);

    return [pixeeImages, dataPagination];
};
