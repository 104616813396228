import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { images } from "../../../../assets/images"
import { Toast } from "../../../../utils/Toast";
import { IOrganization } from "../../../organisations/createorganisation/interfaces/organisation.interface";
import { SearchParentOrganisation } from "../../../organisations/slice/organisation.thunks";
import { OgranizationCard } from "./OrganiserCard"
import {useTranslation} from "react-i18next";

export const FollowOrganizationsPage = () =>{
    const [allOrganizations, setAllOrganizations] = useState<IOrganization[]>();
    const dispatch = useDispatch();
    const { t } = useTranslation('', { keyPrefix: 'FollowOrganizationsPage' });
    useEffect(() => {
        getAllOrganizations('');
    }, [])

    const handleMemberRequest = (status: string, id: string) => {
        const tempOrg = allOrganizations!.find((org) => org._id === id)
        tempOrg!.isMember = status;
        setAllOrganizations([...allOrganizations!])
    }
    const getAllOrganizations = async (input: string) => {
        const res: any = await dispatch(SearchParentOrganisation(input))
        if (res.payload.statusCode == 200) {
            setAllOrganizations([...res.payload.payload.organizations.records])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.payload.message
            })
        }
}
    return(
        <div className="up-followorgaizerspage">
            <div className="up-container">
                <strong className="up-logo">
                    <img src={images.Logo} alt="Logo Image"/>
                </strong>
                <div className="up-followerhead">
                    <h2>{t('followOrganization')}</h2>
                    <form className="up-formtheme up-formsearchorganisation ct-widthfull">
                        <fieldset>
                            <div className="form-group">
                                <input onChange={(e) => { getAllOrganizations(e.target.value) }} type="search" name="search-organisers" className="form-control" placeholder={t('searchPlaceholder')}/>
                                <button className="up-btnsearch" type="button"><i className="icon-search"></i></button>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="up-followorganizers">
                    <div className="up-followorganizersholder">
                       {allOrganizations && allOrganizations.map((item: IOrganization, index) => {
                            return <OgranizationCard socialLinks={[]} setIsMember={handleMemberRequest} _id={item._id} key={item._id} name={item.name} type={item.type} members={item.members} avatar={item.avatar ?? images.RestPlaceholder} banner={item.banner ?? images.RestPlaceholder} isMember={item.isMember} />
                        })}
                    </div>
                    <a href="/home" className="up-btn">{t('ready')}</a>
                </div>
            </div>
        </div>
    )
}