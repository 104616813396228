import { group } from "console";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { images } from "../../assets/images";
import Cover from "../../assets/images/organiser/cover-img.jpg"
import Orgin from "../../assets/images/organiser/organiser-logo.jpg"
import { EChatType } from "../../features/chatmessages/enum/type.enum";
import { IGroup } from "../../features/organisations/createorganisation/interfaces/group.interface";
import { EPolicy } from "../../interfaces/organization-policies.enums";

export  interface IUserGroupCardProps {
    data: IGroup
  }

export const UserGroupCard: React.FC<IUserGroupCardProps> = (props) => {
    const history = useHistory();

    const openChildGroups = () => {
        history.push(`/dashboard-user-groups/${props.data.organization}/group-detail/${props.data._id}`)
    }   

    return (
        <div key={props.data._id} className="up-group">
                <div className="up-groupInfoHolder">
                    <div className="up-gourpimagename">
                        <figure className="up-groupimg">
                            <img src={props.data.avatar ?? images.RestPlaceholder} alt="Group Image" />
                        </figure>
                        <div className="up-groupname">
                            <h3>{props.data.name}<i onClick={() => openChildGroups()} className="icon-arrow-down2" style={{ marginLeft: '5px' }} /></h3>
                        </div>
                    </div>
                    <div className="up-usergroupupdate">
                        <h3>{props?.data?.members?.length + ' Users, ' + props?.data?.admins?.length + ' Admins'}</h3>
                    </div>
                    {/* <Dropdown className="up-themedropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                            <i className="icon-menu-icon"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="up-themedropdownmenu">
                            {props.myRole.role.policies.includes(EPolicy.CAN_UPDATE_GROUPS) && <Dropdown.Item onClick={() => editGroup(group, index)}>Edit Group</Dropdown.Item>}
                            {props.myRole.role.policies.includes(EPolicy.CAN_DELETE_GROUPS) && <Dropdown.Item onClick={() => deleteGroup(group, index)} >Delete</Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown> */}
                    {/* <Link to={`/all-chat?tab=${EChatType.GROUP}&active=${props.data._id}`} className="up-btn up-btnwithicon">
                        <span>Open Group chat</span>
                        <i className="icon-chat"></i>
                    </Link> */}
                </div>
            </div>
        // <div className="up-organisercard">
        //     <figure className="up-organisercoverimg">
        //         <img src={props.data.avatar ?? Cover} alt="Organiser Cover Image" />
        //     </figure>
        //     <div className="up-organisercardcontent">
        //         {/* <figure className="up-organiserlogo">
        //             <img src={Orgin} alt="Organiser Logo" />
        //         </figure> */}
        //         <div className="up-organisername">
        //             <h3><Link to={`/dashboard-user-groups/${props.data.organization}/group-detail/${props.data._id}`}>{props.data.name}</Link></h3>
        //             {/* <span>@fashion_craze</span> */}
        //         </div>
        //         <ul className="up-organisermetadata">
        //             <li>
        //                 <h4>{props.data.admins.length}</h4>
        //                 <span>Admin</span>
        //             </li>
        //             <li>
        //                 <h4>{props.data.members.length}</h4>
        //                 <span>Members</span>
        //             </li>
        //         </ul>
        //     </div>
        // </div>
    );
};
