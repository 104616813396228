import {useEffect, useState} from "react"
import Cover from "../../assets/images/organiser/cover-img.jpg"
import Orgin from "../../assets/images/organiser/organiser-logo.jpg"
import {DashboardLayout} from "../layouts/DashboardLayout"
import {useHistory, useLocation, useParams} from "react-router";
import {IGroupIdFromUrl} from "../../interfaces/id-from-url.interface";
import {User} from "../user/User"
import {IGroup} from "../../features/organisations/createorganisation/interfaces/group.interface"
import {groupService} from "../../services/group.service"
import {Toast} from "../../utils/Toast"
import {
    IOrganizationRequestedMembers
} from "../../features/organisations/createorganisation/interfaces/organization-requested-members.interface"
import {useDebounce} from "../../hooks/use-debounce.hook"
import {FullPageLoader} from "../../utils/FullPageLoader"
import {useTranslation} from "react-i18next";

export default function UserGroupDetail() {
    const [userGroup, setUserGroup] = useState<IGroup>();
    const [childGroups, setChildGroups] = useState<IGroup[]>([]);
    const [isLoading, setIsloading] = useState(true);
    const [groupMembers, setGroupMembers] = useState<IOrganizationRequestedMembers[]>([]);
    const [allMembers, setAllMembers] = useState<IOrganizationRequestedMembers[]>([]);
    const [search, setSearch] = useState("");
    const [debounceSearch] = useDebounce(search, 300);
    let { id, groupId } = useParams<IGroupIdFromUrl>();
    const history = useHistory();
    const location = useLocation();
    const [organizationName, setOrganizationName] = useState(location?.search.replace('?',''));
    const { t } = useTranslation('', { keyPrefix: 'UserGroupDetail' });
    useEffect(() => {
        getGroupById();
        getChildGroups();
    }, []);

    useEffect(() => {
        if (debounceSearch.length > 0) {
            setGroupMembers([...groupMembers.filter((item, index) => item.member.name.toLowerCase().includes(`${debounceSearch.toLowerCase()}`))]);
        } else {
            setGroupMembers([...allMembers!])
        }
    }, [debounceSearch]);

    const getGroupById = async () => {
        setIsloading(true);
        const res: any = await groupService.getGroupById(groupId);
        setIsloading(false);
        if (res.statusCode == 200) {
            setAllMembers([...res.payload.group.admins!, ...res.payload.group.members!])
            setGroupMembers([...res.payload.group.admins!, ...res.payload.group.members!])
            setUserGroup(res.payload.group)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const getChildGroups = async () => {
        const res: any = await groupService.getChildGroups(groupId);
        if (res.statusCode == 200) {
            setChildGroups([...res.payload.childGroups])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const backToGroupListing = () => {
        history.push(`/dashboard-user-groups/${id}`)
    }
    return (
        <DashboardLayout id={id} orgName={organizationName}>
            {isLoading && <FullPageLoader />}
            <div className="up-usergroupholder">
                <div className="up-userfilterholder">
                    <div className="up-userspagetitle">
                        <h4 onClick={backToGroupListing}>{t('goBack')}</h4> <h2>{t('userGroups')}</h2>
                    </div>
                </div>
                <div className="up-usergroupdetail">
                    <div className="up-organisercard">
                        <figure className="up-organisercoverimg">
                            <img src={userGroup?.avatar ?? Cover} alt="Organiser Cover Image" />
                        </figure>
                        <div className="up-organisercardcontent">
                            <figure className="up-organiserlogo">
                                <img src={userGroup?.avatar ?? Orgin} alt="Organiser Logo" />
                            </figure>
                            <div className="up-organisername">
                                <h3>{userGroup?.name}</h3>
                                {/* <span>@fashion_craze</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="up-eventtwocol">
                        <div className="up-eventcolone">
                            <div className="up-dashboarddata">
                                <div className="up-userfilterholder">
                                    <div className="up-userspagetitle">
                                        <h3>{t('members')}</h3>
                                    </div>
                                    <form className="up-formtheme">
                                        <fieldset>
                                            <div className="form-group">
                                                <div className="up-inputwithicon">
                                                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} name="search" placeholder={t('searchMember')} />
                                                    <i className="icon-search"></i>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                                <ul>
                                    <li>
                                        {userGroup && <User data={groupMembers} />}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="up-eventcoltwo">
                            <div className="up-dashboarddata">
                                <div className="up-dashboardsectiontitle">
                                    <h3>{t('childGroups')}</h3>
                                </div>
                                <ul>
                                    <li>
                                        {childGroups && childGroups.length > 0 && <User data={[]} childGroups={childGroups} />}
                                        {childGroups && childGroups.length === 0 && <div>{t('noChildGroups')}</div>}

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}