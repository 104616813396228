
import NoData from '../../assets/images/d-icon/no-data.png'


export default function NoDataFound() {
    return (
        <div className='up-eventholder up-flexbox'>
            <figure className='up-nodatafound'>
                <img src={NoData} alt="" />
            </figure>
        </div>
    )
}