import { createDraftSafeSelector, createSelector } from "@reduxjs/toolkit";
import { IChatState } from "./types";

const selectDomain = (state: any) => state.chatReducer;

export const getIsLoading = createSelector(
  selectDomain,
  (chat: IChatState) => chat.isLoading
);

export const getActiveType = createSelector(
  selectDomain,
  (chat: IChatState) => chat.activeType
);

export const getGroupChatHeads = createSelector(
  selectDomain,
  (chat: IChatState) => chat.groupChatHeads
);

export const getIndividualChatHeads = createSelector(
  selectDomain,
  (chat: IChatState) => chat.individualChatHeads
);

export const getHeadsLoading = createSelector(
  selectDomain,
  (chat: IChatState) => chat.headsLoading
);

export const getActiveHead = createSelector(
  selectDomain,
  (chat: IChatState) => chat.activeHead
);

export const getMessages = createSelector(
  selectDomain,
  (chat: IChatState) => chat.messages
);

export const getMessagePagination = createSelector(selectDomain, (chat:IChatState) => chat.messagePagination)

export const getChatMembers = createSelector(
  selectDomain,
  (chat: IChatState) => chat.members
);


export const getChatMedias = createSelector(
  selectDomain,
  (chat: IChatState) => chat.medias
);


export const getChatAdmins = createSelector(
  selectDomain,
  (chat: IChatState) => chat.admins
);

export const getMessagesLoader = createSelector(
  selectDomain,
  (chat: IChatState) => chat.messagesLoader
);
