// import User from "../user/User";
import Image from '../../assets/images/d-icon/icon.svg'
import Media from '../../assets/images/media/img-01.jpg';
import MediaTwo from '../../assets/images/media/img-02.jpg';
import { DashboardLayout } from "../layouts/DashboardLayout";
import { useLocation, useParams } from "react-router";
import { IIdFromUrl } from "../../interfaces/id-from-url.interface";
import { User } from '../user/User';
import UsersIcon from "../../assets/images/d-icon/users.svg";
import { useState } from 'react';


export default function NotUpdates() {
    let { id } = useParams<IIdFromUrl>();
    const location = useLocation();
    const [organizationName, setOrganizationName] = useState(location?.search.replace('?',''));
    return (
        <DashboardLayout id={id} orgName={organizationName}>
            <div className="up-eventspageholder">
                <div className="up-userfilterholder">
                    <div className="up-userspagetitle">
                        <h2>Updates</h2>
                    </div>
                    <form className="up-formtheme">
                        <fieldset>
                            <button className="up-btn">Download CSV</button>
                            <div className="form-group">
                                <div className="up-inputwithicon">
                                    <input type="text" name="search" placeholder="Search artists" />
                                    <i className="icon-search"></i>
                                </div>
                            </div>
                            <div className="up-filterbtn">
                                <button className="up-btnfilter">
                                    <i className="icon-filter"></i>
                                    <span>Filter</span>
                                </button>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="up-eventtwocol">
                    <div className="up-eventcolone">
                        <div className="up-eventholder">
                            <div className="up-eventuserhead">
                                <figure>
                                    <img src={Image} alt="event user img" />
                                </figure>
                                <div>
                                    <h3><b>Laur­a AJ </b>Posted in the Organization <b>Fashion Craze</b> </h3>
                                    <span>Public . 2 min ago</span>
                                </div>
                                <a href="javascript: void(0);" className="up-iconmenuinevent">
                                    <i className="icon-menu"></i>
                                </a>
                            </div>
                            <div className="up-eventbody">
                                <div className="up-description up-postdescription">
                                    <p>Ethically sourced skulls from animals that have already died. Beautiful art to cherish life, even after death.</p>
                                </div>
                                <div className="up-eventsupdatesimageholder">
                                    <figure>
                                        <img src={Media} alt="media image" />
                                    </figure>
                                    <figure>
                                        <img src={MediaTwo} alt="media image" />
                                    </figure>
                                </div>
                                <ul className="up-eventattendees">
                                    <li>
                                        <span>
                                            <i className=""></i>
                                            Attendees 3.52k
                                        </span>
                                    </li>
                                    <li>
                                        <span>853  Attendees</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="up-eventcoltwo up-postudatestopevents">
                        <div className='up-statsgrid'>
                            <div className="up-stat">
                                <span className="up-staticon">
                                    <img src={UsersIcon} alt='statsicon' />
                                </span>
                                <div className="up-statcontent">
                                    <ul>
                                        <li>
                                            <span>Users</span>
                                            <h3>10k</h3>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="up-stat">
                                <span className="up-staticon">
                                    <img src={UsersIcon} alt='statsicon' />
                                </span>
                                <div className="up-statcontent">
                                    <ul>
                                        <li>
                                            <span>Users</span>
                                            <h3>10k</h3>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="up-dashboarddata">
                            <div className="up-dashboardsectiontitle">
                                <h3>Top 10 Popular Events</h3>
                                <a href="javascript: void(0);">View All</a>
                            </div>
                            <ul>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                            </ul>
                        </div> */}
                        {/* <div className="up-dashboarddata">
                            <div className="up-dashboardsectiontitle">
                                <h3>Top 5 Admins</h3>
                            </div>
                            <ul>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                                <li>
                                    <User data={[]} />
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}