// import { styles } from "./"

import { images } from "../../../../assets/images";
import { AdminLoginForm } from "./components/AdminLoginForm";
import { LoginForm } from "./components/LoginForm";

export const AdminLoginPage = () => {
  return (
    <div className="up-loginarea">
      <figure className="up-circlimages">
        <img src={images.LoginCircles} alt="Circles"/>
      </figure>
      <div className="up-logincontentarea">
        <h3>The Most Secure Social <span>Network is Here!</span></h3>
        <div className="up-description">
          <p>Up2D8 is a communication platform that channels relevant messages (of any kind) from the organization towards its members, a specific group of members or individual members. These  messages, we call them “updates”
</p>
        </div>
      </div>
      <div className="up-loginformholder">
        <div className="up-loginformcontentholder">
          <strong className="up-logo">
            <img src={images.Logo} alt="Logo Image"/>
          </strong>
          <div className="up-formHead">
            <h1>Admin Panel</h1>
            {/* <span>Enter your email address and password to access <em>to Updates.</em></span> */}
          </div>
          <AdminLoginForm/>
        </div>
      </div>
    </div>
  )
};
