import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getToken, getUser } from "../features/auth/slice/user.selector";

interface IProps {
  component: React.FC;
  path: string;
  exact: boolean;
}

const PrivateRoute: React.FC<IProps> = (props) => {
  const { path, exact, component } = props;
  const token = useSelector(getToken);
  const {role} = useSelector(getUser)
  const condition = (token && role == "USER") ? true: false;

  return condition ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to="/" />
  );
};
export default PrivateRoute;
