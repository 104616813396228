import * as yup from "yup";

export const createPostSchema = yup.object().shape({
    content: yup.string().required('Post description is required'),
    allowComment: yup.boolean().optional(),
    sendEmail: yup.boolean().optional(),
    allowShare: yup.boolean().optional(),
    isPinned: yup.boolean().optional(),
    isPersonalPushNotification: yup.boolean().optional(),
    personalPushNotificationMessage: yup.string().when("isPersonalPushNotification", {
            is: true,
            then: yup.string().required("Please enter personal notification message")
        }),
    isScheduledPost: yup.boolean().optional(),
}).required();
export const requestedPostSchema = yup.object().shape({
    content: yup.string().required('Post description is required'),
    allowComment: yup.boolean().optional(),
    isContributorVisible: yup.boolean().optional(),
    sendEmail: yup.boolean().optional(),
    allowShare: yup.boolean().optional(),
    isPinned: yup.boolean().optional(),
    isPersonalPushNotification: yup.boolean().optional(),
    personalPushNotificationMessage: yup.string().when("isPersonalPushNotification", {
            is: true,
            then: yup.string().required("Please enter personal notification message")
        }),
    isScheduledPost: yup.boolean().optional(),
}).required();