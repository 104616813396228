import {useEffect, useState} from "react";
import {Modal} from "react-bootstrap"
import {Toast} from "../../../utils/Toast";
import {organisationService} from "../../../services/organisation.service";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

export const deleteCsvSchema = yup.object().shape({
    type: yup.string().required('Filename is required'),
}).required();

export const DeleteCSVModal: React.FC<any> = (props: any) => {

    const [fileNames, setFileNames] = useState<any[]>([])
    const [isLoading, setIsloading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(deleteCsvSchema)
    });
    const { t } = useTranslation('', { keyPrefix: 'DeleteCSVModal' });

    useEffect(() => {
        getOrganizationCsvFiles();
    }, [])
    const closeDisplayToModal = () => {
        props.closeModal(false)
    }
    const getOrganizationCsvFiles = async () => {
        const res: any = await organisationService.getOrganizationCsvFiles(props.id)
        if (res.payload.fileNames.length > 0) {
            const temp = ['Select previously imported csv', ...res.payload.fileNames]
            setFileNames(temp)
        }
    }

    const onSubmit = async (data: any) => {
        if (data.type == 'Select previously imported csv') {
            Toast.fire({
                icon: 'warning',
                title: 'Please Select A File.'
            })
            return;
        }
        setIsloading(true)
        const temp = {
            organization: props.id,
            csvFileName: data.type
        }
        const res: any = await organisationService.deleteOrganizationCsvFile(temp);
        setIsloading(false)
        if (res.statusCode == 200) {
            props.closeModal(true)
            Toast.fire({
                icon: "success",
                title: res.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    };

    return <Modal className="up-thememodal up-invitemembersmodal" show={true} onHide={closeDisplayToModal}>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-forminvitememners" onSubmit={handleSubmit(onSubmit)}>
                    <fieldset>
                        <legend>{t('deletePreviousCSV')}</legend>
                        <p>{t('detail')}</p>
                        {fileNames.length > 0 && <>
                            <div className="form-group">
                                <label>{t('selectFile')}</label>
                                <select {...register("type")} className="form-control">
                                    {fileNames && fileNames.map((e, key) => {
                                        return <option key={key + e} value={e}>{e}</option>;
                                    })}
                                </select>
                                <small id="emailHelp" className="form-text text-muted">
                                    {errors.type?.message}
                                </small>
                            </div>
                            <button type="submit" className="up-btn up-btnwithloader">{t('delete')} {isLoading && <div className="lds-dual-ring"></div>}</button>
                        </>}
                        {fileNames.length == 0 && <div>
                            {t('NoCSVFiles')}
                        </div>}

                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}