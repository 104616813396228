import {PublicAppLayout} from "../../../components/layouts/PublicAppLayout";
import {FullPageLoader} from "../../../utils/FullPageLoader";
import {images} from "../../../assets/images";
import React, {useEffect, useState} from "react";
import {IOrganization} from "../createorganisation/interfaces/organisation.interface";
import {organisationService} from "../../../services/organisation.service";
import {Toast} from "../../../utils/Toast";
import {useParams} from "react-router";
import {IIdFromUrl} from "../../../interfaces/id-from-url.interface";
import InfiniteScroll from "react-infinite-scroll-component";
import {OrganizationPost} from "../../../components/Post/Post";
import {URLReplacer} from "../../../utils/url-replacer";
import {IPost} from "../createorganisation/interfaces/post.interface";
import Badge from 'react-bootstrap/Badge';
import {useTranslation} from "react-i18next";


export const PublicOrganizationPage = () => {
    const [isLoading, setIsloading] = useState(true);
    const [organization, setOrganization] = useState<IOrganization>();
    const [organizationPosts, setOrganizationPosts] = useState<IPost[]>([]);
    let {id} = useParams<IIdFromUrl>();
    const { t } = useTranslation('', { keyPrefix: 'PublicOrganizationPage' });

    useEffect(() => {
        getOrganizationById(id)
    }, [])
    const getOrganizationById = async (id: string) => {
        const res: any = await organisationService.getPublicOrganizationById(id);
        setIsloading(false)
        if (res.statusCode == 200) {
            setOrganizationPosts(res.payload.posts)
            setOrganization(res.payload.organization);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const sendFollowRequest = async () => {
        // setOpenModal(true);
        pleaseLogin()
    }
    const openUrl = (item: any) => {
        if (item.slice(0, 3) === 'www') {
            item = 'https://' + item
        }
        window.open(item, "_blank")
    }

    function setLikePost() {
        pleaseLogin()
    }

    function reportPost() {
        pleaseLogin()
    }

    function editPost() {
        pleaseLogin()
    }

    function sharePostInOrganization() {
        pleaseLogin()
    }

    function shareInUserGroupsPost() {
        pleaseLogin()
    }

    function deletePost() {
        pleaseLogin()
    }

    const pleaseLogin = () => {
        Toast.fire({
            icon: 'warning',
            title: t('pleaseLogin')
        })
    }
    return <PublicAppLayout>
        <div className="up-ownorganisationprofilepage">
            {isLoading && <FullPageLoader/>}
            <div className="up-themebanner">
                <figure className="up-bannerimg">
                    <img src={organization?.banner ?? images.RestPlaceholder} alt="Profile Banner Image"/>
                </figure>
                <div className="up-profilebannercontent">
                    {/*{!selectedBannerFile && <>*/}
                    {/*    {myRole && currentUser._id == organization?.creator! && <div className="up-changebannerbtn">*/}
                    {/*        <input accept="image/*" onChange={bannerImageChange} type="file" name="change-banner" id="up-changeimage" />*/}
                    {/*        <label htmlFor="up-changeimage">Edit Banner</label>*/}
                    {/*    </div>}*/}
                    {/*</>}*/}
                    <div className="up-profilenameimage">
                        <figure className="up-organisationprofileimg">
                            <img src={organization?.avatar ?? images.RestPlaceholder} alt="Organisation Profile Image"/>

                        </figure>
                        <div className="up-organisationname">
                            <h3>{organization?.name}<span className="up-verifedbadge">{(organization?.subscription?.proAccount && organization?.subscription?.proAccount == "YES") && (<Badge pill><img src={images.Veifed} alt="verifed badge" /></Badge>) }</span></h3>
                            <span>{organization?.type.name}</span>
                            
                        </div>
                        <div style={{marginLeft: '18px'}}>
                            <div onClick={sendFollowRequest} className="up-cardtbns">
                                <a href="/login" className="up-btn">{t('follow')}</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="up-organiserprofiletabholder">
                <div className="up-organiserprofiletabarea">
                    <div className="up-timlines">
                        <div className="up-twocolumns">
                            <div className="up-postsholder">
                                <div className="up-postsarea">
                                    {organizationPosts && <InfiniteScroll
                                        dataLength={organizationPosts!.length} //This is important field to render the next data
                                        next={() => getOrganizationById(id)}
                                        hasMore={false}
                                        loader={<h4>{t('loading')}</h4>}
                                        endMessage={
                                            <p className="up-allpostseen" style={{textAlign: 'center'}}>
                                                <b>{t('pleaseLoginPosts')}</b>
                                            </p>
                                        }>
                                        {organizationPosts && organizationPosts.map((item, index) => {
                                            return <OrganizationPost fromAdmin={false} public={true} fromDashboard={true}
                                                                     setLikePost={setLikePost}
                                                                     reportPost={reportPost}
                                                                     fromTimeline={false} key={item._id + index}
                                                                     post={item} showInOrganization={true}
                                                                     setEditPost={editPost}
                                                                     setShareOrganizationPost={sharePostInOrganization}
                                                                     setShareInUserGroupsPost={shareInUserGroupsPost}
                                                                     setDeletePost={deletePost}/>
                                        })}
                                    </InfiniteScroll>}

                                </div>
                            </div>
                            <aside className="up-sidebar">
                                <div className="up-widget up-widgetaboutorganisation">
                                    <div className="up-widgethead up-textalignleft">
                                        <h3>{t('organisationInfo')}</h3>
                                    </div>
                                    <div className="up-organisationcontent">
                                        <div className="up-description handle-line-breaks">
                                            <p dangerouslySetInnerHTML={{__html: URLReplacer(organization?.about! ?? t('notAvailable'))}}></p>
                                        </div>
                                        <ul className="up-organisationinfo">
                                            <li>
                                                <span>{t('email')}</span>
                                                <span>{organization?.email ?? t('notAvailable')}</span>
                                            </li>
                                            <li>
                                                <span>{t('address')}</span>
                                                <span>{organization?.address ?? t('notAvailable')}</span>
                                            </li>
                                            <li>
                                                <span>{t('createdDate')}</span>
                                                <span>{organization?.createdAt!.split('T')[0] ?? t('notAvailable')}</span>
                                            </li>
                                            <li className="up-websitelink">
                                                <span>{t('web')}</span>
                                                {organization?.website &&
                                                    <span style={{wordBreak: 'break-word'}}><a
                                                        href="javascript:void(0);">{organization?.website}</a></span>}
                                                {!organization?.website && <span>{t('notAvailable')}</span>}
                                            </li>
                                            <li>
                                                <span>{t('social')}</span>
                                                <div className="up-socialicons">
                                                    {organization?.socialLinks && organization?.socialLinks.map((link: string) => (
                                                        <>
                                                            {link && <>
                                                                {link.includes('facebook') &&
                                                                    <a className="up-facebooksocial cursor"
                                                                       onClick={() => openUrl(link)}>
                                                                        <i className="icon-facebook"></i>
                                                                    </a>}
                                                                {link.includes('twitter') &&
                                                                    <a className="up-twittersocial cursor"
                                                                       onClick={() => openUrl(link)}>
                                                                        <i className="icon-twitter"></i>
                                                                    </a>}
                                                                {link.includes('instagram') &&
                                                                    <a className="up-instagramsocial cursor"
                                                                       onClick={() => openUrl(link)}>
                                                                        <i className="icon-instagram"></i>
                                                                    </a>}
                                                                {link.includes('linkedin') &&
                                                                    <a className="up-linkedinsocial cursor"
                                                                       onClick={() => openUrl(link)}>
                                                                        <i className="icon-linkedin"></i>
                                                                    </a>}
                                                            </>}
                                                        </>
                                                    ))}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </PublicAppLayout>
}