import { createSlice } from "@reduxjs/toolkit";
import { EBoolean } from "../../auth/pages/signup/interfaces/drop-down.enums";
import { EChatType } from "../enum/type.enum";
import {
  deleteChat1v1,
  GetGroupChatHeads,
  GetIndividualChatHeads,
  GetMedias,
  GetMembers,
  GetMessages,
  UpdateChatNotificationStatus
} from "./chat.thunks";
import { IChatState } from "./types";

const initialState: IChatState = {
  isLoading: false,
  headsLoading: false,
  groupChatHeads: [],
  individualChatHeads: [],
  activeType: EChatType.ONE_TO_ONE,
  activeHead: null,
  messages: [],
  messagePagination:{
    currentPage: 1,
    pages: 0,
    perPage:10, 
    totalRecords: 0
  },
  messagesLoader: false,
  members: [],
  admins: [],
  medias: []
};

const chatReducer = createSlice({
  name: "chatReducer",
  initialState,
  reducers: {
    changeActiveTab: (state, action) => {
      state.activeType = action.payload;
      state.messages = [];
      state.members = [];
      state.medias = [];
      state.activeHead = null;
    },
    updateActiveHead: (state, action) => {
      // state.activeHead = action.payload;
      // state.messages = [];
      if (state.activeType == EChatType.GROUP) {
        const findIndex = state.groupChatHeads.findIndex(c => c?._id == action.payload);
        if (findIndex > -1) {
          state.activeHead = state.groupChatHeads[findIndex]
        }
      } else {
        const findIndex = state.individualChatHeads.findIndex(c => c._id == action.payload);
        if (findIndex > -1) {
          state.activeHead = state.individualChatHeads[findIndex]
        }
      }
    },
    addMessage: (state, action) => {
      state.messages = [...state.messages!, action.payload];
      // if (state.activeType == EChatType.GROUP) {
      //   const findIndex = state.groupChatHeads.findIndex(c => c._id == action.payload.chat);
      //   if (findIndex > -1) {
      //     const chats = [...state.groupChatHeads];
      //     chats[findIndex] = { ...chats[findIndex], lastMessage: action.payload };
      //     state.groupChatHeads = [...chats];
      //   }
      // } else {
      //   const findIndex = state.individualChatHeads.findIndex(c => c._id == action.payload.chat);
      //   if (findIndex > -1) {
      //     const chats = [...state.individualChatHeads];
      //     chats[findIndex] = { ...chats[findIndex], lastMessage: action.payload };
      //     state.individualChatHeads = [...chats];

      //   }
      // }
    },
    updateLastSentMessage: (state, action) => {
      if (state.messages){
        const mesIndex = state.messages.findIndex(item => item.content === action.payload.content)
        if (mesIndex !== -1){
          state.messages[mesIndex] = action.payload
        }
      }
    },
    loaderStartStop: (state, action) => {
      state.messagesLoader = action.payload;
    },
    addMessages: (state, action) => {
      state.messagesLoader = false;
      if (action.payload.currentPagination.currentPage == 1) {
        state.messages = [...action.payload.messages];
      } else {
        state.messages = [...action.payload.messages, ...state.messages!];
      }
      state.messagePagination = action.payload.currentPagination;
    },
    handleSocketReaction: (state, action) => {
      state.messagesLoader = false;
      if (state.messages) {
        const mesIndex = state.messages.findIndex(item => item._id === action.payload.messageReaction.message)
        if (mesIndex && mesIndex > -1) {
          if (action.payload.reactionType === 'ADD') {
            state.messages[mesIndex].reactions.push(action.payload.messageReaction)
          } else {
            const reactionIndex = state.messages[mesIndex].reactions.findIndex(item => item._id === action.payload.messageReaction._id)
            state.messages[mesIndex].reactions.splice(reactionIndex, 1)
          }
        }
      }
    },
    updateLastMessageInChatHead: (state, action)=>{
      if (action.payload.chat.type === EChatType.ONE_TO_ONE){
        const index = state.individualChatHeads.findIndex(item => item._id === action.payload.chat._id)
        if (index > -1){
          state.individualChatHeads[index].lastMessage[0].createdAt = action.payload.message.createdAt
          state.individualChatHeads[index].unreadMessageCount = state.individualChatHeads[index].unreadMessageCount + 1;
          state.individualChatHeads = state.individualChatHeads.sort((x: any,y: any) => new Date(y.lastMessage[0]?.createdAt).valueOf() - new Date(x.lastMessage[0]?.createdAt).valueOf());
        }
      }else{
        const index = state.groupChatHeads.findIndex(item => item._id === action.payload.chat._id)
        if (index > -1){
          state.groupChatHeads[index].lastMessage[0].createdAt = action.payload.message.createdAt
          state.groupChatHeads[index].unreadMessageCount = state.groupChatHeads[index].unreadMessageCount + 1;
          state.groupChatHeads = state.groupChatHeads.sort((x: any,y: any) => new Date(y.lastMessage[0]?.createdAt).valueOf() - new Date(x.lastMessage[0]?.createdAt).valueOf());
        }
      }
    },
    addNewChatHead: (state, action) => {
      action.payload.chat.unreadMessageCount = 1
      if (action.payload.chat.type === EChatType.ONE_TO_ONE) {
        const index = state.individualChatHeads.findIndex(item => item._id === action.payload.chat._id)
        if (index === -1) {
          state.individualChatHeads.unshift(action.payload.chat)
        }
      } else {
        const index = state.groupChatHeads.findIndex(item => item._id === action.payload.chat._id)
        if (index === -1) {
          state.groupChatHeads.unshift(action.payload.chat)
        }
      }
    }
  },
  extraReducers: {
    [GetGroupChatHeads.pending.toString()]: (state) => {
      // state.isLoading = true;
      state.headsLoading = true;
    },
    [GetGroupChatHeads.fulfilled.toString()]: (state, action) => {
      // state.isLoading = false;
      state.headsLoading = false;
      state.groupChatHeads = action.payload.payload.chats.records.sort((x: any,y: any) => new Date(y.lastMessage[0]?.createdAt).valueOf() - new Date(x.lastMessage[0]?.createdAt).valueOf());
    },
    [GetGroupChatHeads.rejected.toString()]: (state, action) => {
      // state.isLoading = false;
      state.headsLoading = false;
      console.log("actionError", action.payload);
    },
    [deleteChat1v1.pending.toString()]: (state) => {
      state.headsLoading = true;
    },
    [deleteChat1v1.fulfilled.toString()]: (state, action) => {
      state.headsLoading = false;
      state.messages = [];
      state.individualChatHeads = state.individualChatHeads.filter(item => item._id !== action.meta.arg);
      state.members = [];
      state.medias = [];
      state.activeHead = null;
    },
    [deleteChat1v1.rejected.toString()]: (state, action) => {
      state.headsLoading = false;
      console.log("actionError", action.payload);
    },
    // [addMessageReaction.pending.toString()]: (state) => {
    //   state.messagesLoader = true;
    // },
    // [addMessageReaction.fulfilled.toString()]: (state, action) => {
    //   state.messagesLoader = false;
    //   if (state.messages) {
    //     const mesIndex = state.messages.findIndex(item => item._id === action.payload.payload.messageReaction.message)
    //     if (mesIndex && mesIndex > -1) {
    //       state.messages[mesIndex].reactions.push(action.payload.payload.messageReaction)
    //     }
    //   }
    // },
    // [addMessageReaction.rejected.toString()]: (state, action) => {
    //   state.messagesLoader = false;
    //   console.log("actionError", action.payload);
    //   Toast.fire({
    //     icon: 'warning',
    //     title: action.payload.message
    //   })
    // },
    [GetIndividualChatHeads.pending.toString()]: (state) => {
      // state.isLoading = true;
      state.headsLoading = true;
    },
    [GetIndividualChatHeads.fulfilled.toString()]: (state, action) => {
      // state.isLoading = false;
      state.headsLoading = false;
      state.individualChatHeads = action.payload.payload.chats.records.sort((x: any,y: any) => new Date(y.lastMessage[0]?.createdAt).valueOf() - new Date(x.lastMessage[0]?.createdAt).valueOf());
    },
    [GetIndividualChatHeads.rejected.toString()]: (state, action) => {
      // state.isLoading = false;
      state.headsLoading = false;
      console.log("actionError", action.payload);
    },
    [GetMessages.pending.toString()]: (state) => {
      state.messagesLoader = true;
    },
    [GetMessages.fulfilled.toString()]: (state, action) => {
      state.messagesLoader = false;
      const countIndex = state.individualChatHeads.findIndex(item => item._id === action.meta.arg.chatId)
      if (countIndex !== -1) {
        state.individualChatHeads[countIndex].unreadMessageCount = 0
      } else {
        const groupCountIndex = state.groupChatHeads.findIndex(item => item._id === action.meta.arg.chatId)
        if (groupCountIndex !== -1) {
          state.groupChatHeads[groupCountIndex].unreadMessageCount = 0
        }
      }
      if(action.payload.payload.messages.paginationInfo.currentPage == 1){
        state.messages = [...action.payload.payload.messages.records] ;
      }else{
        state.messages = [...action.payload.payload.messages.records,...state.messages!] ;
      }
      state.messagePagination = action.payload.payload.messages.paginationInfo;
    },
    [GetMessages.rejected.toString()]: (state, action) => {
      state.messagesLoader = false;
      console.log("actionError", action.payload);
    },
    [UpdateChatNotificationStatus.fulfilled.toString()]: (state, action) => {
      const head: any = { ...state.activeHead };
      if (action.payload.body.status == EBoolean.YES) {
        head.muteNotification = head.muteNotification?.filter((el: string) => el != action.payload.userId)
      } else {
        head.muteNotification = [...(head.muteNotification as string[]), action.payload.userId]
      }

      state.activeHead = head
    },
    [GetMembers.fulfilled.toString()]: (state, action) => {
      state.members = action.payload.payload.members;
      state.admins = action.payload.payload.admins;
    },
    [GetMedias.fulfilled.toString()]: (state, action) => {
      state.medias = action.payload.payload.medias;
    },
  },
});

export const { updateLastSentMessage, changeActiveTab, updateActiveHead, addMessage, loaderStartStop, addMessages, handleSocketReaction, updateLastMessageInChatHead, addNewChatHead } = chatReducer.actions;

export default chatReducer.reducer;
