import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQuery } from "../../../interfaces/query.interface";
import { store } from "../../../redux/store";
import { chatService } from "../../../services/chat.service";
import { messageService } from "../../../services/message.service";
import { EBoolean } from "../../auth/pages/signup/interfaces/drop-down.enums";

export const GetGroupChatHeads = createAsyncThunk(
  "chat/getGroupChat",
  async (query: IQuery, thunkApi) => {
    try {
      return await chatService.getGroupChat(query);
    } catch (err: any) {
      console.log("err", err.response);
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const GetIndividualChatHeads = createAsyncThunk(
  "chat/getIndividualChat",
  async (query: IQuery, thunkApi) => {
    try {
      return await chatService.getIndividualChat(query);
    } catch (err: any) {
      console.log("err", err.response);
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);


export const GetMessages = createAsyncThunk(
  "chat/getMessages",
  async (data: { chatId: string; query: IQuery }, thunkApi) => {
    try {
      return await messageService.getMessages(data.chatId, data.query);
    } catch (err: any) {
      console.log("err", err.response);
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const GetMembers = createAsyncThunk(
  "chat/getMembers",
  async (chatId: string, thunkApi) => {
    try {
      return await chatService.chatMembers(chatId);
    } catch (err: any) {
      console.log("err", err.response);
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const GetMedias = createAsyncThunk(
  "chat/getMedias",
  async (chatId: string, thunkApi) => {
    try {
      return await chatService.chatMedia(chatId);
    } catch (err: any) {
      console.log("err", err.response);
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);


export const UpdateChatNotificationStatus = createAsyncThunk(
  "chat/updateChatNotificationStatus",
  async (data: { chatId: string; body: {status: EBoolean}, userId: string }, thunkApi) => {
    try {
      await chatService.updateNotificationStatus(data.chatId, data.body);
      return data;
    } catch (err: any) {
      console.log("err", err.response);
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const deleteChat1v1 = createAsyncThunk(
  "chat/delete1v1",
  async (chatId: string , thunkApi) => {
    try {
      return await chatService.deleteChat1v1(chatId);
    } catch (err: any) {
      console.log("err", err.response);
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

// export const addMessageReaction = createAsyncThunk(
//   "message-reaction",
//   async (data: any , thunkApi) => {
//     try {
//       return await messageService.addReaction(data);
//     } catch (err: any) {
//       console.log("err", err.response);
//       return thunkApi.rejectWithValue(err.response.data);
//     }
//   }
// );
