import {Modal} from "react-bootstrap";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import React, {useEffect, useState} from "react";
import {organisationService} from "../../../services/organisation.service";
import {Toast} from "../../../utils/Toast";
import {useTranslation} from "react-i18next";
import {DynamicLoader} from "../../../utils/DynamicLoader";

export const ValidTillModal: React.FC<any> = (props) => {

    const [startDate, setStartDate] = useState<any>();
    const [isLoading, setIsloading] = useState(false);
    const {t} = useTranslation('', {keyPrefix: 'ValidTillModal'});

    useEffect(() => {
        if (props?.member?.validTill) {
            setStartDate(moment(props?.member?.validTill).toDate())
        }
    }, [])
    const handleClose = () => {
        props.closeModal()
    }
    const validTillSubmit = async () => {
        setIsloading(true)
        const res: any = await organisationService.updateOrganizationMemberValidity({
            validTill: startDate,
            member: props.member._id,
        });
        setIsloading(false)
        if (res.statusCode == 200) {
            if (props?.updateMemberValidity) {
                props?.updateMemberValidity(startDate)
            } else {
                props?.closeModal(startDate)
            }
            Toast.fire({
                icon: 'success',
                title: res.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const handleStartDateChange = (data: any) => {
        setStartDate(data)
    };
    return <Modal className="up-thememodal up-modalcreategroup up-modalcreateevents up-modaloverflowremove" show={true}
                  onHide={handleClose}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('validTill')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            {isLoading && <DynamicLoader/>}
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formcreateevent">
                    <fieldset>
                        <div className="form-group">
                            <DatePicker
                                selected={startDate}
                                showTimeSelect
                                placeholderText={t('selectDateTill')}
                                minDate={moment().toDate()}
                                timeFormat="HH:mm"
                                onChange={handleStartDateChange}
                                dateFormat="yyyy-MM-dd HH:mm"
                            />
                        </div>
                        <button type="button" onClick={validTillSubmit} className="up-btn">{t('Confirm')}</button>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}
