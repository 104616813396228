import {images} from "../../assets/images"
import {AppLayout} from "../../components/layouts/AppLayout"
import {useEffect, useState} from "react";
import {IPost} from "../organisations/createorganisation/interfaces/post.interface";
import {postService} from "../../services/post.service";
import {Toast} from "../../utils/Toast";
import moment from "moment";
import {organisationService} from "../../services/organisation.service";
import {FullPageLoader} from "../../utils/FullPageLoader";
import {useSelector} from "react-redux";
import {getUser} from "../auth/slice/user.selector";
import Select from "react-select";
import {useTranslation} from "react-i18next";

export const FilesPage = () => {
    const [isLoading, setIsloading] = useState(true);
    const [organization, setOrganization] = useState();
    const [userFiles, setUserFiles] = useState<IPost[]>([])
    const [userOrganizations, setUserOrganizations] = useState<any[]>([]);
    const currentUser = useSelector(getUser);
    const { t } = useTranslation('', { keyPrefix: 'FilesPage' });

    useEffect(() => {
        getUserOrganizations();
    }, [])

    const getUserOrganizations = async () => {
        const res: any = await organisationService.getUserOrganizations(currentUser._id)
        setIsloading(false)
        if (res.statusCode == 200) {
            const tempArray: any[] = [];
            for (let i = 0; i < res.payload.organizations.length; i++) {
                tempArray.push({
                    label: res.payload.organizations[i].name,
                    value: res.payload.organizations[i]._id
                })
            }
            setUserOrganizations(tempArray)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getUserFiles = async (page: number, id: string) => {
        setIsloading(true)
        if (id) {
            let res: any = await postService.getNewSharedFiles(page, id);
            if (res.statusCode == 200) {
                setUserFiles([...res.payload.posts.records])
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }
        setIsloading(false)
    }
    const onChangeSelectedOption = (e: any) => {
        setOrganization(e.value);
        getUserFiles(1, e.value);
    };
    return (
        <AppLayout>
            <div className="up-filespage">
                {isLoading && <FullPageLoader/>}
                <div className="up-allfilesarea">
                    <div className="up-themebox">
                        <div className="up-themeboxhead">
                            <h3>{t('mySharedFiles')}</h3>
                        </div>
                        <div className="up-searchfilesarea">
                            <form className="up-formtheme up-formsearchfiles">
                                <fieldset>
                                    <Select
                                        className="basic-single up-async-select"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        name="color"
                                        onChange={onChangeSelectedOption}
                                        options={userOrganizations}
                                    />
                                </fieldset>
                            </form>
                        </div>
                        {userFiles.length > 0 && <div className="up-filebox">
                            <div className="up-tablearea">
                                <table className="up-filestable table up-responsivetable">
                                    <thead>
                                    <tr>
                                        <th><span>{t('name')}</span></th>
                                        <th>&nbsp;</th>
                                        {/* <th><span>Organisation</span></th> */}
                                        <th><span>{t('dateAdded')}</span></th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {userFiles && userFiles!.map((item: IPost) => (item.medias!.map((source: any) => (
                                        <tr key={source}>
                                            <td data-title="Name">
                                                <div className="up-fileiconname">
                                                    <span className="up-fileicon up-bgproject">
                                                        {source.toString().includes('postImage') &&
                                                            <img src={images.PictureIcon} alt="File Icon"/>}
                                                        {source.toString().includes('postFile') &&
                                                            <img src={images.FileIcon} alt="File Icon"/>}
                                                        {source.toString().includes('postVideo') &&
                                                            <img src={images.PlayIcon} alt="File Icon"/>}
                                                    </span>
                                                    <h4>{source.split('postImage')[1]?.toString()?.substring(1)}</h4>
                                                    <h4>{source.split('postFile')[1]?.toString()?.substring(1)}</h4>
                                                    <h4>{source.split('postVideo')[1]?.toString()?.substring(1)}</h4>
                                                </div>
                                            </td>
                                            <td data-title="Organisation">
                                                <span>{item?.organization?.name!}</span>
                                            </td>
                                            <td data-title="Date">
                                                <h5>{moment(item.createdAt).fromNow()}</h5>
                                            </td>
                                            <td data-title="">
                                                <div className="up-btndropdownholder">
                                                    <a href={source} download rel="noopener noreferrer" target="_blank">
                                                        <button className="up-btn" type="button">{t('download')}</button>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>))))}
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        {userFiles.length == 0 && organization && <div>{t('noFilesFound')}</div>}
                        {userFiles.length == 0 && !organization &&
                            <div>{t('pleaseSelect')}</div>}

                    </div>
                </div>
            </div>
        </AppLayout>
    )
}