import moment from "moment";
import {useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import {useSelector} from "react-redux";
import {images} from "../../assets/images";
import {getUser} from "../../features/auth/slice/user.selector";
import {
    IOrganizationRequestedMembers
} from "../../features/organisations/createorganisation/interfaces/organization-requested-members.interface";
import {IPostComp} from "../../features/organisations/createorganisation/interfaces/post.interface";
import {EPolicy} from "../../interfaces/organization-policies.enums";
import {organisationService} from "../../services/organisation.service";
import {postService} from "../../services/post.service";
import {Toast} from "../../utils/Toast";
import "react-image-lightbox/style.css";
import {URLReplacer} from "../../utils/url-replacer";
import {FullPageLoader} from "../../utils/FullPageLoader";
import {useHistory} from "react-router-dom";
import PinIcon from "../../assets/images/pin.svg"
import {DisplayToDetailModal} from "../displayToDetailModal/DisplayToDetailModal";
import {useTranslation} from "react-i18next";

export const ScheduledPost: React.FC<IPostComp> = (props) => {

    const [myRole, setMyRole] = useState<IOrganizationRequestedMembers>({
        isBlocked: "NO",
        member: {
            _id: 'string',
            name: 'string',
            username: 'string',
            email: 'string'
        },
        organization: "",
        role: {
            _id: 'string',
            role: 'string',
            policies: [],
        },
        status: "",
        _id: "",
        isMember: false,
        _v: 0
    });
    const [isOpen, setIsOpen] = useState(false);
    const [displayToDetail, setDisplayToDetail] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [postImages, setPostImages] = useState<any[]>([])
    const [isLoading, setIsloading] = useState(false)
    const currentUser = useSelector(getUser);
    const { t } = useTranslation('', { keyPrefix: 'ScheduledPost' });

    const history = useHistory();
    const isPublic = props.public
    const isLoggedIn = Object.keys(currentUser).length > 1
    useEffect(() => {
        if (props.post.mediaType === 'IMAGE') {
            for (const iterator of props.post.medias!) {
                if (iterator.toString().includes('postImage')) {
                    postImages.push(iterator);
                }
            }
            setPostImages([...postImages]);
        }
        if (!isPublic) {
            let tempID = props?.post?.organization?._id! ?? props?.post?.organization;
            if (tempID.length > 0) {
                getMyOrganizationRole(tempID);
            }
        }
    }, [])

    const getMyOrganizationRole = async (orgId: string) => {
        const res: any = await organisationService.getMyOrganizationRole(orgId);
        if (res.statusCode == 200) {
            if (res.payload.organizationMember && res.payload.organizationMember.status == "ACCEPTED") {
                res.payload.organizationMember.isMember = true;
                setMyRole(res.payload.organizationMember)
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const openImages = (index: number) => {
        setPhotoIndex(index);
        setIsOpen(true);
    }
    const openProfile = (id: string) => {
        if (currentUser._id === id) {
            history.push(`/profile-page`)
        } else {
            history.push(`/memberprofilepage/${id}`)
        }
    }

    const markPostAsSeen = async () => {
        setIsloading(true);
        const res: any = await postService.markPostAsSeen(props.post._id)
        setIsloading(false);
        if (res.statusCode == 200) {
            if (props.removeFromList) {
                props.removeFromList(props.post._id)
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const openDisplayToDetail = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.post.displayTo!.length > 0) {
            setDisplayToDetail(true);
        }
    }
    const getDisplayToName = (displayTo: any[]) => {
        return displayTo.map(item => item?.name || item?.role).toString()
    }
    const closeDisplayToDetailModal = () => {
        setDisplayToDetail(false)
    };
    return <div className="up-post">
        {isLoading && <FullPageLoader/>}
        <div className="up-posthead">
            <div className="up-postnameuserimage">
                <figure className="up-userimage">
                    <img src={props.post.creator?.avatar ?? images.UserPlaceholder} alt="User Image"/>
                </figure>
                <div className="up-postheadcontent">
                    {props.showInOrganization &&
                        <h3 style={{fontWeight: 'bold', cursor: 'pointer', color: '#000', float: 'left',}}
                            onClick={() => openProfile(props.post.creator._id)}>{props.post.creator.name}</h3>}
                    <div className="up-publicdatearea"><span><span
                        onClick={openDisplayToDetail}>{props.post.displayTo!.length > 0 ? getDisplayToName(props.post.displayTo) : t('public')}</span> {props.post.isRequested !== 'YES' ? (t('scheduledOn') + (moment(props.post.dayOfMonth).format('YYYY-MM-DD HH:mm'))) : (t('requestedOn') + (moment(props.post.createdAt).format('YYYY-MM-DD')))} </span>
                    </div>
                </div>
                {props.post?.isPinned === 'YES' && !props.post.markedAsSeen?.includes(currentUser._id) &&
                    <span className="up-markasreadpin" onClick={markPostAsSeen}> <img src={PinIcon}/></span>}
                {isLoggedIn && <Dropdown className="up-themedropdown">
                    <Dropdown.Toggle id="dropdown-basic">
                        <i className="icon-menu-icon"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="up-themedropdownmenu">
                        {props.post?.isRequested === 'NO' && <>{((currentUser._id == props.post.creator._id) || props.fromAdmin) &&
                            <Dropdown.Item onClick={() => props.setEditPost(props.post)}>{t('edit')}</Dropdown.Item>}
                            {((currentUser._id == props.post.creator._id) || (myRole.isMember && myRole.role.policies.includes(EPolicy.CAN_DELETE_POSTS)) || props.fromAdmin) &&
                                <Dropdown.Item onClick={() => props.setDeletePost(props.post)}>{t('deletePost')}</Dropdown.Item>}</>}
                        {props.post.isRequested === 'YES' &&
                            <Dropdown.Item onClick={() => {
                                props.post.requestedStatus = 'YES'
                                props.setEditPost(props.post)
                            }}>{t('accept')}</Dropdown.Item>}
                        {props.post.isRequested === 'YES' &&
                            <Dropdown.Item onClick={() => {
                                props.post.requestedStatus = 'NO'
                                props?.rejectRequestedPost && props.rejectRequestedPost(props.post)
                            }}>{t('reject')}</Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>}
            </div>
        </div>
        <div className="up-postdescription">
            <p dangerouslySetInnerHTML={{__html: URLReplacer(props.post.content)}}></p>
        </div>
        <div className={`up-postsimagearea ${props?.post?.medias?.length == 1 ? 'up-singleimage' : 'up-twoimages'}`}>
            <div className="up-allfilesholder">
            </div>
            {props.post.mediaType === 'FILE' && props.post.medias && props.post.medias?.map((item: any, index: number) => {
                return <>
                    {item.toString().includes('postFile') &&
                        <div className="up-fileiconparent">
                            <a href={item} key={item} target="_blank" rel="noreferrer" className="up-postfileuploaded">
                                <em>
                                    <i className="icon-file"/>
                                </em>
                                <span>{item.split('postFile')[1]?.replace('-', '')?.replace(/%20/g, " ")}</span>
                            </a>
                        </div>
                    }
                </>
            })}

            {props.post.mediaType === 'IMAGE' && props.post.medias && props.post.medias?.map((item: any, index: number) => {
                return <>
                    {item.toString().includes('postVideo') && <video key={item} width="666" controls>
                        <source src={item}/>
                    </video>}
                </>
            })}
            {props.post.mediaType === 'IMAGE' && postImages?.map((img, index) => (
                <figure onClick={() => openImages(index)} key={img} className="up-postimage">
                    <img src={img} alt="up-postimage"/>
                </figure>))}
            {isOpen && (
                <Lightbox
                    mainSrc={postImages[photoIndex]}
                    enableZoom={false}
                    nextSrc={postImages[(photoIndex + 1) % postImages.length]}
                    prevSrc={postImages[(photoIndex + postImages.length - 1) % postImages.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + postImages.length - 1) % postImages.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % postImages.length)
                    }
                />
            )}
            {displayToDetail && <DisplayToDetailModal closeModal={closeDisplayToDetailModal} id={props.post._id}
                                                      title={props.post.eWhichDisplayTo}/>}
        </div>
    </div>
}
