import { createSlice } from "@reduxjs/toolkit";
import { LoginUser, SignupUser, ForgotPasswordThunk, ResetPasswordThunk } from "./auth.thunks";
import { IUserState } from "./types";

const initialState: IUserState = {
  token: '',
  isloggedIn: false,
  user: {},
  isLoading: false
};

const userReducer = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.token = action.payload.token.token;
      state.isloggedIn = true;
      state.user = action.payload.user;
      state.isLoading = false;
    },
    setLogout: (state) => {
      state.token ='';
      state.isloggedIn = false;
      state.user = {};
    },
    setUserAvatar: (state,action) => {
      state.user.avatar = action.payload
    },
    setUpdatedUser: (state,action) => {
      state.user = action.payload
    },
  },
  extraReducers: {
    [LoginUser.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [LoginUser.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.token = action.payload.payload.token.token ?? null;
      state.isloggedIn = true;
      state.user = action.payload.payload.user ?? null;
    },
    [LoginUser.rejected.toString()]: (state, action) => {
      state.isLoading = false;
    },
    [SignupUser.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [SignupUser.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      // state.token = action.payload.payload.token.token ?? null;
      // state.isloggedIn = true;
      // state.user = action.payload.payload.user ?? null;
    },
    [SignupUser.rejected.toString()]: (state, action) => {
      state.isLoading = false;
    },
    [ForgotPasswordThunk.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [ForgotPasswordThunk.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
    },
    [ForgotPasswordThunk.rejected.toString()]: (state, action) => {
      state.isLoading = false;
    },
    [ResetPasswordThunk.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [ResetPasswordThunk.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
    },
    [ResetPasswordThunk.rejected.toString()]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { setLogin, setLogout, setUserAvatar, setUpdatedUser } = userReducer.actions;

export default userReducer.reducer;
