import { images } from "../../../assets/images"
import { OrganisationSetupForm } from "./components/OrganisationSetupForm"
import {useTranslation} from "react-i18next";

export const OrganisationSetupPage = () => {
    const { t } = useTranslation('', { keyPrefix: 'OrganisationSetupPage' });
    return (
        <div className="up-loginarea up-createorganisationtwo up-paddingminus">
            <figure className="up-circlimages">
                <img src={images.LoginCircles} alt="Circles"/>
            </figure>
            <div className="up-logincontentarea">
                <figure className="up-createorganisationimage">
                    <img src={images.CreateOrganisationImageTwo} alt="Create Organisation Image"/>
                </figure>
                <h3>{t('registerOrganization')}</h3>
                <div className="up-description">
                    <p>{t('registerDetail')}</p>
                </div>
            </div>
            <div className="up-loginformholder">
                <div className="up-loginformcontentholder">
                    <strong className="up-logo">
                        <img src={images.Logo} alt="Logo Image"/>
                    </strong>
                    <div className="up-formHead">
                        <h1>{t('setupOrganization')}</h1>
                    </div>
                    <OrganisationSetupForm/>
                </div>
            </div>
        </div>
    )
}