import {useEffect, useState} from "react"
import {DashboardLayout} from "../layouts/DashboardLayout"
import {useLocation, useParams} from "react-router";
import {IIdFromUrl} from "../../interfaces/id-from-url.interface";
import {IGroup} from "../../features/organisations/createorganisation/interfaces/group.interface"
import {groupService} from "../../services/group.service"
import {FullPageLoader} from "../../utils/FullPageLoader"
import {Toast} from "../../utils/Toast"
import {UserGroupCard} from "./UserGroupCard"
import {useDebounce} from "../../hooks/use-debounce.hook"
import NoDataFound from "../NoData/NoDataFound"
import {useTranslation} from "react-i18next";

export default function Usergroup() {
    const [organizationGroups, setOrganizationGroups] = useState<IGroup[]>([]);
    const [isLoading, setIsloading] = useState(false);
    const [search, setSearch] = useState("");
    const [debounceSearch] = useDebounce(search, 300);
    let { id } = useParams<IIdFromUrl>();
    const location = useLocation();
    const [organizationName, setOrganizationName] = useState(location?.search.replace('?',''));
    // useEffect(() => {
    //     getOrganizationGroups(1, '')
    // }, [])
    const { t } = useTranslation('', { keyPrefix: 'UserData' });

    useEffect(() => {
        getOrganizationGroups(1, debounceSearch)

    }, [debounceSearch]);

    const getOrganizationGroups = async (page: number, query: string) => {
        setIsloading(true);
        const res: any = await groupService.getOrganizationGroups(id, page, query, 10000);
        setIsloading(false);
        if (res.statusCode == 200) {
            setOrganizationGroups([...res.payload.groups.records])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    return (
        <DashboardLayout id={id} orgName={organizationName}>
            {isLoading && <FullPageLoader />}
            <div className="up-usergroupholder">
                <div className="up-userfilterholder">
                    <div className="up-userspagetitle">
                        <h2>{t('userGroups')}</h2>
                    </div>
                    <form className="up-formtheme">
                        <fieldset>
                            <div className="form-group">
                                <div className="up-inputwithicon">
                                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} name="search" placeholder={t('searchUserGroups')} />
                                    <i className="icon-search"></i>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div className="up-usergroupwithchatbtn up-haslayout">
                    {organizationGroups && organizationGroups.length > 0 && organizationGroups.map((item, index) => {
                        return <UserGroupCard data={item} key={index + item._id + item.updatedAt} />
                    })}
                    {organizationGroups && organizationGroups.length === 0 && (
                        <NoDataFound/>
                    )}
                </div>
            </div>
        </DashboardLayout>
    )
}