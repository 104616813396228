import { combineReducers } from "@reduxjs/toolkit";
import userReducer from '../features/auth/slice/user.slice'
import organisationReducer from '../features/organisations/slice/organisation.slice'
import chatReducer from '../features/chatmessages/slice/chat.slice';
import cacheReducer from './cache-slice/cache.slice'
export const rootReducer = combineReducers({
    userReducer,
    organisationReducer,
    chatReducer,
    cacheReducer
});
