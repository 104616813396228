import Modal from 'react-bootstrap/Modal';
import {useTranslation} from "react-i18next";

export const NoActionNeededModal: React.FC<any> = (props) => {

    const { t } = useTranslation('', { keyPrefix: 'NoActionNeededModal' });

    const handleCloseeight = () => {
        props.closeModal(true)
    }

    return <Modal className="up-thememodal up-modalcreategroup up-modalcreateevents" show={true}
                  onHide={handleCloseeight}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('alreadyRejectedAccepted')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody up-eventmodalwidget">
            <div className="up-modalcontentholder">
                    <fieldset>
                        <button style={{display: 'block', margin: '0 auto', clear: 'both', width: '100px'}} onClick={handleCloseeight} className="up-btn">{t('okay')}</button>
                    </fieldset>
            </div>
        </Modal.Body>
    </Modal>
}