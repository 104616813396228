import React, { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { images } from "../../../assets/images";
import { AppLayout } from "../../../components/layouts/AppLayout";
import { useDebounce } from "../../../hooks/use-debounce.hook";
import { IPaginationInfo } from "../../../interfaces/pagination-info.interface";
import { organisationService } from "../../../services/organisation.service";
import { FullPageLoader } from "../../../utils/FullPageLoader";
import { Toast } from "../../../utils/Toast";
import { OgranizationCard } from "../../auth/pages/follow-organisations/OrganiserCard";
import { IOrganization } from "../createorganisation/interfaces/organisation.interface";
import Select from "react-select";
import countryList from "react-select-country-list";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Badge, Chip } from "@material-ui/core";
import {useTranslation} from "react-i18next";

export const AllOrganizations = () => {
  const [isLoading, setIsloading] = useState(true);
  const [search, setSearch] = useState("");
  const [debounceSearch] = useDebounce(search, 300);
  
  const [allOrganizations, setAllOrganizations] = useState<IOrganization[]>([]);
  const [followOrganizations, setFollowOrganizations] = useState<
    IOrganization[]
  >([]);
  const [paginationInfoOrg, setPaginationInfoOrg] = useState<IPaginationInfo>();
  const [followPaginationInfoOrg, setFollowPaginationInfoOrg] =
    useState<IPaginationInfo>();
  const [currentTab, setCurrentTab] = useState<string>("following");
  const [country, setCountry] = useState({
    label: "",
    value: "",
  });
  const [city, setCity] = useState("");
  const [debounceCity] = useDebounce(city, 300);
  const options = useMemo(() => countryList().getData(), []);
  const { t } = useTranslation('', { keyPrefix: 'AllOrganizationsPage' });

  useEffect(() => {
    getResults();
    // getFollowOrganizations(debounceSearch, 1, false)
  }, [debounceSearch, currentTab, country, debounceCity]);

  const handleMemberRequest = (status: string, id: string) => {
    let tempOrg;
    if (currentTab === 'all') {
      tempOrg = allOrganizations!.find((org) => org._id === id);
      tempOrg!.isMember = status;
      setAllOrganizations([...allOrganizations!])
    } else {
      tempOrg = followOrganizations!.find((org) => org._id === id);
      tempOrg!.isMember = status;
      setFollowOrganizations([...followOrganizations!])
    }
  };
  const getAllOrganizations = async (
    input: string,
    page = 1,
    paginate: boolean
  ) => {
    setIsloading(true);
    const res: any = await organisationService.searchParentOrganization(
      input,
      page,
      country.label.toLowerCase(),
      city.toLowerCase()
    );
    setIsloading(false);
    if (res.statusCode == 200) {
      if (paginate && page > 1) {
        setAllOrganizations([
          ...allOrganizations,
          ...res.payload.organizations.records,
        ]);
      } else {
        setAllOrganizations([...res.payload.organizations.records]);
      }
      setPaginationInfoOrg(res.payload.organizations.paginationInfo);
    } else {
      Toast.fire({
        icon: "warning",
        title: res.message,
      });
    }
  };
  const getFollowOrganizations = async (
    input: string,
    page = 1,
    paginate: boolean
  ) => {
    setIsloading(true);
    const res: any = await organisationService.followingOrganizations(
      input,
      page,
      country.label.toLowerCase(),
      city.toLowerCase()
    );
    setIsloading(false);
    if (res.statusCode == 200) {
      if (paginate && page > 1) {
        setFollowOrganizations([
          ...followOrganizations,
          ...res.payload.organizations.records,
        ]);
      } else {
        setFollowOrganizations([...res.payload.organizations.records]);
      }
      setFollowPaginationInfoOrg(res.payload.organizations.paginationInfo);
    } else {
      Toast.fire({
        icon: "warning",
        title: res.message,
      });
    }
  };
  const changeHandler = (value: any) => {
    setCountry(value);
    // if (value == "all") {
    //   getAllOrganizations(debounceSearch, 1, false);
    // } else {
    //   getFollowOrganizations(debounceSearch, 1, false);
    // }
  };
  const handleTabSelection = (e: any) => {
    setCurrentTab(e);
    // setCountry({
    //   label: "",
    //   value: "",
    // });
    // setCity("");
    // setSearch("");
  };

  const getResults = () => {
    if (currentTab == "all") {
      getAllOrganizations(debounceSearch, 1, false);
    } else {
      getFollowOrganizations(debounceSearch, 1, false);
    }
  };

  return (
    <AppLayout>
      <div className="up-allorganisationspage">
        {isLoading && <FullPageLoader />}
        <div className="up-themeboxhead">
          <h3>{t('organizations')}</h3>
        </div>
        <div className="up-followerhead">
          <form className="up-formtheme up-formsearchorganisation">
            <fieldset>
        
              <div className="form-group">
                <Select
                  placeholder={t('selectCountry')}
                  options={options}
                  value={country}
                  onChange={changeHandler}
                />
              </div>
              <div className="form-group">
                <input
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                  name="city"
                  className="form-control"
                  placeholder={t('enterCity')}
                />
              </div>
              <div className="form-group">
                <input
                  type="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  name="search-organisers"
                  className="form-control"
                  placeholder={t('searchOrgClub')}
                />
                {/* <button
                  onClick={getResults}
                  className="up-btnsearch"
                  type="button"
                >
                  <i className="icon-search"></i>
                </button> */}
              </div>
            </fieldset>
          </form>
          <div>
            {country.label != "" && (
              <Badge
                badgeContent={"x"}
                className="m-3"
                color="primary"
                onClick={async () => {
                  setCountry(prev => ({...prev, label: "", value: "" }));
                }}
              >
                <Chip color="default" label={`country: ${country.label}`} />
              </Badge>
            )}

            {city != "" && (
              <Badge
                badgeContent={"x"}
                className="m-3"
                color="primary"
                onClick={() => {
                  setCity("");
                }}
              >
                <Chip color="default" label={`city: ${city}`} />
              </Badge>
            )}
            {search != "" && (
              <Badge
                badgeContent={"x"}
                className="m-3"
                color="primary"
                onClick={() => setSearch("")}
              >
                <Chip color="default" label={`search: ${search}`} />
              </Badge>
            )}
          </div>
        </div>

        <div className="up-followorganizers">
          <Tabs
            defaultActiveKey="following"
            onSelect={(e: any) => handleTabSelection(e)}
          >
            <Tab eventKey="following" title={t('following')}>
              {followOrganizations && currentTab === "following" && (
                <InfiniteScroll
                  dataLength={followOrganizations!.length} //This is important field to render the next data
                  next={() =>
                    getFollowOrganizations(
                      debounceSearch,
                      +((followPaginationInfoOrg?.currentPage! || 0) + 1),
                      true
                    )
                  }
                  hasMore={
                    followOrganizations!.length !==
                    followPaginationInfoOrg?.totalRecords!
                  }
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <p
                      className="up-allpostseen"
                      style={{ textAlign: "center" }}
                    >
                      <b>{t('seenItAll')}</b>
                    </p>
                  }
                >
                  <div className="up-followorganizersholder">
                    {followOrganizations &&
                      followOrganizations.map((item: IOrganization, index) => {
                        return (
                          <OgranizationCard
                            socialLinks={[]}
                            setIsMember={handleMemberRequest}
                            postCount={item.postCount}
                            _id={item._id}
                            key={item._id}
                            name={item.name}
                            type={item.type}
                            members={item.members}
                            avatar={item.avatar ?? images.RestPlaceholder}
                            banner={item.banner ?? images.RestPlaceholder}
                            isMember={item.isMember}
                          />
                        );
                      })}
                  </div>
                </InfiniteScroll>
              )}
            </Tab>
            <Tab eventKey="all" title={t('all')}>
              {allOrganizations && currentTab === "all" && (
                <InfiniteScroll
                  dataLength={allOrganizations!.length} //This is important field to render the next data
                  next={() =>
                    getAllOrganizations(
                      debounceSearch,
                      +((paginationInfoOrg?.currentPage! || 0) + 1),
                      true
                    )
                  }
                  hasMore={
                    allOrganizations!.length !==
                    paginationInfoOrg?.totalRecords!
                  }
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <p
                      className="up-allpostseen"
                      style={{ textAlign: "center" }}
                    >
                      <b>{t('seenItAll')}</b>
                    </p>
                  }
                >
                  <div className="up-followorganizersholder">
                    {allOrganizations &&
                      allOrganizations.map((item: IOrganization, index) => {
                        return (
                          <OgranizationCard
                            socialLinks={[]}
                            setIsMember={handleMemberRequest}
                            postCount={item.postCount}
                            _id={item._id}
                            key={item._id}
                            name={item.name}
                            type={item.type}
                            members={item.members}
                            avatar={item.avatar ?? images.RestPlaceholder}
                            banner={item.banner ?? images.RestPlaceholder}
                            isMember={item.isMember}
                          />
                        );
                      })}
                  </div>
                </InfiniteScroll>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </AppLayout>
  );
};
