// import { styles } from "./"

import { images } from "../../../assets/images";
import { CreateOrganisationForm } from "./components/CreateOrganisationForm";
import {useTranslation} from "react-i18next";

export const CreateOrganisation = () => {
    const { t } = useTranslation('', { keyPrefix: 'CreateOrganisation' });
    return (
    <div className="up-loginarea up-createorganisationpage">
      <figure className="up-circlimages">
        <img src={images.LoginCircles} alt="Circles"/>
      </figure>
      <div className="up-logincontentarea">
          <figure className="up-createorganisationimage">
              <img src={images.CreateOrganisationImage} alt="Create Organisation Image"/>
          </figure>
        <h3>{t('registerOrganization')}</h3>
        <div className="up-description">
          <p>{t('registerDetail')}</p>
        </div>
      </div>
      <div className="up-loginformholder">
        <div className="up-loginformcontentholder">
          <strong className="up-logo">
            <img src={images.Logo} alt="Logo Image"/>
          </strong>
          <div className="up-formHead">
            <h1>{t('createOrganization')}</h1>
            {/* <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc<em>rhoncus odio quam, interdum aliquet enim varius vel.</em></span> */}
          </div>
          <CreateOrganisationForm/>
        </div>
      </div>
    </div>
  )
};
