import { ICreateEvent } from "../features/organisations/createorganisation/interfaces/create-event.interface";
import { IGroup } from "../features/organisations/createorganisation/interfaces/group.interface";
import { IResponseInterface } from "../interfaces/api-response.interface";
import { HttpService } from "./base.service";

class NotificationService extends HttpService {
    private readonly notificationPrefix: string = "notification";
    private readonly messagePrefix: string = "message";

  getNotifications = (page:number,limit:number): Promise<IResponseInterface<{}>> => this.get(`${this.notificationPrefix}?page=${page}&limit=${limit}`)
 
  getNotificationUnreadCount = (): Promise<IResponseInterface<{}>> => this.get(`${this.notificationPrefix}/unread-count`)

  markAsReadNotification = (): Promise<IResponseInterface<{}>> => this.put(`${this.notificationPrefix}/mark-read`)
  
  deleteNotification = (id: string): Promise<IResponseInterface<{}>> => this.delete(`${this.notificationPrefix}/${id}`);

  getUnreadMessages = (page:number,limit:number): Promise<IResponseInterface<{}>> => this.get(`${this.messagePrefix}/unread?page=${page}&limit=${limit}`)
  markAllAsRead = (): Promise<IResponseInterface<{}>> => this.get(`${this.messagePrefix}/all/mark-read`)

}
  export const notificationService = new NotificationService();