import {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {useSelector} from 'react-redux';
import {EEventJoiningStatus} from '../../interfaces/event-join-status.interface';
import {eventService} from '../../services/events.service';
import {Toast} from '../../utils/Toast';
import {getUser} from '../auth/slice/user.selector';
import {IEventDetailModalProps} from '../organisations/createorganisation/interfaces/create-event-modal.interface';
import {IEvent} from '../organisations/createorganisation/interfaces/event.interface';
import {Event} from '../ownorganisation/components/Event'
import {EventDetailModal} from '../ownorganisation/components/EventDetailModal';
import {EventDisplayToModal} from './EventDisplayToModal';
import {useTranslation} from "react-i18next";
import {DynamicLoader} from "../../utils/DynamicLoader";

export const ShowEventModal: React.FC<IEventDetailModalProps> = (props) => {
    const [eventDetail, setEventDetail] = useState<IEvent>(Object.assign({}, props.editEvent));
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [temp, setTemp] = useState(false);
    const currentUser = useSelector(getUser);
    const [showDisplayToModal, setShowDisplayToModal] = useState(false);
    const [isLoading, setIsloading] = useState(true);
    const {t} = useTranslation('', {keyPrefix: 'ShowEventModal'});

    useEffect(() => {
        showDetail(props.editEvent._id, 0, true)
    }, [])
    const handleCloseeight = () => {
        props.closeModal(true)
    }

    const updateJoiningStatus = async (event: IEvent, status: string, index: number) => {
        const data = {
            organizationId: event.organization,
            eventId: event._id,
            joiningStatus: status
        }
        const res: any = await eventService.joinEventOrganization(data)
        if (res.statusCode == 200) {
            switch (status) {
                case EEventJoiningStatus.JOINING:
                    eventDetail.joiningStatus = EEventJoiningStatus.JOINING;
                    Toast.fire({
                        icon: 'success',
                        title: res.message
                    })
                    break;
                case EEventJoiningStatus.INTERESTED:
                    eventDetail.joiningStatus = EEventJoiningStatus.INTERESTED;
                    Toast.fire({
                        icon: 'success',
                        title: 'Event status updated'
                    })
                    break;
                case EEventJoiningStatus.NOT_JOINING:
                    eventDetail.joiningStatus = EEventJoiningStatus.NOT_JOINING;

                    Toast.fire({
                        icon: 'success',
                        title: 'Event status updated'
                    })
                    break;
            }
            setEventDetail(eventDetail)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const showDetail = async (id: string, index: number, show: boolean) => {
        if (show) {
            setIsloading(true)
            const res: any = await eventService.getEventDetail(id);
            setIsloading(false)
            res.payload.event.isExpanded = show;
            setEventDetail(res.payload.event)
        } else {
            eventDetail.isExpanded = show;
            setEventDetail(eventDetail)
        }
        setTemp(!temp)
    }

    const closeEventDetailModal = (data: any) => {
        setShowDetailModal(false)
    }

    const openDisplayToModal = (event: IEvent) => {
        if (event.displayTo.length > 0) {
            // setSelectedEditEvent(event);
            setShowDisplayToModal(true)
        }
    }
    const closeEventDisplayToModal = (data: any) => {
        setShowDisplayToModal(false)
    }
    return <><Modal className="up-thememodal up-modalcreategroup up-modalcreateevents" show={!showDetailModal}
                    onHide={handleCloseeight}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('eventDetail')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody up-eventmodalwidget">
            {isLoading && <DynamicLoader/>}
            {eventDetail && <Event fromAdminDashboard={false} fromTab={false} index={69} event={eventDetail}
                                   memberId={currentUser._id}
                                   updateJoiningStatus={updateJoiningStatus} openDisplayToModal={openDisplayToModal}
                                   showDetail={showDetail} setShowDetailModal={setShowDetailModal}/>}
        </Modal.Body>
    </Modal>
        {showDetailModal && <EventDetailModal editEvent={eventDetail} closeModal={closeEventDetailModal}/>}
        {showDisplayToModal && <EventDisplayToModal editEvent={eventDetail} closeModal={closeEventDisplayToModal}/>}
    </>
}