// import User from "../user/User";
import {DashboardLayout} from "../layouts/DashboardLayout";
import {useLocation, useParams} from "react-router";
import {IIdFromUrl} from "../../interfaces/id-from-url.interface";
import UsersIcon from "../../assets/images/d-icon/users.svg";
import {useEffect, useState} from 'react';
import {IEvent} from '../../features/organisations/createorganisation/interfaces/event.interface';
import {dashboardService} from '../../services/dashboard.service';
import {Toast} from '../../utils/Toast';
import {IPaginationInfo} from '../../interfaces/pagination-info.interface';
import {FullPageLoader} from '../../utils/FullPageLoader';
import NoDataFound from '../NoData/NoDataFound';
import {CreateEventModal} from "../../features/ownorganisation/components/CreateEventModal";
import {organisationService} from "../../services/organisation.service";
import {
    IOrganizationRequestedMembers,
    IOrganizationRole
} from "../../features/organisations/createorganisation/interfaces/organization-requested-members.interface";
import Swal from "sweetalert2";
import {eventService} from "../../services/events.service";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import {groupService} from "../../services/group.service";
import InfiniteScroll from "react-infinite-scroll-component";
import {Event} from "../../features/ownorganisation/components/Event";
import {EEventJoiningStatus} from "../../interfaces/event-join-status.interface";
import {EventDetailModal} from "../../features/ownorganisation/components/EventDetailModal";
import {EventDisplayToModal} from "../../features/themecomponents/EventDisplayToModal";
import {useTranslation} from "react-i18next";


export default function Events() {
    const [isLoading, setIsloading] = useState(true);
    const [avgAttendees, setAvgAttendees] = useState(0);
    const [organizationEvents, setOrganizationEvents] = useState<IEvent[]>([])
    const [paginationInfoEvents, setPaginationInfoEvents] = useState<IPaginationInfo>();
    const [selectedEditEvent, setSelectedEditEvent] = useState<IEvent>();
    const [isEdit, setIsEdit] = useState(false);
    const [showCreateEventModal, setCreateEventModal] = useState(false);
    const [organizationRoles, setOrganizationRoles] = useState<IOrganizationRole[]>([])
    const [selectedGroup, setSelectedGroup] = useState<string>();
    const [selectedUser, setSelectedUser] = useState<string>();
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const location = useLocation();
    const [organizationName] = useState(location?.search.replace('?',''));
    const [myRole, setMyRole] = useState<IOrganizationRequestedMembers>({
        isBlocked: "NO",
        member: {
            _id: 'string',
            name: 'string',
            username: 'string',
            email: 'string'
        },
        organization: "",
        role: {
            _id: 'string',
            role: 'string',
            policies: [],
        },
        status: "",
        _id: "",
        isMember: false,
        _v: 0
    });
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showDisplayToModal, setShowDisplayToModal] = useState(false);

    let { id } = useParams<IIdFromUrl>();
    const { t } = useTranslation('', { keyPrefix: 'Events' });

    useEffect(() => {
        allCalls()
    }, [])

    const allCalls = async () => {
        setIsloading(true)
        await Promise.all([getDashboardEventAttendees(),getOrganizationRoles(id),getMyOrganizationRole()]);
        setIsloading(false)
    }
    useEffect(() => {
        getOrganizationEvents(1)
    }, [selectedGroup, selectedUser, dateRange])

    const getMyOrganizationRole = async () => {
        const res: any = await organisationService.getMyOrganizationRole(id);
        if (res.statusCode == 200) {
            if (res.payload.organizationMember && res.payload.organizationMember.status == "ACCEPTED") {
                res.payload.organizationMember.isMember = true;
                setMyRole(res.payload.organizationMember)
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getOrganizationEvents = async (page:number = 1) => {
        let params = `?page=${page}&limit=10`;
        setIsloading(true)
        if (selectedGroup) {
            params = params + `&group=${selectedGroup}`;
        }
        if (selectedUser) {
            params = params + `&creator=${selectedUser}`;
        }
        if (dateRange[0]) {
            let startDate: any = dateRange[0];
            let endDate: any = dateRange[1];
            startDate = `${startDate.getMonth() + 1}-${startDate.getDate()}-${startDate.getFullYear()}`
            if (endDate)
            {
                endDate = `${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`
            }
            params = params + `&startDate=${startDate}&endDate=${endDate || startDate}`;
        }
        const res: any = await dashboardService.getDashboardEvents(id,params);
        setIsloading(false)
        if (res.statusCode == 200) {
            if (page ==1 )
            {
                setOrganizationEvents(res.payload.events.records)
            }else{
                setOrganizationEvents([...organizationEvents,...res.payload.events.records])
            }
            setPaginationInfoEvents(res.payload.events.paginationInfo)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const editEvent = (event: IEvent) => {
        setSelectedEditEvent(event);
        setIsEdit(true);
        setCreateEventModal(true)
    }
    const closeEventModal = (data: any) => {
        setCreateEventModal(false)
        setIsEdit(false);
        setSelectedEditEvent(undefined)
    }

    const eventCreated = (event: IEvent, created: boolean) => {
        if (created) {
            if (organizationEvents) {
                setOrganizationEvents([event,...organizationEvents!])
            } else {
                setOrganizationEvents([event])
            }
        } else {
            const tempIndex = organizationEvents!.findIndex(eve => eve._id == event._id);
            organizationEvents!.splice(tempIndex, 1, event)
            setOrganizationEvents([...organizationEvents!])
        }
    }

    const deleteEvent = async (event: IEvent, index: number) => {
        Swal.fire({
            title: t('deleteEvent'),
            text: t('areYouSure'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('delete'),
            cancelButtonText: t('cancel')
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await eventService.deleteEvent(event._id);
                    if (res.statusCode === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: res.message
                        })
                        organizationEvents!.splice(index, 1)
                        setOrganizationEvents([...organizationEvents!])
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }
    const onChangeSelectedOptionGroups = (e: any) => {
        setSelectedGroup(e?.value ?? null);
    };
    const onChangeSelectedOptionUsers = (e: any) => {
        setSelectedUser(e?.value ?? null);
    };
    const getDashboardEventAttendees = async () => {
        const res: any = await dashboardService.getDashboardEventAttendees(id);
        if (res.statusCode == 200) {
            setAvgAttendees(res.payload.avgNumOfAttendees)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getOrganizationRoles = async (id: string) => {
        const res: any = await organisationService.getOrganizationRoles(id);
        if (res.statusCode == 200) {
            // const temp = res.payload.organizationRoles.filter((role: any) => role.role !== 'Admin');
            for (const iterator of res.payload.organizationRoles) {
                iterator.checked = false;
            }
            setOrganizationRoles(res.payload.organizationRoles)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const promiseOptionsGroups = async (inputValue: string) => {
        // if (inputValue.length > 1) {
        const res: any = await groupService.getOrganizationGroups(id, 1, inputValue, 10);
        if (res.statusCode == 200) {
            const tempArray: any[] = [];
            for (let i = 0; i < res.payload.groups.records.length; i++) {
                tempArray.push({
                    label: res.payload.groups.records[i].name,
                    value: res.payload.groups.records[i]._id
                })
            }
            return tempArray;
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        // }
    }
    const promiseOptionsUsers = async (inputValue: string) => {
        if (inputValue.length > 2) {
            const res: any = await organisationService.getOrganizationMembers(id, 1, inputValue, 10);
            if (res.statusCode == 200) {
                const tempArray: any[] = [];
                for (let i = 0; i < res.payload.organizationMembers.records.length; i++) {
                    tempArray.push({
                        label: res.payload.organizationMembers.records[i].member.name,
                        value: res.payload.organizationMembers.records[i].member._id
                    })
                }
                return tempArray;
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }
    }

    const updateJoiningStatus = async (event: IEvent, status: string, index: number) => {
        const data = {
            memberId: myRole._id,
            joiningStatus: status
        }
        const res: any = await eventService.joinEvent(event._id, data)
        if (res.statusCode == 200) {
            const tempEvents: any = organizationEvents
            tempEvents[index].joiningStatus = status;
            switch (status) {
                case EEventJoiningStatus.JOINING:
                    Toast.fire({
                        icon: 'success',
                        title: res.message
                    })
                    break;
                case EEventJoiningStatus.INTERESTED:
                    Toast.fire({
                        icon: 'success',
                        title: t('eventStatusUpdated')
                    })
                    break;
                case EEventJoiningStatus.NOT_JOINING:
                    Toast.fire({
                        icon: 'success',
                        title: t('eventStatusUpdated')
                    })
                    break;
            }
            setOrganizationEvents([...tempEvents])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const showDetail = async (id: string, index: number, show: boolean) => {
        if (show) {
            const res: any = await eventService.getEventDetail(id);
            let tempEvents: any = organizationEvents
            tempEvents[index] = res.payload.event;
            tempEvents[index].isExpanded = show;
            setOrganizationEvents([...tempEvents])
        } else {
            const tempEvents: any = organizationEvents
            tempEvents[index].isExpanded = show;
            setOrganizationEvents([...tempEvents])
        }
    }
    const openDisplayToModal = (event: IEvent) => {
        if (event.displayTo.length > 0) {
            setSelectedEditEvent(event);
            setShowDisplayToModal(true)
        }
    }
    const closeEventDetailModal = (data: any) => {
        setShowDetailModal(false)
    }
    const closeEventDisplayToModal = (data: any) => {
        setShowDisplayToModal(false)
    }
    return (
        <DashboardLayout id={id} orgName={organizationName}>
            {isLoading && <FullPageLoader />}
            <div className="up-eventspageholder">
                <div className="up-userfilterholder">
                    <div className="up-userspagetitle">
                        <h2>{t('events')}</h2>
                    </div>
                </div>
                <div className="up-eventtwocol">
                    <div className="up-eventcolone up-eventflexparent">
                        {organizationEvents && organizationEvents.length === 0 && <NoDataFound />}
                        {organizationEvents && <InfiniteScroll
                            dataLength={organizationEvents!.length} //This is important field to render the next data
                            next={() => getOrganizationEvents(paginationInfoEvents?.currentPage! + 1)}
                            hasMore={organizationEvents!.length !== paginationInfoEvents?.totalRecords!}
                            loader={<h4>{t('loading')}</h4>}
                            endMessage={<></>}>
                            {organizationEvents && organizationEvents!.map((event: IEvent, index: number) => <Event
                                fromAdminDashboard={true}
                                fromTab={true} index={index} event={event} memberId={myRole}
                                key={event._id} deleteEvent={deleteEvent} editEvent={editEvent}
                                setSelectedEditEvent={setSelectedEditEvent} updateJoiningStatus={updateJoiningStatus}
                                showDetail={showDetail} setShowDetailModal={setShowDetailModal}
                                openDisplayToModal={openDisplayToModal}/>)}
                        </InfiniteScroll>}
                    </div>  
                    <div className="up-eventcoltwo">
                        <div className='up-statsgrid'>
                            <div className="up-stat">
                                <form className="up-formtheme">
                                    <fieldset>
                                        <div className="up-inputwidthfifty up-inputresponsive">
                                            <div className="form-group">
                                                <div className="up-inputwithicon">
                                                    <AsyncSelect
                                                        defaultValue={selectedGroup}
                                                        isClearable={true}
                                                        // menuIsOpen={true} 
                                                        className="up-async-select up-mainsearchholder up-eventpageselectissue"
                                                        placeholder={t('userGroups')}
                                                        onChange={onChangeSelectedOptionGroups}
                                                        cacheOptions
                                                        defaultOptions
                                                        loadOptions={promiseOptionsGroups} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="up-inputwithicon">
                                                    <AsyncSelect defaultValue={selectedUser} isClearable={true}  className="up-async-select up-mainsearchholder up-eventpageselectissue" placeholder={t('searchUsers')} onChange={onChangeSelectedOptionUsers} cacheOptions defaultOptions loadOptions={promiseOptionsUsers} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="up-filterbtn up-datapickerfilterarea">

                                            <DatePicker
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(update: any) => {
                                                    setDateRange(update);
                                                }}
                                                placeholderText={t('selectDateRange')}
                                                isClearable={true}
                                            />
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                            <div className="up-stat">
                                <span className="up-staticon">
                                    <img src={UsersIcon} alt='statsicon' />
                                </span>
                                <div className="up-statcontent">
                                    <ul>
                                        <li>
                                            <span>{t('totalEvents')}</span>
                                            <h3>{paginationInfoEvents?.totalRecords}</h3>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="up-stat">
                                <span className="up-staticon">
                                    <img src={UsersIcon} alt='statsicon' />
                                </span>
                                <div className="up-statcontent">
                                    <ul>
                                        <li>
                                            <span>{t('averageAttendees')}</span>
                                            <h3>{avgAttendees}</h3>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showCreateEventModal && <CreateEventModal isEdit={isEdit} organizationRoles={organizationRoles} editEvent={selectedEditEvent!} closeModal={closeEventModal} eventCreated={eventCreated} />}
            {showDetailModal && <EventDetailModal editEvent={selectedEditEvent!} closeModal={closeEventDetailModal} />}
            {showDisplayToModal && <EventDisplayToModal editEvent={selectedEditEvent!} closeModal={closeEventDisplayToModal} />}
        </DashboardLayout>
    )
}