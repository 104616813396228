import {useEffect, useState} from "react";
import {Modal} from "react-bootstrap"
import {organisationService} from "../../../services/organisation.service";
import moment from "moment";
import {useTranslation} from "react-i18next";

export const KeyLogModal: React.FC<any> = (props: any) => {

    const [logs, setLogs] = useState<any[]>([])
    const { t } = useTranslation('', { keyPrefix: 'KeyLogModal' });

    useEffect(() => {
        getCodeLogData();
    }, [])
    const closeDisplayToModal = () => {
        props.closeModal(false)
    }
    const getCodeLogData = async () => {
        const res: any = await organisationService.getOrganizationCodeLogs(props.id, props.code)
        setLogs(res.payload.logs)
    }

    return <Modal className="up-thememodal up-invitemembersmodal" show={true} onHide={closeDisplayToModal}>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-forminvitememners">
                    <fieldset>
                        <legend>{t('keyLogs')}</legend>
                        {logs && logs.length !== 0 && <table className="up-filestable table up-responsivetable">
                            <thead>
                                <tr>
                                    <th><span>{t('userName')}</span></th>
                                    <th><span>{t('key')}</span></th>
                                    <th><span>{t('usedAt')}</span></th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs!.map((item: any, index: number) => (<tr key={item.organization + index}>
                                    <td data-title="File Name">
                                        {item?.member?.name}
                                    </td>
                                    <td data-title="Key">
                                        <span>{item?.comment}</span>
                                    </td>
                                    <td data-title="User Group">
                                        {moment(item.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>}
                        {logs.length === 0 && <div>{t('noLogsFound')}</div>}
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}