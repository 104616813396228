import * as React from 'react';
export const NotFound: React.FC = () => {
    return ( 
        <div>
            <h1>
                404 Not Found
            </h1>
        </div>
     );
}
 
 
