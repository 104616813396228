import * as yup from "yup";
import moment from "moment";
const today = new Date();
today.setFullYear(today.getFullYear() - 1);
export const createEventSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  url: yup.string().optional(),
  ticket: yup.string().optional(),
  // location: yup.string(),
  description: yup.string().required('description is required'),
  notificationType: yup.string().optional(),
  avatar: yup.string(),
  // joiningEndDate: yup.date().required('description is required'),
  // startDate: yup.date().nullable()
  // .transform((curr, orig) => orig === '' ? null : curr)
  // .required('Start Date is required').required('Start Date is required').min(today),
  // endDate: yup.date().nullable()
  // .transform((curr, orig) => orig === '' ? null : curr)
  // .required('End Date is required').min(yup.ref('startDate'), "end date can't be before start date"),
  isSubscriptionNeeded : yup.boolean(),
  canAnswerUntilEndDate : yup.string().required('Field is required'),
  isAllDayEvent : yup.boolean(),
  startTime: yup.string(),
  endTime: yup.string()
}).required();
const isSameOrBefore = (startTime: any, endTime: any) => {
  return moment(startTime, 'HH:mm').isSameOrBefore(moment(endTime, 'HH:mm'));
}
//  TIME VALIDATION BELOW
// .test(
//   'not empty',
//   'Start time cant be empty',
//   function (value: any) {
//     return !!value;
//   }
// )
// .test(
//   "start_time_test",
//   "Start time must be before end time",
//   function (value: any) {
//     const { endTime } = this.parent;
//     return isSameOrBefore(value, endTime);
//   }
// )