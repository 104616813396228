import { IResponseInterface } from "../interfaces/api-response.interface";
import { HttpService } from "./base.service";

class ScheduledPostService extends HttpService {
    private readonly prefix: string = "scheduled-post";

    getSchedulePost = (id: string, page: number): Promise<IResponseInterface<{ }>> => this.get(`${this.prefix}/organization/${id}?page=${page}&limit=10`)
    createSchedulePost = (data: any): Promise<IResponseInterface<{ }>> => this.post(`${this.prefix}`, data)

    updateSchedulePost = (id: string, data: any): Promise<IResponseInterface<{ }>> => this.put(`${this.prefix}/${id}`, data)

    deleteSchedulePost = (id: string): Promise<IResponseInterface<{ }>> => this.delete(`${this.prefix}/${id}`)

}
export const scheduledPostService = new ScheduledPostService();