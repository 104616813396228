import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { IForgotPasswordInterface } from "../interfaces/forgot-password.interface";
import { forgotPasswordSchema } from "../validations/forgot-password.validation";
import { useDispatch } from "react-redux";
import { Toast } from "../../../../../utils/Toast";
import { ForgotPasswordThunk } from "../../../slice/auth.thunks";
import { useState } from "react";
import {useTranslation} from "react-i18next";

export const ForgotPassword = () => {
    const [isLoading, setIsloading] = useState(false);
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(forgotPasswordSchema)
    });
    const { t } = useTranslation('', { keyPrefix: 'ForgotPassword' });
    const onSubmit = async (data: IForgotPasswordInterface) => {
        setIsloading(true)
        data.email.toLowerCase()
        const res: any = await dispatch(ForgotPasswordThunk(data));
        setIsloading(false)
        if (res.payload.statusCode == 200) {
            Toast.fire({
                icon: "success",
                title: res.payload.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.payload.message
            })
        }
    };
    return (
        <form className="up-formtheme up-formforgotpassword" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <div className="form-group">
                    <label>{t('email')}</label>
                    <input type="email"  {...register("email")} name="email" className="form-control" placeholder={t('enterEmail')} />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.email?.message}
                    </small>
                </div>
                <div className="up-formbuttons">
                    <button type="submit" className="up-btn up-btn-lg up-btnwithloader">{t("resetPassword")} {isLoading && <div className="lds-dual-ring"></div>}</button>
                    <a href="/login" className="up-btn up-btn-lg">{t('signIn')}</a>
                </div>
            </fieldset>
        </form>
    )
};
