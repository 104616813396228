import {useEffect, useRef, useState} from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { images } from '../../../assets/images';
import { eventService } from '../../../services/events.service';
import { FullPageLoader } from '../../../utils/FullPageLoader';
import { Toast } from '../../../utils/Toast';
import { IEventDetailModalProps } from '../../organisations/createorganisation/interfaces/create-event-modal.interface';
import { IEvent } from '../../organisations/createorganisation/interfaces/event.interface';
import {CSVLink} from "react-csv";
import {useTranslation} from "react-i18next";

export const EventDetailModal: React.FC<IEventDetailModalProps> = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [eventDetail, setEventDetail] = useState<IEvent>(props.editEvent);
    const [csvEvent, setCsvEvent] = useState<any[]>([]);
    const csvLink: any = useRef()
    const { t } = useTranslation('', { keyPrefix: 'GuestListDetailModal' });

    useEffect(() => {
        getEventDetail()
    }, [])

    useEffect(() => {
        if (csvEvent && csvEvent.length > 0) {
            csvLink?.current?.link?.click();
            setCsvEvent([]);
        }
    }, [csvEvent])
    const getEventDetail = async () => {
        const res: any = await eventService.getEventDetail(props.editEvent?._id);
        setIsLoading(false);
        if (res.statusCode = 200) {
            setEventDetail(res.payload.event)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const handleCloseeight = () => {
        props.closeModal(true)
    }

    const downLoadCSV = ()=> {
        const {editEvent} = props
        const csvData = [['Event title', 'Event Start Date/Time', 'Event End Date/Time', 'Event Organizer Username', 'Guest User Name', 'Guest User Username', 'Guest User Email', 'Guest User Answer']];

        for (let joinedMember of editEvent.joinedMembers) {
            csvData.push([editEvent.name,editEvent.startDate,editEvent.endDate,editEvent.creator.name,joinedMember.member.name,joinedMember.member.username,joinedMember.member?.email ?? '','Joining'])
        }
        for (let joinedMember of editEvent.notJoiningMembers) {
            csvData.push([editEvent.name,editEvent.startDate,editEvent.endDate,editEvent.creator.name,joinedMember.member.name,joinedMember.member.username,joinedMember.member?.email ?? '','Not Joining'])
        }
        for (let joinedMember of editEvent.interestedMembers) {
            csvData.push([editEvent.name,editEvent.startDate,editEvent.endDate,editEvent.creator.name,joinedMember.member.name,joinedMember.member.username,joinedMember.member?.email ?? '','Interested'])
        }
        if (csvData.length === 1) {
            csvData.push([editEvent.name,editEvent.startDate,editEvent.endDate,editEvent.creator.name,'','','','', ''])
        }
        setCsvEvent(csvData)
    }

    return <Modal className="up-thememodal up-modalcreategroup up-modalcreateevents" show={true} onHide={handleCloseeight}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('guestListDetail')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            {isLoading && <FullPageLoader />}
            <CSVLink
                data={csvEvent}
                filename={`${props.editEvent.name}.csv`}
                className='hidden'
                ref={csvLink}
                target='_blank'
            />
            <div className="up-modalcontentholder up-showmemderinevent">
                <Tabs className="up-profiletabs">
                    <Tab eventKey="joined" title={`${t('joined')} (${eventDetail.joinedMembers?.length})`}>
                        {eventDetail.joinedMembers.length > 0 && eventDetail.joinedMembers.map((member) => {
                            return <div className="up-usertabledetail up-eventuserdetail" style={{ marginBottom: '12px' }}>
                                <figure>
                                    <img src={member.member?.avatar || images.UserPlaceholder} alt="user image" />
                                </figure>
                                <h4>{member.member?.name}</h4>
                            </div>
                        })}
                        {eventDetail.joinedMembers.length === 0 && <div className="text-center up-minheight">{t('noMembersFound')}</div>}
                    </Tab>
                    <Tab eventKey="notJoined" title={`${t('notJoined')} (${eventDetail.notJoiningMembers?.length})`}>
                        {eventDetail.notJoiningMembers.map((member) => {
                            return <div className="up-usertabledetail up-eventuserdetail" style={{ marginBottom: '12px' }}>
                                <figure>
                                    <img src={member.member?.avatar || images.UserPlaceholder} alt="user image" />
                                </figure>
                                <h4>{member.member?.name}</h4>
                            </div>
                        })}
                        {eventDetail.notJoiningMembers.length === 0 && <div className="text-center up-minheight">{t('noMembersFound')}</div>}
                    </Tab>
                    <Tab eventKey="interested" title={`${t('interested')} (${eventDetail.interestedMembers?.length})`}>
                        {eventDetail.interestedMembers.map((member) => {
                            return <div className="up-usertabledetail up-eventuserdetail" style={{ marginBottom: '12px' }}>
                                <figure>
                                    <img src={member.member?.avatar || images.UserPlaceholder} alt="user image" />
                                </figure>
                                <h4>{member.member?.name}</h4>
                            </div>
                        })}
                        {eventDetail.interestedMembers.length === 0 && <div className="text-center up-minheight">{t('noMembersFound')}</div>}
                    </Tab>
                </Tabs>
            </div>
            <button className="up-btn mb-3 mx-4" style={{float: 'right'}} onClick={downLoadCSV}>{t('downloadCSV')}</button>
        </Modal.Body>
    </Modal>
}
