import { IPostComment } from "../features/organisations/createorganisation/interfaces/post-comment.interface";
import { IPost } from "../features/organisations/createorganisation/interfaces/post.interface";
import { IResponseInterface } from "../interfaces/api-response.interface";
import { HttpService } from "./base.service";

class PostService extends HttpService {
    private readonly postPrefix: string = "post";
    private readonly commentPrefix: string = "comment";
  
  createPost = (data: any): Promise<IResponseInterface<{post:IPost}>> => this.post(`${this.postPrefix}`, data);

  getOrganizationsPosts = (id: string,page:number): Promise<IResponseInterface<{posts: IPost[]}>> => this.get(`${this.postPrefix}/organization/${id}?page=${page}&limit=5`)
 
  getOrganizationPublicPosts = (id: string,page:number): Promise<IResponseInterface<{posts: IPost[]}>> => this.get(`${this.postPrefix}/public/${id}?page=${page}&limit=5`)

  getHomePosts = (page:number): Promise<IResponseInterface<{posts: IPost[]}>> => this.get(`${this.postPrefix}/all?page=${page}&limit=3`)

  getPostById = (id:string): Promise<IResponseInterface<{posts: IPost}>> => this.get(`${this.postPrefix}/${id}`)

  getPostComments = (id: string,page:number): Promise<IResponseInterface<{comments: IPostComment[]}>> => this.get(`${this.commentPrefix}/${this.postPrefix}/${id}?page=${page}&limit=3`)

  createPostComment = (data: any): Promise<IResponseInterface<{comment: IPostComment}>> => this.post(`${this.commentPrefix}`, data);

  deletePostComment = (id: string): Promise<IResponseInterface<{}>> => this.delete(`${this.commentPrefix}/${id}`);

  updatePost = (data: any,id:string) : Promise<IResponseInterface<{}>> => this.put(`${this.postPrefix}/${id}`,data)
  
  sharePost = (data: any): Promise<IResponseInterface<{}>> => this.post(`${this.postPrefix}/share`, data);
 
  likePost = (data: any): Promise<IResponseInterface<{}>> => this.post(`${this.postPrefix}/toggle-like`, data);
  
  sharePostInUserGroup = (data: any): Promise<IResponseInterface<{}>> => this.put(`${this.postPrefix}/share/in-group`, data);

  deletePost = (postId: string): Promise<IResponseInterface<{}>> => this.delete(`${this.postPrefix}/${postId}`);

  getSharedFiles = (page:number, organizationId? : string): Promise<IResponseInterface<{posts: IPost[]}>> => this.get(`${this.postPrefix}/my-shared/files?page=${page}&limit=10${organizationId?'&organizationId='+organizationId: ''}`)

  getNewSharedFiles = (page:number, organizationId : string): Promise<IResponseInterface<{posts: IPost[]}>> => this.get(`organization/${organizationId}/media?page=${page}&limit=100`)

  readPost = (id:string) : Promise<IResponseInterface<{}>> => this.put(`${this.postPrefix}/${id}/view?addLog=true`)

  markPostAsSeen = (id:string) : Promise<IResponseInterface<{}>> => this.put(`${this.postPrefix}/${id}/seen`)

  castPollVote = (data: any): Promise<IResponseInterface<{}>> => this.post(`poll-option/vote`, data);
  
  removePollVote = (data: any): Promise<IResponseInterface<{}>> => this.post(`poll-option/remove-vote`, data);

  addCustomPoll = (data: any): Promise<IResponseInterface<{}>> => this.post(`poll-option/custom-vote`, data);

  getVotingDetail = (id: string): Promise<IResponseInterface<{}>> => this.get(`poll-option/voter-details/${id}`);

  getLikeDetails = (id: string, page: number): Promise<IResponseInterface<{}>> => this.get(`${this.postPrefix}/likes-detail/${id}?page=${page}&limit=20000`);

  getViewDetails = (id: string, page: number): Promise<IResponseInterface<{}>> => this.get(`${this.postPrefix}/views-detail/${id}?page=${page}&limit=20000`);

  getDisplayToDetails = (id: string): Promise<IResponseInterface<{}>> => this.get(`${this.postPrefix}/display-to/${id}`);
  getPixeeImages = (page:number): Promise<IResponseInterface<{posts: IPost[]}>> => this.get(`pixee-digital-signage-screen?page=${page}&limit=50`)

}
  export const postService = new PostService();
