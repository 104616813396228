import * as yup from "yup";
const re = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
export const externalSurveySchema = yup.object().shape({
    url: yup.string().required(),
    label: yup.string().required()
}).required();


export const internalSurveySchema = yup.object().shape({
    pollResultType: yup.string(),
    showPollResults: yup.string(),
    allowCustomPollOption: yup.string(),
    isPollMultiSelect: yup.string(),
}).required();
