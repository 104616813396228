import React from "react";
import { Route, Switch } from "react-router-dom";
import { FollowOrganizationsPage } from "./features/auth/pages/follow-organisations/FollowOrganizersPage";
import { ForgotPasswordPage } from "./features/auth/pages/forgotpassword/ForgotPasswordPage";
import { ResetPasswordPage } from "./features/auth/pages/forgotpassword/ResetPassowrdPage";
import { LoginPage } from "./features/auth/pages/login/LoginPage";
import { SignUpPage } from "./features/auth/pages/signup/SignUpPage";
import { ProfileSetupPage } from "./features/auth/pages/signup/ProfileSetupPage";
import { PrivacySettingsPage } from "./features/auth/pages/signup/PrivacySettingsPage";
import { routes } from "./router/routes";
import { HomePage } from "./features/dashboard/pages/home/HomePage";
import { AllOrganizations } from "./features/organisations/pages/AllOrganisationPage";
import { CreateOrganisation } from "./features/organisations/createorganisation/CreateOrganisation";
import { OrganisationSocialLinksPage } from "./features/organisations/createorganisation/OrganisationSocialLinksPage";
import { NotFound } from "./components/NotFound/NotFound";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { OwnOrganisationProfile } from "./features/ownorganisation/OwnOrganisationProfilePage";
import { FilesPage } from "./features/files/FIlesPage";
import { EventsPage } from "./features/events/EventsPage";
import { UsersGroupsPage } from "./features/users-groups/UsersGroupsPage";
import { AllNotificationsPage } from "./features/all-notifications/AllNotificationsPage";
import PrivateRoute from "./router/private.route";
import { OrganisationProfile } from "./features/organisations/pages/OrganisationProfilePage";
import { OrganisationSetupPage } from "./features/organisations/createorganisation/OrganisationSetupPage";
import { ProfilePage } from "./features/profile/ProfilePage";
import { AllChatPage } from "./features/chatmessages/pages/AllChatPage";
import { TermsAndConditions } from "./features/auth/pages/Terms&Conditions";
import { MemberProfilePage } from "./features/profile/MemberProfilePage";
import { GdprPolicy } from "./features/auth/pages/GdprPolicy";
import { PostDetailsPage } from "./features/post-details/PostDetailsPage";
import Dashboard from "./components/Dashboard/Dashboard";
import DashboardUsers from "./components/Users/DashboardUsers";
import Events from "./components/Events/Events";
import NotUpdates from "./components/Updates/Updates";
import Profile from "./components/Profile/Profile";
import Updates from "./components/Userdata/UserData";
import Usergroup from "./components/usergroup/Usergroup";
import UserGroupDetail from "./components/usergroup/Usergroupdetail";
import VerifyEmail  from "./features/auth/pages/signup/VerifyEmail";
import {ShortCutsPage} from "./features/short-cuts/ShortCutsPage";
import {PublicOrganizationPage} from "./features/organisations/PublicOrganization/PublicOrganizationPage";
import {PostSearchPage} from "./features/post-search/PostSearchPage";
import { AdminLoginPage } from "./features/auth/pages/login/AdminLoginPage";
import AdminDashboardOrganizations from "./features/organisations/pages/AdminDashboardOrganizations";
import AdminPrivateRoute from "./router/admin-private.route";

function App() {
  return (
    <ErrorBoundary>
      <Switch>
        <Route exact path={routes.default} component={LoginPage} />
        <Route exact path={routes.login} component={LoginPage} />
        <Route exact path={routes.adminLogin} component={AdminLoginPage} />
        <Route
          exact
          path={routes.forgotPassword}
          component={ForgotPasswordPage}
        />
        <Route path={routes.resetPassword} component={ResetPasswordPage} />
        <Route exact path={routes.signup} component={SignUpPage} />
        <Route exact path={routes.verifyemail} component={VerifyEmail} />
        <Route exact path={routes.profileSetup} component={ProfileSetupPage} />
        <Route exact path={routes.publicOrganizationProfile} component={PublicOrganizationPage} />
        <Route
          exact
          path={routes.privacySettings}
          component={PrivacySettingsPage}
        />
        {/* <Route exact path={routes.followOrganizations} component={FollorOrganizersPage} /> */}
        <PrivateRoute exact path={routes.home} component={HomePage} />
        <PrivateRoute exact path={routes.allfiles} component={FilesPage} />
        <PrivateRoute exact path={routes.eventspage} component={EventsPage} />
        <PrivateRoute exact path={routes.groups} component={UsersGroupsPage} />
        <PrivateRoute
          exact
          path={routes.allnotifications}
          component={AllNotificationsPage}
        />
        <PrivateRoute exact path={routes.allChatPage} component={AllChatPage} />
        <PrivateRoute
          exact
          path={routes.profileSetup}
          component={ProfileSetupPage}
        />
        <PrivateRoute
          exact
          path={routes.privacySettings}
          component={PrivacySettingsPage}
        />
        <PrivateRoute
          exact
          path={routes.followOrganizations}
          component={FollowOrganizationsPage}
        />
        <PrivateRoute exact path={routes.home} component={HomePage} />
        <PrivateRoute
          exact
          path={routes.allOrganizations}
          component={AllOrganizations}
        />
        <PrivateRoute
          exact
          path={routes.organisationprofile}
          component={OrganisationProfile}
        />
        <PrivateRoute
          exact
          path={routes.createogranisation}
          component={CreateOrganisation}
        />
        <PrivateRoute
          exact
          path={routes.organisationsetup}
          component={OrganisationSetupPage}
        />
        <PrivateRoute
          exact
          path={routes.organisationsociallinks}
          component={OrganisationSocialLinksPage}
        />
        <PrivateRoute
          exact
          path={routes.organizationprofile}
          component={OwnOrganisationProfile}
        />
        <Route
          exact
          path={routes.postDetails}
          component={PostDetailsPage}
        />
        <PrivateRoute exact path={routes.profilepage} component={ProfilePage} />
        <PrivateRoute exact path={routes.postSearch} component={PostSearchPage} />
        <Route
          exact
          path={routes.termsandconditions}
          component={TermsAndConditions}
        />
        <Route exact path={routes.gdprPolicy} component={GdprPolicy} />
        <PrivateRoute
          exact
          path={routes.memberprofilepage}
          component={MemberProfilePage}
        />
        <PrivateRoute
          exact
          path={routes.dashboardHome}
          component={Dashboard}
        />
        <PrivateRoute
          exact
          path={routes.dashboardUsers}
          component={DashboardUsers}
        />
        <PrivateRoute
          exact
          path={routes.dashboardEvents}
          component={Events}
        />
        <PrivateRoute
          exact
          path={routes.dashboardUpdates}
          component={Updates}
        />
        <PrivateRoute
          exact
          path={routes.dashboardProfile}
          component={Profile}
        />
        <PrivateRoute
          exact
          path={routes.dashboardUserGroups}
          component={Usergroup}
        />
        <PrivateRoute
          exact
          path={routes.dashboardUserGroupDetail}
          component={UserGroupDetail}
        />
        <PrivateRoute
          exact
          path={routes.shortCutsPage}
          component={ShortCutsPage}
        />
        <PrivateRoute
          exact
          path={routes.dashboardUserData}
          component={NotUpdates}
        />
        <AdminPrivateRoute
          exact
          path={routes.adminDashboard}
          component={AdminDashboardOrganizations}
        />
        <Route component={NotFound} />
      </Switch>
    </ErrorBoundary>
  );
}

export default App;
