import moment from "moment";
import { useState, useEffect } from "react";
import { images } from "../../assets/images"
import { AppLayout } from "../../components/layouts/AppLayout"
import { useDebounce } from "../../hooks/use-debounce.hook";
import { groupService } from "../../services/group.service";
import { FullPageLoader } from "../../utils/FullPageLoader";
import { Toast } from "../../utils/Toast";
import { IGroup } from "../organisations/createorganisation/interfaces/group.interface";
import AsyncSelect from 'react-select/async';
import { organisationService } from "../../services/organisation.service";
import { EChatType } from "../chatmessages/enum/type.enum";
import { useHistory } from "react-router";
import { chatService } from "../../services/chat.service";
import {useTranslation} from "react-i18next";

export const UsersGroupsPage = () => {

    const [userGroups, setUserGroups] = useState<IGroup[]>([]);
    const [isLoading, setIsloading] = useState(true);
    const [organization, setOrganization] = useState();
    const [search, setSearch] = useState("");
    const [debounceSearch] = useDebounce(search, 300);
    const history = useHistory()
    const { t } = useTranslation('', { keyPrefix: 'UsersGroupsPage' });
    useEffect(() => {
        getUserGroups(1, '');
    }, [])

    useEffect(() => {
        setIsloading(true)
        getUserGroups(1, debounceSearch);
    }, [debounceSearch]);

    const getUserGroups = async (page: number, query: string) => {
        let res: any;
        if (organization) {
            res = await groupService.getUserGroups(page, query, organization)
        } else {
            res = await groupService.getUserGroups(page, query)
        }
        if (res.statusCode == 200) {
            setUserGroups([...res.payload.groups.records])
            setIsloading(false)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
            setIsloading(false)
        }

    }
    const onChangeSelectedOption = (e: any) => {
        setOrganization(e.value);
    };
    const promiseOptions = async (inputValue: string) => {
        if (inputValue.length > 2) {
            const res: any = await organisationService.searchParentOrganization(inputValue, 1);
            if (res.statusCode == 200) {
                const tempArray: any[] = [];
                for (let i = 0; i < res.payload.organizations.records.length; i++) {
                    tempArray.push({
                        label: res.payload.organizations.records[i].name,
                        value: res.payload.organizations.records[i]._id
                    })
                }
                return tempArray;
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }
    }
    const searchSubmit = () => {
        getUserGroups(1, search);
    }

    const chatRedirectionHandler =async (groupId: string) => {
        const res = await chatService.getGroupChatDetail(groupId);
        history.push(`/all-chat?tab=${EChatType.GROUP}&active=${res.payload.chat._id}&groupId=${groupId}`) 
    }
    return (
        <AppLayout>
            <div className="up-usersgroups">
                {isLoading && <FullPageLoader />}
                <div className="up-allusergroupsarea">
                    <div className="up-themebox">
                        <div className="up-themeboxhead">
                            <h3>{t('myUserGroups')}</h3>
                        </div>
                        <div className="up-searchfilesarea">
                            <form className="up-formtheme up-formsearchfiles">
                                <fieldset>
                                    <div className="form-group">
                                        <input type="search" value={search} onChange={(e) => setSearch(e.target.value)} name="search-files" className="form-control" placeholder={t('searchForGroup')} />
                                    </div>
                                    <div className="form-group">
                                        <AsyncSelect className="up-async-select" onChange={onChangeSelectedOption} cacheOptions defaultOptions loadOptions={promiseOptions} />
                                    </div>
                                    <button className="up-btn up-btnwithicon" onClick={searchSubmit} type="button">
                                        <i className="icon-search"></i>
                                        <span>{t('search')}</span>
                                    </button>
                                </fieldset>
                            </form>
                        </div>
                        {userGroups.length > 0 && <div className="up-filebox">
                            <div className="up-tablearea">
                                <table className="up-filestable table up-groupstable up-responsivetable up-grouptableresposnive">
                                    <thead>
                                        <tr>
                                            <th><span>{t('name')}</span></th>
                                            <th><span>{t('organisation')}</span></th>
                                            <th><span>{t('lastUpdate')}</span></th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userGroups.map((group: IGroup, index: number) => (<tr key={group._id}>

                                            <td data-title="Name">
                                                <div className="up-fileiconname">
                                                    <span className="up-fileicon up-bgproject">
                                                        <img src={group?.avatar ?? images.RestPlaceholder} alt="Organisation Icon" />
                                                    </span>
                                                    <h4>{group.name}</h4>
                                                </div>
                                            </td>
                                            <td data-title="Organisation">
                                                <a href={`/organization-profile/${group.organization?._id}`} >
                                                <h5>{group.organization.name}</h5>
                                                </a>
                                            </td>
                                            <td data-title="Last Update">
                                                <h5>{moment(group.updatedAt).fromNow()}</h5>
                                            </td>
                                            <td data-title="">
                                                <button type="button" onClick={() => chatRedirectionHandler(group._id)} className="up-btn up-btnwithicon">
                                                    <span>{t('openGroupChat')}</span>
                                                    <i className="icon-chat"></i>
                                                </button>
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        {userGroups.length == 0 && <div>{t('noUserGroupsFound')}</div>}
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}