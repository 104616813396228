import {useState} from "react";
import {Modal} from "react-bootstrap"
import Papa from 'papaparse';
import {Toast} from "../../../utils/Toast";
import {organisationService} from "../../../services/organisation.service";
import {CSVLink} from "react-csv";
import {breakArray} from "../../../utils/ArrayBreaker";
import {FullPageLoader} from "../../../utils/FullPageLoader";
import {IEvent} from "../../organisations/createorganisation/interfaces/event.interface";
import {EWhichDisplayTo} from "../../organisations/createorganisation/interfaces/display-to-enum";
import {useParams} from "react-router";
import {IIdFromUrl} from "../../../interfaces/id-from-url.interface";
import moment from "moment";
import {isLatitude, isLongitude} from "../../../utils/HelperFunctions";
import {EBoolean} from "../../auth/pages/signup/interfaces/drop-down.enums";
import {useTranslation} from "react-i18next";

const csvData = [
    ["Title", "location", "Latitude", "Longitude", "description", "avatar", "startDate", "endDate", "joiningEndDate", "DisplayToType", "displayTo", "notificationType", "notificationHours", "eventURL", 'ticketsURL',"isAllDayEvent", "isSubscriptionNeeded", "canAnswerUntilEndDate"],
    ["event 1", "new york", "21.69", "96.21", 'ljalkjljlk', "https://source.unsplash.com/user/c_v_r/100x100", "2022-11-23T20:29:32.368+01:00", "2022-12-23T20:29:32.368+01:00", "2022-10-23T20:29:32.368+01:00", "Group", "group 1, group 2, group 3", "IN_APP_NOTIFICATION", "1,2,4", "", "","YES","NO","YES"],
    ["event 2", "new york", "21.69", "96.21", 'mbmnbmnbm', "https://source.unsplash.com/user/c_v_r/100x100", "2022-11-23T20:29:32.368+01:00", "2022-12-23T20:29:32.368+01:00", "2022-10-23T20:29:32.368+01:00", "organization-roles", "role 1, role 2, role 3", "EMAIL", "6,2,1", "", "","NO","YES","NO"],
    ["event 3", "new york", "21.69", "96.21", 'aksndlkalsd', "https://source.unsplash.com/user/c_v_r/100x100", "2022-11-23T20:29:32.368+01:00", "2022-12-23T20:29:32.368+01:00", "2022-10-23T20:29:32.368+01:00", "", "", "IN_APP_NOTIFICATION", "3,2,1", "", "","NO","NO","YES"],
    ["event 4", "new york", "", "", 'kahsdamsbdmbiu', "https://source.unsplash.com/user/c_v_r/100x100", "2022-11-23T20:29:32.368+01:00", "2022-12-23T20:29:32.368+01:00", "2022-10-23T20:29:32.368+01:00", "organization-roles", "role 1, role 2, role 3", "EMAIL", "5,2,4", "", "","YES","YES","NO"],
];
export const ImportEventModal: React.FC<any> = (props) => {

    const [isLoading, setIsloading] = useState(false)
    const [selectedEvents, setSelectedEvents] = useState<any>();
    let { id } = useParams<IIdFromUrl>();
    const { t } = useTranslation('', { keyPrefix: 'ImportEventModal' });

    const closeDisplayToModal = () => {
        props.closeModal(false)
    }
    const parseFile = (file: any) => {
        Papa.parse(file, {
            header: true,
            complete: (results: any) => {
                let events: IEvent[] = []
                let groupsList: any = []
                let rolesList: any = []
                if (results.data.length > 0) {

                    for (let i = 0; i < results.data.length; i++) {
                        if (Object.keys(results.data[i]).length < 2) {
                            results.data.splice(i, 1)
                        }
                    }
                    for (let [index, data] of results.data.entries()) {
                        const userRolesNames: string[] = [];
                        const notificationHours: any[] = [];
                        data.organization = id;
                        try {
                            if (!data?.startDate) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: `${t('startDateRequired')} ${index+1}`
                                })
                                return
                            }
                            if (!data?.location) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: `${t('locationRequired')} ${index+1}`
                                })
                                return
                            }
                            if (!data?.joiningEndDate) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: `${t('joinEndDateRequired')} ${index+1}`
                                })
                                return
                            }
                            if (!data?.endDate) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: `${t('endDateRequired')} ${index+1}`
                                })
                                return
                            }
                            if (!data?.Title) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: `${t('titleRequired')} ${index+1}`
                                })
                                return
                            }
                            if (data.displayTo && data.displayTo.split(',').length > 0) {
                                let displayName = data.displayTo.split(',');
                                for (const key of displayName) {
                                    if (!userRolesNames.includes(key)) {
                                        userRolesNames.push(key.trim());
                                    }
                                }
                            }
                            data.endDate = moment(data.endDate).toISOString();
                            data.joiningEndDate = moment(data.joiningEndDate).toISOString();
                            data.startDate = moment(data.startDate).toISOString();
                            if (!data.startDate) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: `${t('invalidStartDate')} ${index+1}`
                                })
                                return
                            }
                            if (!data.endDate) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: `${t('invalidEndDate')} ${index+1}`
                                })
                                return
                            }
                            if (data.canAnswerUntilEndDate == EBoolean.NO && !data.joiningEndDate) {
                                Toast.fire({
                                    icon: 'warning',
                                    title: `${t('invalidJoiningEndDate')} ${index+1}`
                                })
                                return
                            }
                            if (data.notificationHours && data.notificationHours.split(',').length > 0) {
                                let notificationHoursList = data.notificationHours.split(',');
                                for (const key of notificationHoursList) {
                                    notificationHours.push(+(key.trim()));
                                }
                            }
                            if (data.DisplayToType === EWhichDisplayTo.Group) {
                                data.eWhichDisplayTo = EWhichDisplayTo.Group
                                groupsList = [...groupsList, ...userRolesNames]
                            } else if (data.DisplayToType === EWhichDisplayTo.OrganizationRole) {
                                data.eWhichDisplayTo = EWhichDisplayTo.OrganizationRole
                                rolesList = [...rolesList, ...userRolesNames]
                            }
                            delete data.DisplayToType;
                            if (data.Title) {
                                data.name = data.Title;
                                delete data.Title;
                            }
                            if (data.Longitude) {
                                if (!isLongitude(+data.Longitude)){
                                    Toast.fire({
                                        icon: 'warning',
                                        title: `${t('invalidLongitude')} ${index+1}`
                                    })
                                    return
                                }
                                data.long = data.Longitude;
                                delete data.Longitude;
                            }
                            if (data.Latitude) {
                                if (!isLatitude(+data.Latitude)){
                                    Toast.fire({
                                        icon: 'warning',
                                        title: `${t('invalidLatitude')} ${index+1}`
                                    })
                                    return
                                }
                                data.lat = data.Latitude;
                                delete data.Latitude;
                            }
                            if (data.eventURL) {
                                data.url = data.eventURL;
                                delete data.eventURL;
                            }
                            if (data.ticketsURL) {
                                data.ticket = data.ticketsURL;
                                delete data.ticketsURL;
                            }
                            if (data.Avatar) {
                                data.avatar = data.Avatar;
                                delete data.Avatar;
                            }

                            data.notificationHours = notificationHours;
                            data.displayTo = userRolesNames
                            events.push(data);
                        } catch (error) {
                            console.log({ error })
                            Toast.fire({
                                icon: 'warning',
                                title: t('fileNotCorrect')
                            })
                            return;
                        }
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: t('fileNotCorrect')
                    })
                    return
                }
                groupsList = new Set(groupsList);
                groupsList = Array.from(groupsList);
                rolesList = new Set(rolesList);
                rolesList = Array.from(rolesList);
                setSelectedEvents({
                    csvFileName: file.name,
                    roles: rolesList,
                    groups: groupsList,
                    events: events
                })
            }
        });
    };

    const csvChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            parseFile(e.target.files[0]);
        }
    };

    const addCodesSubmit = async () => {
        if (selectedEvents) {
            setIsloading(true);
            try {
                const result = breakArray(selectedEvents.events, 50);
                const promisesArray: any[] = [];
                result.map(async (item: any) => {
                    selectedEvents.events = item;
                    promisesArray.push(organisationService.eventsBulkUpload(selectedEvents));
                })
                const res = await Promise.allSettled(promisesArray);
                setIsloading(false);
                for (const iterator of res) {
                    if (iterator.status == "rejected") {
                        Toast.fire({
                            icon: 'warning',
                            title: iterator.reason.response.data.message
                        })
                        return
                    }
                }
                Toast.fire({
                    icon: 'success',
                    title: t('importSuccess')
                })
                props.closeModal(true);
            } catch (error) {
                setIsloading(false);
                Toast.fire({
                    icon: 'warning',
                    title: t('importFailed')
                })
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: t('pleaseSelect')
            })
        }
    };

    return <Modal className="up-thememodal up-invitemembersmodal" show={true} onHide={closeDisplayToModal}>
        <Modal.Body className="up-modalbody">
            {isLoading && <FullPageLoader />}
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-forminvitememners">
                    <fieldset>
                        <h4 style={{ fontWeight: "bold" }}>{t('bulkEventImport')}</h4>
                        {/* <div>csv file containing a list of Assignment Keys that will be added to the active automated Assignment Keys. It the automatically assignes users to a user role and one or more usergroups when this user uses the Assignment Keys while sending a request to follow your organization. Attention : identity of the user is not checked, the assignment is purely based on the userkey so only share your Assignment Keys with your own members and once everybody is on board, delete them so that they become inactive!</div> */}
                        <div className="up-imprteventmodattextholder">
                            <p style={{ margin: "30px 0" }}>{t('p1')}</p>
                            <strong>{t('p2')}</strong>
                            <p>Title,location,latitude,longitude,description,avatar,startDate,endDate,joiningEndDate,DisplayType,displayTo,notificationType,notificationHours</p>
                            {/* <p style={{ margin: "0 0 30px" }}><strong>AssignmentKey:</strong> a text or number that one or more of your users will specify while requesting to follow your organization. Example: UserKey123</p> */}
                            <p><strong>Title :</strong>Title of the event</p>
                            <p><strong>Location :</strong>location (city, address,...) like if you would search for it on google maps</p>
                            <p><strong>Latitude + Longitude :</strong>to show the exact location on a map Avatar : The URL of the event avatar</p>
                            <p><strong>StartDate, EndDate :</strong> format YYYY-MM-DD HH:MI:SS</p>
                            <p><strong>JoiningEndDate :</strong>Until when users can subscribe for the event. Format YYYY-MM-DD HH:MI:SS</p>
                            <p><strong>DisplayType :</strong>Display to 'Public', one or more user roles, or one or more user groups</p>
                            <p><strong>DisplayTo :</strong>List of user roles or list of user groups.</p>
                            <p><strong>NotificationType :</strong>Type of notification. Chose between values "email" or "push"</p>
                            <p><strong>NotificationHours :</strong> Nr of hours before the event start that a notification should be sent.</p>
                            <p><strong>IsAllDayEvent :</strong> Chose between "YES" or "NO"</p>
                            <p><strong>IsSubscriptionNeeded :</strong> Chose between "YES" or "NO"</p>
                            <p><strong>CanAnswerUntilEndDate :</strong> Chose between "YES" or "NO"</p>
                            {/* <p><strong>Example import line :</strong>  ......................................</p> */}
                            <p>Download an example Event CSV file <strong> <CSVLink style={{ textDecoration: 'underline !important', color: '#414141' }} filename={"sample_event_csv.csv"} data={csvData}>here</CSVLink></strong>
                            </p>
                        </div>
                        {!selectedEvents && <div className="up-addmoreuploadcsv">
                            <div className="up-uploadcsv">
                                <input accept=".csv" onChange={csvChange} type="file" name="file" id="up-uploadcsvfile" />
                                <label style={{ fontWeight: "bold", fontSize: "16px" }} htmlFor="up-uploadcsvfile">{t('selectFile')}</label>
                            </div>
                        </div>}
                        {selectedEvents && <div className="up-addmoreuploadcsv">
                            <div className="up-uploadcsv">
                                <label style={{ fontWeight: "bold", fontSize: "16px" }}>{selectedEvents.csvFileName}</label>
                            </div>
                        </div>}
                        <div className="up-formbuttonsarea">
                            <button type="button" onClick={() => addCodesSubmit()} className="up-btn">{t('addEvents')}</button>
                            <button type="button" className="up-btnclosmodal" onClick={closeDisplayToModal}>{t('close')}</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}