
export const breakArray = (inputArray: any[], perChunk : number): any[] => {
     return inputArray.reduce((resultArray: any[][], item: any, index: number) => {
        const chunkIndex = Math.floor(index / perChunk)
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, [])

}