import { createAsyncThunk } from "@reduxjs/toolkit";
import { userService } from "../../../services/user.service";
import { IForgotPasswordInterface } from "../pages/forgotpassword/interfaces/forgot-password.interface";
import { IResetPasswordInterface } from "../pages/forgotpassword/interfaces/reset-password.interface";
import { ILogInInterface } from "../pages/login/interface/login.interface";
import { IProfileSetupInterface } from "../pages/signup/interfaces/profile-setup.interface";
import { ISignUpInterface } from "../pages/signup/interfaces/sign-up.interface";

export const SignupUser = createAsyncThunk(
    "user/signup",
    async (data: ISignUpInterface, thunkApi) => {
      try {
        return await userService.signUpUser(data);
      } catch (err: any) {
        console.log('err',err.response);
        return thunkApi.rejectWithValue(err.response.data);
      }
    }
  );
  
  export const LoginUser = createAsyncThunk(
    "user/login",
    async (data: ILogInInterface, thunkApi) => {
      try {
        return await userService.loginUser(data);
      } catch (err:any) {
        console.log('err',err.response);
        return thunkApi.rejectWithValue(err.response.data);
      }
    }
  );
  
  export const ProfileSetup = createAsyncThunk(
    "user/profile-setup",
    async (data: IProfileSetupInterface, thunkApi) => {
      try {
        return await userService.profileSetup(data);
      } catch (err:any) {
        console.log('err',err.response);
        return thunkApi.rejectWithValue(err.response.data);
      }
    }
  );
  
  export const ResetPasswordThunk = createAsyncThunk(
    "user/reset-password",
    async (data: IResetPasswordInterface, thunkApi) => {
      try {
        return await userService.resetPassword(data);
      } catch (err: any) {
        console.log('err',err.response);
        return thunkApi.rejectWithValue(err.response.data);
      }
    }
  );
  
  export const ForgotPasswordThunk = createAsyncThunk(
    "user/forget-password",
    async (data: IForgotPasswordInterface, thunkApi) => {
      try {
        return await userService.forgotPassword(data);
      } catch (err: any) {
        console.log('err',err.response);
        return thunkApi.rejectWithValue(err.response.data);
      }
    }
  );