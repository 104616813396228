import {useEffect, useState} from "react";

import {useHistory, useParams} from "react-router";
import {PostDetailLayout} from "../../components/layouts/PostDetailLayout"
import {OrganizationPost} from "../../components/Post/Post";
import {IIdFromUrl} from "../../interfaces/id-from-url.interface";
import {groupService} from "../../services/group.service";
import {postService} from "../../services/post.service";
import {FullPageLoader} from "../../utils/FullPageLoader";
import {Toast} from "../../utils/Toast";
import {IGroup} from "../organisations/createorganisation/interfaces/group.interface";
import {IPost} from "../organisations/createorganisation/interfaces/post.interface";
import {SharePostInUserGroupModal} from "../ownorganisation/components/SharePostInUserGroupsModal";
import {ScheduledPost} from "../../components/ScheduledPost/ScheduledPost";
import {RejectReasonModal} from "../ownorganisation/components/RejectReasonModal";
import {CreatePostModall} from "../ownorganisation/components/CreatePostModal";

export const PostDetailsPage: React.FC<any> = (props) => {

    const [isLoading, setIsloading] = useState(false)
    const [isRequested, setIsRequested] = useState<boolean>(false);
    const [post, setPost] = useState<IPost>();
    const [rejectReasonModal, setRejectReasonModal] = useState(false);
    const [sharePostInUserGroupsModal, setSharePostInUserGroupsModal] = useState(false);
    const [postToBeSharedInOrganization, setPostToBeSharedInOrganization] = useState<IPost>();
    const [userGroups, setUserGroups] = useState<IGroup[]>([]);
    const [isEditPost, setIsEditPost] = useState(false);
    const [createPostModal, setCreatePostModal] = useState(false);

    let {id} = useParams<IIdFromUrl>();
    const history = useHistory();

    useEffect(() => {
        getPostById(id);
    }, [])
    const getPostById = async (id: string) => {
        setIsloading(true)
        const res: any = await postService.getPostById(id);
        if (res.statusCode == 200) {
            setIsloading(false)
            if (res.payload.post.isRequested === 'YES') {
                setIsRequested(true)
            }
            setPost(res.payload.post);
        } else {
            setIsloading(false)
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const closeSharePostModal = (data: boolean) => {
        setSharePostInUserGroupsModal(false);
    }
    const closeReasonModal = (data: boolean) => {
        if (data) {
            history.push('/')
        }
        setRejectReasonModal(false);
    }
    const openRejectionModal = (selectedPost: IPost) => {
        setRejectReasonModal(true);
    }
    const shareInUserGroupsPost = async (selectedPost: IPost) => {
        setPostToBeSharedInOrganization(selectedPost);
        const res: any = await groupService.getUserGroups(1, '', selectedPost.organization?._id)
        if (res.statusCode === 200) {
            const temp = [{_id: 0, name: 'Select User Group'}, ...res.payload.groups.records]
            setUserGroups(temp)
            setSharePostInUserGroupsModal(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const setLikePost = (postId: string, userId: string, status: string) => {
        if (status === 'YES') {
            post!.likes.push(userId);
        } else {
            const index = post!.likes.findIndex((lik) => lik === userId);
            if (index !== -1) {
                post!.likes.splice(index, 1);
            }
        }
        setPost({...post!})
    }
    const editPost = (selectedPost: IPost) => {
        setIsEditPost(true);
        setCreatePostModal(true);
    }
    const handleClosePostModal = () => {
        setCreatePostModal(false);
        setIsEditPost(false);
    }
    const setPosts = (data: IPost[]) => {
        setCreatePostModal(false);
    }
    return <PostDetailLayout>
        {isLoading && <FullPageLoader/>}
        {sharePostInUserGroupsModal &&
            <SharePostInUserGroupModal postToBeShared={postToBeSharedInOrganization!} userGroups={userGroups!}
                                       closeModal={closeSharePostModal}/>}
        {post && !isRequested &&
            <OrganizationPost fromAdmin={false} public={false} fromDashboard={true} setLikePost={setLikePost}
                              reportPost={() => console.log('REPORT')} setEditPost={() => console.log('EDIT')}
                              setShareOrganizationPost={() => console.log('SHARE')}
                              setShareInUserGroupsPost={shareInUserGroupsPost}
                              setDeletePost={() => console.log('DELETE')} post={post} fromTimeline={true}
                              showInOrganization={false}/>}
        {post && isRequested &&
            <ScheduledPost fromAdmin={false} public={false} fromDashboard={true} setLikePost={setLikePost}
                           reportPost={editPost} setEditPost={editPost}
                           rejectRequestedPost={openRejectionModal}
                           setShareOrganizationPost={() => console.log('SHARE')}
                           setShareInUserGroupsPost={shareInUserGroupsPost} setDeletePost={() => console.log('DELETE')}
                           post={post} fromTimeline={false} showInOrganization={true}/>}
        {rejectReasonModal && <RejectReasonModal post={post} closeModal={closeReasonModal}/>}
        {createPostModal && <CreatePostModall id={post?.organization?._id!} organizationPosts={[]}
                                              selectedEditPost={post!} isEditPost={isEditPost}
                                              setOrganizationPosts={setPosts} closeModal={handleClosePostModal}
                                              organizationRoles={[]}/>}
    </PostDetailLayout>
}