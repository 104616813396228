import {AppLayout} from "../../components/layouts/AppLayout"
import {useEffect, useState} from "react";
import {shortcutService} from "../../services/shortcut.service";
import {FullPageLoader} from "../../utils/FullPageLoader";
import {Toast} from "../../utils/Toast";
import {images} from "../../assets/images";
import {UrlConst} from "../../utils/url.-consts";
import {useHistory} from "react-router-dom";

export const ShortCutsPage = () => {
    const [isLoading, setIsloading] = useState(true);
    const [myShortCuts, setMyShortCuts] = useState<any[]>([])
    const [fields, setFields] = useState<any[]>([])
    const history = useHistory();

    useEffect(() => {
        getMyShortCuts()
    }, [])

    const getMyShortCuts = async () => {
        const res: any = await shortcutService.getMyShortcuts()
        setIsloading(false)
        if (res.statusCode == 200) {
            setFields(res.payload.fieldValues)
            setMyShortCuts(res.payload.organizationShortcuts.records);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const openUrl = (item: any) => {
        if (item.urlType === 'FIXED') {
            window.open(item.url, "_blank")
        } else {
            let tempURL = item.url;
            const tempArray = UrlConst.slice(0, item.dynamicFields.length)
            for (let i = 0; i < tempArray.length; i++) {
                const value = fields.find(field => field.organizationField._id == item.dynamicFields[i]._id)?.value
                if (value) {
                    tempURL = tempURL.replace(tempArray[i].toString(), value)
                }
            }
            if (tempURL.slice(0, 3) === 'www') {
                tempURL = 'https://' + tempURL
            }
            window.open(tempURL, "_blank")
        }
    }

    const openOrg = (item: any) => {
        history.push(`/organization-profile/${item.organization?._id}`)
    }

    return (
        <AppLayout>
            <div className="up-shortcutgrid">
                {isLoading && <FullPageLoader/>}
                {myShortCuts && myShortCuts.map((item: any, index: number) => (
                    <div className="up-shortcutgriditem">
                        <div className="up-shortcutcontent">
                            <figure>
                                <img style={{ cursor: 'pointer' }} onClick={() => openUrl(item)} src={item?.icon ?? images.RestPlaceholder}
                                     alt="shortcut image"/>
                                <figcaption>
                                <span>
                                    <img style={{ cursor: 'pointer' }} onClick={() => openOrg(item)} src={item?.organization?.avatar ?? images.RestPlaceholder}
                                         alt="organization logo"/>
                                </span>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="up-shortcutcardtitle">
                            <h4>{item.label}</h4>
                        </div>
                    </div>))}
            </div>
        </AppLayout>
    )
}