import {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {
    IMemberRoleGroupFieldChangeProps
} from '../../organisations/createorganisation/interfaces/member-role-change-modal.interface';
import {
    IOrganizationRole
} from '../../organisations/createorganisation/interfaces/organization-requested-members.interface'
import {organisationService} from '../../../services/organisation.service';
import {Toast} from '../../../utils/Toast';
import {Tab, Tabs} from "react-bootstrap";
import {groupService} from "../../../services/group.service";
import {IGroup} from "../../organisations/createorganisation/interfaces/group.interface";
import {images} from "../../../assets/images";
import {IUserField, IUserFieldValue} from "../../../interfaces/user-fields.interface";
import {DynamicLoader} from "../../../utils/DynamicLoader";
import DatePicker from "react-datepicker";
import moment from "moment";
import {useTranslation} from "react-i18next";

export const UserRoleGroupFieldModal: React.FC<IMemberRoleGroupFieldChangeProps> = (props) => {

    const [tempRoles, setTempRoles] = useState<IOrganizationRole[]>([]);
    const [isLoading, setIsloading] = useState(false);
    const [organizationGroups, setOrganizationGroups] = useState<IGroup[]>([]);
    const [breadCrumb, setBreadCrumb] = useState<any[]>([])
    const [organizationFields, setOrganizationFields] = useState<IUserField[]>([]);
    const [userFields, setUserFields] = useState<IUserFieldValue[]>([]);
    const [startDate, setStartDate] = useState<any>();
    const { t } = useTranslation('', { keyPrefix: 'UserRoleGroupFieldModal' });

    const memberId = props.selectedMember._id
    useEffect(() => {
        for (const iterator of props.organizationRoles) {
            if (iterator.role == props.selectedMember.role.role) {
                iterator.checked = true;
            } else {
                iterator.checked = false;
            }
        }
        setTempRoles(props.organizationRoles)
        getOrganizationGroups(1, '');
        getOrganizationFields();
        getUserFields();
    }, [])

    const roleChange = (e: any, role: IOrganizationRole, index: number) => {

        for (const iterator of tempRoles) {
            if (iterator._id === role._id) {
                iterator.checked = true;
            } else {
                iterator.checked = false;
            }
        }
        setTempRoles([...tempRoles])
    }

    const updateMemberRole = async () => {
        setIsloading(true)
        const selectedRole = tempRoles.find(r => r.checked === true);
        const res: any = await organisationService.updateOrganizationMemberRole({
            organization: props.selectedMember.organization,
            member: props.selectedMember.member._id, organizationRole: selectedRole!._id
        });
        if (res.statusCode == 200) {
            setIsloading(false)
            Toast.fire({
                icon: 'success',
                title: res.message
            })
            props.updateMemberRole(selectedRole, true)
        } else {
            setIsloading(false)
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const closeModal = () => {
        props.closeModal(true)
    }

    const getOrganizationGroups = async (page: number, query: string) => {
        setIsloading(true);
        const res: any = await groupService.getOrganizationGroups(props.organizationId, page, query, 1000);
        if (res.statusCode == 200) {
            for (const group of res.payload.groups.records) {
                group.childGroups = [];
            }
            setOrganizationGroups([...res.payload.groups.records])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setIsloading(false);
    }

    const getChildGroups = async (group: IGroup, index: number) => {
        setIsloading(true);
        breadCrumb.push({
            groupId: group._id,
            groupName: group.name
        })
        const res: any = await groupService.getChildGroups(group._id);
        if (res.statusCode == 200) {
            setBreadCrumb([...breadCrumb])
            setOrganizationGroups([...res.payload.childGroups])
        }
        setIsloading(false);
    }

    const removeMember = async (group: IGroup, index: number, isAdmin: boolean, isMember: boolean) => {
        setIsloading(true);
        const res: any = await groupService.removeGroupMember({
            memberId: memberId,
            group: group._id
        });
        if (res.statusCode == 201) {
            if (isAdmin) {
                const adminIndex = organizationGroups[index].admins.findIndex(ele => ele?._id == memberId)
                organizationGroups[index].admins.splice(adminIndex, 1);
                setOrganizationGroups([...organizationGroups])
            } else if (isMember) {
                const memberIndex = organizationGroups[index].members.findIndex(ele => ele?._id == memberId)
                organizationGroups[index].members.splice(memberIndex, 1);
                setOrganizationGroups([...organizationGroups])
            }
            Toast.fire({
                icon: 'success',
                title: t('userRemovedSuccessfully')
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setIsloading(false);
    }
    const addUser = async (memberType: string, group: IGroup, index: number, isAdmin: boolean, isMember: boolean) => {
        setIsloading(true);
        const res: any = await groupService.addGroupMember({
            memberId: memberId,
            memberType: memberType,
            group: group._id
        });
        if (res.statusCode == 201) {
            if (!isAdmin && !isMember) {
                if (memberType == 'admin') {
                    organizationGroups[index].admins.push({_id: memberId});
                } else {
                    organizationGroups[index].members.push({_id: memberId});
                }
                setOrganizationGroups([...organizationGroups])
            } else if (isMember) {
                const memberIndex = organizationGroups[index].members.findIndex(ele => ele?._id == memberId)
                organizationGroups[index].members.splice(memberIndex, 1);
                organizationGroups[index].admins.push({_id: memberId});
                setOrganizationGroups([...organizationGroups])
            } else if (isAdmin) {
                const adminIndex = organizationGroups[index].admins.findIndex(ele => ele?._id == memberId)
                organizationGroups[index].admins.splice(adminIndex, 1);
                organizationGroups[index].members.push({_id: memberId});
                setOrganizationGroups([...organizationGroups])
            }
            Toast.fire({
                icon: 'success',
                title: `User made ${memberType} successfully!`
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setIsloading(false);
    }

    const handleIsMember = (event: any, group: IGroup, index: number, isAdmin: boolean, isMember: boolean) => {
        if (event.target.checked) {
            addUser('member', group, index, isAdmin, isMember)
        } else {
            removeMember(group, index, isAdmin, isMember)
        }
    }
    const handleIsAdmin = (event: any, group: IGroup, index: number, isAdmin: boolean, isMember: boolean) => {
        if (event.target.checked) {
            addUser('admin', group, index, isAdmin, isMember)
        } else {
            removeMember(group, index, isAdmin, isMember)
        }
    }
    const renderGroups = () => {
        if (organizationGroups.length === 0) {
            return <h4>{t('noUserGroups')}</h4>
        }
        return organizationGroups!.map((group: IGroup, index: number) => {
            const isAdmin = group.admins.find(ele => ele?._id == memberId)
            const isMember = group.members.find(ele => ele?._id == memberId)
            return <div key={group._id} className="up-group">
                <div className="up-groupInfoHolder">
                    <div className="up-gourpimagename">
                        <figure className="up-groupimg">
                            <img src={group?.avatar ?? images.RestPlaceholder} alt="Group Image"/>
                        </figure>
                        <div className="up-groupname">
                            <h3>{group.name}<i onClick={() => getChildGroups(group, index)} className="icon-arrow-down2"
                                               style={{marginLeft: '5px'}}/></h3>
                        </div>

                    </div>
                    <input className="up-checkboxmemeber"
                           onChange={(event: any): any => handleIsMember(event, group, index, isAdmin, isMember)}
                           checked={!!isMember} type="checkbox" name="poll-option"/>
                    <input className="up-checkboxadmin"
                           onChange={(event: any): any => handleIsAdmin(event, group, index, isAdmin, isMember)}
                           checked={!!isAdmin} type="checkbox" name="poll-option"/>
                </div>
            </div>
        })
    }
    const getSpecficGroupChild = async (group: any, index: number) => {
        setIsloading(true);
        breadCrumb.splice(++index, breadCrumb.length)
        const res: any = await groupService.getChildGroups(group.groupId);
        if (res.statusCode == 200) {
            setBreadCrumb([...breadCrumb])
            setOrganizationGroups([...res.payload.childGroups])
        }
        setIsloading(false);
    }
    const getAllGroups = () => {
        setBreadCrumb([]);
        setOrganizationGroups([]);
        getOrganizationGroups(1, ' ');
    }
    const getOrganizationFields = async () => {
        setIsloading(true);
        const res: any = await organisationService.getOrganizationFields(props.organizationId);
        if (res.statusCode == 200) {
            setOrganizationFields(res.payload.fields)
        }
        setIsloading(false);
    }
    const getUserFields = async () => {
        const res: any = await organisationService.getOrganizationFieldValue(memberId);
        if (res.statusCode == 200) {
            setUserFields(res.payload.fieldValues)
        }
    }

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, currentField?: IUserFieldValue) => {
        const {value} = e.target
        if (currentField) {
            currentField.value = value
            const fieldIndex = userFields.findIndex(item => item._id == currentField._id)
            if (fieldIndex >= 0) {
                userFields[fieldIndex] = currentField
                setUserFields([...userFields])
            }
        } else {
            userFields.push({
                "_id": "",
                "member": memberId,
                "organizationField": organizationFields[index],
                "createdAt": "",
                "updatedAt": "",
                "value": value
            })
            setUserFields([...userFields])
        }
    }

    const saveUserField = async (item: IUserField, index: number, currentField?: IUserFieldValue) => {
        if (currentField?.value) {
            setIsloading(true);
            const res: any = await organisationService.createUpdateOrganizationFieldValue({
                member: memberId,
                organizationField: currentField.organizationField._id,
                value: currentField.value
            })
            setIsloading(false);
            if (res.statusCode == 201) {
                Toast.fire({
                    icon: "success",
                    title: res.message
                })
            } else {
                Toast.fire({
                    icon: "warning",
                    title: res.message
                })
            }
        } else {
            Toast.fire({
                icon: "info",
                title: t('cannotSaveEmpty')
            })
        }
    }
    const validTillSubmit = async () => {
        if (startDate) {
            setIsloading(true);
            const res: any = await organisationService.updateOrganizationMemberValidity({
                validTill: startDate,
                member: memberId,
            });
            setIsloading(false);
            if (res.statusCode == 200) {
                props.updateMemberValidity(startDate)
                Toast.fire({
                    icon: 'success',
                    title: res.message
                })
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: t('pleaseStartDate')
            })
        }
    }
    const handleStartDateChange = (data: any) => {
        setStartDate(data)
    };
    return <Modal className="up-thememodal up-modalreport up-modaluserreporttabs" show={true} onHide={() => props.closeModal(true)}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            {isLoading && <DynamicLoader/>}
            <div className="up-modalcontentholder">
                <Tabs className="up-profiletabs up-userassignstabsnav">
                    <Tab eventKey="valid" title={t('validTill')}>
                        <div className="up-modalcontentholder">
                            <form className="up-formtheme up-formcreateevent">
                                <fieldset>
                                    <div className="form-group ct-inputcenter">
                                        <DatePicker
                                            selected={startDate}
                                            showTimeSelect
                                            placeholderText={t('selectValidDate')}
                                            minDate={moment().toDate()}
                                            timeFormat="HH:mm"
                                            onChange={handleStartDateChange}
                                            dateFormat="yyyy-MM-dd HH:mm"
                                        />
                                    </div>
                                    <button type="button" onClick={validTillSubmit} className="up-btn">{t('confirm')}</button>
                                </fieldset>
                            </form>
                        </div>
                    </Tab>
                    <Tab eventKey="role" title={t('changeRole')}>
                        <form className="up-formtheme up-formreport up-reportmodalformcontent">
                            <fieldset>
                                <ul className="up-reportoptions">
                                    {tempRoles && tempRoles.map((role: IOrganizationRole, index: number) => (
                                        <li key={role._id}>
                                            <div className="up-reportoption">
                                                <span>{role.role}</span>
                                                <div className="up-radio">
                                                    <input type="radio" onChange={(e) => roleChange(e, role, index)}
                                                           checked={role.checked} name="admin" id={role._id}/>
                                                    <label htmlFor={role._id}></label>
                                                </div>
                                            </div>
                                        </li>))}
                                </ul>
                                <button onClick={updateMemberRole} type="button" className="up-btn up-btn-lg">{t('updateRole')}</button>
                            </fieldset>
                        </form>
                    </Tab>
                    <Tab eventKey="group" title={t('assignUserGroups')}>
                        <div className="up-themebox up-boxpaddingnone up-assignusergroupmodalcontent">
                            {organizationGroups.length !== 0 && <>
                                <div className="up-usergroupmodalheadforhom">
                                    <span className="up-getallgrouphome" onClick={() => getAllGroups()}>{t('home')}</span>
                                    <span className="up-getallgrouphome float-right">{t('member')}</span>
                                    <span className="up-getallgrouphome">{t('admin')}</span>
                                </div>
                                {breadCrumb && breadCrumb.map((bread, index) => (<><span
                                    onClick={() => getSpecficGroupChild(bread, index)}> / {bread.groupName}</span></>))}
                            </>}
                            <div className="up-allgroups">
                                {renderGroups()}
                                {breadCrumb.length !== 0 && organizationGroups.length == 0 &&
                                    <h4 style={{marginTop: '16px'}}>{t('noChildGroups')}</h4>}
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="field" title={t('assignUserFields')}>
                        <div className="up-themebox up-boxpaddingnone up-assignuserfieldbox">
                            {organizationFields.map((item, index) => {
                                const currentField = userFields.find(element => element.organizationField._id === item._id)
                                return <div className="up-editprofileinputs">
                                    <div className="up-adduserfieldinputholder">
                                        <div className="form-group up-assignuserfieldinput">
                                            <label>{item.label}</label>
                                            <div className="up-inputwithicon">
                                                <input type="text"
                                                       onChange={e => handleFieldChange(e, index, currentField)}
                                                       value={currentField?.value || ''}/>
                                            </div>
                                            <button onClick={() => saveUserField(item, index, currentField)}
                                                    className="up-btn" type="button">
                                                {t('save')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </Modal.Body>
    </Modal>
}