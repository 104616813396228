export const URLReplacer = (str: string) => {
    let match: any = str.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    var res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res || match) {
        let final = str;
        match?.map((url: any) => {
            final = final.replace(url, "<a href=\"" + url + "\" target=\"_BLANK\">" + url + "</a>")
        })
        return final;
    } else {
        return str;
    }
}