import { yupResolver } from "@hookform/resolvers/yup";
import { S3 } from "aws-sdk";
import {useEffect, useMemo, useRef, useState} from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { images } from "../../../../assets/images"
import { AWS_S3_BUCKET, s3 } from "../../../../config/aws.config";
import { IIdFromUrl } from "../../../../interfaces/id-from-url.interface";
import { Toast } from "../../../../utils/Toast";
import { OrganisationProfileSetup } from "../../slice/organisation.thunks";
import { IOrganisationSetup } from "../interfaces/organisation-setup.interface";
import { organisationSetupSchema } from "../validations/organisation-setup.validation";
import countryList from 'react-select-country-list';
import Select from "react-select";
import {useTranslation} from "react-i18next";

export const OrganisationSetupForm = () => {
    const [isLoading, setIsloading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>()
    const [preview, setPreview] = useState<any>()
    const [value, setSelectedValue] = useState('')
    const { t } = useTranslation('', { keyPrefix: 'OrganisationSetupPage' });

    const dispatch = useDispatch();
    let history = useHistory();
    const inputFile = useRef<HTMLInputElement>(null);
    const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm({
        resolver: yupResolver(organisationSetupSchema)
    });
    const options = useMemo(() => countryList().getData(), [])

    let { id } = useParams<IIdFromUrl>();

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl: any = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const imageChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };
    const onSubmit = async (data: IOrganisationSetup) => {
        setIsloading(true)
        data.id = id;
        if (selectedFile) {
            const params: S3.PutObjectRequest = {
                Body: selectedFile,
                Bucket: AWS_S3_BUCKET!,
                Key: `${parseInt(
                    (new Date().getTime() / 1000).toFixed(0)
                )}-${selectedFile.name}`,
                ContentType: selectedFile.type,
                ACL: "public-read",
            };

            const uploadResult: any = await s3.upload(params).promise();
            data = {
                ...data,
                avatar: uploadResult.Location,
            };
        }
        data.country = data.country?.toLowerCase()
        data.city = data.city?.toLowerCase()
        const res: any = await dispatch(OrganisationProfileSetup(data));
        setIsloading(false)
        if (res.payload.statusCode == 200) {
            Toast.fire({
                icon: "success",
                title: res.payload.message
            })
            history.push(`/organisation-links/${id}`);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.payload.message
            })
        }
    };
    const onImageClick = () => {
        if (inputFile.current) {
            inputFile.current.click();
        }
    };
    const changeHandler = (value: any) => {
        setSelectedValue(value)
        setValue('country', value.label)
        clearErrors('country')
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="up-formtheme up-createorganisationformtwo">
            <fieldset>
                <div className="up-uploadlogoarea">
                    {selectedFile && <> <img className="up-uploadlogoimg" src={preview} onClick={onImageClick} />
                        <input type="file" ref={inputFile} onChange={imageChange} style={{ display: "none" }} />
                    </>}
                    {!selectedFile && <figure className="up-uploadlogoimg">
                        <div className="up-cameraicon">
                            <input type="file" name="upload-logo" onChange={imageChange} id="up-uploadlogo" />
                            <label htmlFor="up-uploadlogo">
                                <i className="icon-camera"></i>
                            </label>
                        </div>
                        <img className="up-themeplaceholder" src={images.RestPlaceholder} alt="Logo Image" />
                    </figure>}
                </div>
                <div className="form-group">
                    <label>{t('email')}</label>
                    <input type="email" {...register("email")} name="email" className="form-control" placeholder="info@up2d8.com" />
                    <small className="form-text text-muted">
                        {errors.email?.message}
                    </small>
                </div>
                <div className="form-group">
                    <div>{t('country')}</div>
                    <Select {...register("country")}  options={options} value={value} onChange={changeHandler} />
                    <small className="form-text text-muted">
                        {errors.country?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('city')}</label>
                    <input type="text" {...register("city")} name="city" className="form-control" placeholder={t('lahore')} />
                    <small className="form-text text-muted">
                        {errors.city?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('address')}</label>
                    <input type="text" {...register("address")} name="address" className="form-control" placeholder="2210 Luxembourg" />
                    <small className="form-text text-muted">
                        {errors.address?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('phone')}</label>
                    <input type="tel" {...register("phoneNumber")} name="phoneNumber" className="form-control" placeholder="+32 900 78 60 10" />
                    <small className="form-text text-muted">
                        {errors.phoneNumber?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('about')}</label>
                    <textarea {...register("about")} placeholder=""></textarea>
                </div>
                <div className="up-formbuttons">
                    <button type='submit' className="up-btn up-btn-lg up-btnwithloader">{t('next')} {isLoading && <div className="lds-dual-ring"></div>}</button><a href="/home" className="up-btnskip">{t('skip')}</a></div>
            </fieldset>
        </form>
    )
}