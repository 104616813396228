import * as yup from "yup";

const today = new Date();
today.setFullYear(today.getFullYear()-1);
export const signUpSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email().required('Email is required'),
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
    termsAndConditions: yup.boolean().oneOf([true], 'Please accept Terms and Conditions.'),
    grps: yup.boolean().oneOf([true], 'Please accept gdpr policy.')
}).required();