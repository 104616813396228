import {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {
    IMemberRoleChangeProps
} from '../../organisations/createorganisation/interfaces/member-role-change-modal.interface';
import {
    IOrganizationRole
} from '../../organisations/createorganisation/interfaces/organization-requested-members.interface'
import {organisationService} from '../../../services/organisation.service';
import {Toast} from '../../../utils/Toast';
import {useTranslation} from "react-i18next";

export const MemberRoleChangeModal: React.FC<IMemberRoleChangeProps> = (props) => {

    const [tempRoles, setTempRoles] = useState<IOrganizationRole[]>([]);
    const [isLoading, setIsloading] = useState(false);
    const { t } = useTranslation('', { keyPrefix: 'CreateGroupModal' });

    useEffect(() => {
        for (const iterator of props.organizationRoles) {
            if (iterator.role == props.selectedMember.role.role) {
                iterator.checked = true;
            } else {
                iterator.checked = false;
            }
        }
        setTempRoles(props.organizationRoles)

    }, [])

    const roleChange = (e: any, role: IOrganizationRole, index: number) => {

        for (const iterator of tempRoles) {
            if (iterator._id === role._id) {
                iterator.checked = true;
            } else {
                iterator.checked = false;
            }
        }
        setTempRoles([...tempRoles])
    }

    const updateMemberRole = async () => {
        setIsloading(true)
        const selectedRole = tempRoles.find(r => r.checked === true);
        const res: any = await organisationService.updateOrganizationMemberRole({
            organization: props.selectedMember.organization,
            member: props.selectedMember.member._id, organizationRole: selectedRole!._id
        });
        if (res.statusCode == 200) {
            setIsloading(false)
            Toast.fire({
                icon: 'success',
                title: res.message
            })
            props.updateMemberRole(selectedRole, false)
        } else {
            setIsloading(false)
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    return <Modal className="up-thememodal up-modalreport" show={true} onHide={() => props.closeModal(true)}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('changeRole')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formreport">
                    <fieldset>
                        <ul className="up-reportoptions">
                            {tempRoles && tempRoles.map((role: IOrganizationRole, index: number) => (<li key={role._id}>
                                <div className="up-reportoption">
                                    <span>{role.role}</span>
                                    <div className="up-radio">
                                        <input type="radio" onChange={(e) => roleChange(e, role, index)}
                                               checked={role.checked} name="admin" id={role._id}/>
                                        <label htmlFor={role._id}></label>
                                    </div>
                                </div>
                            </li>))}
                        </ul>
                        <button onClick={updateMemberRole} type="button" className="up-btn up-btn-lg">{isLoading && <div className="lds-dual-ring" style={{"height" : "20px"}}></div>}{t('updateRole')}</button>
                        {/* <button onClick={updateMemberRole} type="button" className="up-btn up-btn-lg"><div className="lds-dual-ring"></div>Update Role </button> */}
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}