import { createAsyncThunk } from "@reduxjs/toolkit";
import { organisationService } from "../../../services/organisation.service";
import { ICreateOrganisation } from "../createorganisation/interfaces/create-organisation.interface";
import { IOrganisationSetup } from "../createorganisation/interfaces/organisation-setup.interface";


export const GetOrganisationTypes = createAsyncThunk(
  "organisation/types",
  async (data, thunkApi) => {
    try {
      return await organisationService.getOrganisationTypes();
    } catch (err: any) {
      console.log('err',err.response);
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const CreateOrganisation = createAsyncThunk(
    "organisation/creation",
    async (data: ICreateOrganisation, thunkApi) => {
      try {
        return await organisationService.createOrganization(data);
      } catch (err: any) {
        console.log('err',err.response);
        return thunkApi.rejectWithValue(err.response.data);
      }
    }
  );
export const OrganisationProfileSetup = createAsyncThunk(
    "organisation/profile-setup",
    async (data: IOrganisationSetup, thunkApi) => {
      try {
        return await organisationService.organizationProfileSetup(data,data.id);
      } catch (err: any) {
        console.log('err',err.response);
        return thunkApi.rejectWithValue(err.response.data);
      }
    }
  );

  export const SearchParentOrganisation = createAsyncThunk(
    "organisation/parent-search",
    async (data: string, thunkApi) => {
      try {
        return await organisationService.searchParentOrganization(data,1);
      } catch (err: any) {
        console.log('err',err.response);
        return thunkApi.rejectWithValue(err.response.data);
      }
    }
  );
