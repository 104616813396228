import Modal from 'react-bootstrap/Modal';
import { IDisplayToModal } from '../../organisations/createorganisation/interfaces/display-to-modal.interface';
import { IOrganizationRole } from '../../organisations/createorganisation/interfaces/organization-requested-members.interface';
import {useTranslation} from "react-i18next";


export const DisplayToModal: React.FC<IDisplayToModal> = (props) => {
    const { t } = useTranslation('', { keyPrefix: 'DisplayToModal' });

    const closeDisplayToModal = () => {
        props.closeModal(true)
    }

    return <Modal className="up-thememodal up-modalcreateupdates" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('displayTo')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formdisplayto">
                    <fieldset>
                        <ul className="up-reportoptions">
                            <li>
                                <div className="up-reportoption">
                                    <span>{t('public')}</span>
                                    <div className="up-togglebutton">
                                        <input checked={props.isPublicChecked} onChange={props.publicChangeHandler} type="checkbox" name="advance-option" id="up-displayToPublic" />
                                        <label htmlFor="up-displayToPublic"></label>
                                    </div>
                                </div>
                            </li>
                            {props.organizationRoles && props.organizationRoles!.map((role: IOrganizationRole, index: number) => {
                                return <li key={role._id}>
                                    <div className="up-advanceoption">
                                        <span>{role?.role}</span>
                                        <div className="up-togglebutton">
                                            <input onChange={(event: any): any => props.handleRoleSelection(event, role, index)} checked={role.checked!} disabled={props.isPublicChecked} id={role._id} type="checkbox" name="advance-option" />
                                            <label htmlFor={role._id}></label>
                                        </div>
                                    </div>
                                </li>
                            })}
                        </ul>
                        <button onClick={closeDisplayToModal} type="button" className="up-btn up-btn-lg">{t('confirm')}</button>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}