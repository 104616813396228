import { IGroup } from "../features/organisations/createorganisation/interfaces/group.interface";
import { IResponseInterface } from "../interfaces/api-response.interface";
import { HttpService } from "./base.service";

class GroupService extends HttpService {
    private readonly groupPrefix: string = "group";
  
  createGroup = (data: any): Promise<IResponseInterface<{group:IGroup}>> => this.post(`${this.groupPrefix}`, data);

  getGroupById = (id: string): Promise<IResponseInterface<{group: IGroup}>> => this.get(`${this.groupPrefix}/${id}`)

  getOrganizationGroups = (id: string,page:number, query:string, limit:number): Promise<IResponseInterface<{posts: IGroup[]}>> => this.get(`${this.groupPrefix}/organization/${id}?query=${query}&page=${page}&limit=${limit}`)

  updateGroup = (groupId:string,data:any): Promise<IResponseInterface<{}>> => this.put(`${this.groupPrefix}/${groupId}`,data);

  deleteGroup = (groupId:string): Promise<IResponseInterface<{}>> => this.delete(`${this.groupPrefix}/${groupId}`);

  getUserGroups = (page:number, query:string, organizationId? : string): Promise<IResponseInterface<{groups: IGroup[]}>> => this.get(`${this.groupPrefix}/my?page=${page}&limit=10${organizationId?'&organizationId='+organizationId: ''}&query=${query}`)

  getGroupMembers = (groupId: string): Promise<IResponseInterface<{groups: IGroup[]}>> => this.get(`chat/members/${groupId}`)
  
  getChildGroups = (groupId: string): Promise<IResponseInterface<{groups: IGroup[]}>> => this.get(`${this.groupPrefix}/child/${groupId}`)

  removeGroupMember = (data: any): Promise<IResponseInterface<{}>> => this.post(`${this.groupPrefix}/remove-member`, data);

  addGroupMember = (data: any): Promise<IResponseInterface<{}>> => this.post(`${this.groupPrefix}/add-member`, data);

  removeMembersFromParentGroup = (data: any): Promise<IResponseInterface<{}>> => this.post(`${this.groupPrefix}/remove-members-from-parents`, data);

  clearGroupHistory = (id: string): Promise<IResponseInterface<{group: IGroup}>> => this.get(`${this.groupPrefix}/clear-history/${id}`)

  clearGroupChatHistory = (id: string): Promise<IResponseInterface<{group: IGroup}>> => this.get(`${this.groupPrefix}/clear-chat/${id}`)

}
  export const groupService = new GroupService();
