import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getToken } from "../features/auth/slice/user.selector";
import { HttpService } from "../services/base.service";

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  const token = useSelector(getToken);

  useEffect(() => {
    window.scrollTo(0, 0);
    HttpService.setToken(token);
  }, [pathname]);

  return null;
};
