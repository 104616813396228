import * as yup from "yup";

var fbPattern = /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
var twitterPatter = /^(?:(?:http|https):\/\/)?(?:www.)?twitter.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
var instagramPatten = /^(?:(?:http|https):\/\/)?(?:www.)?instagram.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
var linkedinPatten = /^(?:(?:http|https):\/\/)?(?:www.)?linkedin.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/;
var httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const editOrganizationProfileSchema = yup.object().shape({
  name: yup.string().required('Orgnaztion name is required'),
  email: yup.string().email().required('Email is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  address: yup.string(),
  website: yup.string().matches(httpRegex,'URL is not valid'),
  about: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr),
  facebookLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(fbPattern, 'Not a facebook url'),
  twitterLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(twitterPatter, 'Not a twitter url'),
  instagramLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(instagramPatten, 'Not a instagram url'),
  linkedinLink: yup.string().nullable().transform((curr, orig) => orig === '' ? null : curr).matches(linkedinPatten, 'Not a Linkedin url'),
}).required();