
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { organisationService } from '../../../../services/organisation.service';
import { Toast } from '../../../../utils/Toast';
import {useTranslation} from "react-i18next";

const followRequestSchema = yup.object().shape({
    comment: yup.string()
}).required();

export const SendFollowRequestModal: React.FC<any> = (props) => {

    const [isLoading, setIsloading] = useState(false);
    const { t } = useTranslation('', { keyPrefix: 'SendFollowRequestModal' });

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(followRequestSchema)
    });

    useEffect(() => {
        if (props.isEdit) {
            setValue('comment', props.selectedComment)
        }
    }, [])

    const handleCloseeight = () => {
        props.closeModal(false)
    }

    const followRequestSubmit = async (data: any) => {
        const temp = {
            organization: props.id,
            comment: data.comment
        }
        const res: any = await organisationService.followOrganizationRequest(temp)
        if (res.statusCode == 201) {
            Toast.fire({
                icon: 'success',
                title: res.message
            })
            props.closeModal(res.payload.member)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    return <Modal className="up-thememodal up-modalcreategroup up-modalcreateevents" show={true} onHide={handleCloseeight}>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formcreateevent" onSubmit={handleSubmit(followRequestSubmit)}>
                    <fieldset>
                        <div className="form-group">
                            <label>{props.isEdit ? t('viewKey') : t('yourAssignment')}</label>
                            <input disabled={props.isEdit} {...register("comment")} type="text" name="comment" className="form-control" placeholder={t('placeholder')} />
                            <small id="content" className="form-text text-muted">
                                {errors.comment?.message}
                            </small>
                        </div>
                        {!props.isEdit && <button disabled={isLoading} type="submit" className="up-btn">{t('sendFollowRequest')} {isLoading && <div className="lds-dual-ring"></div>}</button>}
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}