import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { IProfileSetupInterface } from "../interfaces/profile-setup.interface";
import { profileSetupSchema } from "../validations/profile-setup.validation";
import * as Actions from '../../../slice/user.slice'
import { routes } from "../../../../../router/routes";
import { AWS_S3_BUCKET, s3 } from "../../../../../config/aws.config";
import { S3 } from 'aws-sdk';
import { Toast } from "../../../../../utils/Toast";
import { ProfileSetup } from "../../../slice/auth.thunks";
import { setUserAvatar } from "../../../slice/user.slice";
import {useTranslation} from "react-i18next";

export const ProfileSetupForm = () => {
    const [isLoading, setIsloading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>()
    const [preview, setPreview] = useState<any>()
    const dispatch = useDispatch();
    let history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(profileSetupSchema)
    });
    const { t } = useTranslation('', { keyPrefix: 'ProfileSetupPage' });
    const inputFile = useRef<HTMLInputElement>(null); 
    const onSubmit = async (data: IProfileSetupInterface) => {
        setIsloading(true)
        if (selectedFile) {
            const params: S3.PutObjectRequest = {
                Body: selectedFile,
                Bucket: AWS_S3_BUCKET!,
                Key: `${parseInt(
                    (new Date().getTime() / 1000).toFixed(0)
                )}-${selectedFile.name}`,
                ContentType: selectedFile.type,
                ACL: "public-read",
            };

            const uploadResult: any = await s3.upload(params).promise();
            data = {
                ...data,
                avatar: uploadResult.Location,
            };
        }
        const res: any = await dispatch(ProfileSetup(data));
        setIsloading(false)
        if (res.payload.statusCode == 200) {
            Toast.fire({
                icon: "success",
                title: res.payload.message
            })
            await dispatch(setUserAvatar(data.avatar))
            history.push(routes.privacySettings);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.payload.message
            })
        }
    };
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl: any = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const imageChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };
    const onImageClick = () => {
        if (inputFile.current) {
            inputFile.current.click();            
        }
      };
    return (
        <form className="up-formtheme up-formsignupsteptwo" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <legend>{t('setupProfile')}</legend>
                <div className="up-uploadprofilepic">
                    {selectedFile && <> <img className="up-profilepic" onClick={onImageClick} src={preview} />
                    <input type="file" ref={inputFile} onChange={imageChange} style={{ display: "none" }} />
                     </>}
                    {!selectedFile && <figure className="up-profilepic">
                        <div className="up-uploadimageicon">
                            <input accept="image/*" type="file" name="upload-camera-icon" onChange={imageChange} id="up-uploadcameraicon" />
                            <label htmlFor="up-uploadcameraicon">
                                <i className="icon-camera"></i>
                            </label>
                        </div>
                        <i className="icon-user"></i>
                        <span>{t('upload')}</span>
                    </figure>}
                </div>
                <div className="form-group">
                    <label>{t('address')}</label>
                    <input type="text" {...register("address")} name="address" className="form-control" placeholder={t('addressPlaceholder')} />
                    <small className="form-text text-muted">
                        {errors.address?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('postalCode')}</label>
                    <input type="number"  {...register("postalCode")} name="postalCode" className="form-control" placeholder={t('postalCodePlace')} />
                    <small className="form-text text-muted">
                        {errors.postalCode?.message}
                    </small>
                </div>
                <div className="up-formbuttons">
                    <button type="submit" className="up-btn up-btn-lg up-btnwithloader">{t('next')} {isLoading && <div className="lds-dual-ring"></div>}</button>
                    <a href="/privacy-settings" className="up-btnskip">{t('skip')}</a>
                </div>
            </fieldset>
        </form>
    )
};
