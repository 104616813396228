import {useState} from "react";
import {Dropdown, Modal} from "react-bootstrap"
import Papa from 'papaparse';
import {Toast} from "../../../utils/Toast";
import {userService} from "../../../services/user.service";
import AsyncCreatableSelect from 'react-select/async-creatable';
import {useTranslation} from "react-i18next";

export const InviteMembersModal: React.FC<any> = (props) => {

    const [isLoading, setIsloading] = useState(false)
    const [selectedEmails, setSelectedEmails] = useState<any[]>([]);
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const { t } = useTranslation('', { keyPrefix: 'InviteMembersModal' });

    const closeDisplayToModal = () => {
        props.closeModal(true)
    }
    const parseFile = (file: any) => {
        Papa.parse(file, {
            header: true,
            complete: (results: any) => {
                const temp: any = []
                for (const data of results.data) {
                    if (data.email.match(regexEmail)) {
                        temp.push({
                            label: data.email,
                            value: null
                        })
                    }
                }
                setSelectedEmails([...selectedEmails, ...temp])
            }
        });
    };
    const onChangeSelectedOption = (e: any) => {
        selectedEmails.push(e);
        setSelectedEmails([...selectedEmails])
    };
    const onSubmit = (e: any) => {
        e.preventDefault();
        if (e.key === 'Enter') {
        }
    };
    const createNewOption = (e: any) => {
        if (e.match(regexEmail)) {
            selectedEmails.push({
                label: e,
                value: null
            });
            setSelectedEmails([...selectedEmails])
        } else {
            Toast.fire({
                icon: 'warning',
                title: t('validEmail')
            })
        }
    };
    const promiseOptions = async (inputValue: string) => {
        if (inputValue.length > 1) {
            const res: any = await userService.searchUserByEmail(inputValue, props.id);
            if (res.statusCode == 200) {
                const tempArray: any[] = [];
                for (let i = 0; i < res.payload.users.length; i++) {
                    tempArray.push({
                        label: res.payload.users[i].email,
                        value: res.payload.users[i]._id
                    })
                }
                return tempArray;
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }
    }
    const csvChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            parseFile(e.target.files[0]);
        }
    };
    const removeSelectedEmail = (index: number) => {
        selectedEmails.splice(index, 1);
        setSelectedEmails([...selectedEmails])
    };
    const sendInvite = async () => {
        if (selectedEmails.length > 0) {
            setIsloading(true);
            let newEmails = selectedEmails.filter(e => e.value === null)
            let existingEmails = selectedEmails.filter(e => e.value !== null)
            newEmails = newEmails.map(email => (email.label))
            existingEmails = existingEmails.map(email => (email.value))
            const promises = [];
            if (existingEmails.length > 0) {
                promises.push(userService.sendOrganisationSimpleInvite({
                    organization: props.id,
                    users: existingEmails
                }));
            }
            if (newEmails.length > 0) {
                promises.push(userService.sendInviteToNonSystemUser({
                    organization: props.id,
                    emails: newEmails
                }))
            }
            await Promise.all(promises);
            Toast.fire({
                icon: 'success',
                title: t('inviteSentSuccessfully')
            })
            props.closeModal(true)
            setIsloading(false);

        } else {
            Toast.fire({
                icon: 'warning',
                title: t('pleaseSelect')
            })
        }
    };

    return <Modal className="up-thememodal up-invitemembersmodal" show={true} onHide={closeDisplayToModal}>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-forminvitememners" onSubmit={onSubmit}>
                    <fieldset>
                        <legend>{t('inviteMembers')}</legend>
                        <div className="up-formtwocols">
                            <div className="up-inputsholder" style={{ width: 'calc(100% - 24px)' }}>
                                <label>{t('email')}</label>
                                <AsyncCreatableSelect name='email' onCreateOption={createNewOption} className="up-async-select" onChange={onChangeSelectedOption} cacheOptions defaultOptions loadOptions={promiseOptions} />
                            </div>
                        </div>
                        <div className="up-addmoreuploadcsv">
                            <div className="up-uploadcsv">
                                <input accept=".csv" onChange={csvChange} type="file" name="file" id="up-uploadcsvfile" />
                                <label htmlFor="up-uploadcsvfile">{t('importCSV')}</label>
                            </div>
                        </div>
                        {selectedEmails.length > 0 && selectedEmails.map((email: any, index: number) => {
                            return <div key={email.label + index} className="up-member" style={{ justifyContent: 'space-between' }}>
                                <div className="up-membername">
                                    <h4>{email.label}</h4>
                                    <Dropdown className="up-themedropdown">
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <i className="icon-menu-icon"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="up-themedropdownmenu">
                                            <Dropdown.Item href="javascript:void(0);" onClick={() => removeSelectedEmail(index)} >{t('remove')}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        })}

                        <div className="up-formbuttonsarea">
                            <button type="button" onClick={() => sendInvite()} className="up-btn">{t('sendInvitation')} {isLoading && <div className="lds-dual-ring"></div>}</button>
                            <button type="button" className="up-btnclosmodal" onClick={closeDisplayToModal}>{t('close')}</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}