import * as yup from "yup";

export const verifyEmailSchema = yup.object().shape({
    email: yup.string().email().required('Email is required'),
}).required();
export const verifyPhoneSchema = yup.object().shape({
    phone: yup.string().matches(/^[0-9]+$/, "Must be only digits").min(8, 'Invalid Number').max(15, 'Invalid Number').required('Phone is required'),
}).required();
export const verifyEmailCodeSchema = yup.object().shape({
    otpCode: yup.string().required('Code is required'),
}).required();