import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { AppLayout } from "../../components/layouts/AppLayout"
import { useDebounce } from "../../hooks/use-debounce.hook";
import { eventService } from "../../services/events.service";
import { organisationService } from "../../services/organisation.service";
import { FullPageLoader } from "../../utils/FullPageLoader";
import { Toast } from "../../utils/Toast";
import { getUser } from "../auth/slice/user.selector";
import { IEvent } from "../organisations/createorganisation/interfaces/event.interface";
import { ThemeCalendar } from "../themecomponents/ThemeCalendar";
import { Event } from '../ownorganisation/components/Event';
import Swal from "sweetalert2";
import { EEventJoiningStatus } from "../../interfaces/event-join-status.interface";
import { EventDetailModal } from "../ownorganisation/components/EventDetailModal";
import { EventDisplayToModal } from "../themecomponents/EventDisplayToModal";
import {useTranslation} from "react-i18next";
export const EventsPage = () => {
    const [userEvents, setUserEvents] = useState<IEvent[]>([]);
    const [isLoading, setIsloading] = useState(true);
    const [organization, setOrganization] = useState();
    const [search, setSearch] = useState("");
    const [selectedEditEvent, setSelectedEditEvent] = useState<IEvent>();
    const [isEdit, setIsEdit] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showDisplayToModal, setShowDisplayToModal] = useState(false);
    const { t } = useTranslation('', { keyPrefix: 'EventsPage' });

    const [debounceSearch] = useDebounce(search, 300);
    const currentUser = useSelector(getUser);

    useEffect(() => {
        // getUserEvents(1, '')
    }, [])
    useEffect(() => {
        setIsloading(true)
        getUserEvents(1, debounceSearch);
    }, [debounceSearch]);

    const getUserEvents = async (page: number, query: string) => {
        let res: any;
        if (organization) {
            res = await eventService.getUserEvents(page, query, organization)
        } else {
            res = await eventService.getUserEvents(page, query)
        }
        if (res.statusCode == 200) {
            for (const iterator of res.payload.events.records) {
                iterator.isExpanded = false;
            }
            setUserEvents([...res.payload.events.records])
            setIsloading(false)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
            setIsloading(false)
        }

    }
    const onChangeSelectedOption = (e: any) => {
        setOrganization(e?.value ?? '');
    };
    const promiseOptions = async (inputValue: string) => {
        const res: any = await organisationService.searchUserOrganization(inputValue, 1);
        if (res.statusCode == 200) {
            const tempArray: any[] = [];
            for (let i = 0; i < res.payload.organizations.records.length; i++) {
                tempArray.push({
                    label: res.payload.organizations.records[i].name,
                    value: res.payload.organizations.records[i]._id
                })
            }
            return tempArray;
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const searchSubmit = () => {
        getUserEvents(1, search);
    }
    const showDetail = async (id: string, index: number, show: boolean) => {
        if (show) {
            const res: any = await eventService.getEventDetail(id);
            let tempEvents: any = userEvents
            tempEvents[index] = res.payload.event;
            tempEvents[index].isExpanded = show;
            setUserEvents([...tempEvents])
        } else {
            const tempEvents: any = userEvents
            tempEvents[index].isExpanded = show;
            setUserEvents([...tempEvents])
        }
    }
    const updateJoiningStatus = async (event: IEvent, status: string, index: number) => {
        const data = {
            organizationId: event.organization,
            eventId: event._id,
            joiningStatus: status
        }
        const res: any = await eventService.joinEventOrganization(data)
        if (res.statusCode == 200) {
            const tempEvents: any = userEvents
            tempEvents[index].joiningStatus = status;
            switch (status) {
                case EEventJoiningStatus.JOINING:
                    Toast.fire({
                        icon: 'success',
                        title: t('eventStatusUpdated')
                    })
                    break;
                case EEventJoiningStatus.INTERESTED:
                    Toast.fire({
                        icon: 'success',
                        title: t('eventStatusUpdated')
                    })
                    break;
                case EEventJoiningStatus.NOT_JOINING:
                    Toast.fire({
                        icon: 'success',
                        title: t('eventStatusUpdated')
                    })
                    break;
            }
            setUserEvents([...tempEvents])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const deleteEvent = async (event: IEvent, index: number) => {
        Swal.fire({
            title: t('deleteEvent'),
            text: t('areYouSure'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('delete'),
            cancelButtonText: t('cancel')
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await eventService.deleteEvent(event._id);
                    if (res.statusCode === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: res.message
                        })
                        userEvents!.splice(index, 1)
                        setUserEvents([...userEvents!])
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }

    const editEvent = (event: IEvent, index: number) => {
        setSelectedEditEvent(event);
        setIsEdit(true);
    }
    const closeEventDetailModal = (data: any) => {
        setShowDetailModal(false)
    }
    const closeEventDisplayToModal = (data: any) => {
        setShowDisplayToModal(false)
    }

    const openDisplayToModal = (event: IEvent) => {
        if (event.displayTo.length > 0) {
            setSelectedEditEvent(event);
            setShowDisplayToModal(true)
        }
    }
    return (
        <AppLayout>
            <div className="up-eventspage">
                {isLoading && <FullPageLoader />}
                <div className="up-alleventsarea">
                    <div className="up-themebox">
                        <div className="up-themeboxhead">
                            <h3>{t('myEvents')}</h3>
                        </div>
                        <div className="up-searchfilesarea">
                            <form className="up-formtheme up-formsearchfiles">
                                <fieldset>
                                    <div className="form-group">
                                        <input type="search" value={search} onChange={(e) => setSearch(e.target.value)} name="search-files" className="form-control" placeholder={t('searchForEvent')} />
                                    </div>
                                    <div className="form-group">
                                        <AsyncSelect isClearable={true} className="up-async-select" onChange={onChangeSelectedOption} cacheOptions defaultOptions loadOptions={promiseOptions} />
                                    </div>
                                    <button className="up-btn up-btnwithicon" type="button" onClick={searchSubmit}>
                                        <i className="icon-search"></i>
                                        <span>{t('search')}</span>
                                    </button>
                                </fieldset>
                            </form>
                        </div>
                        <div className="up-alleventstwocols">
                            <div className="up-eventcalendarholder">
                                <div className="up-themebox">
                                    <ThemeCalendar />
                                </div>
                            </div>
                            <div className="up-eventsholder">
                                <div className="up-alleventsholder">

                                    {userEvents.length > 0 && userEvents.map((event: IEvent, index: number) => <Event fromAdminDashboard={false} fromTab={false} index={index} event={event} memberId={currentUser._id}
                                        key={event._id} deleteEvent={deleteEvent} editEvent={editEvent} setSelectedEditEvent={setSelectedEditEvent} updateJoiningStatus={updateJoiningStatus}
                                        showDetail={showDetail} setShowDetailModal={setShowDetailModal} openDisplayToModal={openDisplayToModal} />)}
                                    {userEvents.length == 0 && <div>{t('noUserEventsFound')}</div>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showDetailModal && <EventDetailModal editEvent={selectedEditEvent!} closeModal={closeEventDetailModal} />}
                {showDisplayToModal && <EventDisplayToModal editEvent={selectedEditEvent!} closeModal={closeEventDisplayToModal} />}
            </div>
        </AppLayout >
    )
}