import React, { useState } from "react";
import { images } from "../../assets/images";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUser } from "../../features/auth/slice/user.selector";

export const PostDetailLayout: React.FC = (props) => {
  const [sideBarToggle, setSideBarToggle] = useState(false);
  const [rightSideChatToggle, setRightSideChatToggle] = useState(false);
  const [fullWidthContent, setFullWidthContent] = useState(false);
  const history = useHistory();
  const currentUser = useSelector(getUser);

  const redirectToLogin = () => {
    history.push(`login`);
  };

  return (
    <div
      className={`up-wrapper ${sideBarToggle ? "up-openclose-sidebar-menu" : ""
        } ${rightSideChatToggle ? "up-opensidebarchat" : ""} ${fullWidthContent ? "up-fullwidthcontent" : ""}`}
    >
      <header id="up-header" className="up-header">
        <div className="up-headercontent">
          <strong className="up-logo">
            <Link to="/home">
              <img src={images.Logo} alt="Logo Image" />
            </Link>
          </strong>
          <div className="up-headerrightarea">

            <Dropdown className="up-themedropdown up-userdropdown">
              <Dropdown.Toggle id="dropdown-basic">
                <figure className="up-userimage" onClick={() => redirectToLogin()}>
                  <img src={currentUser?.avatar ?? images.UserPlaceholder}
                    alt="User Profile Image" />
                </figure>
              </Dropdown.Toggle>
            </Dropdown>
          </div>
        </div>
      </header>
      <main className="up-main">{props.children}</main>
    </div>
  );
};
