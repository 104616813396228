import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Toast } from "../../../../utils/Toast";
import { CreateOrganisation, GetOrganisationTypes, SearchParentOrganisation } from "../../slice/organisation.thunks";
import { IOrganisationTypes } from "../interfaces/organisation-types.interface";
import { createOrganisationSchema } from "../validations/create-organisation.validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ICreateOrganisation } from "../interfaces/create-organisation.interface";
import AsyncSelect from 'react-select/async';
import {useTranslation} from "react-i18next";
export const CreateOrganisationForm = () => {
    const [isLoading, setIsloading] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
    const [dropDown, setDropDown] = useState<IOrganisationTypes[]>()
    const { t } = useTranslation('', { keyPrefix: 'CreateOrganisation' });

    const dispatch = useDispatch();
    let history = useHistory();
    useEffect(() => {
        getDropDownData()
    }, [])

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(createOrganisationSchema)
    });

    const onSubmit = async (data: ICreateOrganisation) => {
        setIsloading(true)
        delete data.termsAndConditions;
        if (data.type == '0') {
            Toast.fire({
                icon: 'warning',
                title: t('pleaseSelectOrganisationType')
            })
            return;
        }
        if (selectedOption) {
            data.parentOrganization = selectedOption
        } else {
            delete data.parentOrganization;
        }
        const res: any = await dispatch(CreateOrganisation(data));
        setIsloading(false)
        if (res.payload.statusCode == 201) {
            Toast.fire({
                icon: "success",
                title: res.payload.message
            })
            history.push(`/organisation-setup/${res.payload.payload.organization._id}`);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.payload.message
            })
        }
    };
    const getDropDownData = async () => {
        const res: any = await dispatch(GetOrganisationTypes())
        if (res.payload.statusCode == 200) {
            const temp = [{ _id: 0, name: 'Select Organisation Type' }, ...res.payload.payload.organizationTypes]
            setDropDown(temp)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.payload.message
            })
        }
    }
    const onChangeSelectedOption = (e: any) => {
        setSelectedOption(e.value);
    };
    const promiseOptions = async (inputValue: string) => {
        if (inputValue.length > 1) {
            const res: any = await dispatch(SearchParentOrganisation(inputValue))
            if (res.payload.statusCode == 200) {
                const tempArray: any[] = [];
                for (let i = 0; i < res.payload.payload.organizations.records.length; i++) {
                    tempArray.push({
                        label: res.payload.payload.organizations.records[i].name,
                        value: res.payload.payload.organizations.records[i]._id
                    })
                }
                return tempArray;
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.payload.message
                })
            }
        }
    }
    return (
        <form className="up-formtheme up-formcreateorganisation" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <div className="form-group">
                    <label>{t('enterOrganizationName')}</label>
                    <input type="text" {...register("name")} name="name" className="form-control" placeholder={t('organizationName')} />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.name?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('selectOrganizationType')}</label>
                    <select {...register("type")} className="form-control">
                        {dropDown && dropDown.map((e, key) => {
                            return <option key={key} value={e._id}>{e.name}</option>;
                        })}
                    </select>
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.type?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('searchParentOrganisation')} <em>{t('optional')}</em></label>
                    <AsyncSelect className="up-async-select" {...register("parentOrganization")} onChange={onChangeSelectedOption} cacheOptions defaultOptions loadOptions={promiseOptions} />
                </div>
                <div className="form-group">
                    <div className="up-remebermearea">
                        <input type="checkbox" {...register("termsAndConditions")} name="termsAndConditions" id="up-rememberme" />
                        <label htmlFor="up-rememberme">{t('iAccept')} <a href="/termsandconditions" target="_blank">{t('termsConditions')}</a></label>
                        <small id="emailHelp" className="form-text text-muted">
                            {errors.termsAndConditions?.message}
                        </small>
                    </div>
                </div>
                <div className="form-group">
                <div className="up-remebermearea" style={{ marginTop: '8px'}}>
                        <input type="checkbox" {...register("grps")} name="grps" id="up-grps" />
                        <label htmlFor="up-grps">{t('iAccept')} <a href="/gdpr-policy" target="_blank"> {t('gdprPrivacyPolicy')}</a></label>
                        <small id="emailHelp" className="form-text text-muted">
                            {errors.grps?.message}
                        </small>
                    </div>
                </div>
                <button type="submit" className="up-btn up-btnwithloader">{t('create')} {isLoading && <div className="lds-dual-ring"></div>}</button>
            </fieldset>
        </form>
    )
};
