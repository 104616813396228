import * as yup from "yup";

const today = new Date();
today.setFullYear(today.getFullYear()-1);
export const createGroupSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    parentGroup: yup.string(),
    avatar: yup.string(),
    startDate: yup.date().required('Start Date is required').min(today,'Start Date cant be before today'),
    endDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).min(yup.ref('startDate'),"end date can't be before start date"),
    canMemberChat: yup.string(),
    isParentGroup: yup.string()
}).required();