import {yupResolver} from '@hookform/resolvers/yup';
import Modal from 'react-bootstrap/Modal';
import {useForm} from 'react-hook-form';
import * as yup from "yup";

const requestRejectSchema = yup.object().shape({
    reason: yup.string()
}).required();

export const RequestRejectionModal: React.FC<any> = (props) => {

    const {register, handleSubmit, formState: {errors}, setValue} = useForm({
        resolver: yupResolver(requestRejectSchema)
    });


    const handleCloseeight = () => {
        props.closeModal()
    }

    const followRequestSubmit = async (data: any) => {
        props.closeModal(data.reason)
    }

    return <Modal className="up-thememodal up-modalcreategroup up-modalcreateevents" show={props.openRejectionModal}
                  onHide={handleCloseeight}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>Rejection Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formcreateevent" onSubmit={handleSubmit(followRequestSubmit)}>
                    <fieldset>
                        <div className="form-group">
                            {/*<label>{props.isEdit ? 'View key' : 'Your assignment key OR additional information'}</label>*/}
                            <input disabled={props.isEdit} {...register("reason")} type="text" name="reason"
                                   className="form-control" placeholder="Enter reason for rejection"/>
                            <small id="content" className="form-text text-muted">
                                {errors.reason?.message}
                            </small>
                        </div>
                        <button type="submit" className="up-btn">Reject Request</button>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}
