import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { userService } from "../../../services/user.service";
import { Toast } from "../../../utils/Toast";
import { setUpdatedUser } from "../../auth/slice/user.slice";
import { verifyEmailCodeSchema, verifyEmailSchema } from "../validations/email-verify.validation";
import {useTranslation} from "react-i18next";

export const ChangeEmailModal: React.FC<any> = (props) => {
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(verifyEmailSchema)
    });
    const { register: codeRegister, handleSubmit: codeHandleSubmit, formState: { errors: codeErrors } } = useForm({
        resolver: yupResolver(verifyEmailCodeSchema)
    });
    const { t } = useTranslation('', { keyPrefix: 'ChangeEmailModal' });

    const closeDisplayToModal = () => {
        props.closeModal(true)
    }

    const sendVerifyCode = async (data: any) => {
        data.email = data.email.toLowerCase();
        const res: any = await userService.sendEmailOTP(data)
        if (res.statusCode === 200) {
            setNewEmail(data.email)
            setShowCodeInput(true)
            Toast.fire({
                icon: 'success',
                title: res.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const sendVerifyEmail = async (data: any) => {
        data.email = newEmail;
        const res: any = await userService.verifyEmailOTP(data)
        if (res.statusCode === 200) {
            await dispatch(setUpdatedUser(res.payload.user))
            props.closeModal(true)
            Toast.fire({
                icon: 'success',
                title: res.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    return <Modal className="up-thememodal up-modalcreateupdates" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('changeEmail')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formdisplayto" onSubmit={handleSubmit(sendVerifyCode)}>
                    <fieldset>
                        <div className="form-group">
                            <label>{t('emailAddress')}</label>
                            <input type="email" {...register("email")} name="email" className="form-control" placeholder={t('emailAddress')} />
                            <small id="emailHelp" className="form-text text-muted">
                                {errors.email?.message}
                            </small>
                        </div>
                        <button type="submit" className="up-btn up-btn-lg">{t('sendCode')}</button>
                    </fieldset>
                </form>
                {showCodeInput && <form className="up-formtheme up-formdisplayto up-margintop" onSubmit={codeHandleSubmit(sendVerifyEmail)}>
                    <fieldset>
                        <div className="form-group">
                            <label>{t('enterCode')}</label>
                            <input type="text" {...codeRegister("otpCode")} name="otpCode" className="form-control" placeholder={t('enterCode')} />
                            <small id="emailHelp" className="form-text text-muted">
                                {codeErrors.otpCode?.message}
                            </small>
                        </div>
                        <button type="submit" className="up-btn up-btn-lg">{t('verifyCode')}</button>
                    </fieldset>
                </form>}
            </div>
        </Modal.Body>
    </Modal>
}