import {useEffect, useState} from "react";
import {Modal} from "react-bootstrap"
import {images} from "../../../assets/images";
import {groupService} from "../../../services/group.service";
import {FullPageLoader} from "../../../utils/FullPageLoader";
import {Toast} from "../../../utils/Toast";
import {IGroup} from "../../organisations/createorganisation/interfaces/group.interface";
import {useTranslation} from "react-i18next";

export const UpdateUserGroupModal: React.FC<any> = (props) => {

    const [fullLoader, setFullLoader] = useState(false);
    const [organizationGroups, setOrganizationGroups] = useState<IGroup[]>([]);
    const [breadCrumb, setBreadCrumb] = useState<any[]>([])
    const {memberId} = props
    const { t } = useTranslation('', { keyPrefix: 'UpdateUserGroupModal' });

    useEffect(() => {
        getOrganizationGroups(1, '');
    }, []);

    const closeModal = () => {
        props.closeModal(true)
    }

    const getOrganizationGroups = async (page: number, query: string) => {
        setFullLoader(true);
        const res: any = await groupService.getOrganizationGroups(props.organizationId, page, query, 1000);
        if (res.statusCode == 200) {
            setOrganizationGroups([...res.payload.groups.records])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setFullLoader(false);
    }

    const getChildGroups = async (group: IGroup, index: number) => {
        setFullLoader(true);
        breadCrumb.push({
            groupId: group._id,
            groupName: group.name
        })
        const res: any = await groupService.getChildGroups(group._id);
        if (res.statusCode == 200) {
            setBreadCrumb([...breadCrumb])
            setOrganizationGroups([...res.payload.childGroups])
        }
        setFullLoader(false);
    }

    const removeMember = async (group: IGroup, index: number, isAdmin: boolean, isMember: boolean) => {
        setFullLoader(true);
        const res: any = await groupService.removeGroupMember({
            memberId: memberId,
            group: group._id
        });
        if (res.statusCode == 201) {
            if (isAdmin) {
                const adminIndex = organizationGroups[index].admins.findIndex(ele => ele?._id == memberId)
                organizationGroups[index].admins.splice(adminIndex, 1);
                setOrganizationGroups([...organizationGroups])
            } else if (isMember) {
                const memberIndex = organizationGroups[index].members.findIndex(ele => ele?._id == memberId)
                organizationGroups[index].members.splice(memberIndex, 1);
                setOrganizationGroups([...organizationGroups])
            }
            Toast.fire({
                icon: 'success',
                title: t('userRemovedSuccessfully')
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setFullLoader(false);
    }
    const addUser = async (memberType: string, group: IGroup, index: number, isAdmin: boolean, isMember: boolean) => {
        setFullLoader(true);
        const res: any = await groupService.addGroupMember({
            memberId: memberId,
            memberType: memberType,
            group: group._id
        });
        if (res.statusCode == 201) {
            if (!isAdmin && !isMember) {
                if (memberType == 'admin') {
                    organizationGroups[index].admins.push({_id: memberId});
                } else {
                    organizationGroups[index].members.push({_id: memberId});
                }
                setOrganizationGroups([...organizationGroups])
            } else if (isMember) {
                const memberIndex = organizationGroups[index].members.findIndex(ele => ele?._id == memberId)
                organizationGroups[index].members.splice(memberIndex, 1);
                organizationGroups[index].admins.push({_id: memberId});
                setOrganizationGroups([...organizationGroups])
            } else if (isAdmin) {
                const adminIndex = organizationGroups[index].admins.findIndex(ele => ele?._id == memberId)
                organizationGroups[index].admins.splice(adminIndex, 1);
                organizationGroups[index].members.push({_id: memberId});
                setOrganizationGroups([...organizationGroups])
            }
            Toast.fire({
                icon: 'success',
                title: `${t('userMade')} ${memberType} ${t('successfully')}`
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setFullLoader(false);
    }

    const handleIsMember = (event: any, group: IGroup, index: number, isAdmin: boolean, isMember: boolean) => {
        if (event.target.checked) {
            addUser('member', group, index, isAdmin, isMember)
        } else {
            removeMember(group, index, isAdmin, isMember)
        }
    }
    const handleIsAdmin = (event: any, group: IGroup, index: number, isAdmin: boolean, isMember: boolean) => {
        if (event.target.checked) {
            addUser('admin', group, index, isAdmin, isMember)
        } else {
            removeMember(group, index, isAdmin, isMember)
        }
    }
    const renderGroups = () => {
        if (organizationGroups.length === 0) {
            return <h4>{t('noUserGroupsFound')}</h4>
        }
        return organizationGroups!.map((group: IGroup, index: number) => {
            const isAdmin = group.admins.find(ele => ele?._id == memberId)
            const isMember = group.members.find(ele => ele?._id == memberId)
            return <div key={group._id} className="up-group">
                <div className="up-groupInfoHolder">
                    <div className="up-gourpimagename">
                        <figure className="up-groupimg">
                            <img src={group?.avatar ?? images.RestPlaceholder} alt="Group Image"/>
                        </figure>
                        <div className="up-groupname up-popupgroupname">
                            <h3> <span style={{ cursor: 'pointer' }}>{group.name}</span> {group.childGroups.length > 0 && <>
                                
                                <i onClick={() => getChildGroups(group, index)} className="icon-arrow-down2" style={{ marginLeft: '5px', cursor: 'pointer'  }} />
                                <p style={{ cursor: 'pointer' }} onClick={() => getChildGroups(group, index)}>{t('has')} {group.childGroups.length} {t('subGroups')}</p>
                            </>}</h3>
                        </div>

                    </div>
                    <input className="up-checkboxmemeber"
                           onChange={(event: any): any => handleIsMember(event, group, index, isAdmin, isMember)}
                           checked={!!isMember} type="checkbox" name="poll-option"/>
                    <input className="up-checkboxadmin"
                           onChange={(event: any): any => handleIsAdmin(event, group, index, isAdmin, isMember)}
                           checked={!!isAdmin} type="checkbox" name="poll-option"/>
                </div>
            </div>
        })
    }
    const getSpecficGroupChild = async (group: any, index: number) => {
        setFullLoader(true);
        breadCrumb.splice(++index, breadCrumb.length)
        const res: any = await groupService.getChildGroups(group.groupId);
        if (res.statusCode == 200) {
            setBreadCrumb([...breadCrumb])
            setOrganizationGroups([...res.payload.childGroups])
        }
        setFullLoader(false);
    }
    const getAllGroups = () => {
        setBreadCrumb([]);
        setOrganizationGroups([]);
        getOrganizationGroups(1, '');
    }
    return <Modal className="up-thememodal up-modalcreateupdates up-modalusergrouparea" show={true} onHide={closeModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('userGroupsFor')} {props.memberName}</Modal.Title>
        </Modal.Header>
        {fullLoader && <FullPageLoader/>}
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder ">
                <div className="up-themebox up-boxpaddingnone">
                    {organizationGroups.length !== 0 && <>
                        <div className="up-usergroupmodalheadforhom">
                            <span className="up-getallgrouphome" onClick={() => getAllGroups()}>{breadCrumb.length == 0 ? t('topLevel') : t('home')}</span>
                            <span className="up-getallgrouphome float-right">{t('member')}</span>
                            <span className="up-getallgrouphome">{t('admin')}</span>
                        </div>
                        {breadCrumb && breadCrumb.map((bread, index) => (<><span
                            onClick={() => getSpecficGroupChild(bread, index)}> / {bread.groupName}</span></>))}
                    </>}
                    <div className="up-allgroups up-modalinnerscroll">
                        {renderGroups()}
                        {breadCrumb.length !== 0 && organizationGroups.length == 0 &&
                            <h4 style={{marginTop: '16px'}}>{t('noChildGroups')}</h4>}
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}