import * as yup from "yup";

export const createRoleSchema = yup.object().shape({
    role: yup.string().required('Role is required'),
}).required();


export const createURLSchema = yup.object().shape({
    label: yup.string().required('Label is required'),
    url: yup.string().required('URL is required'),
    urlType: yup.string().required('URL Type is required'),
}).required();