// import { styles } from "./"

import { images } from "../../../../assets/images";
import { LoginForm } from "./components/LoginForm";
import {useTranslation} from "react-i18next";

export const LoginPage = () => {
    const { t } = useTranslation();
  return (
    <div className="up-loginarea">
      <figure className="up-circlimages">
        <img src={images.LoginCircles} alt="Circles"/>
      </figure>
      <div className="up-logincontentarea">
        <h3>{t('LoginPage.secureSocial')} <span>{t('LoginPage.networkIsHere')}</span></h3>
        <div className="up-description">
          <p>{t('LoginPage.description')}</p>
        </div>
      </div>
      <div className="up-loginformholder">
        <div className="up-loginformcontentholder">
          <strong className="up-logo">
            <img src={images.Logo} alt="Logo Image"/>
          </strong>
          <div className="up-formHead">
            <h1>{t('LoginPage.signIn')}</h1>
            <span>{t('LoginPage.text')}</span>
          </div>
          <LoginForm/>
        </div>
      </div>
    </div>
  )
};
