import { images } from "../../../../assets/images";
import { ForgotPassword } from "./components/ForgotPassword";
import {useTranslation} from "react-i18next";

export const ForgotPasswordPage = () => {
    const { t } = useTranslation('', { keyPrefix: 'ForgotPassword' });
  return (
    <div className="up-forgotpasswordpage">
      <figure className="up-circlimages">
        <img src={images.LoginCircles} alt="Circles"/>
      </figure>
      <div className="up-loginformholder">
        <div className="up-loginformcontentholder">
          <strong className="up-logo">
            <img src={images.Logo} alt="Logo Image"/>
          </strong>
          <div className="up-formHead">
            <h1>{t('forgotYourPassword')}</h1>
            <span>{t('enterEmailText')}</span>
          </div>
          <ForgotPassword/>
        </div>
      </div>
    </div>
  )
};
