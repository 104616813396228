import {useEffect, useState} from "react";
import {Modal} from "react-bootstrap"
import {FullPageLoader} from "../../../utils/FullPageLoader";
import {Toast} from "../../../utils/Toast";
import {organisationService} from "../../../services/organisation.service";
import {IUserField, IUserFieldValue} from "../../../interfaces/user-fields.interface";
import {useTranslation} from "react-i18next";

export const AssignUserFieldsModal: React.FC<any> = (props) => {

    const [fullLoader, setFullLoader] = useState(false);
    const [organizationFields, setOrganizationFields] = useState<IUserField[]>([]);
    const [userFields, setUserFields] = useState<IUserFieldValue[]>([]);

    const { t } = useTranslation('', { keyPrefix: 'AssignUserFieldsModal' });

    useEffect(() => {
        getOrganizationFields();
        getUserFields();
    }, []);

    const closeModal = () => {
        props.closeModal(true)
    }

    const getOrganizationFields = async () => {
        setFullLoader(true);
        const res: any = await organisationService.getOrganizationFields(props.organizationId);
        if (res.statusCode == 200) {
            setOrganizationFields(res.payload.fields)
        }
        setFullLoader(false);
    }
    const getUserFields = async () => {
        const res: any = await organisationService.getOrganizationFieldValue(props.memberId);
        if (res.statusCode == 200) {
            setUserFields(res.payload.fieldValues)
        }
    }

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, currentField?: IUserFieldValue) => {
        const {value} = e.target
        if (currentField) {
            currentField.value = value
            const fieldIndex = userFields.findIndex(item => item._id == currentField._id)
            if (fieldIndex >= 0) {
                userFields[fieldIndex] = currentField
                setUserFields([...userFields])
            }
        } else {
            userFields.push({
                "_id": "",
                "member": props.memberId,
                "organizationField": organizationFields[index],
                "createdAt": "",
                "updatedAt": "",
                "value": value
            })
            setUserFields([...userFields])
        }
    }

    const saveUserField = async (item: IUserField, index: number, currentField?: IUserFieldValue) => {
        if (currentField?.value) {
            setFullLoader(true);
            const res: any = await organisationService.createUpdateOrganizationFieldValue({
                member: props.memberId,
                organizationField: currentField.organizationField._id,
                value: currentField.value
            })
            setFullLoader(false);
            if (res.statusCode == 201) {
                Toast.fire({
                    icon: "success",
                    title: res.message
                })
            } else {
                Toast.fire({
                    icon: "warning",
                    title: res.message
                })
            }
        } else {
            Toast.fire({
                icon: "info",
                title: 'Cannot save empty value'
            })
        }
    }

    return <Modal className="up-thememodal up-modalcreateupdates" show={true} onHide={closeModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('assignUserFieldsFor')} {props.memberName}</Modal.Title>
        </Modal.Header>
        {fullLoader && <FullPageLoader/>}
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <div className="up-themebox up-boxpaddingnone up-assignuserfieldbox" style={{margin: "0 auto"}}>
                    {props?.orgComment && <div className="up-editprofileinputs">
                        <div className="up-adduserfieldinputholder">
                            <div className="form-group up-assignuserfieldinput">
                                <label>{t('userComment')}</label>
                                <div className="up-inputwithicon">
                                    <input type="text" disabled={true}
                                           value={props.orgComment || ''}/>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {organizationFields.map((item, index) => {
                        const currentField = userFields.find(element => element.organizationField._id === item._id)
                        return <div className="up-editprofileinputs">
                            <div className="up-adduserfieldinputholder">
                                <div className="form-group up-assignuserfieldinput">
                                    <label>{item.label}</label>
                                    <div className="up-inputwithicon">
                                        <input type="text" onChange={e => handleFieldChange(e, index, currentField)}
                                               value={currentField?.value || ''}/>
                                    </div>
                                <button onClick={() => saveUserField(item, index, currentField)}
                                        className="up-btn" type="button">
                                    {t('Save')}
                                </button>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </Modal.Body>
    </Modal>
}