import { images } from "../../../assets/images"
import { OrganisationSocialLinksForm } from "./components/OrganisationSocialLinksForm"
import {useTranslation} from "react-i18next";

export const OrganisationSocialLinksPage = () =>{
    const { t } = useTranslation('', { keyPrefix: 'OrganisationSocialLinksForm' });
    return(
        <div className="up-loginarea up-createorganisationtwo up-paddingminus">
            <figure className="up-circlimages">
                <img src={images.LoginCircles} alt="Circles"/>
            </figure>
            <div className="up-logincontentarea">
                <figure className="up-createorganisationimage">
                    <img src={images.CreateOrganisationImageRight} alt="Create Organisation Image"/>
                </figure>
                <h3>{t('registerOrganization')}</h3>
                <div className="up-description">
                    <p>{t('registerDetail')}</p>
                </div>
            </div>
            <div className="up-loginformholder">
                <div className="up-loginformcontentholder">
                    <strong className="up-logo">
                        <img src={images.Logo} alt="Logo Image"/>
                    </strong>
                    <div className="up-formHead">
                        <h1>{t('setupOrganization')}</h1>
                    </div>
                    <OrganisationSocialLinksForm/>
                </div>
            </div>
        </div>
    )
}