import { images } from "../../../../assets/images"
import { ResetPassword } from "./components/ResetPasswordForm";
import {useTranslation} from "react-i18next";

export const ResetPasswordPage = () => {
  const { t } = useTranslation('', { keyPrefix: 'ResetPassword' });
    return(
        <div className="up-resetpasswordpage">
      <figure className="up-circlimages">
        <img src={images.LoginCircles} alt="Circles"/>
      </figure>
      <div className="up-loginformholder">
        <div className="up-loginformcontentholder">
          <strong className="up-logo">
            <img src={images.Logo} alt="Logo Image"/>
          </strong>
          <div className="up-formHead">
            <h1>{t('setNewPassword')}</h1>
          </div>
          <ResetPassword/>
        </div>
      </div>
    </div>
    )
};
  