import {useEffect, useState} from "react";
import {
    IOrganizationRole
} from "../../organisations/createorganisation/interfaces/organization-requested-members.interface";
import {ISettingsTab} from "../../organisations/createorganisation/interfaces/settings-tab.interface"
import {CreateNewRoleModal} from "./CreateNewRoleModal";
import {Dropdown} from "react-bootstrap";
import Swal from "sweetalert2";
import {Toast} from "../../../utils/Toast";
import {organisationService} from "../../../services/organisation.service";
import {useHistory} from "react-router-dom";
import {CreateURLModal} from "./CreateURLModal";
import {IUserField} from "../../../interfaces/user-fields.interface";
import {shortcutService} from "../../../services/shortcut.service";
import {UrlConst} from "../../../utils/url.-consts";
import {routes} from "../../../router/routes";
import {EBoolean} from "../../auth/pages/signup/interfaces/drop-down.enums";
import {FullPageLoader} from "../../../utils/FullPageLoader";
import {useTranslation} from "react-i18next";


export const SettingsTab: React.FC<ISettingsTab> = (props) => {
    const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);
    const [showCreateURLModal, setShowCreateURLModal] = useState(false);
    const [editToggle, setEditToggle] = useState(false);
    const [orgShortcuts, setOrgShortcuts] = useState<IUserField[]>([]);
    const [selectedEditRole, setSelectedEditRole] = useState<any>();
    const [isLoading, setIsloading] = useState(false);
    const [selectedEditURL, setSelectedEditURL] = useState<any>();
    const { t } = useTranslation('', { keyPrefix: 'SettingsTab' });
    const history = useHistory();
    const closeRoleModal = (isCreate: boolean, data: IOrganizationRole): any => {
        setShowCreateRoleModal(false);
        if (data) {
            props.setOrganizationRole(isCreate, data);
        }
    }
    const closeURLModal = (isCreate: boolean, data: any): any => {
        setShowCreateURLModal(false);
        if (data) {
            if (editToggle) {
                const tempIndex = orgShortcuts.findIndex(item => item._id === data._id);
                if (tempIndex !== -1) {
                    orgShortcuts.splice(tempIndex, 1, data)
                    setOrgShortcuts([...orgShortcuts])
                }
            } else {
                setOrgShortcuts([...orgShortcuts, data])
            }
        }
    }
    useEffect(()=> {
        getOrgUrls()
    }, [])

    const getOrgUrls = async () => {
        const res: any = await shortcutService.getShortcuts(props.organizationId);
        if (res.statusCode == 200) {
            setOrgShortcuts(res.payload.organizationShortcuts.records)
        }
    }
    const editRole = (role: any, index: number) => {
        setSelectedEditRole(role);
        setEditToggle(true);
        setShowCreateRoleModal(true);
    }
    const setAsDefaultRole = async (role: any, index: number) => {
        setIsloading(true)
        const res: any = await organisationService.setDefaultRole({
            organization: props.organizationId,
            organizationRoleId: role._id
        });
        setIsloading(false)
        if (res.statusCode == 200)
        {
            role.isDefault = EBoolean.YES
            props.setOrganizationRole(false, role);
            Toast.fire({
                icon: 'success',
                title: t('defaultRoleUpdated')
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const editUrl = (url: any, index: number) => {
        setSelectedEditURL(url);
        setEditToggle(true);
        setShowCreateURLModal(true);
    }
    const deleteUrl = (role: any, index: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await shortcutService.deleteShortcut(role._id);
                    if (res.statusCode === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: res.message
                        })
                        orgShortcuts.splice(index,1);
                        setOrgShortcuts([...orgShortcuts]);
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }

    const deleteRole = (role: any, index: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await organisationService.deleteOrganizationRole(role._id);
                    if (res.statusCode === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: res.message
                        })
                        props.deleteOrganizationRole(index)
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }

    const openURL = (cut: any, index: number) => {
        if (cut.urlType === 'FIXED') {
            window.open(cut.url, "_blank")
        }else{
            let tempURL = cut.url;
            const tempArray = UrlConst.slice(0,cut.dynamicFields.length)
            for (let i = 0; i < tempArray.length; i++) {
                tempURL = tempURL.replace(tempArray[i].toString(),cut.dynamicFields[i]._id.toString() || cut.dynamicFields[i])
            }
            if (tempURL.slice(0, 3) === 'www')
            {
                tempURL = 'https://' + tempURL
            }
            window.open(tempURL, "_blank")
        }
    }

    const copyURL = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_FE_BASE_URL}${routes.publicOrganizationProfile.replace(':id',props.organizationId)}`);
        Toast.fire({
            icon: 'success',
            title: t('urlCopied')
        })
    }
    return <> <div className="up-organisationsettings">
        <div className="up-themeboxhead">
            {/* <h2>Setting</h2> */}
            {isLoading && <FullPageLoader />}
        </div>
        <div className="up-themebox">
            <div className="up-themeboxhead">
                {props.myRole.role.role == 'Admin' && <button className="up-btn" style={{ float: 'right' }} onClick={() => setShowCreateRoleModal(true)}>{t('createNewRole')}</button>}
                {props.myRole.role.role == 'Admin' && <button className="up-btn" style={{ float: 'right', marginRight: '8px' }} onClick={() => history.push({
                    pathname: `/dashboard/${props.organizationId}`,
                    search: props.organizationName
                })}>{t('adminPanel')}</button>}
                {props.myRole.role.role == 'Admin' && <button className="up-btn" style={{ float: 'right', marginRight: '8px' }} onClick={() => setShowCreateURLModal(true)}>{t('createURLForward')}</button>}
                {props.myRole.role.role == 'Admin' && <button className="up-btn" style={{ float: 'right', marginRight: '8px' }} onClick={() => copyURL()}>{t('copyPublicURL')}</button>}
            </div>
            <div className="up-organisationroles">
                <h4>{t('userGroupRoles')}</h4>
                <div className="up-usergroupsroles">
                    {props.organizationRoles && props.organizationRoles.map((role: any, index: number) => (
                        <div key={index} className="up-eventnameimgdate">
                            <div className="up-eventnamedate up-defaultmemberarea">
                                <h3>{role.role}</h3> {role.isDefault === EBoolean.YES && <span>{role.isDefault === EBoolean.YES ? t('defaultRole') : ''}</span>}
                            </div>
                            {(props.myRole.role.role == 'Admin') && <Dropdown className="up-themedropdown up-btneditrole">
                                <Dropdown.Toggle id="dropdown-basic">
                                    <i className="icon-menu-icon"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="up-themedropdownmenu">
                                    <Dropdown.Item onClick={() => setAsDefaultRole(role, index)}>{t('setAsDefaultRole')}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => editRole(role, index)}>{t('editRole')}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => deleteRole(role, index)} >{t('deleteRole')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>}
                        </div>
                    ))}
                </div>
            </div>
            <div className="up-organisationroles">
                <h4>{t('URLForwards')}</h4>
                <div className="up-usergroupsroles">
                    {orgShortcuts && orgShortcuts.map((cut: any, index: number) => (
                        <div key={index} className="up-eventnameimgdate">
                            <div className="up-eventnamedate">
                                <h3>{cut.label}</h3>
                            </div>
                            {props.myRole.role.role == 'Admin' && <Dropdown className="up-themedropdown up-btneditrole">
                                <Dropdown.Toggle id="dropdown-basic">
                                    <i className="icon-menu-icon"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="up-themedropdownmenu">
                                    <Dropdown.Item onClick={() => editUrl(cut, index)}>{t('editURL')}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => deleteUrl(cut, index)} >{t('deleteURL')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
        {showCreateRoleModal && <CreateNewRoleModal organizationId={props.organizationId} isEdit={editToggle} closeModal={closeRoleModal} selectedEditRole={selectedEditRole!} />}
        {showCreateURLModal && <CreateURLModal organizationRoles={props.organizationRoles} organizationId={props.organizationId} isEdit={editToggle} closeModal={closeURLModal} selectedEditURL={selectedEditURL!} />}
    </>
}