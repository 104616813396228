import React, {useState} from "react";
import {images} from "../../assets/images";
import {Link} from "react-router-dom";
import {routes} from "../../router/routes";
import {useTranslation} from "react-i18next";

export const PublicAppLayout: React.FC = (props) => {
    const [sideBarToggle, setSideBarToggle] = useState(false);
    const [fullWidthContent] = useState(true);
    const { t } = useTranslation('', { keyPrefix: 'PublicAppLayout' });

    return (
        <div
            className={`up-wrapper up-wrapperthree ${sideBarToggle ? "up-openclose-sidebar-menu" : ""
            } ${fullWidthContent ? "up-fullwidthcontent" : ""}`}
        >
            <header id="up-header" className="up-header">
                <div className="up-headercontent">
          {/* <span className="up-menuiconholder">
            <button
                onClick={() => setSideBarToggle(!sideBarToggle)}
                className="up-btnmenuicon"
                type="button"
            >
              <img src={images.MenuIcon} alt="Menu Icon"/>
            </button>
          </span> */}
                    <strong className="up-logo">
                        <Link to="/home">
                            <img src={images.Logo} alt="Logo Image"/>
                        </Link>
                    </strong>
                    {/* <div className="up-globalsearch">
                    </div> */}
                    <span>{t('registerTo')}</span>
                    <div className="up-headerrightarea">
                        <Link to={routes.login}> {t('login')}</Link>
                    </div>
                </div>
            </header>
            <main className="up-main">{props.children}</main>
        </div>
    );
};
