import { images } from "../../../assets/images"
import { AppLayout } from "../../../components/layouts/AppLayout"
import { OrganisationProfileTabs } from "./OrganisationProfileTabs"

export const OrganisationProfile = () =>{
    return(
        <AppLayout>
            <div className="up-Organisationprofilepage">
                <div className="up-themebanner">
                    <figure className="up-bannerimg">
                        <img src={images.RestPlaceholder} alt="Profile Banner Image"/>
                    </figure>
                    <div className="up-profilebannercontent">
                        <div className="up-profilenameimage">
                            <figure className="up-organisationprofileimg">
                                <img src={images.RestPlaceholder} alt="Organisation Profile Image"/>
                            </figure>
                            <div className="up-organisationname">
                                <h3>Fash­­ion Craze</h3>
                                <span>@Fashion</span>
                            </div>
                        </div>
                        <ul className="up-organisermetadatadashboard">
                            <li>
                                <span>Orgnasation­ Type: Public</span>
                            </li>
                            <li>
                                <span>Updates: 25</span>
                            </li>
                            <li>
                                <span>Members: 230</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="up-organiserprofiletabholder">
                    <OrganisationProfileTabs/>
                </div>
            </div>
        </AppLayout>
    )
}