import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { eventService } from '../../services/events.service';
import { FullPageLoader } from '../../utils/FullPageLoader';
import { Toast } from '../../utils/Toast';
import { IEventDetailModalProps } from '../organisations/createorganisation/interfaces/create-event-modal.interface';
import { EWhichDisplayTo } from '../organisations/createorganisation/interfaces/display-to-enum';
import { IEvent } from '../organisations/createorganisation/interfaces/event.interface';
import {useTranslation} from "react-i18next";


export const EventDisplayToModal: React.FC<IEventDetailModalProps> = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [eventDetail, setEventDetail] = useState<IEvent>(props.editEvent);
    const { t } = useTranslation('', { keyPrefix: 'EventDisplayToModal' });

    useEffect(() => {
        getEventDetail()
    }, [])

    const getEventDetail = async () => {
        const res: any = await eventService.getEventDetail(props.editEvent?._id);
        setIsLoading(false);
        if (res.statusCode = 200) {
            setEventDetail(res.payload.event)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const handleCloseeight = () => {
        props.closeModal(true)
    }
    return <Modal className="up-thememodal up-modalcreategroup up-modalcreateevents" show={true} onHide={handleCloseeight}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('eventDisplayTo')} {eventDetail.eWhichDisplayTo === EWhichDisplayTo.OrganizationRole ? t('organizationRoles') : t('userGroups')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            {isLoading && <FullPageLoader />}
            {eventDetail.displayTo.map((display) => {
                return <div>{display?.name || display?.role}</div>
            })}
        </Modal.Body>
    </Modal>
}