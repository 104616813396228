import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import {images} from "../../assets/images"
import {AppLayout} from "../../components/layouts/AppLayout"
import {FullPageLoader} from "../../utils/FullPageLoader";
import {Toast} from "../../utils/Toast";
import {getUser} from "../auth/slice/user.selector";
import {S3} from 'aws-sdk';
import {AWS_S3_BUCKET, s3} from "../../config/aws.config";
import {userService} from "../../services/user.service";
import {setUpdatedUser, setUserAvatar} from "../auth/slice/user.slice";
import {IOrganization} from "../organisations/createorganisation/interfaces/organisation.interface";
import {organisationService} from "../../services/organisation.service";
import moment from "moment";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {editProfileSchema} from "./validations/edit-profile.validation";
import {ChangePasswardModal} from "./components/ChangePasswardModal";
import {ImageCropper} from "../../utils/ImageCropper";
import {ChangeEmailModal} from "./components/ChangeEmailModal";
import {ChangePhoneModal} from "./components/ChangePhoneModal";
import {UpdateUserGroupModal} from "./components/UpdateUserGroupModal";
import {AssignUserFieldsModal} from "./components/AssignUserFieldsModal";
import {ValidTillModal} from "../ownorganisation/components/ValidTillModal";
import {useTranslation} from "react-i18next";

export const ProfilePage = () => {
    const [isLoading, setIsloading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showChangeEmail, setShowChangeEmail] = useState(false);
    const [showChangePhone, setShowChangePhone] = useState(false);
    const [selectedBannerFile, setSelectedBannerFile] = useState<any>()
    const [bannerPreview, setBannerPreview] = useState<any>()
    const [selectedAvatarFile, setSelectedAvatarFile] = useState<any>()
    const [avatarPreview, setAvatarPreview] = useState<any>()
    const [userOrganizations, setUserOrganization] = useState<IOrganization[]>();
    const [userGroups, setUserGroups] = useState<any[]>();
    const [memberIds, setMemberIds] = useState<string[]>([]);
    const [showImageCropper, setShowImageCropper] = useState(false);
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [showFieldsModal, setShowFieldsModal] = useState(false);
    const [orgRole, setOrgRole] = useState<any>();
    const [showValidityModal, setShowValidityModal] = useState(false);
    const bannerRef = useRef<HTMLInputElement>(null);
    const avatarRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const currentUser = useSelector(getUser);
    const {register, setValue, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(editProfileSchema)
    });
    const location = useLocation();
    const [organizationId] = useState(location?.search?.replace('?', '').split('&')[0]);
    const [memberId] = useState(location?.search?.replace('?', '').split('&')[1]);
    const { t } = useTranslation('', { keyPrefix: 'ProfilePage' });
    useEffect(() => {
        getUpdatedUser()
        if (memberId) {
            getUserOrganizationComment(memberId)
        }
    }, [])
    useEffect(() => {
        if (!selectedBannerFile) {
            setBannerPreview(undefined)
            return
        }
        const objectUrl: any = URL.createObjectURL(selectedBannerFile)
        setBannerPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedBannerFile])

    useEffect(() => {
        if (!selectedAvatarFile) {
            setAvatarPreview(undefined)
            return
        }
        const objectUrl: any = URL.createObjectURL(selectedAvatarFile)
        setAvatarPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedAvatarFile])

    const getUserOrganizationComment = async (sample_id: string) => {
        setIsloading(true)
        const res: any = await organisationService.getUserOrganizationComment(sample_id)
        setIsloading(false)
        if (res.statusCode === 200) {
            setOrgRole(res?.payload?.member)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const onBannerImageClick = () => {
        if (bannerRef.current) {
            bannerRef.current.click();
        }
    };
    const onAvatarImageClick = () => {
        if (avatarRef.current) {
            avatarRef.current.click();
        }
    };
    const bannerImageChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedBannerFile(e.target.files[0]);
            setShowImageCropper(true);
        }
    };
    const avatarImageChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedAvatarFile(e.target.files[0]);
        }
    };

    const uploadBannerImage = async (file: any) => {
        setIsloading(true);
        let data: any = {};
        if (selectedBannerFile) {
            const params: S3.PutObjectRequest = {
                Body: file,
                Bucket: AWS_S3_BUCKET!,
                Key: `${parseInt(
                    (new Date().getTime() / 1000).toFixed(0)
                )}-${selectedBannerFile.name}`,
                ContentType: selectedBannerFile.type,
                ACL: "public-read",
            };
            const uploadResult: any = await s3.upload(params).promise();
            data.banner = uploadResult.Location
        }
        const res: any = await userService.profileSetup(data);
        if (res.statusCode == 200) {
            await dispatch(setUpdatedUser(res.payload.user))
            setSelectedBannerFile(null);
            Toast.fire({
                icon: 'success',
                title: res.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setIsloading(false);
    }
    const uploadAvatarImage = async () => {
        setIsloading(true);
        let data: any = {};
        if (selectedAvatarFile) {
            const params: S3.PutObjectRequest = {
                Body: selectedAvatarFile,
                Bucket: AWS_S3_BUCKET!,
                Key: `${parseInt(
                    (new Date().getTime() / 1000).toFixed(0)
                )}-${selectedAvatarFile.name}`,
                ContentType: selectedAvatarFile.type,
                ACL: "public-read",
            };
            const uploadResult: any = await s3.upload(params).promise();
            data.avatar = uploadResult.Location
        }
        const res: any = await await userService.profileSetup(data);
        if (res.statusCode == 200) {
            await dispatch(setUserAvatar(data.avatar))
            setSelectedAvatarFile(null);
            Toast.fire({
                icon: 'success',
                title: res.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setIsloading(false);
    }
    const getUpdatedUser = async () => {
        const res: any = await userService.getUserProfile(currentUser._id)
        if (res.statusCode === 200) {
            setUserGroups(res.payload.groups)
            const remp = [];
            for (const organization of res.payload.organizations) {
                remp.push(organization._id)
            }
            setMemberIds(remp)
            setUserOrganization(res.payload.organizations)
            await dispatch(setUpdatedUser(res.payload.user))
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const editProfile = () => {
        if (isEdit) {
            setIsEdit(!isEdit)
        } else {
            setValue('name', currentUser.name);
            setValue('username', currentUser.username);
            setValue('dob', currentUser.dob ? currentUser?.dob?.split('T')[0] : '');
            setValue('address', currentUser.address);
            setValue('postalCode', currentUser.postalCode);
            setValue('bio', currentUser.bio);
            for (const iterator of currentUser.socialLinks) {
                if (iterator && iterator.includes('facebook')) {
                    setValue('facebookLink', iterator);
                }
                if (iterator && iterator.includes('twitter')) {
                    setValue('twitterLink', iterator);
                }
                if (iterator && iterator.includes('instagram')) {
                    setValue('instagramLink', iterator);
                }
                if (iterator && iterator.includes('linkedin')) {
                    setValue('linkedinLink', iterator);
                }
            }
            setIsEdit(!isEdit)
        }
    }
    const onSubmit = async (data: any) => {
        setIsloading(true)
        data.socialLinks = [data.facebookLink, data.twitterLink, data.instagramLink, data.linkedinLink]
        data.dob = moment(data.dob).add(12, 'hours')
        const res: any = await userService.profileSetup(data);
        setIsloading(false)
        if (res.statusCode == 200) {
            await dispatch(setUpdatedUser(res.payload.user))
            Toast.fire({
                icon: "success",
                title: 'Profile Updated'
            })
            setIsEdit(false)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    };
    const closePasswordModal = () => {
        setShowChangePassword(false);
    }
    const closeEmailModal = () => {
        setShowChangeEmail(false);
    }
    const closePhoneModal = () => {
        setShowChangePhone(false);
    }
    const closeImageCropper = () => {
        setSelectedBannerFile(null)
        setShowImageCropper(false);
    }
    const getCroppedImage = (image: any, blob: any) => {
        const file = new File([blob], selectedBannerFile.name)
        setBannerPreview(image);
        setShowImageCropper(false);
        setTimeout(() => {
            uploadBannerImage(file)
        }, 250)
    }
    const closeGroupModal = () => {
        setShowGroupModal(false);
    }
    const closeFieldsModal = () => {
        setShowFieldsModal(false);
    }
    const getGroupRole = (group: any) => {
        for (let i = 0; i < memberIds?.length; i++) {
            if (group.admins.includes(memberIds[i])) {
                return 'Admin'
            }
            if (group.members.includes(memberIds[i])) {
                return 'Follower'
            }
        }
    }
    const validTillCloseModal = (data?: string) => {
        setShowValidityModal(false);
        if (data)
        {
            setOrgRole({...orgRole, validTill: moment(data)})
        }
    }
    return (
        <AppLayout>
            <div className="up-ownorganisationprofilepage up-myprofilepage">
                {isLoading && <FullPageLoader/>}
                {showImageCropper && <ImageCropper image={selectedBannerFile!} closeModal={closeImageCropper}
                                                   croppedImage={getCroppedImage}/>}
                <div className="up-themebanner">
                    {!selectedBannerFile && <figure className="up-bannerimg">
                        <img src={currentUser?.banner! ?? images.RestPlaceholder} alt="Profile Banner Image"/>
                    </figure>}
                    {selectedBannerFile && <figure className="up-bannerimg">
                        <img src={bannerPreview} alt="Profile Banner Image"/>
                    </figure>}
                    {selectedBannerFile && <>
                        <div className="up-profilebannercontent">
                            <label style={{marginRight: "4px"}} onClick={onBannerImageClick}><i
                                className="icon-camera"/></label>
                            <label onClick={uploadBannerImage}><i className="icon-checkmark"/></label>
                            <input accept="image/*" type="file" ref={bannerRef} onChange={bannerImageChange}
                                   style={{display: "none"}}/>
                        </div>
                    </>}
                    {!selectedBannerFile && <>
                        <div className="up-profilebannercontent">
                            <div className="up-changebannerbtn">
                                <input accept="image/*" onChange={bannerImageChange} type="file" name="change-banner"
                                       id="up-changeimage"/>
                                <label htmlFor="up-changeimage">{t("Edit Banner")}</label>
                            </div>
                        </div>
                    </>}
                </div>
                <div className="up-bannerbottomarea">
                    <div className="up-profilenameimage">
                        <figure className="up-organisationprofileimg">
                            {!selectedAvatarFile && <img src={currentUser?.avatar! ?? images.UserPlaceholder}
                                                         alt="Organisation Profile Image"/>}
                            {selectedAvatarFile && <img src={avatarPreview} alt="Profile Image"/>}
                            {selectedAvatarFile && <div className="up-uploadprofilepic at-changeprofilebtns">
                                <input accept="image/*" type="file" ref={avatarRef} onChange={avatarImageChange}
                                       style={{display: "none"}}/>
                                <label onClick={onAvatarImageClick}><i className="icon-camera"/></label>
                                <label className="up-checkbutton" onClick={uploadAvatarImage}><i
                                    className="icon-checkmark"/></label>
                            </div>}
                            {!selectedAvatarFile && <>
                                <div className="up-uploadprofilepic">
                                    <input accept="image/*" onChange={avatarImageChange} type="file"
                                           name="upload-profile" id="up-uploadprofile"/>
                                    <label htmlFor="up-uploadprofile"><i className="icon-camera"/></label>
                                </div>
                            </>}
                        </figure>
                        <div className="up-organisationname">
                            <h3>{currentUser.name}</h3>
                            <span>{"@" + currentUser.username}</span>
                        </div>
                    </div>
                    <a className="up-btn" onClick={() => editProfile()}>{isEdit ? 'Cancel' : 'Edit Profile'}</a>
                    {organizationId && <button type="button" className="up-btn" style={{marginRight: '8px'}}
                                               onClick={() => setShowGroupModal(true)}>{t("assignUserGroups")}</button>}
                    {organizationId && <button type="button" className="up-btn" style={{marginRight: '8px'}}
                                               onClick={() => setShowFieldsModal(true)}>{t("assignUserFields")}</button>}
                    {organizationId && <button type="button" className="up-btn" style={{marginRight: '8px'}}
                                               onClick={() => setShowValidityModal(true)}>{t("validity")}</button>}
                    {/* {isEdit && <a className="up-btn" onClick={() => setIsEdit(!isEdit)}>Save</a>} */}
                </div>
                {showGroupModal && <UpdateUserGroupModal memberName={currentUser?.name} memberId={memberId}
                                                         organizationId={organizationId} closeModal={closeGroupModal}/>}
                {showFieldsModal &&
                    <AssignUserFieldsModal orgComment={orgRole.comment} memberName={currentUser?.name} memberId={memberId}
                                           organizationId={organizationId} closeModal={closeFieldsModal}/>}
                {showValidityModal && <ValidTillModal member={orgRole} closeModal={validTillCloseModal} />}

                <div className="up-twocolumns">
                    <aside className="up-sidebar">
                        <div className="up-widget up-widgetaboutorganisation">
                            <div className="up-widgethead up-textalignleft">
                                <h3>{t("about")}</h3>
                            </div>
                            <div className="up-organisationcontent">
                                <div className="up-description">
                                    <p>{currentUser.bio ?? t('notAvailable')}</p>
                                </div>
                                <ul className="up-organisationinfo">
                                    <li>
                                        <span>{t("email:")}</span>
                                        <span>{currentUser.email}</span>
                                    </li>
                                    <li>
                                        <span>{t("address:")}</span>
                                        <span>{currentUser.address ?? t('notAvailable')}</span>
                                    </li>
                                    {currentUser?.dob && <li>
                                        <span>{t("dob")}</span>
                                        <span>{moment(currentUser?.dob).format("MMM Do YYYY") ?? t('notAvailable')}</span>
                                    </li>}
                                    <li>
                                        <span>{t("memberSince")}</span>
                                        <span>{(currentUser?.createdAt ? moment(currentUser?.createdAt).format("YYYY-MM-DD HH:mm") : t('notAvailable'))}</span>
                                    </li>
                                    <li>
                                        <span>{t("social:")}</span>
                                        <div className="up-socialicons">
                                            {currentUser?.socialLinks && currentUser.socialLinks.map((link: string) => (
                                                <>
                                                    {link && <>
                                                        {link.includes('facebook') &&
                                                            <a className="up-facebooksocial" href={link}
                                                               target="_blank">
                                                                <i className="icon-facebook"></i>
                                                            </a>}
                                                        {link.includes('twitter') &&
                                                            <a className="up-twittersocial" href={link} target="_blank">
                                                                <i className="icon-twitter"></i>
                                                            </a>}
                                                        {link.includes('instagram') &&
                                                            <a className="up-instagramsocial" href={link}
                                                               target="_blank">
                                                                <i className="icon-instagram"></i>
                                                            </a>}
                                                        {link.includes('linkedin') &&
                                                            <a className="up-linkedinsocial" href={link}
                                                               target="_blank">
                                                                <i className="icon-linkedin"></i>
                                                            </a>}
                                                    </>}
                                                </>
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                    <div className="up-profilefollowingarea">
                        {isEdit && <div className="up-editprofilearea">
                            <h3>{t("editProfile")}</h3>
                            <button type="button" className="up-btn" onClick={() => setShowChangePassword(true)}>{t("changePassword")}
                            </button>
                            <div className="up-themebox">
                                <form className="up-formtheme up-formeditprofile" onSubmit={handleSubmit(onSubmit)}>
                                    <fieldset>
                                        <div className="up-editprofileinputs">
                                            <div className="form-group">
                                                <label>{t("email")}</label>
                                                <div className="up-iconcontentholder">
                                                    <span className="up-profileicon">
                                                        <img src={images.UserIconEnvelope} alt="User Icon"/>
                                                        {/* <i>Email</i> */}
                                                    </span>
                                                    <em contentEditable={false}>{currentUser?.email}</em>
                                                    <ul className="up-saveeditbtns">
                                                        <li>
                                                            <button onClick={() => setShowChangeEmail(true)}
                                                                    className="up-editbtnpencil" type="button">
                                                                <i className="icon-pencil"></i>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{t("phone")}</label>
                                                <div className="up-iconcontentholder">
                                                    <span className="up-profileicon">
                                                        <img src={images.UserIconPhone} alt="User Icon"/>
                                                        {/* <i>Phone</i> */}
                                                    </span>
                                                    <em contentEditable={false}>{currentUser?.phone ?? t('notFound')}</em>
                                                    <ul className="up-saveeditbtns">
                                                        <li>
                                                            <button onClick={() => setShowChangePhone(true)}
                                                                    className="up-editbtnpencil" type="button">
                                                                <i className="icon-pencil"></i>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{t("name")}</label>
                                                <div className="up-profileiconinputholder">
                                                    <span className="up-profileicon">
                                                        <img src={images.UserIconProfile} alt="User Icon"/>
                                                    </span>
                                                    <input type="text"
                                                           {...register("name")} name='name' className="form-control"
                                                           placeholder={t("enterName")}/>
                                                    <small id="emailHelp" className="form-text text-muted">
                                                        {errors.name?.message}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{t("username")}</label>
                                                <div className="up-profileiconinputholder">
                                                    <span className="up-profileicon">
                                                        <img src={images.UserIconWeb} alt="User Icon"/>
                                                    </span>
                                                    <input type="text"
                                                           {...register("username")} name='username'
                                                           className="form-control" placeholder={t("enterUsername")}/>
                                                    <small id="emailHelp" className="form-text text-muted">
                                                        {errors.username?.message}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{t("dateOfBirth")}</label>
                                                <div className="up-profileiconinputholder">
                                                    <span className="up-profileicon">
                                                        <img src={images.UserIconCalendar} alt="User Icon"/>
                                                    </span>
                                                    <input type="date"
                                                           {...register("dob")} name='dob' className="form-control"
                                                           placeholder={t("enterDateOfBirth")}/>
                                                    <small id="emailHelp" className="form-text text-muted">
                                                        {errors.dob?.message}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{t("address")}</label>
                                                <div className="up-profileiconinputholder">
                                                    <span className="up-profileicon">
                                                        <img src={images.UserIconLocation} alt="User Icon"/>
                                                    </span>
                                                    <input type="text"
                                                           {...register("address")} name='address'
                                                           className="form-control" placeholder={t("address")}/>
                                                    <small id="emailHelp" className="form-text text-muted">
                                                        {errors.address?.message}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{t("postalCode")}</label>
                                                <div className="up-profileiconinputholder">
                                                    <span className="up-profileicon">
                                                        <img src={images.UserIconPostCode} alt="User Icon"/>
                                                        <i>{t("postalCode")}</i>
                                                    </span>
                                                    <input type="text"
                                                           {...register("postalCode")} name='postalCode'
                                                           className="form-control" placeholder={t("postalCode")}/>
                                                    <small id="emailHelp" className="form-text text-muted">
                                                        {errors.postalCode?.message}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="up-profiledescriptionarea">
                                            <div className="form-group">
                                                <label>{t("about")}</label>
                                                <textarea {...register("bio")} name="bio"
                                                          placeholder={t("writeHere")}></textarea>
                                            </div>
                                        </div>
                                        <div className="up-editprofileinputs up-editsocialmediainouts up-errormessage">
                                            <label>{t("social")}</label>
                                            <div className="form-group">
                                                <div className="up-iconcontentholder">
                                                    <span className="up-profileicon">
                                                        <img src={images.FaceBookIconTwo} alt="Facebook Icon"/>
                                                    </span>
                                                    <input {...register("facebookLink")} type="text" name="facebookLink"
                                                           className="form-control" placeholder={t("facebookURL")}/>
                                                    <small id="emailHelp" className="form-text text-muted">
                                                        {errors.facebookLink?.message}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="up-iconcontentholder">
                                                    <span className="up-profileicon up-instagramicon">
                                                        <img src={images.TwitterIconTwo} alt="Twitter Icon"/>
                                                    </span>
                                                    <input {...register("twitterLink")} type="text" name="twitterLink"
                                                           className="form-control" placeholder={t("twitterURL")}/>
                                                    <small id="emailHelp" className="form-text text-muted">
                                                        {errors.twitterLink?.message}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="up-iconcontentholder">
                                                    <span className="up-profileicon up-instagramicon">
                                                        <img src={images.InstaGramIconTwo} alt="Instagram Icon"/>
                                                    </span>
                                                    <input {...register("instagramLink")} type="text"
                                                           name="instagramLink" className="form-control"
                                                           placeholder={t("instagramURL")}/>
                                                    <small id="emailHelp" className="form-text text-muted">
                                                        {errors.instagramLink?.message}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="up-iconcontentholder">
                                                    <span className="up-profileicon up-instagramicon">
                                                        <img src={images.PinterestIconTwo} alt="Pinterest Icon"/>
                                                    </span>
                                                    <input {...register("linkedinLink")} type="text" name="linkedinLink"
                                                           className="form-control" placeholder={t("linkedInURL")}/>
                                                    <small id="emailHelp" className="form-text text-muted">
                                                        {errors.linkedinLink?.message}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="up-formbuttons">
                                            <button onClick={() => setIsEdit(!isEdit)} type="button"
                                                    className="up-btn up-btnwithloader">{t("cancel")}
                                            </button>
                                            <button type="submit" className="up-btn up-btnwithloader">{t("update")}</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>}
                        {!isEdit && <div className="up-tablearea">
                            <div className="up-tablearea">
                                <h3>{t("organisationGroups")}</h3>
                                <table className="up-filestable table up-groupstable up-tableheading">
                                    <thead>
                                    <tr>
                                        <th>{t("organisationName")}</th>
                                        <th>{t("userGroup")}</th>
                                        <th>{t("userRole")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {userGroups && userGroups.map(group => (
                                        <tr>
                                            <td>
                                                <div className="up-fileiconname up-tableimagecenter">
                                                    <span className="up-fileicon up-bgproject">
                                                        <img src={group?.organization?.avatar ?? images.RestPlaceholder}
                                                             alt="Organisation Icon"/>
                                                    </span>
                                                    <h4><a
                                                        href={`/organization-profile/${group?.organization?._id}`}>{group?.organization?.name} </a>
                                                    </h4>
                                                </div>
                                            </td>
                                            <td>
                                                <ul className="up-organisermetadata">
                                                    <li>
                                                        <h4>{group.name}</h4>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className="up-organisermetadata">
                                                    <li>
                                                        <h4>{getGroupRole(group)}</h4>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                    {userGroups && userGroups.length == 0 && <tr>
                                        <td>{t("noRecordFound")}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <h3>{t("followingOrganisation")}</h3>
                            <table className="up-filestable table up-groupstable up-tableheading">
                                <thead>
                                <tr>
                                    <th>{t("organisationName")}</th>
                                    <th>{t("userRole")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userOrganizations && userOrganizations.map((org: any) => (
                                    <tr>
                                        <td>
                                            <div className="up-fileiconname up-tableimagecenter">
                                                    <span className="up-fileicon up-bgproject">
                                                        <img src={org?.organization?.avatar ?? images.RestPlaceholder}
                                                             alt="Organisation Icon"/>
                                                    </span>
                                                <h4><a
                                                    href={`/organization-profile/${org?.organization?._id}`}>{org?.organization?.name} </a>
                                                </h4>
                                            </div>
                                        </td>
                                        <td>
                                            <ul className="up-organisermetadata">
                                                <li>
                                                    <h4>{org.role.role}</h4>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                                {userOrganizations && userOrganizations.length == 0 && <tr>
                                    <td>{t("noRecordFound")}</td>
                                    <td></td>
                                </tr>}
                                </tbody>
                            </table>
                        </div>}

                    </div>
                </div>
            </div>
            {showChangePassword && <ChangePasswardModal closeModal={closePasswordModal}/>}
            {showChangeEmail && <ChangeEmailModal closeModal={closeEmailModal}/>}
            {showChangePhone && <ChangePhoneModal closeModal={closePhoneModal}/>}
        </AppLayout>
    )
}
