import { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { images } from "../../../../assets/images"
import { organisationService } from "../../../../services/organisation.service"
import { Toast } from "../../../../utils/Toast"
import { IFollowOrganizationRequestStatusIds } from "../../../organisations/createorganisation/interfaces/child-organisation-status-update.interface"
import { IOrganization } from "../../../organisations/createorganisation/interfaces/organisation.interface"
import { IOrganizationRequestedMembers } from "../../../organisations/createorganisation/interfaces/organization-requested-members.interface"
import { getUser } from "../../slice/user.selector"
import { SendFollowRequestModal } from "./FollowRequestModal"
import {useTranslation} from "react-i18next";

export const OgranizationCard: React.FC<IOrganization> = (props) => {

    const [opneModal, setOpenModal] = useState(false);
    const user = useSelector(getUser);
    const { t } = useTranslation('', { keyPrefix: 'FollowOrganizationsPage' });

    const sendFollowRequest = async () => {
        setOpenModal(true);
    }
    const cancelFollowRequest = async () => {
        const data: IFollowOrganizationRequestStatusIds = {
            status: 'REJECTED',
            organizationId: props._id,
            userId: user._id
        }
        const res: any = await organisationService.updateOrganizationFollowStatusIds(data);
        if (res.statusCode == 200) {
            Toast.fire({
                icon: 'success',
                title: res.message
            })
            props.setIsMember('NO', props._id)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const unFollowRequest = async () => {
        const data = {
            organization: props?._id!
        }
        const res: any = await organisationService.unfollowOrganization(data);
        if (res.statusCode == 201) {
            Toast.fire({
                icon: 'success',
                title: res.message
            })
            props.setIsMember('NO', props._id)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const closeModal =(data:IOrganizationRequestedMembers) => {
         setOpenModal(false)
         if (data) {
            props.setIsMember(data.status, props._id)
         }
    }
    return (
        <div className="up-organisercard">
            <figure className="up-organisercoverimg">
                <img src={props.banner ?? images.RestPlaceholder} alt="Organiser Cover Image" />
            </figure>
            <div className="up-organisercardcontent">
                <figure className="up-organiserlogo">
                    <img src={props.avatar} alt="Organiser Logo" />
                </figure>
                <div className="up-organisername">
                    <h3><Link to={`/organization-profile/${props._id}`}>{props.name}</Link></h3>
                    <span>{props.type.name}</span>
                </div>
                <ul className="up-organisermetadata">
                    <li>
                        <h4>{props.postCount ?? 0}</h4>
                        <span>{t('updates')}</span>
                    </li>
                    <li>
                        <h4>{props.members.length}</h4>
                        <span>{t('members')}</span>
                    </li>
                </ul>
                {props.isMember === 'NO' &&
                    <div onClick={sendFollowRequest} className="up-cardtbns">
                        <a href="javascript:void(0);" className="up-btn">{t('follow')}</a>
                    </div>
                }
                {props.isMember === 'ACCEPTED' &&
                    <div onClick={unFollowRequest} className="up-cardtbns">
                        <a href="javascript:void(0);" className="up-btn">{t('unfollow')}</a>
                    </div>
                }
                {props.isMember === 'PENDING' &&
                    <div onClick={cancelFollowRequest} className="up-cardtbns">
                        <a href="javascript:void(0);" className="up-btn up-btnfollowrequestsend">{t('followRequestSent')}</a>
                    </div>
                }
                {opneModal && <SendFollowRequestModal closeModal={closeModal} id={props._id} isEdit={false} />}
            </div>
        </div>
    )
}