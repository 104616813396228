import {yupResolver} from "@hookform/resolvers/yup";
import {S3} from "aws-sdk";
import React, {useEffect, useRef, useState} from "react";
import {Modal} from "react-bootstrap"
import {useForm} from "react-hook-form";
import {images} from "../../../assets/images"
import {AWS_S3_BUCKET, s3} from "../../../config/aws.config";
import {EEmailFrom, EPostCategory} from "../../../interfaces/post-category.interface";
import {groupService} from "../../../services/group.service";
import {postService} from "../../../services/post.service";
import {Toast} from "../../../utils/Toast";
import {IScheduledPostModal} from "../../organisations/createorganisation/interfaces/create-post-modal";
import {ICreatePost} from "../../organisations/createorganisation/interfaces/create-post.interface";
import {EWhichDisplayTo} from "../../organisations/createorganisation/interfaces/display-to-enum";
import {IGroup} from "../../organisations/createorganisation/interfaces/group.interface";
import {
    createPostSchema,
    requestedPostSchema
} from "../../organisations/createorganisation/validations/create-post.validation";
import {CreatePollModal} from "./PollModal";
import {PostDisplayToModal} from "./PostDisplayToModal";
import {requestedPostService} from "../../../services/requested-post.service";
import {useTranslation} from "react-i18next";

export const CreateScheduledPostModal: React.FC<IScheduledPostModal> = (props) => {

    const [isLoading, setIsloading] = useState(false)
    const [isPublicChecked, setIsPublicChecked] = useState(true)
    const [selectedPostImageVideo, setSelectedPostImageVideo] = useState<any>()
    const [selectedRoles, setSelectedRoles] = useState<string[]>([])
    const [selectedGroups, setSelectedGroups] = useState<string[]>([])
    const [selectedPostFile, setSelectedPostFile] = useState<any>()
    const [editPostMedia, setEditPostMedia] = useState<string[]>()
    const [postDescription, setPostDescription] = useState<string>('')
    const [advancedOptionsToggle, setAdvancedOptionsToggle] = useState(false);
    const [displayToModal, setDisplayToModal] = useState(false);
    const [createPostModal, setCreatePostModal] = useState(true);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [openPollModal, setOpenPollModal] = useState(false);
    const [category, setCategory] = useState<EPostCategory>(EPostCategory.DEFAULT);
    const [pollData, setPollData] = useState<any>();
    const [groupsForPostCreation, setGroupsForPostCreation] = useState<IGroup[]>([]);
    useEffect(() => {
        setData();
    }, [])
    const setData = async () => {
        const tempData = await getGroupsForPostCreation();
        if (props.isEditPost) {
            const strippedString = props.selectedEditPost.content.replace(/(<([^>]+)>)/gi, "");
            setPostDescription(strippedString);
            setEditPostMedia(props.selectedEditPost.medias!);
            setValue('content', strippedString);
            if (props.selectedEditPost.scheduledDates && props.selectedEditPost.scheduledDates?.length > 0) {
                setValue('isScheduledPost', true);
            }
            props.selectedEditPost.allowComment == 'NO' ?
                setValue('allowComment', false) :
                setValue('allowComment', true);
            props.selectedEditPost.allowShare == 'NO' ?
                setValue('allowShare', false) :
                setValue('allowShare', true);
            props.selectedEditPost.isPersonalPushNotification == 'NO' ?
                setValue('isPersonalPushNotification', false) :
                setValue('isPersonalPushNotification', true);
            setValue('isPinned', props.selectedEditPost?.isPinned === 'YES');
            setValue('personalPushNotificationMessage', props.selectedEditPost.personalPushNotificationMessage);
            if (props.selectedEditPost?.medias!.length > 0) {
                setIsFileSelected(true)
            } else {
                setIsFileSelected(false)
            }
            if (props.selectedEditPost.sendEmail == 'YES') {
                setValue('sendEmail', true);
            }
            const tempRoles: string[] = []
            for (const iterator of props.selectedEditPost.displayTo) {
                const objIndex = tempData.findIndex((role: any) => role._id == iterator._id)
                if (objIndex != -1) {
                    tempData[objIndex].checked = true;
                    tempRoles.push(iterator);
                }
            }
            setGroupsForPostCreation([...tempData])
            setSelectedGroups(tempRoles);
            setIsPublicChecked(false)
        }
    }
    const imageVideoRef = useRef<HTMLInputElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);
    const {register: postRegister, setValue, handleSubmit: postSubmit, formState: {errors}, reset, watch} = useForm({
        resolver: yupResolver(requestedPostSchema),
        defaultValues: {
            allowComment: true,
            isContributorVisible: true
        }
    });
    const { t } = useTranslation('', { keyPrefix: 'CreateScheduledPostModal' });
    const isPersonalPushNotification = watch('isPersonalPushNotification')
    const createPostSubmit = async (data: ICreatePost, event: any) => {
        data.category = category;
        if (category === EPostCategory.EXTERNAL) {
            let externalURL: any = pollData.url;
            if (externalURL.slice(0, 3) == 'www') {
                externalURL = 'https://' + externalURL;
            }
            data.externalUrl = externalURL;
            data.urlLabel = pollData.label;
        } else if (category === EPostCategory.INTERNAL) {
            data = {...data, ...pollData}
        }
        switch (event.nativeEvent.submitter.name) {
            case 'accept':
                data.requestedStatus = 'YES';
                break;
            case 'reject':
                data.requestedStatus = 'NO';
                break;
            default:
                break;
        }
        data.allowComment = data.allowComment ? 'YES' : 'NO';
        data.allowShare = data.allowShare ? 'YES' : 'NO';
        data.isPinned = data.isPinned ? 'YES' : 'NO';
        data.isPersonalPushNotification = data.isPersonalPushNotification ? 'YES' : 'NO';
        if (data.sendEmail) {
            data.emailFrom = EEmailFrom.NO_REPLY
        }
        data.sendEmail = data.sendEmail ? 'YES' : 'NO';
        data.organization = props.id;
        if (selectedRoles.length > 0) {
            data.displayTo = [...selectedRoles]
            data.eWhichDisplayTo = EWhichDisplayTo.OrganizationRole
        } else if (selectedGroups.length > 0) {
            data.displayTo = [...selectedGroups]
            data.eWhichDisplayTo = EWhichDisplayTo.Group
        } else {
            data.displayTo = []
        }
        const uploadedMedia: string[] = [];
        if (selectedPostImageVideo) {
            for (const item of selectedPostImageVideo) {

                let params: any = {};
                if (item.type.split('/')[0] === 'video') {
                    params = {
                        Body: item,
                        Bucket: AWS_S3_BUCKET!,
                        Key: `${parseInt(
                            (new Date().getTime() / 1000).toFixed(0)
                        )}-postVideo-${item.name}`,
                        ContentType: item.type,
                        ACL: "public-read",
                    }
                } else {
                    params = {
                        Body: item,
                        Bucket: AWS_S3_BUCKET!,
                        Key: `${parseInt(
                            (new Date().getTime() / 1000).toFixed(0)
                        )}-postImage-${item.name}`,
                        ContentType: item.type,
                        ACL: "public-read",
                    }
                }
                const uploadResult: any = await s3.upload(params).promise();
                uploadedMedia.push(uploadResult.Location)
            }
            data.mediaType = 'IMAGE'
        }
        if (selectedPostFile) {
            for (const item of selectedPostFile) {
                const params: S3.PutObjectRequest = {
                    Body: item,
                    Bucket: AWS_S3_BUCKET!,
                    Key: `${parseInt(
                        (new Date().getTime() / 1000).toFixed(0)
                    )}-postFile-${item.name}`,
                    ContentType: item.type,
                    ACL: "public-read",
                };
                const uploadResult: any = await s3.upload(params).promise();
                uploadedMedia.push(uploadResult.Location)
            }
            data.mediaType = 'FILE'
        }
        data.medias = [...uploadedMedia]
        data.isRequested = 'YES';
        data.isContributorVisible = data.isContributorVisible ? 'YES' : 'NO';
        setIsloading(true)
        if (props.isEditPost) {
            data.medias = [...editPostMedia!, ...data.medias]
            data.category = props.selectedEditPost.category;
            if (props.selectedEditPost.category === EPostCategory.EXTERNAL) {
                data.externalUrl = props.selectedEditPost.externalUrl;
                data.urlLabel = props.selectedEditPost.urlLabel;
            } else if (props.selectedEditPost.category === EPostCategory.INTERNAL) {
                data.pollType = props.selectedEditPost.pollType;
                data.pollResultType = props.selectedEditPost.pollResultType;
                data.showPollResults = props.selectedEditPost.showPollResults;
                data.isPollMultiSelect = props.selectedEditPost.isPollMultiSelect;
                data.allowCustomPollOption = props.selectedEditPost.allowCustomPollOption;
                data.pollOptions = props.selectedEditPost.pollOptions;
                data.pollEndsAt = props.selectedEditPost.pollEndsAt;
            }
            if ((props.selectedEditPost?.isRequested == 'YES') || (props.selectedEditPost?.requestedStatus && props.selectedEditPost?.requestedStatus == 'YES')) {
                if (props.selectedEditPost?.requestedStatus) {
                    data.requestedStatus = props.selectedEditPost.requestedStatus
                }
                const res: any = await requestedPostService.updateRequestedPost(props.selectedEditPost?._id!, data)
                setIsloading(false)
                if (res.statusCode == 200) {
                    Toast.fire({
                        icon: 'success',
                        title: res.message
                    })
                    if (props.organizationPosts && props.setOrganizationPosts) {
                        const postIndex = props.organizationPosts!.findIndex(post => post._id == props.selectedEditPost?._id!)
                        const tempPosts = [...props.organizationPosts!]
                        tempPosts.splice(postIndex, 1)
                        props.setOrganizationPosts([...tempPosts])
                    }
                    reset({content: '', allowShare: false, allowComment: false})
                    setCreatePostModal(false);
                    setSelectedRoles([]);
                    setSelectedGroups([]);
                    setSelectedPostImageVideo(null);
                    setSelectedPostFile(null);
                    setPostDescription('');

                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: res.message
                    })
                }
            } else {
                const res: any = await postService.updatePost(data, props.selectedEditPost?._id!)
                setIsloading(false)
                if (res.statusCode == 200) {
                    Toast.fire({
                        icon: 'success',
                        title: res.message
                    })
                    if (props.organizationPosts && props.setOrganizationPosts) {
                        const postIndex = props.organizationPosts!.findIndex(post => post._id == props.selectedEditPost?._id!)
                        const tempPosts = [...props.organizationPosts!]
                        tempPosts.splice(postIndex, 1)
                        tempPosts.unshift(res.payload.post)
                        props.setOrganizationPosts([...tempPosts])
                    }
                    reset({content: '', allowShare: false, allowComment: false})
                    setCreatePostModal(false);
                    setSelectedRoles([]);
                    setSelectedGroups([]);
                    setSelectedPostImageVideo(null);
                    setSelectedPostFile(null);
                    setPostDescription('');

                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: res.message
                    })
                }
            }
        } else {
            const res: any = await postService.createPost(data)
            setIsloading(false)
            if (res.statusCode == 201) {
                Toast.fire({
                    icon: 'success',
                    title: 'Your update request has been sent to your admins'
                })
                const temp: any = []
                if (props.setOrganizationPosts) {
                    if (props.organizationPosts) {
                        props.setOrganizationPosts([...temp, ...props.organizationPosts])
                    } else {
                        props.setOrganizationPosts([...temp])
                    }
                }
                reset({content: '', allowShare: false, allowComment: false})
                setCreatePostModal(false);
                setSelectedRoles([]);
                setSelectedGroups([]);
                setSelectedPostImageVideo(null);
                setSelectedPostFile(null);
                setPostDescription('');
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }
    }
    const showDisplayToModal = () => {
        setDisplayToModal(!displayToModal);
        setCreatePostModal(!createPostModal);
    }
    const showPollModal = () => {
        setOpenPollModal(!openPollModal);
        setCreatePostModal(!createPostModal);
    }
    const closePollModal = (data?: any) => {
        setCreatePostModal(!createPostModal);
        setOpenPollModal(!openPollModal);
        if (data) {
            setPollData(data);
            if (data.type === EPostCategory.EXTERNAL) {
                delete data.type;
                setCategory(EPostCategory.EXTERNAL);
            } else if (data.type === EPostCategory.INTERNAL) {
                delete data.type;
                setCategory(EPostCategory.INTERNAL);
            }
        }
    }
    const publicChangeHandler = (event: any) => {
        if (event.target.checked) {
            setSelectedRoles([]);
            setSelectedGroups([]);
            for (const iterator of groupsForPostCreation) {
                iterator.checked = false;
            }
            setGroupsForPostCreation([...groupsForPostCreation])
        }
        setIsPublicChecked(!isPublicChecked)
    }
    const closeDisplayToModal = () => {
        setDisplayToModal(!displayToModal);
        setCreatePostModal(!createPostModal);
    }
    const handleRoleSelection = (event: any, role: any, index: number, type: string) => {
        role.checked = event.target.checked
        switch (type) {
            case 'ROLE':
                if (event.target.checked) {
                    const temp: string[] = []
                    temp.push(event.target.id)
                    if (selectedRoles) {
                        setSelectedRoles([...selectedRoles, ...temp])
                    } else {
                        setSelectedRoles([...temp])
                    }
                } else {
                    const temp = selectedRoles!.findIndex((role) => role === event.target.id)
                    if (temp != -1) {
                        selectedRoles!.splice(temp, 1)
                        setSelectedRoles([...selectedRoles!])
                    }
                }
                for (const iterator of groupsForPostCreation) {
                    iterator.checked = false;
                }
                setGroupsForPostCreation([...groupsForPostCreation])
                setSelectedGroups([]);
                break;
            case 'GROUP':
                if (event.target.checked) {
                    const temp: string[] = []
                    temp.push(event.target.id)
                    if (selectedGroups) {
                        setSelectedGroups([...selectedGroups, ...temp])
                    } else {
                        setSelectedGroups([...temp])
                    }
                } else {
                    const temp = selectedGroups!.findIndex((role) => role === event.target.id)
                    if (temp != -1) {
                        selectedGroups!.splice(temp, 1)
                        setSelectedGroups([...selectedGroups!])
                    }
                }
                setSelectedRoles([])
                break;
            default:
                break;
        }
    }
    const removeEditMedia = (index: number) => {
        editPostMedia!.splice(index, 1);
        setEditPostMedia([...editPostMedia!])
    }
    const removeSelectedFile = (index: number) => {
        selectedPostFile.splice(index, 1);
        if (selectedPostFile.length == 0) {
            setIsFileSelected(false);
            setSelectedPostFile(undefined)
        } else {
            setSelectedPostFile([...selectedPostFile])
        }
    }
    const removeSelectedImageVideo = (index: number) => {
        selectedPostImageVideo.splice(index, 1);
        if (selectedPostImageVideo.length == 0) {
            setIsFileSelected(false);
            setSelectedPostImageVideo(undefined);
        } else {
            setSelectedPostImageVideo([...selectedPostImageVideo])
        }
    }
    const postFileChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setIsFileSelected(true)
            const tempFiles: any = []
            for (const file of e.target.files) {
                tempFiles.push(file)
            }
            if (selectedPostFile) {
                setSelectedPostFile([...selectedPostFile, ...tempFiles]);
            } else {
                setSelectedPostFile([...tempFiles]);
            }
        }
    };

    const postImageVideoChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setIsFileSelected(true)
            const tempFiles: any = []
            for (const file of e.target.files) {
                tempFiles.push(file)
            }
            if (selectedPostImageVideo) {
                setSelectedPostImageVideo([...selectedPostImageVideo, ...tempFiles]);
            } else {
                setSelectedPostImageVideo([...tempFiles]);
            }
        }
    };
    const onImageVideoClick = () => {
        if (imageVideoRef.current) {
            imageVideoRef.current.click();
        }
    };
    const onFileClick = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    };
    const handleClose = () => {
        props.closeModal(true);
    }
    const getGroupsForPostCreation = async () => {
        const res: any = await groupService.getOrganizationGroups(props.id, 1, ' ', 1000);
        if (res.statusCode == 200) {
            for (const group of res.payload.groups.records) {
                group.childGroups = [];
                group.checked = false;
            }
            if (!props.isEditPost) {
                setGroupsForPostCreation(res.payload.groups.records)
            }
            return res.payload.groups.records
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    return <>  <Modal className="up-thememodal up-modalcreateupdates" show={createPostModal} onHide={handleClose}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{props.isEditPost ? t('createUpdate') : t('createRequest')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formcreateupdates" onSubmit={postSubmit(createPostSubmit)}>
                    <fieldset>
                        <figure className="up-organisationogo">
                            <img
                                src={props?.currentOrganizationAvatar || props?.selectedEditPost?.organization?.avatar || images.RestPlaceholder}
                                alt="Organisation Logo"/>
                        </figure>
                        <div className="form-group">
                            <textarea  {...postRegister("content")} name="content" id='content'
                                       placeholder={t('writeHere')}></textarea>
                            <small id="content" className="form-text text-muted">
                                {errors?.content?.message}
                            </small>
                        </div>
                        <div className="up-selectimagvideoarea">
                            {!isFileSelected && <ul className="up-selectfilearea">
                                <li>
                                    <div className="up-uploadfiles">
                                        <input accept="image/*, video/*" onChange={postImageVideoChange} type="file"
                                               name="select-files" id="up-upload-img-video" multiple/>
                                        <label htmlFor="up-upload-img-video">
                                            <i className="icon-images"/>
                                            <span>{t('photoVideo')}</span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="up-uploadfiles">
                                        <input type="file" name="select-files" onChange={postFileChange}
                                               id="up-all-post-files"
                                               accept=".doc,.docx,.xml,.pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                               multiple/>
                                        <label htmlFor="up-all-post-files">
                                            <i className="icon-file"/>
                                            <span>{t('files')}</span>
                                        </label>
                                    </div>
                                </li>
                                {/* TODO_SAJID */}
                                <li className={`${category !== EPostCategory.DEFAULT ? 'up-allreadycreatedpoll' : ''}`}>
                                    <div className="up-uploadfiles"
                                         onClick={() => props.isEditPost ? '' : showPollModal()}>
                                        <input type="hidden" name="hidden"/>
                                        <label>
                                            <i className="icon-file"/>
                                            <span>{t('pollSurvey')}</span>
                                        </label>
                                    </div>
                                    {/* <div className="up-uploadfiles">
                                        Open Poll Modal
                                    </div> */}
                                </li>
                            </ul>}
                        </div>
                        {(selectedPostImageVideo || props.selectedEditPost?.mediaType?.includes('IMAGE')) &&
                            <>
                                <div className="up-selectmoreimages">
                                    <label onClick={onImageVideoClick}>
                                        <i className="icon-images"/>
                                        <span>{t('photoVideo')}</span>
                                    </label>
                                    <input accept="image/*, video/*" type="file" ref={imageVideoRef}
                                           onChange={postImageVideoChange} style={{display: "none"}} multiple/>
                                </div>
                            </>}
                        {(selectedPostFile || props.selectedEditPost?.mediaType?.includes('FILE')) &&
                            <>
                                <label onClick={onFileClick}>
                                    <i className="icon-images"/>
                                    <span>{t('files')}</span>
                                </label>
                                <input type="file" ref={fileRef} onChange={postFileChange} style={{display: "none"}}
                                       accept=".doc,.docx,.xml,.pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
                            </>}
                        <div className="up-previewimagesarea">
                            {selectedPostImageVideo &&
                                <>
                                    {selectedPostImageVideo.map((item: any, index: number) => {
                                        return <div key={index}>
                                            {item.type.split('/')[0] === 'image' &&
                                                <img src={URL.createObjectURL(item)} alt="Post Image"/>}
                                            {item.type.split('/')[0] === 'video' && <video width="400" controls>
                                                <source src={URL.createObjectURL(item)}/>
                                            </video>}
                                            <button className="up-btndelete"
                                                    onClick={() => removeSelectedImageVideo(index)} type="button">
                                                <i className="icon-bin2"/>
                                            </button>
                                        </div>
                                    })}
                                </>
                            }
                            {selectedPostFile &&
                                <>
                                    {selectedPostFile.map((item: any, index: number) => {
                                        return <div className="up-filepreiview" key={index}>
                                            <i className="icon-file"/>
                                            <span>{item.name}</span>
                                            <button className="up-btndelete" onClick={() => removeSelectedFile(index)}
                                                    type="button">
                                                <i className="icon-bin2"/>
                                            </button>
                                        </div>
                                    })}
                                </>
                            }
                        </div>
                        <div className="up-previewimagesarea">
                            {editPostMedia && editPostMedia!.length > 0 && <>
                                {editPostMedia!.map((media: any, index: number) => {
                                    return <div key={index}>
                                        {media.toString().includes('postVideo') && <video width="400" controls>
                                            <source src={media}/>
                                        </video>}
                                        {media.toString().includes('postImage')! &&
                                            <img src={media} alt="up-postimage"/>
                                        }
                                        {media.toString().includes('postFile')! &&
                                            <div className="up-filepreiview" key={index}>
                                                <i className="icon-file"/>
                                                <button className="up-btndelete" type="button">
                                                    <i className="icon-bin2"/>
                                                </button>
                                            </div>}
                                        <button className="up-btndelete" onClick={() => removeEditMedia(index)}
                                                type="button">
                                            <i className="icon-bin2"/>
                                        </button>
                                    </div>
                                })} </>}
                        </div>
                        <div className="up-displaytoadvanceoption">
                            <div className="up-displayto">
                                <em>{t('displayTo')}</em>
                                <span
                                    onClick={showDisplayToModal}>{(selectedRoles.length > 0 || selectedGroups.length > 0) ? t('custom') : t('public')}<img
                                    src={images.ArrowDownIcon} alt="Arrow Icon"/></span>
                            </div>
                            <button onClick={() => setAdvancedOptionsToggle(!advancedOptionsToggle)}
                                    className="up-btnadvanceoptions" type="button"><span>{t('advanceOption')}</span><img
                                src={images.ArrowDownIcon} alt="Arrow Icon Down"/></button>
                        </div>
                        {advancedOptionsToggle && <div className="up-advanceoptions">
                            {props.isEditPost && <div className="up-advanceoption">
                                <span>{t('updateContribution')} {props.selectedEditPost.creator.name}</span>
                                <div className="up-togglebutton">
                                    <input {...postRegister("isContributorVisible")} type="checkbox"
                                           name="isContributorVisible"
                                           id="up-isContributorVisible"/>
                                    <label htmlFor="up-isContributorVisible"></label>
                                </div>
                            </div>}
                            <div className="up-advanceoption">
                                <span>{t('allowComments')}</span>
                                <div className="up-togglebutton">
                                    <input {...postRegister("allowComment")} type="checkbox" name="allowComment"
                                           id="up-allowcomments"/>
                                    <label htmlFor="up-allowcomments"></label>
                                </div>
                            </div>
                            <div className="up-advanceoption">
                                <span>{t('allowSharing')}</span>
                                <div className="up-togglebutton">
                                    <input {...postRegister("allowShare")} type="checkbox" name="allowShare"
                                           id="up-allowsharing"/>
                                    <label htmlFor="up-allowsharing"></label>
                                </div>
                            </div>
                            <div className="up-advanceoption">
                                <span>{t('pinnedPost')}</span>
                                <div className="up-togglebutton">
                                    <input {...postRegister("isPinned")} type="checkbox" name="isPinned"
                                           id="up-isPinned" disabled={!props.isEditPost}/>
                                    <label htmlFor="up-isPinned"></label>
                                </div>
                            </div>
                            <div className="up-advanceoption">
                                <span>{t('personalPush')}</span>
                                <div className="up-togglebutton">
                                    <input {...postRegister("isPersonalPushNotification")} type="checkbox"
                                           name="isPersonalPushNotification" id="up-isPersonalPushNotification"/>
                                    <label htmlFor="up-isPersonalPushNotification"></label>
                                </div>
                            </div>
                            {isPersonalPushNotification && <div className="full-width form-group">
                                <input {...postRegister("personalPushNotificationMessage")} type="text"
                                       name="personalPushNotificationMessage" className="form-control"
                                       placeholder={t('writeNotificationMessage')}/>
                                <small id="emailHelp" className="form-text text-muted">
                                    {errors?.personalPushNotificationMessage?.message}
                                </small>
                            </div>}
                            <div className="up-advanceoption">
                                <span>{t('notifyUsers')}</span>
                                <div className="up-togglebutton">
                                    <input {...postRegister("sendEmail")} type="checkbox" name="sendEmail"
                                           id="up-sendEmail" disabled={!props.isEditPost}/>
                                    <label htmlFor="up-sendEmail"></label>
                                </div>
                            </div>
                        </div>}
                        {!props.showSelectionButtons && <button disabled={isLoading} type="submit" name="submit"
                                 className="up-btn up-btn-lg up-btn-postcreate">{props.isEditPost ? t('updatePost') : t('sendYourUpdate')} {isLoading &&
                            <div className="lds-dual-ring"></div>}</button>}
                        {props.showSelectionButtons && <> <button disabled={isLoading} type="submit" name="accept"
                                 className="up-btn up-btn-lg up-btn-postcreate">{t('acceptEdit')} {isLoading &&
                            <div className="lds-dual-ring"></div>}</button>
                            <button disabled={isLoading} type="submit" name="reject"
                                    className="up-btn up-btn-lg up-btn-postcreate">{t('reject')} {isLoading &&
                                <div className="lds-dual-ring"></div>}</button></>}
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
        {displayToModal &&
            <PostDisplayToModal hideRoles={true} organizationGroups={groupsForPostCreation} organizationRoles={[]}
                                publicChangeHandler={publicChangeHandler} isPublicChecked={isPublicChecked}
                                closeModal={closeDisplayToModal} handleRoleSelection={handleRoleSelection}/>}
        {openPollModal && <CreatePollModal closeModal={closePollModal}/>}

    </>
}