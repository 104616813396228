// import { styles } from "./"

import { useParams } from "react-router";
import { images } from "../../../../assets/images";
import { IEmailFromUrl } from "../../../../interfaces/id-from-url.interface";
import EmailVerifyForm from "./components/EmailVerifyForm";
import {useTranslation} from "react-i18next";


const VerifyEmail = () => {

  let { email } = useParams<IEmailFromUrl>();
    const { t } = useTranslation('', { keyPrefix: 'VerifyEmail' });
  return (
    <div className="up-loginarea up-signuparea">
      <figure className="up-circlimages">
        <img src={images.LoginCircles} alt="Circles" />
      </figure>
      <div className="up-logincontentarea">
        <h3>{t('secureSocial')} <span>{t('networkIsHere')}</span></h3>
        <div className="up-description">
          <p>{t('description')}</p>
        </div>
      </div>
      <div className="up-loginformholder up-verifyemailpage">
        <div className="up-loginformcontentholder">
          <strong className="up-logo">
            <img src={images.Logo} alt="Logo Image" />
          </strong>
          <div className="up-formHead">
            <h1>{t('verifyEmail')}</h1>
            <span>{t('emailTextOne')}<em>{email}</em> {t('emailTextTwo')} </span>
          </div>
          <EmailVerifyForm email={email} />
        </div>
      </div>
    </div>
  )
};
export default VerifyEmail;