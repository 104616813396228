import moment from "moment"
import React, {useEffect, useState} from "react"
import {useHistory} from "react-router"
import {images} from "../../assets/images"
import {AppLayout} from "../../components/layouts/AppLayout"
import {ENotificationType, EWhich} from "../../interfaces/notification-type.enum"
import {INotification} from "../../interfaces/notification.interface"
import {IPaginationInfo} from "../../interfaces/pagination-info.interface"
import {eventService} from "../../services/events.service"
import {notificationService} from "../../services/notification.service"
import {FullPageLoader} from "../../utils/FullPageLoader"
import {Toast} from "../../utils/Toast"
import {IEvent} from "../organisations/createorganisation/interfaces/event.interface"
import {ShowEventModal} from "../themecomponents/ShowEventModal"
import InfiniteScroll from "react-infinite-scroll-component";
import {postService} from "../../services/post.service";
import {IPost} from "../organisations/createorganisation/interfaces/post.interface";
import {CreateScheduledPostModal} from "../ownorganisation/components/CreateScheduledPostModal";
import {NoActionNeededModal} from "../ownorganisation/components/NoActionNeededModal";
import {useTranslation} from "react-i18next";


export const AllNotificationsPage = () => {

    const [dynamicLoader, setDynamicLoader] = useState(false)
    const [notifications, setNotifications] = useState<INotification[]>([])
    const [paginationInfoNotifications, setPaginationInfoNotifications] = useState<IPaginationInfo>({
        currentPage: 0,
        pages: 0,
        perPage: 0,
        totalRecords: 0
    });
    const [selectedEvent, setSelectedEvent] = useState<IEvent>();
    const [showMemberDetailModal, setShowMemberDetailModal] = useState<boolean>(false);
    const [isLoading, setIsloading] = useState(false)
    const [post, setPost] = useState<IPost>();
    const [alreadyApprovedModal, setAlreadyApprovedModal] = useState(false);
    const [createScheduledPostModal, setCreateScheduledPostModal] = useState(false);
    const { t } = useTranslation('', { keyPrefix: 'AllNotificationsPage' });
    const history = useHistory();

    useEffect(() => {
        getNotifications(1)
    }, [])

    const getNotifications = async (page: number) => {
        setDynamicLoader(true);
        const res: any = await notificationService.getNotifications(page, 20)
        if (res.statusCode == 200) {
            setPaginationInfoNotifications(res.payload.notifications.paginationInfo)
            if (page == 1)
            {
                setNotifications(res.payload.notifications.records);
            }else{
                setNotifications([...notifications,...res.payload.notifications.records]);
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
        setDynamicLoader(false);
    }
    const openOrganization = (noti: INotification, endpoint: string) => {
        switch (endpoint) {
            case 'requested':
                history.push({
                    pathname: `/organization-profile/${noti.kind?._id}`,
                    search: 'requested'
                })
                break;
            case 'members':
                history.push({
                    pathname: `/organization-profile/${noti.kind?._id}`,
                    search: 'members'
                })
                break;

            default:
                break;

        }
    }
    const openEventDetail = async (id: string) => {
        setDynamicLoader(true);
        const res: any = await eventService.getEventDetail(id);
        setDynamicLoader(false);
        res.payload.event.isExpanded = true;
        setSelectedEvent(res.payload.event)
        setShowMemberDetailModal(true)
    }
    const closeEventDetailModal = () => {
        setShowMemberDetailModal(false)
    }
    const closeNoActionModal = () => {
        setAlreadyApprovedModal(false)
    }

    const openConfirmationDialog = async (noti: any) => {
        history.push(`/organization-profile/${noti.kind?._id}`);
        // Swal.fire({
        //     title: 'Accept Invite',
        //     text: "",
        //     icon: 'question',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Accept',
        //     cancelButtonText: 'Reject'
        // }).then(async (result: any) => {
        //     if (result) {
        //         if (result.isConfirmed) {
        //             await updateRequestedFollowStatus('ACCEPTED', noti)
        //         } else {
        //             await updateRequestedFollowStatus('REJECTED', noti)
        //         }
        //     }
        // })
    }

    const getPostId = (meta:any): string => {
        return JSON.parse(meta)?.post
    }
    const getEventId = (meta:any): string => {
        return JSON.parse(meta)?.event
    }

    const getExtraMessage = (meta: any) => {
        const message = JSON.parse(meta)?.personalPushNotificationMessage
        if (message)
        {
            return ': ' + message;
        }
    }

    const getRejectionReason = (meta:any): string => {
        return JSON.parse(meta)?.reason
    }
    const openScheduleModal = async (postId: string) => {
        setIsloading(true)
        const res: any = await postService.getPostById(postId);
        if (res.statusCode == 200) {
            setIsloading(false)
            if (res.payload.post.isRequested === 'YES' && !res.payload.post?.deletedAt) {
                setPost(res.payload.post);
                setCreateScheduledPostModal(true)
            }else if(res.payload.post.isRequested === 'NO' || res.payload.post?.deletedAt){
                setAlreadyApprovedModal(true)
            }
        } else {
            setIsloading(false)
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const handleClose = () => {
        setCreateScheduledPostModal(false)
    }
    return (
        <AppLayout>
            {isLoading && <FullPageLoader/>}
            <div className="up-allnotificationspage">
                <div className="up-themebox">
                    <div className="up-themeboxhead">
                        <h3>{t('allNotification')}</h3>
                    </div>
                    {dynamicLoader && <FullPageLoader />}
                    <div className="up-allnotifications">
                        <div className="up-filebox">
                            <div className="up-tablearea">
                                <InfiniteScroll
                                    dataLength={notifications!.length} //This is important field to render the next data
                                    next={() => getNotifications(paginationInfoNotifications?.currentPage! + 1)}
                                    hasMore={notifications!.length !== paginationInfoNotifications?.totalRecords!}
                                    loader={<h4>Loading...</h4>}
                                    endMessage={
                                        <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                            <b>{t('noNotifications')}</b>
                                        </p>
                                    }>
                                    <table className="up-filestable table up-tablenotifications">
                                        <tbody>
                                        {notifications && notifications.map((noti) => (<tr>
                                            <td>
                                                <figure className="up-fileicon">
                                                    <img src={noti.sender.avatar ?? images.UserPlaceholder} alt="User Image" />
                                                </figure>

                                                {noti.which === EWhich.Organization && <div className="up-fileiconname">
                                                    <h4>{noti?.kind?.name}</h4>
                                                    {noti.type === ENotificationType.POST_REQUEST_REJECTED && <p style={{ cursor: 'pointer' }}>{noti.sender.name} {t('requestRejected')}{getRejectionReason(noti?.metaData) ?  ': ' + getRejectionReason(noti?.metaData) : ''}</p>}
                                                    {noti.type === ENotificationType.NEW_POST_REQUESTED_ADDED && <p onClick={() => openScheduleModal(getPostId(noti?.metaData))} style={{ cursor: 'pointer' }}>{noti.sender.name} {t('requestAnUpdate')}</p>}
                                                    {noti.type === ENotificationType.POST_REQUEST_APPROVED && <p style={{ cursor: 'pointer' }}>{noti.sender.name} {t('requestApproved')}</p>}
                                                    {noti.type === ENotificationType.ORGANIZATION_FOLLOW_REQUEST && <p onClick={() => openOrganization(noti, 'requested')} style={{ cursor: 'pointer' }}>{noti.sender.name} {t('requestToFollow')}</p>}
                                                    {noti.type === ENotificationType.ORGANIZATION_FOLLOW_REQUEST_RESPONSE && <a href={`/organization-profile/${noti.kind?._id}`} style={{ cursor: 'pointer' }}><p>{noti.sender.name} {t('requestAccepted')}</p></a>}
                                                    {noti.type === ENotificationType.ORGANIZATION_FOLLOW_REQUEST_REJECTED && <a href={`/organization-profile/${noti.kind?._id}`} style={{ cursor: 'pointer' }}><p>{noti.sender.name} {t('hasRejectedRequest')} {getRejectionReason(noti?.metaData) ? t('reason') + getRejectionReason(noti?.metaData) : ''}</p></a>}
                                                    {noti.type === ENotificationType.ORGANIZATION_MEMBER_INVITE && <p onClick={() => openConfirmationDialog(noti)} style={{ cursor: 'pointer' }}>{noti.sender.name} {t('invitedYouTo')} {noti.kind.name}.</p>}
                                                    {noti.type === ENotificationType.NEW_POST_ADDED && <a href={`/post-detail/${getPostId(noti?.metaData)}`}> {noti.sender.name} {t('hasPostedNewUpdate')}{noti?.kind?.name} {getExtraMessage(noti?.metaData)}</a>}
                                                    {noti.type === ENotificationType.POST_UPDATED && <a href={`/post-detail/${getPostId(noti?.metaData)}`}>{t('anUpdateIn')}{noti?.kind?.name} {t('hasBeenEdited')}</a>}
                                                    {noti.type === ENotificationType.POST_LIKED && <a href={`/post-detail/${getPostId(noti?.metaData)}`}> {noti?.sender?.name} {t('hasLikedYourUpdate')}{noti?.kind?.name}</a>}
                                                    {noti.type === ENotificationType.SHARE_POST_IN_ORGANIZATION && <a href={`/post-detail/${getPostId(noti?.metaData)}`}> {noti?.sender?.name} {t('hasSharedYourUpdate')}{noti?.kind?.name}</a>}
                                                    {noti.type === ENotificationType.NEW_EVENT_ADDED && <p style={{ cursor: 'pointer' }} onClick={() => openEventDetail(getEventId(noti?.metaData))}>{noti.sender.name} {t('hasAddedEvent')}{noti.kind.name}</p>}
                                                    {noti.type === ENotificationType.EVENT_UPDATED && <p style={{ cursor: 'pointer' }} onClick={() => openEventDetail(getEventId(noti?.metaData))}>{t('anEventIn')} {" " + noti.kind.name + " "} {t('hasBeenEdited')}</p>}
                                                </div>}
                                                {noti.which === EWhich.Post && <div className="up-fileiconname">
                                                    <h4 style={{ cursor: 'pointer' }}>{noti.kind.content.length > 30 ? noti.kind.content.slice(0,30).trim() + '...': noti.kind.content}</h4>
                                                    <div className="up-description" style={{ cursor: 'pointer' }}>
                                                        {noti.type === ENotificationType.COMMENT_ADDED && noti.kind.creator == noti.sender._id && <a href={`/post-detail/${noti.kind?._id}`}><p>{noti.sender.name} {t('commentAddedImportant')}</p></a>}
                                                        {noti.type === ENotificationType.COMMENT_ADDED && noti.kind.creator !== noti.sender._id &&<a href={`/post-detail/${noti.kind?._id}`}><p>{noti.sender.name} {t('commentAdded')}</p></a>}
                                                        {noti.type === ENotificationType.TAGGED_ON_COMMENT && noti.kind.creator !== noti.sender._id &&<a href={`/post-detail/${noti.kind?._id}`}><p>{noti.sender.name} {t('commentTagged')}</p></a>}
                                                    </div></div>}
                                                {noti.which === EWhich.Group && <div className="up-fileiconname">
                                                    <h4 style={{ cursor: 'pointer' }}>{noti.kind.name}</h4>
                                                    <div className="up-description" style={{ cursor: 'pointer' }}>
                                                        {noti.type === ENotificationType.GROUP_MEMBER_ADDED && <p onClick={() => openOrganization(noti, 'members')}>{noti.sender.name} {t('addedMemberGroup')}</p>}
                                                    </div></div>}
                                                {noti.which === EWhich.Event && <div className="up-fileiconname">
                                                    <h4 style={{ cursor: 'pointer' }}>{noti.kind.name}</h4>
                                                    <div className="up-description" style={{ cursor: 'pointer' }}>
                                                        {noti.type === ENotificationType.EVENT_REMAINDER && <p onClick={() => openEventDetail(noti.kind._id)}>{Math.abs(moment(noti.createdAt).diff(moment(noti.kind.startDate), 'hours'))} {t('hoursUntil')} {noti.kind.name} {t('starts')}</p>}
                                                    </div></div>}
                                            </td>
                                            <td>
                                                <span>{moment(noti.createdAt).fromNow()}</span>
                                            </td>
                                        </tr>))}
                                        {showMemberDetailModal && <ShowEventModal editEvent={selectedEvent!} closeModal={closeEventDetailModal} />}
                                        {alreadyApprovedModal && <NoActionNeededModal closeModal={closeNoActionModal} />}
                                        {createScheduledPostModal && <CreateScheduledPostModal id={post?.organization?._id ?? ''} showSelectionButtons={true} selectedEditPost={post!} isEditPost={true} closeModal={handleClose} />}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}
