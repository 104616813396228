import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { IOrganizationRequestedMembers } from "../../features/organisations/createorganisation/interfaces/organization-requested-members.interface";
import { IDashboardStats } from "../../interfaces/dashboard-stats.interface";
import { IIdFromUrl } from "../../interfaces/id-from-url.interface";
import { IPaginationInfo } from "../../interfaces/pagination-info.interface";
import { dashboardService } from "../../services/dashboard.service";
import { FullPageLoader } from "../../utils/FullPageLoader";
import { Toast } from "../../utils/Toast";
import { DashboardLayout } from "../layouts/DashboardLayout";
import { Stat } from "../stats/Stat";
import { User } from "../user/User";
import {useTranslation} from "react-i18next";

export default function Dashboard() {
    const [isLoading, setIsloading] = useState(true);
    const [organizationStats, setOrganizationStats] = useState<IDashboardStats>();
    const [postCount, setMemberPostCount] = useState<IOrganizationRequestedMembers[]>([]);
    const [recentUsers, setRecentUsers] = useState<IOrganizationRequestedMembers[]>([]);
    const [topMembers, setTopMembers] = useState<IOrganizationRequestedMembers[]>([]);
    // const [paginationInfoVisits, setPaginationInfoVisits] = useState<IPaginationInfo>();
    let { id } = useParams<IIdFromUrl>();
    const location = useLocation();
    const [organizationName, setOrganizationName] = useState(location?.search.replace('?', ''));
    const { t } = useTranslation('', { keyPrefix: 'Dashboard' });

    useEffect(() => {
        allCalls()
    }, [])

    const allCalls = async () => {
        setIsloading(true)
        await Promise.all([getOrganizationStats(id), getDashboardMemberPostCount(id, 1), getDashboardMemberRecent(id, 1), getDashboardMemberTop(id, 1)])
        setIsloading(false)
    }

    const getOrganizationStats = async (orgId: string) => {
        const res: any = await dashboardService.getDashboardStats(orgId);
        if (res.statusCode == 200) {
            setOrganizationStats(res.payload)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getDashboardMemberPostCount = async (orgId: string, page: number) => {
        const res: any = await dashboardService.getDashboardMemberPostCount(orgId, page);
        if (res.statusCode == 200) {
            // setPaginationInfoVisits(res.payload.topOrganizationMembers.paginationInfo);
            setMemberPostCount(res.payload.topOrganizationMembers.records)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getDashboardMemberRecent = async (orgId: string, page: number) => {
        const res: any = await dashboardService.getDashboardMemberRecent(orgId, page, ' ');
        if (res.statusCode == 200) {
            // setPaginationInfoVisits(res.payload.organizationMembers.paginationInfo);
            setRecentUsers(res.payload.organizationMembers.records)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getDashboardMemberTop = async (orgId: string, page: number) => {
        const res: any = await dashboardService.getDashboardMemberTop(orgId, page, ' ');
        if (res.statusCode == 200) {
            // setPaginationInfoVisits(res.payload.topOrganizationMembers.paginationInfo);
            setTopMembers(res.payload.topOrganizationMembers.records)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    return (
        <DashboardLayout id={id} orgName={organizationName}>
            {isLoading && <FullPageLoader />}
            <div className="up-dashboard">
                <div className="up-stats">
                    {organizationStats && <Stat id={id} data={organizationStats!} />}
                </div>
                <div className="up-dashboradcontentholder">
                    <div className="up-dashboradcontent">
                        <div className="up-dashboarddata">
                            <div className="up-dashboardsectiontitle">
                                <h3>{t('mostUpdates')}</h3>
                            </div>
                            <ul>
                                <li>
                                    <User data={postCount} />
                                </li>
                            </ul>
                        </div>
                        <div className="up-dashboarddata">
                            <div className="up-dashboardsectiontitle">
                                <h3>{t('recentlyJoinedUsers')}</h3>
                            </div>
                            <ul>
                                <li>
                                    <User data={recentUsers} showDate={true} />
                                </li>
                            </ul>
                        </div>
                        <div className="up-dashboarddata">
                            <div className="up-dashboardsectiontitle">
                                <h3>{t('topUsers')}</h3>
                            </div>
                            <ul>
                                <li>
                                    <User data={topMembers} showActivityPoints={true} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}