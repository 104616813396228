import { useState } from "react";
import { Modal } from "react-bootstrap"
import { IReportModalProps } from "../../organisations/createorganisation/interfaces/report-modal.interface";

export const ReportPostModal: React.FC<IReportModalProps> = (props) =>{
    const [showReportModal, setShowReportModal] = useState(true);
    

    const handleClose =() => {
        props.closeModal();
    }

    return  <Modal className="up-thememodal up-modalreport" show={showReportModal} onHide={handleClose}>
    <Modal.Header className="up-modalheader" closeButton>
        <Modal.Title>Report</Modal.Title>
    </Modal.Header>
    <Modal.Body className="up-modalbody">
        <div className="up-modalcontentholder">
            <form className="up-formtheme up-formreport">
                <fieldset>
                    <legend>Choose reason for report this update</legend>
                    <ul className="up-reportoptions">
                        <li>
                            <div className="up-reportoption">
                                <span>Nudity</span>
                                <div className="up-radio">
                                    <input type="radio" name="report-option" id="up-optionone" />
                                    <label htmlFor="up-optionone"></label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="up-reportoption">
                                <span>Violence</span>
                                <div className="up-radio">
                                    <input type="radio" name="report-option" id="up-optiontwo" />
                                    <label htmlFor="up-optiontwo"></label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="up-reportoption">
                                <span>optionthree</span>
                                <div className="up-radio">
                                    <input type="radio" name="report-option" id="up-optionthree" />
                                    <label htmlFor="up-optionthree"></label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="up-reportoption">
                                <span>False Information</span>
                                <div className="up-radio">
                                    <input type="radio" name="report-option" id="up-optionfour" />
                                    <label htmlFor="up-optionfour"></label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="up-reportoption">
                                <span>Spam</span>
                                <div className="up-radio">
                                    <input type="radio" name="report-option" id="up-optionfive" />
                                    <label htmlFor="up-optionfive"></label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="up-reportoption">
                                <span>Hate Speech</span>
                                <div className="up-radio">
                                    <input type="radio" name="report-option" id="up-optionsix" />
                                    <label htmlFor="up-optionsix"></label>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="up-reportoption">
                                <span>Something Else</span>
                                <div className="up-radio">
                                    <input type="radio" name="report-option" id="up-optionseven" />
                                    <label htmlFor="up-optionseven"></label>
                                </div>
                                <textarea placeholder="Type your reasons"></textarea>
                            </div>
                        </li>
                    </ul>
                </fieldset>
            </form>
        </div>
    </Modal.Body>
    {/* <Modal.Footer className="up-modalfooter">
        <Button  onClick={handleClose}>
        Close
        </Button>
        <Button  onClick={handleClose}>
        Save Changes
        </Button>
    </Modal.Footer> */}
</Modal>
}