import { useForm } from "react-hook-form";
import { ILogInInterface } from "../interface/login.interface";
import { loginSchema } from "../validations/login.validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { HttpService } from "../../../../../services/base.service";
import { getIsLoading, getToken, getUser } from "../../../slice/user.selector";
import { useEffect } from "react";
import { routes } from "../../../../../router/routes";
import { Toast } from "../../../../../utils/Toast";
import { LoginUser } from "../../../slice/auth.thunks";
import { useState } from "react";
import { userService } from "../../../../../services/user.service";
import {useTranslation} from "react-i18next";

export const LoginForm = () => {
    const [isLoading, setIsloading] = useState(false);
    const dispatch = useDispatch();
    let history = useHistory();
    const token = useSelector(getToken);
    const {role} = useSelector(getUser)
    const { t } = useTranslation('', { keyPrefix: 'LoginPage' });

    useEffect(() => {
        if (token) {
            HttpService.setToken(token);
            if(role == "USER"){
                history.push(routes.home);
            }else {
                history.push(routes.adminDashboard);
            }
            
        }
    }, [])
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(loginSchema)
    });

    const onSubmit = async (data: ILogInInterface) => {
        setIsloading(true)
        data.email = data.email.toLowerCase();
        const res: any = await dispatch(LoginUser(data));
        setIsloading(false)
        if (res.payload.statusCode == 200) {
            Toast.fire({
                icon: "success",
                title: 'Welcome back'
            })
            history.push(routes.home);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.payload.message
            })
            if (res.payload.message.includes('verify your email')) {
                const response: any = await userService.resendEmail2FA({
                    email: data.email
                });
                setIsloading(false)
                if (response.statusCode == 200) {
                    history.push(`/verify-email/${data.email}`);
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: response.message
                    })
                }
            }
        }

    };
    return (
        <form className="up-formtheme up-formsignin" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <div className="form-group">
                    <label>{t('emailAddress')}</label>
                    <input type="email"
                        {...register("email")} className="form-control" placeholder={t('enterEmail')} />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.email?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('password')}</label>
                    <a className="up-btnforgotpassword" href="/forgot-password">{t('forgetPassword')}</a>
                    <input type="password"
                        {...register("password")}
                        className="form-control" placeholder={t('enterPassword')} />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.password?.message}
                    </small>
                </div>
                <div className="form-group">
                    <div className="up-remebermearea">
                        <input type="checkbox" name="checkbox" id="up-rememberme" />
                        <label htmlFor="up-rememberme">{t('rememberMe')}</label>
                    </div>
                    <button type="submit" className="up-btn up-btnwithloader">{t('signIn')} {isLoading && <div className="lds-dual-ring"></div>}</button>
                </div>
                <div className="up-alreadyahveaccount">
                    <h2>{t('noAccount')} <a href="/signup">{t('signUp')}</a></h2>
                </div>
            </fieldset>
        </form>
    )
};