import {createAsyncThunk} from "@reduxjs/toolkit";
import {postService} from "../../services/post.service";
import moment from "moment/moment";
import {eventService} from "../../services/events.service";


export const FetchPixeeImages = createAsyncThunk('data/fetchData', async () => {
    const response: any = await postService.getPixeeImages(1);
    return response.payload.screens;
});
export const FetchEventCounts = createAsyncThunk('data/fetchEventCounts', async () => {
    const startOfMonth = moment().clone().startOf('month').subtract(7, 'days').format('MM-DD-YYYY');
    const endOfMonth = moment().clone().endOf('month').add(7, 'days').format('MM-DD-YYYY');
    const res: any = await eventService.getEventCounts(startOfMonth, endOfMonth);
    return res.payload;
});

export const FetchCurrentDayEvents = createAsyncThunk(
    'data/fetchCurrentDayEvents',
    async (date: Date, {getState}) => {
        const response: any = await eventService.getDateEvents(`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`)
        return response.payload
    }
);