import {useState} from "react";
import {Modal} from "react-bootstrap"
import Papa from 'papaparse';
import {Toast} from "../../../utils/Toast";
import {organisationService} from "../../../services/organisation.service";
import {IInviteCode} from "../../../interfaces/invite-code.interface";
import {CSVLink} from "react-csv";
import {breakArray} from "../../../utils/ArrayBreaker";
import {FullPageLoader} from "../../../utils/FullPageLoader";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";

const csvData = [
    ["AssignmentKey", "UserRole", "UserGroups",'ValidTill'],
    ["123", "Follower", "[Give Us Your Feedback]","2023-08-01 04:00"],
    ["45", "Admin", "[working]","2023-08-02 06:00"],
    ["67", "not follower", "[Test]","2023-09-01 02:00"],
    ["17", "following", "[Up2d8,Support Group]","2023-08-14 09:00"],
    ["1245", "Follower", "[final testing]","2023-08-12 12:00"]
];
export const ImportCodeCSVModal: React.FC<any> = (props) => {

    const [isLoading, setIsloading] = useState(false)
    const [selectedCodes, setSelectedCodes] = useState<any>();
    const { t } = useTranslation('', { keyPrefix: 'ImportCodeCSVModal' });

    const closeDisplayToModal = () => {
        props.closeModal(false)
    }
    const parseFile = (file: any) => {
        Papa.parse(file, {
            header: true,
            complete: (results: any) => {
                const codes: IInviteCode[] = [];
                const userRoles: string[] = [];
                let userGroups: string[] = []
                let groups;
                if (results.data.length > 0) {
                    for (const data of results.data) {
                        try {
                            if (data.UserGroups && data.UserRole && data.AssignmentKey) {
                                if (!userRoles.includes(data.UserRole)) {
                                    userRoles.push(data.UserRole);
                                }
                                groups = data.UserGroups.split('[')[1].split(']')[0];
                                if (groups.includes(',')) {
                                    groups = groups.split(',');
                                }
                            } else {
                                throw new Error('Whoops!')
                            }
                        } catch (error) {
                            Toast.fire({
                                icon: 'warning',
                                title: t('fileNotCorrect')
                            })
                            return;
                        }
                        let groupArray: string[] = [];
                        if (typeof groups == 'string') {
                            groupArray = [groups]
                        } else {
                            for (const iterator of groups) {
                                groupArray.push(iterator.trim());
                            }
                        }
                        userGroups = [...userGroups, ...groupArray];
                        const tempObj: IInviteCode = {
                            code: data.AssignmentKey,
                            organizationRole: data.UserRole,
                            groups: groupArray,
                            validTill: moment(data?.ValidTill).format('YYYY-MM-DD hh:mm')
                        }
                        codes.push(tempObj)
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: t('fileNotCorrect')
                    })
                    return
                }
                setSelectedCodes({
                    organization: props.id,
                    csvFileName: file.name,
                    roles: userRoles,
                    groups: userGroups,
                    codes: codes
                })
            }
        });
    };

    const csvChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            parseFile(e.target.files[0]);
        }
    };

    const addCodesSubmit = async () => {
        if (selectedCodes) {
            setIsloading(true);
            try {
                selectedCodes.groups = new Set(selectedCodes.groups);
                selectedCodes.groups = Array.from(selectedCodes.groups.values());
                const result = breakArray(selectedCodes.codes, 500);
                const promisesArray: any[] = [];
                result.map(async (item: any) => {
                    selectedCodes.codes = item;
                    promisesArray.push(organisationService.addOrganizationInviteCodes(selectedCodes));
                })
                await Promise.allSettled(promisesArray);
                setIsloading(false);
                Toast.fire({
                    icon: 'success',
                    title: t('importSuccess')
                })
                props.closeModal(true);
            } catch (error) {
                setIsloading(false);
                Toast.fire({
                    icon: 'warning',
                    title: t('importFailed')
                })
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: t('pleaseSelect')
            })
        }
    };

    return <Modal className="up-thememodal up-invitemembersmodal" show={true} onHide={closeDisplayToModal}>
        <Modal.Body className="up-modalbody">
            {isLoading && <FullPageLoader />}
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-forminvitememners">
                    <fieldset>
                        <h4 style={{ fontWeight: "bold" }}>{t('p1')}</h4>
                        <div>{t('p2')}</div>
                        <div>
                            <p style={{ margin: "30px 0" }}>{t('p3')}</p>
                            <strong>{t('p4')}</strong>
                            <p>{t('p5')}</p>
                            <p style={{ margin: "0 0 30px" }}><strong>{t('p6')}</strong> {t('p7')}</p>
                            <p><strong>{t('p8')}</strong> {t('p9')}</p>
                            <p><strong>{t('p10')}</strong> {t('p11')}</p>
                            <p><strong>{t('p12')}</strong> {t('p13')}</p>
                            <p><strong>{t('p14')}</strong> {t('p15')}</p>
                            <p><strong>{t('p16')} </strong> <CSVLink style={{ textDecoration: 'underline !important', color: '#414141' }} filename={"sample_csv.csv"} data={csvData}>{t('p17')}</CSVLink>
                            </p>
                        </div>
                        {!selectedCodes && <div className="up-addmoreuploadcsv">
                            <div className="up-uploadcsv">
                                <input accept=".csv" onChange={csvChange} type="file" name="file" id="up-uploadcsvfile" />
                                <label style={{ fontWeight: "bold", fontSize: "16px" }} htmlFor="up-uploadcsvfile">{t('p18')}</label>
                            </div>
                        </div>}
                        {selectedCodes && <div className="up-addmoreuploadcsv">
                            <div className="up-uploadcsv">
                                <label style={{ fontWeight: "bold", fontSize: "16px" }}>{selectedCodes.csvFileName}</label>
                            </div>
                        </div>}
                        <div className="up-formbuttonsarea">
                            <button type="button" onClick={() => addCodesSubmit()} className="up-btn">{t('addKeys')}</button>
                            <button type="button" className="up-btnclosmodal" onClick={closeDisplayToModal}>{t('close')}</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}