import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {images} from '../../../assets/images';
import {organisationService} from '../../../services/organisation.service';
import {FullPageLoader} from '../../../utils/FullPageLoader';
import {Toast} from '../../../utils/Toast';
import {IOrganization} from '../../organisations/createorganisation/interfaces/organisation.interface';
import {editOrganizationProfileSchema} from '../../profile/validations/edit-organization-profile.validation';
import Select from "react-select";
import countryList from "react-select-country-list";
import {useTranslation} from "react-i18next";

interface IEditInterface {
    organization: IOrganization;
    setOrganization: (org: any) => void;
}

export const EditOrganization: React.FC<IEditInterface> = (props) => {
    const [isLoading, setIsloading] = useState(false);
    const [value, setSelectedValue] = useState('')
    const { t } = useTranslation('', { keyPrefix: 'EditOrganization' });

    const {register, setValue, handleSubmit, formState: {errors}, clearErrors} = useForm({
        resolver: yupResolver(editOrganizationProfileSchema)
    });
    const options = useMemo(() => countryList().getData(), [])

    useEffect(() => {
        for (let i = 0; i < props?.organization?.socialLinks?.length!; i++) {
            if (props?.organization?.socialLinks[i]?.includes('facebook')!) {
                setValue('facebookLink', props?.organization?.socialLinks[i]!);
            }
            if (props?.organization?.socialLinks[i]?.includes('twitter')!) {
                setValue('twitterLink', props?.organization?.socialLinks[i]!);
            }
            if (props?.organization?.socialLinks[i]?.includes('instagram')!) {
                setValue('instagramLink', props?.organization?.socialLinks[i]!);
            }
            if (props?.organization?.socialLinks[i]?.includes('linkedin')!) {
                setValue('linkedinLink', props?.organization?.socialLinks[i]!);
            }
        }
        setValue('name', props.organization?.name);
        props.organization.city && setValue('city', props.organization.city);
        props.organization.country && setSelectedValue(options.find((item: any) => item.label.toLowerCase() == props.organization.country))
        props.organization.country && setValue('country', props.organization.country);
        setValue('about', props.organization?.about);
        setValue('website', props.organization?.website);
        setValue('email', props.organization?.email);
        setValue('address', props.organization?.address);
        setValue('phoneNumber', props.organization?.phoneNumber);
    }, [])
    const onSubmit = async (data: any) => {
        setIsloading(true)
        data.socialLinks = [data.facebookLink, data.twitterLink, data.instagramLink, data.linkedinLink]
        data.country = data.country?.toLowerCase()
        data.city = data.city?.toLowerCase()
        const res: any = await organisationService.organizationProfileSetup(data, props.organization._id);
        setIsloading(false)
        if (res.statusCode == 200) {
            Toast.fire({
                icon: "success",
                title: t('organizationProfileUpdated')
            })
            props.organization.about = data.about;
            props.organization.name = data.name;
            props.organization.city = data.city;
            props.organization.country = data.country;
            props.organization.email = data.email;
            props.organization.address = data.address;
            props.organization.website = data.website;
            props.organization.phoneNumber = data.phoneNumber;
            props.organization.socialLinks = data.socialLinks;
            props.setOrganization(props.organization)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    };

    const changeHandler = (value: any) => {
        setSelectedValue(value)
        setValue('country', value.label)
        clearErrors('country')
    }
    return <div className="up-editorganisationarea">
        <div className="up-editorganisationtabsarea">
            <div className="up-themeboxhead">
                <h3>{t('editOrganisation')}</h3>
            </div>
            {isLoading && <FullPageLoader/>}
            <div className="up-editogranisationtabs">
                <div className="up-themebox">
                    <form className="up-formtheme up-formeditprofile" onSubmit={handleSubmit(onSubmit)}>
                        <fieldset>
                            <div className="up-editprofileinputs">
                                <div className="form-group">
                                    <label>{t('organizationName')}</label>
                                    <div className="up-iconcontentholder up-padding-top-zero">
                                        <span className="up-profileicon">
                                            <img src={images.Org} alt="User Icon"/>
                                        </span>
                                        <input type="text"
                                               {...register("name")} name='name' className="form-control"
                                               placeholder={t('enterName')}/>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {errors.name?.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t('email')}</label>
                                    <div className="up-iconcontentholder up-padding-top-zero">
                                        <span className="up-profileicon">
                                            <img src={images.UserIconEnvelope} alt="User Icon"/>
                                        </span>
                                        <input type="text"
                                               {...register("email")} name='email' className="form-control"
                                               placeholder={t('enterEmail')}/>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {errors.email?.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t('address')}</label>
                                    <div className="up-profileiconinputholder">
                                        <span className="up-profileicon">
                                            <img src={images.Address} alt="User Icon"/>
                                        </span>
                                        <input type="text"
                                               {...register("address")} name='address' className="form-control"
                                               placeholder={t('enterAddress')}/>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {errors.address?.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t('phoneNumber')}</label>
                                    <div className="up-profileiconinputholder">
                                        <span className="up-profileicon">
                                            <img src={images.UserIconPhone} alt="User Icon"/>
                                        </span>
                                        <input type="text"
                                               {...register("phoneNumber")} name='phoneNumber' className="form-control"
                                               placeholder={t('enterPhoneNumber')}/>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {errors.phoneNumber?.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t('website')}</label>
                                    <div className="up-profileiconinputholder">
                                        <span className="up-profileicon">
                                            <img src={images.Web} alt="User Icon"/>
                                        </span>
                                        <input type="text"
                                               {...register("website")} name='website' className="form-control"
                                               placeholder={t('enterWebsite')}/>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {errors.website?.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t('country')}</label>
                                    <div className="up-profileiconinputholder">
                                        <span className="up-profileicon">
                                            <img src={images.Country} alt="User Icon"/>
                                        </span>
                                        {/*<input type="text"*/}
                                        {/*    {...register("country")} name='country' className="form-control" placeholder="Enter Country" />*/}
                                        <Select {...register("country")} options={options} value={value}
                                                onChange={changeHandler}/>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {errors.country?.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t('city')}</label>
                                    <div className="up-profileiconinputholder">
                                        <span className="up-profileicon">
                                            <img src={images.City} alt="User Icon"/>
                                        </span>
                                        <input type="text"
                                               {...register("city")} name='city' className="form-control"
                                               placeholder={t('enterCity')}/>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {errors.city?.message}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div className="up-profiledescriptionarea">
                                <div className="form-group">
                                    <label>{t('about')}</label>
                                    <textarea {...register("about")} name="about"
                                              placeholder={t('writeHere')}></textarea>
                                </div>
                            </div>
                            <div className="up-editprofileinputs up-editsocialmediainouts up-errormessage">
                                <label>{t('social')}</label>
                                <div className="form-group">
                                    <div className="up-iconcontentholder">
                                        <span className="up-profileicon up-latesticon">
                                            <img src={images.FaceBookIconTwo} alt="Facebook Icon"/>
                                        </span>
                                        <input {...register("facebookLink")} type="text" name="facebookLink"
                                               className="form-control" placeholder={t('facebookURL')}/>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {errors.facebookLink?.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="up-iconcontentholder">
                                        <span className="up-profileicon up-instagramicon up-latesticon">
                                            <img src={images.TwitterIconThree} alt="Twitter Icon"/>
                                        </span>
                                        <input {...register("twitterLink")} type="text" name="twitterLink"
                                               className="form-control" placeholder={t('TwitterURL')}/>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {errors.twitterLink?.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="up-iconcontentholder">
                                        <span className="up-profileicon up-instagramicon up-latesticon">
                                            <img src={images.InstaGramIconTwo} alt="Instagram Icon"/>
                                        </span>
                                        <input {...register("instagramLink")} type="text" name="instagramLink"
                                               className="form-control" placeholder={t('InstagramURL')}/>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {errors.instagramLink?.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="up-iconcontentholder">
                                        <span className="up-profileicon up-instagramicon up-latesticon">
                                            <img src={images.PinterestIconTwo} alt="linkedin Icon"/>
                                        </span>
                                        <input {...register("linkedinLink")} type="text" name="linkedinLink"
                                               className="form-control" placeholder={t('LinkedInURL')}/>
                                        <small id="emailHelp" className="form-text text-muted">
                                            {errors.linkedinLink?.message}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div className="up-formbuttons">
                                <button type="submit" className="up-btn up-btnwithloader">{t('update')}</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
}