import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { IResetPasswordInterface } from "../interfaces/reset-password.interface";
import { resetPasswordSchema } from "../validations/reset-password.validation";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { routes } from "../../../../../router/routes";
import { Toast } from "../../../../../utils/Toast";
import { ResetPasswordThunk } from "../../../slice/auth.thunks";
import { useQuery } from "../../../../../utils/useQuery";
import { useState } from "react";
import {useTranslation} from "react-i18next";

export const ResetPassword = () => {
    const [isLoading, setIsloading] = useState(false);
    const dispatch = useDispatch();
    let history = useHistory();
    let query = useQuery();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(resetPasswordSchema)
    });
    const { t } = useTranslation('', { keyPrefix: 'ResetPassword' });
    const onSubmit = async (data: IResetPasswordInterface) => {
        data.token = query.get("token")!;
        setIsloading(true)
        const res: any = await dispatch(ResetPasswordThunk(data));
        setIsloading(false)
        if (res.payload.statusCode == 200) {
            Toast.fire({
                icon: "success",
                title: res.payload.message
            })
            localStorage.clear();
            history.push(routes.login);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.payload.message
            })
        }
    };
    return (
        <form className="up-formtheme up-formforgotpassword" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <div className="form-group">
                    <label>{t('enterNewPassword')}</label>
                    <input type="password" {...register("password")} name="password" className="form-control" placeholder={t('password')} />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.password?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('confirmNewPassword')}</label>
                    <input type="password" {...register("confirmPassword")} name="confirmPassword" className="form-control" placeholder={t('password')} />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.confirmPassword?.message}
                    </small>
                </div>
                <div className="up-formbuttons">
                    <button type="submit" className="up-btn up-btn-lg up-btnwithloader">{t('confirm')} {isLoading && <div className="lds-dual-ring"></div>}</button>
                </div>
            </fieldset>
        </form>
    )
};
