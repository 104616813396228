import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {ISignUpInterface} from "../interfaces/sign-up.interface";
import {signUpSchema} from "../validations/sign-up.validation";
import {Toast} from "../../../../../utils/Toast";
import {SignupUser} from "../../../slice/auth.thunks";
import {useState} from "react";
import {useTranslation} from "react-i18next";
export const SignUpForm = () => {
    const [isLoading, setIsloading] = useState(false);
    const [dob, setDob] = useState<any>();
    const dispatch = useDispatch();
    let history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(signUpSchema)
    });
    const { t } = useTranslation('', { keyPrefix: 'SignUp' });
    const onSubmit = async (data: ISignUpInterface) => {
        setIsloading(true)
        data.email = data.email.toLowerCase();
        if (dob) {
            data.dob = dob;
        }
        const res: any = await dispatch(SignupUser(data));
        setIsloading(false)
        if (res.payload.statusCode == 201) {
            Toast.fire({
                icon: "info",
                title: "Please check your email for verification code"
            })
            history.push(`/verify-email/${data.email}`);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.payload.message
            })
        }
    };
    return (
        <form className="up-formtheme up-formsignup" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <div className="form-group">
                    <label>{t('yourFullName')}</label>
                    <input type="text" {...register("name")} name="name" className="form-control" placeholder={t('yourFullName')} />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.name?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('emailAddress')}</label>
                    <input type="email" {...register("email")} name="email" className="form-control" placeholder={t('emailAddress')} />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.email?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('username')}</label>
                    <input type="text" {...register("username")} name="username" className="form-control" placeholder={t('username')} />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.username?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('password')}</label>
                    <input type="password" {...register("password")} name="password" className="form-control" placeholder={t('password')} />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.password?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('dateOfBirth')}</label>
                    <input type="date" value={dob} onChange={(e) => setDob(e.target.value)} name="dob" className="form-control" placeholder={t('DOBPlaceholder')} />
                    <small id="emailHelp" className="form-text text-muted">
                        {errors.dob?.message}
                    </small>
                </div>
                <div className="form-group">
                    <div className="up-remebermearea">
                        <input type="checkbox" {...register("termsAndConditions")} name="termsAndConditions" id="up-rememberme" />
                        <label htmlFor="up-rememberme">{t('IAccept')} <a href="/termsandconditions" target="_blank">{t('terms')}</a></label>
                        <small id="emailHelp" className="form-text text-muted">
                            {errors.termsAndConditions?.message}
                        </small>
                    </div>
                    <div className="up-remebermearea" style={{ marginTop: '8px' }}>
                        <input type="checkbox" {...register("grps")} name="grps" id="up-grps" />
                        <label htmlFor="up-grps">{t('IAccept')} <a href="/gdpr-policy" target="_blank"> {t('gdprPrivacyPolicy')}</a></label>
                        <small id="emailHelp" className="form-text text-muted">
                            {errors.grps?.message}
                        </small>
                    </div>
                    <button type="submit" className="up-btn up-btnwithloader">{t('signUp')} {isLoading && <div className="lds-dual-ring"></div>}</button>
                </div>
                <div className="up-alreadyahveaccount">
                    <h2>{t('alreadyHaveAccount')} <a href="/login">{t('logIn')}</a></h2>
                </div>
            </fieldset>
        </form>
    )
};
