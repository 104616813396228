import moment from "moment";
import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { images } from "../../assets/images"
import { AppLayout } from "../../components/layouts/AppLayout"
import { IIdFromUrl } from "../../interfaces/id-from-url.interface";
import { chatService } from "../../services/chat.service";
import { organisationService } from "../../services/organisation.service";
import { userService } from "../../services/user.service";
import { FullPageLoader } from "../../utils/FullPageLoader";
import { Toast } from "../../utils/Toast";
import { EPrivacy } from "../auth/pages/signup/interfaces/drop-down.enums";
import { EChatType } from "../chatmessages/enum/type.enum";
import { UpdateUserGroupModal } from "./components/UpdateUserGroupModal";
import {AssignUserFieldsModal} from "./components/AssignUserFieldsModal";
import {ValidTillModal} from "../ownorganisation/components/ValidTillModal";
import {useTranslation} from "react-i18next";

export const MemberProfilePage = () => {
    const [isLoading, setIsloading] = useState(true);
    const [chatLoader, setChatLoader] = useState(false);
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [showFieldsModal, setShowFieldsModal] = useState(false);
    const [showValidityModal, setShowValidityModal] = useState(false);
    const [orgRole, setOrgRole] = useState<any>();
    const [user, setUser] = useState<any>();
    const [sharedOrganizations, setSharedOrganization] = useState<any[]>();
    const [sharedGroups, setSharedGroups] = useState<any[]>();
    const [memberIds, setMemberIds] = useState<string[]>([]);
    let { id } = useParams<IIdFromUrl>();
    const history = useHistory();
    const location = useLocation();
    const [organizationId] = useState(location?.search?.replace('?', '').split('&')[0]);
    const [memberId] = useState(location?.search?.replace('?', '').split('&')[1]);
    const { t } = useTranslation('', { keyPrefix: 'MemberProfilePage' });

    useEffect(() => {
        getUserProfile(id);
        if (memberId)
        {
            getUserOrganizationComment(memberId)
        }
        // getUserOrganizations()
    }, [])

    const getUserProfile = async (id: string) => {
        setIsloading(true)
        const res: any = await userService.getUserProfile(id);
        setIsloading(false)
        if (res.statusCode == 200) {
            setSharedOrganization(res.payload.organizations)
            const remp = [];
            for (const organization of res.payload.organizations) {
                remp.push(organization._id)
            }
            setMemberIds(remp)
            setUser(res.payload.user);
            setSharedGroups(res.payload.groups);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getUserOrganizations = async () => {
        setIsloading(true)
        const res: any = await organisationService.getUserOrganizations(id)
        setIsloading(false)
        if (res.statusCode === 200) {
            setSharedOrganization(res.payload.organizations)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getUserOrganizationComment = async (sample_id: string) => {
        setIsloading(true)
        const res: any = await organisationService.getUserOrganizationComment(sample_id)
        setIsloading(false)
        if (res.statusCode === 200) {
            setOrgRole(res?.payload?.member)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const chatNowHandler = async (userId: string) => {
        try {
            setChatLoader(true);
            const chat = await chatService.checkIndividualChat(userId);
            history.push(`/all-chat?tab=${EChatType.ONE_TO_ONE}&active=${chat.payload.chat._id}`)
        } catch (err) {
            setChatLoader(false);
            Toast.fire({
                icon: 'warning',
                title: 'Something went wrong.'
            })
        }
    }

    const closeGroupModal = () => {
        setShowGroupModal(false);
    }
    const closeFieldsModal = () => {
        setShowFieldsModal(false);
    }

    const getGroupRole = (group: any) => {
        for (let i = 0; i < memberIds?.length; i++) {
            if (group.admins.includes(memberIds[i]))
            {
                return  'Admin'
            }
            if (group.members.includes(memberIds[i])){
                return  'Follower'
            }
        }
    }
    const validTillCloseModal = (data?: string) => {
        setShowValidityModal(false);
        if (data)
        {
            setOrgRole({...orgRole, validTill: moment(data)})
        }
    }
    return (
        <AppLayout>
            {/* <div>Member Profile</div> */}
            {isLoading && <FullPageLoader />}
            <div className="up-ownorganisationprofilepage up-myprofilepage">
                <div className="up-themebanner">
                    <figure className="up-bannerimg">
                        <img src={user?.banner ?? images.RestPlaceholder} alt="Profile Banner Image" />
                    </figure>
                </div>
                <div className="up-bannerbottomarea">
                    <div className="up-profilenameimage">
                        <figure className="up-organisationprofileimg">
                            <img src={user?.avatar ?? images.UserPlaceholder} alt="Organisation Profile Image" />
                        </figure>
                        <div className="up-organisationname">
                            <h3>{user?.name}</h3>
                            <span>{"@" + user?.username}</span>
                        </div>
                    </div>
                    <div className="up-memberprofilright">
                        <ul className="up-socialmedias">
                            {user?.socialLinks && user.socialLinks.map((link: string) => (
                                <>
                                    {link && <>
                                        {link.includes('facebook') && <li>
                                            <a href={link} target="_blank" className="up-btnsocialmedia up-btnfacebook">
                                                <i className="icon-facebook"></i>
                                            </a>
                                        </li>}
                                        {link.includes('twitter') && <li>
                                            <a href={link} target="_blank" className="up-btnsocialmedia up-btntwitter">
                                                <i className="icon-twitter"></i>
                                            </a>
                                        </li>}
                                        {link.includes('instagram') && <li>
                                            <a href={link} target="_blank" className="up-btnsocialmedia up-btninstagram">
                                                <i className="icon-instagram"></i>
                                            </a>
                                        </li>}
                                        {link.includes('linkedin') && <li>
                                            <a href={link} target="_blank" className="up-btnsocialmedia up-btnlinkedin">
                                                <i className="icon-linkedin"></i>
                                            </a>
                                        </li>}
                                    </>}
                                </>
                            ))}




                        </ul>
                        {organizationId && <button type="button" className="up-btn" style={{ marginRight: '8px' }} onClick={() => setShowValidityModal(true)}>{t('validity')}</button>}
                        {organizationId && <button type="button" className="up-btn" style={{ marginRight: '8px' }} onClick={() => setShowFieldsModal(true)}>{t('assignUserFields')}</button>}
                        {organizationId && <button type="button" className="up-btn" style={{ marginRight: '8px' }} onClick={() => setShowGroupModal(true)}>{t('assignUserGroups')}</button>}
                        {user?.userCanChat === EPrivacy.EVERY_ONE &&
                            <button type="button" className="up-btn" disabled={chatLoader} onClick={() => chatNowHandler(user._id)}>Chat Now {chatLoader && <div className="lds-dual-ring"></div>}</button>
                        }
                        {showGroupModal && <UpdateUserGroupModal memberName={user?.name} memberId={memberId} organizationId={organizationId} closeModal={closeGroupModal} />}
                        {showFieldsModal && <AssignUserFieldsModal orgComment={orgRole?.comment} memberName={user?.name} memberId={memberId} organizationId={organizationId} closeModal={closeFieldsModal} />}
                        {showValidityModal && <ValidTillModal member={orgRole} closeModal={validTillCloseModal} />}
                    </div>
                </div>
                <div className="up-twocolumns">
                <aside className="up-sidebar">
                        <div className="up-widget up-widgetaboutorganisation">
                            <div className="up-widgethead up-textalignleft">
                                <h3>{t('about')}</h3>
                            </div>
                            <div className="up-organisationcontent">
                                <div className="up-description">
                                    <p>{user?.bio ?? t('notAvailable')}</p>
                                </div>
                                <ul className="up-organisationinfo">
                                    <li>
                                        <span>{t('email')}</span>
                                        <span>{user?.emailPrivacy === EPrivacy.ONLY_ME ? t('notAvailable') : user?.email}</span>
                                    </li>
                                    <li>
                                        <span>{t('address')}</span>
                                        <span>{user?.addressPrivacy === EPrivacy.ONLY_ME ? t('notAvailable') : user?.address}</span>
                                    </li>
                                    <li>
                                        <span>{t('dob')}</span>
                                        <span>{user?.dobPrivacy === EPrivacy.ONLY_ME ? t('notAvailable') : (user?.dob ? moment(user?.dob).format("MMM Do YYYY") : t('notAvailable'))}</span>
                                    </li>
                                    <li>
                                        <span>{t('memberSince')}</span>
                                        <span>{(user?.createdAt ? moment(user?.createdAt).format("YYYY-MM-DD HH:mm") : t('notAvailable'))}</span>
                                    </li>
                                    <li>
                                        <span>{t('lastVisit')}</span>
                                        <span>{(user?.appActivityAt ? moment(user?.appActivityAt).format("YYYY-MM-DD HH:mm") : t('notAvailable'))}</span>
                                    </li>
                                    <li>
                                        <span>{t('social')}</span>
                                        <div className="up-socialicons">
                                            {user?.socialLinks && user.socialLinks.map((link: string) => (
                                                <>
                                                    {link && <>
                                                        {link.includes('facebook') && <a className="up-facebooksocial" href={link} target="_blank">
                                                            <i className="icon-facebook"></i>
                                                        </a>}
                                                        {link.includes('twitter') && <a className="up-twittersocial" href={link} target="_blank">
                                                            <i className="icon-twitter"></i>
                                                        </a>}
                                                        {link.includes('instagram') && <a className="up-instagramsocial" href={link} target="_blank">
                                                            <i className="icon-instagram"></i>
                                                        </a>}
                                                        {link.includes('linkedin') && <a className="up-linkedinsocial" href={link} target="_blank">
                                                            <i className="icon-linkedin"></i>
                                                        </a>}
                                                    </>}
                                                </>
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                    <div className="up-profilefollowingarea">
                        <div className="up-tablearea">
                            <h3>{t('sharedOrganisation')}</h3>
                            <table className="up-filestable table up-groupstable up-tableheading">
                                <thead>
                                    <tr>
                                        <th>{t('organisationName')}</th>
                                        <th>{t('userRole')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sharedOrganizations && sharedOrganizations.map(org => (
                                        <tr>
                                            <td>
                                                <div className="up-fileiconname up-tableimagecenter">
                                                    <span className="up-fileicon up-bgproject">
                                                        <img src={org?.organization?.avatar ?? images.RestPlaceholder} alt="Organisation Icon" />
                                                    </span>
                                                    <h4><a href={`/organization-profile/${org?.organization?._id}`}>{org?.organization?.name} </a></h4>
                                                </div>
                                            </td>
                                            <td>
                                                <ul className="up-organisermetadata">
                                                    <li>
                                                        <h4>{org.role.role}</h4>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                    {sharedOrganizations && sharedOrganizations.length == 0 && <tr>
                                        <td>{t('noRecordFound')}</td><td></td> </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="up-profilefollowingarea">
                        <div className="up-tablearea">
                            <h3>{t('organisationGroups')}</h3>
                            <table className="up-filestable table up-groupstable up-tableheading">
                                <thead>
                                    <tr>
                                        <th>{t('organisationName')}</th>
                                        <th>{t('userGroup')}</th>
                                        <th>{t('userRole')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sharedGroups && sharedGroups.map(group => (
                                        <tr>
                                            <td>
                                                <div className="up-fileiconname up-tableimagecenter">
                                                    <span className="up-fileicon up-bgproject">
                                                        <img src={group?.organization?.avatar ?? images.RestPlaceholder} alt="Organisation Icon" />
                                                    </span>
                                                    <h4><a href={`/organization-profile/${group?.organization?._id}`}>{group?.organization?.name} </a></h4>
                                                </div>
                                            </td>
                                            <td>
                                                <ul className="up-organisermetadata">
                                                    <li>
                                                        <h4>{group.name}</h4>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className="up-organisermetadata">
                                                    <li>
                                                        <h4>{getGroupRole(group)}</h4>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                    {sharedGroups && sharedGroups.length == 0 && <tr>
                                        <td>{t('noRecordFound')}</td> <td></td> <td></td> </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                   
                </div>
            </div>
        </AppLayout>
    )
}