import React from "react";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";

export const ChangeLanguageModal: React.FC<any> = (props) => {
    const {t, i18n} = useTranslation('', {keyPrefix: 'NavBar'});

    const handleClose = () => {
        props.closeModal()
    }
    const changeLanguage = (e: any) => {
        i18n.changeLanguage(e.target.value);
    }
    return <Modal
        className="up-thememodal up-modalreport"
        show={true}
        onHide={handleClose}
    >
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('changeLanguage')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formreport">
                    <fieldset>
                        <ul className="up-reportoptions">
                            <li>
                                <div className="up-reportoption">
                                    <span>English</span>
                                    <div className="up-radio">
                                        <input
                                            type="radio"
                                            name="report-option"
                                            id="up-optionone"
                                            value="en"
                                            checked={i18n.language == 'en' || i18n.language == 'en-US'}
                                            onChange={changeLanguage}
                                        />
                                        <label htmlFor="up-optionone"></label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="up-reportoption">
                                    <span>Dutch</span>
                                    <div className="up-radio">
                                        <input
                                            type="radio"
                                            name="report-option"
                                            id="up-optiontwo"
                                            value="nl"
                                            checked={i18n.language == 'nl'}
                                            onChange={changeLanguage}
                                        />
                                        <label htmlFor="up-optiontwo"></label>
                                    </div>
                                </div>
                            </li>
                            {/*<li>*/}
                            {/*    <div className="up-reportoption">*/}
                            {/*        <span>French</span>*/}
                            {/*        <div className="up-radio">*/}
                            {/*            <input*/}
                            {/*                type="radio"*/}
                            {/*                name="report-option"*/}
                            {/*                id="up-optionthree"*/}
                            {/*            />*/}
                            {/*            <label htmlFor="up-optionthree"></label>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                        </ul>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}