import { IResponseInterface } from "../interfaces/api-response.interface";
import { HttpService } from "./base.service";

class RequestedPostService extends HttpService {

    getRequestedPosts = (id: string, page: number): Promise<IResponseInterface<{ }>> => this.get(`post/requested/${id}?page=${page}&limit=10`)
    // createSchedulePost = (data: any): Promise<IResponseInterface<{ }>> => this.post(`${this.prefix}`, data)

    updateRequestedPost = (id: string, data: any): Promise<IResponseInterface<{ }>> => this.put(`post/${id}/requested`, data)

    // deleteSchedulePost = (id: string): Promise<IResponseInterface<{ }>> => this.delete(`${this.prefix}/${id}`)

}
export const requestedPostService = new RequestedPostService();