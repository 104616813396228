// import { styles } from "./"

import { images } from "../../../../assets/images";
import { ProfileSetupForm } from "./components/ProfileSetupForm";
import {useTranslation} from "react-i18next";


export const ProfileSetupPage = () => {
  const { t } = useTranslation('', { keyPrefix: 'ProfileSetupPage' });
  return (
    <div className="up-signupsteptwo">
      <div className="up-logincontentarea">
        <div className="up-singupsteptwocontent">
          <h3>{t('secured')} <span>{t('communication')}</span></h3>
          <ul className="up-bulletpoints">
            <li><span>{t('groupCommunication')}</span></li>
            <li><span>{t('securedNetwork')}</span></li>
            <li><span>{t('unlimitedOrganisations')}</span></li>
            <li><span>{t('displayRules')}</span></li>
            <li><span>{t('createUnlimitedUserGroups')}</span></li>
            <li><span>{t('individualMessagingChat')}</span></li>
          </ul>
          <figure className="up-manvectorimage">
            <img src={images.ManVectoImage} alt="Vector Image"/>
          </figure>
        </div>
      </div>
      <div className="up-loginformholder">
        <div className="up-loginformcontentholder">
          <strong className="up-logo">
            <img src={images.Logo} alt="Logo Image"/>
          </strong>
          <ProfileSetupForm/>
        </div>
      </div>
    </div>
  )
};
