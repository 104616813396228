import {AppLayout} from "../../components/layouts/AppLayout";
import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {userService} from "../../services/user.service";
import {Toast} from "../../utils/Toast";
import {IPost} from "../organisations/createorganisation/interfaces/post.interface";
import InfiniteScroll from "react-infinite-scroll-component";
import {OrganizationPost} from "../../components/Post/Post";
import {CreatePostModall} from "../ownorganisation/components/CreatePostModal";
import {SharePostOrganizationModal} from "../ownorganisation/components/SharePostModal";
import {SharePostInUserGroupModal} from "../ownorganisation/components/SharePostInUserGroupsModal";
import {IPaginationInfo} from "../../interfaces/pagination-info.interface";
import {FullPageLoader} from "../../utils/FullPageLoader";
import {organisationService} from "../../services/organisation.service";
import {groupService} from "../../services/group.service";
import Swal from "sweetalert2";
import {postService} from "../../services/post.service";
import {IGroup} from "../organisations/createorganisation/interfaces/group.interface";
import {IOrganization} from "../organisations/createorganisation/interfaces/organisation.interface";
import {useSelector} from "react-redux";
import {getUser} from "../auth/slice/user.selector";
import {ReportPostModal} from "../ownorganisation/components/ReportPostModal";
import { Badge, Chip } from "@material-ui/core";


export const PostSearchPage = () => {
    const [search, setSearch] = useState('')
    const [updates, setUpdates] = useState<IPost[]>([])
    const [paginationInfoPosts, setPaginationInfoPosts] = useState<IPaginationInfo>();
    const [isLoading, setIsLoading] = useState(true);
    const [sharePostModal, setSharePostModal] = useState(false);
    const [sharePostInUserGroupsModal, setSharePostInUserGroupsModal] = useState(false);
    const [postToBeSharedInOrganization, setPostToBeSharedInOrganization] = useState<IPost>();
    const [userGroups, setUserGroups] = useState<IGroup[]>([]);
    const [postToBeShared, setPostToBeShared] = useState<IPost>();
    const [selectedEditPost, setSelectedEditPost] = useState<IPost>();
    const [isEditPost, setIsEditPost] = useState(false);
    const [showReportPostModal, setShowReportPostModal] = useState(false)
    const [createPostModal, setCreatePostModal] = useState(false);
    const [userOrganizations, setUserOrganization] = useState<IOrganization[]>();
    const currentUser = useSelector(getUser);
    const location = useLocation();
    let history = useHistory();

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.get('search')) {
                setSearch(query.get('search') ?? '')
                getSearchedUpdates(1, query.get('search') ?? '')
            }
        }
    }, [location]);

    const getSearchedUpdates = async (page: number, inputValue?: string) => {
        setIsLoading(true);
        const res: any = await userService.globalUpdateSearch(page, inputValue ?? search)
        setIsLoading(false);
        if (res.statusCode == 200) {
            if (inputValue) {
                setUpdates([...res.payload.posts.records])
            } else {
                setUpdates([...updates, ...res.payload.posts.records])
            }
            setPaginationInfoPosts(res.payload.posts.paginationInfo)

        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const editPost = (selectedPost: IPost) => {
        setSelectedEditPost(selectedPost);
        setIsEditPost(true);
        setCreatePostModal(true);
    }
    const sharePost = async (selectedPost: IPost) => {
        setPostToBeShared(selectedPost);
        const res: any = await organisationService.getUserOrganizations(currentUser._id)
        if (res.statusCode === 200) {
            const temp = [{_id: 0, name: 'Select Organisation.'}, ...res.payload.organizations]
            setUserOrganization(temp)
            setSharePostModal(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const shareInUserGroupsPost = async (selectedPost: IPost) => {
        setPostToBeSharedInOrganization(selectedPost);
        const res: any = await groupService.getUserGroups(1, '', selectedPost.organization?._id)
        if (res.statusCode === 200) {
            const temp = [{_id: 0, name: 'Select User Group'}, ...res.payload.groups.records]
            setUserGroups(temp)
            setSharePostInUserGroupsModal(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const deletePost = async (selectedPost: IPost) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await postService.deletePost(selectedPost._id);
                    if (res.statusCode == 200) {
                        const tempIndex = updates!.findIndex((post: any) => post._id === selectedPost._id)
                        updates!.splice(tempIndex, 1);
                        setUpdates([...updates!])
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }
    const closeSharePostModal = (data: boolean) => {
        setSharePostModal(false);
        setSharePostInUserGroupsModal(false);
    }
    const handleClosePostModal = () => {
        setCreatePostModal(false);
        setIsEditPost(false);
        setSelectedEditPost(undefined)
    }
    const setPosts = (data: IPost[]) => {
        setUpdates(data);
        setCreatePostModal(false);
    }
    const reportPost = (selectedPost: IPost) => {
        setSelectedEditPost(selectedPost);
        setShowReportPostModal(true);
    }
    const setLikePost = (postId: string, userId: string, status: string) => {

        const postIndex = updates?.findIndex((post) => post._id === postId);
        if (postIndex !== -1) {
            if (status === 'YES') {
                updates[postIndex].likes.push(currentUser._id);
            } else {
                const index = updates[postIndex].likes.findIndex((lik) => lik === userId);
                if (index !== -1) {
                    updates[postIndex].likes.splice(index, 1);
                }
            }
            setUpdates([...updates])
        }
    }
    const closeReportPostModal = () => {
        setShowReportPostModal(false);
    }
    return <AppLayout>
        {isLoading && <FullPageLoader/>}

        <div className="up-postsarea">
        <Badge badgeContent={"x"} className="mb-3" color="primary" onClick={() => history.push("/home")}>
            <Chip color="default" label={search} />
        </Badge>
        


            
            {updates && <InfiniteScroll
                dataLength={updates!.length} //This is important field to render the next data
                next={() => getSearchedUpdates(paginationInfoPosts?.currentPage! + 1)}
                hasMore={updates!.length !== paginationInfoPosts?.totalRecords!}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p className="up-allpostseen" style={{textAlign: 'center'}}>
                        <b>No more posts</b>
                    </p>
                }>
                {updates && updates.map((item, index) => {
                    return <OrganizationPost fromAdmin={false} public={false} fromDashboard={true}
                                             setLikePost={setLikePost} reportPost={reportPost} fromTimeline={true}
                                             key={item._id + index} post={item} showInOrganization={false}
                                             setEditPost={editPost} setShareOrganizationPost={sharePost}
                                             setShareInUserGroupsPost={shareInUserGroupsPost}
                                             setDeletePost={deletePost}/>
                })}
            </InfiniteScroll>}
            {createPostModal && <CreatePostModall id={selectedEditPost?.organization?._id!} organizationPosts={updates!}
                                                  selectedEditPost={selectedEditPost!} isEditPost={isEditPost}
                                                  setOrganizationPosts={setPosts} closeModal={handleClosePostModal}
                                                  organizationRoles={[]}/>}
            {showReportPostModal && <ReportPostModal closeModal={closeReportPostModal}/>}
            {sharePostModal &&
                <SharePostOrganizationModal postToBeShared={postToBeShared!} userOrganizations={userOrganizations!}
                                            closeModal={closeSharePostModal}/>}
            {sharePostInUserGroupsModal &&
                <SharePostInUserGroupModal postToBeShared={postToBeSharedInOrganization!} userGroups={userGroups!}
                                           closeModal={closeSharePostModal}/>}
        </div>

    </AppLayout>
}
