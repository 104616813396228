import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { IIdFromUrl } from "../../../../interfaces/id-from-url.interface";
import { routes } from "../../../../router/routes";
import { Toast } from "../../../../utils/Toast";
import { OrganisationProfileSetup } from "../../slice/organisation.thunks";
import { IOrganisationSetup } from "../interfaces/organisation-setup.interface";
import { organisationSocialLinksSchema } from "../validations/organisation-social-links.validation";
import {useTranslation} from "react-i18next";

export const OrganisationSocialLinksForm = () => {
    const [isLoading, setIsloading] = useState(false);
    const dispatch = useDispatch();
    let history = useHistory();
    let { id } = useParams<IIdFromUrl>();
    const { t } = useTranslation('', { keyPrefix: 'OrganisationSocialLinksForm' });
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(organisationSocialLinksSchema)
    });

    const onSubmit = async (data: IOrganisationSetup) => {
        setIsloading(true)
        data.id = id;
        let temp: string[] = [];
        if (data.facebookLink) {
            temp.push(data.facebookLink);
            delete data.facebookLink;
        } else {
            delete data.facebookLink;
        }
        if (data.instagramLink) {
            temp.push(data.instagramLink);
            delete data.instagramLink;
        } else {
            delete data.instagramLink;
        }
        if (data.linkedInLink) {
            temp.push(data.linkedInLink);
            delete data.linkedInLink;
        } else {
            delete data.linkedInLink;
        }
        if (data.twitterLink) {
            temp.push(data.twitterLink);
            delete data.twitterLink;
        } else {
            delete data.twitterLink;
        }
        data.socialLinks = [...temp]
        const res: any = await dispatch(OrganisationProfileSetup(data));
        setIsloading(false);
        if (res.payload.statusCode == 200) {
            Toast.fire({
                icon: "success",
                title: res.payload.message
            })
            history.push(routes.home);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.payload.message
            })
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="up-formtheme up-createorganisationformthree">
            <fieldset>
                <div className="form-group">
                    <label>{t('website')}</label>
                    <input {...register("website")} name="website" className="form-control" placeholder="https://www.up2d8.com" />
                    <small className="form-text text-muted">
                        {errors.website?.message}
                    </small>
                </div>
                <div className="form-group">
                    <label>{t('socialLinks')}</label>
                    <div className="up-socialiconsholder">
                        <div className="up-socialicon">
                            <i className="icon-facebook"></i>
                            <input {...register("facebookLink")} type="text" name="facebookLink" className="form-control" placeholder="https://www.facebook.com/up2d8" />
                            <small className="form-text text-muted">
                                {errors.facebookLink?.message}
                            </small>
                        </div>
                        <div className="up-socialicon">
                            <i className="icon-instagram"></i>
                            <input {...register("instagramLink")} type="text" name="instagramLink" className="form-control" placeholder="https://www.instagram.com/up2d8" />
                            <small className="form-text text-muted">
                                {errors.instagramLink?.message}
                            </small>
                        </div>
                        <div className="up-socialicon">
                            {/* <i className="icon-twitter"></i> */}
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                                    <path fill="#ffffff" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                            </i>
                            <input {...register("twitterLink")} type="text" name="twitterLink" className="form-control" placeholder="https://www.twitter.com/up2d8" />
                            <small className="form-text text-muted">
                                {errors.twitterLink?.message}
                            </small>
                        </div>
                        <div className="up-socialicon">
                            <i className="icon-linkedin2"></i>
                            <input {...register("linkedInLink")} type="text" name="linkedInLink" className="form-control" placeholder="https://www.linkedin.com/up2d8" />
                            <small className="form-text text-muted">
                                {errors.linkedInLink?.message}
                            </small>
                        </div>
                    </div>
                </div>

                <div className="up-formbuttons">
                    <button type="submit" className="up-btn up-btn-lg up-btnwithloader">{t('registerOrganization')} {isLoading && <div className="lds-dual-ring"></div>}</button><a href="/home" className="up-btnskip">{t('registerOrganization')}</a></div>
            </fieldset>
        </form>
    )
}