// import { styles } from "./"

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { images } from "../../../../assets/images";
import { privacySettingSchema } from "./validations/privacy-settings.validation";
import { routes } from "../../../../router/routes";
import { IProfileSetupInterface } from "./interfaces/profile-setup.interface";
import { EBoolean, EPrivacy } from "./interfaces/drop-down.enums";
import { Toast } from "../../../../utils/Toast";
import { ProfileSetup } from "../../slice/auth.thunks";
import { useEffect, useState } from "react";
import { getUser } from "../../slice/user.selector";
import { setUpdatedUser } from "../../slice/user.slice";
import {useTranslation} from "react-i18next";

export const PrivacySettingsPage = () => {
    const [isLoading, setIsloading] = useState(false);
    const [fromHome, setFromHome] = useState(false);
    const dispatch = useDispatch();
    let history = useHistory();
    const currentUser = useSelector(getUser);
    const { t } = useTranslation('', { keyPrefix: 'PrivacySettingsPage' });

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(privacySettingSchema)
    });
    useEffect(() => {
        if (history.location.search === '?search=general') {
            setFromHome(true)
            setValue('emailPrivacy', currentUser.emailPrivacy)
            setValue('dobPrivacy', currentUser.dobPrivacy)
            setValue('addressPrivacy', currentUser.addressPrivacy)
            setValue('userCanChat', currentUser.userCanChat)
            setValue('groupUserCanChat', currentUser.groupUserCanChat)
        }
    }, [])
    const onSubmit = async (data: IProfileSetupInterface) => {
        setIsloading(true)
        const res: any = await dispatch(ProfileSetup(data));
        setIsloading(false)
        if (res.payload.statusCode == 200) {
            Toast.fire({
                icon: "success",
                title: res.payload.message
            })
            await dispatch(setUpdatedUser(res.payload.payload.user))
            if (fromHome) {
                history.push(routes.home);
            } else {
                history.push(routes.followOrganizations);
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.payload.message
            })
        }
    };
    return (
        <div className="up-signupsteptwo up-signupstepthree">
            <div className="up-logincontentarea">
                <div className="up-singupsteptwocontent">
                    <h3>{t('secured')} <span>{t('communication')}</span></h3>
                    <ul className="up-bulletpoints">
                        <li><span>{t('groupCommunication')}</span></li>
                        <li><span>{t('securedNetwork')}</span></li>
                        <li><span>{t('unlimitedOrganisations')}</span></li>
                        <li><span>{t('displayRules')}</span></li>
                        <li><span>{t('createUnlimitedUserGroups')}</span></li>
                        <li><span>{t('individualMessagingChat')}</span></li>
                    </ul>
                    <figure className="up-manvectorimage">
                        <img src={images.ManVectoImage} alt="Vector Image"/>
                    </figure>
                </div>
            </div>
            <div className="up-loginformholder">
                <div className="up-loginformcontentholder">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="up-signupthreehead">
                            <a onClick={() => history.goBack()} className="up-btngoback">
                                <i className="icon-arrow"></i>
                            </a>
                            <h3>{t('privacySetting')}</h3>
                        </div>
                        <div className="up-privacysettings">
                            <div className="up-privacysetting">
                                <div className="up-privacysettingtitle">
                                    <h4>{t('personalInformation')}</h4>
                                </div>
                                <ul>
                                    <li>
                                        <span>{t('email')}</span>
                                        <div className="up-select">
                                            <select {...register("emailPrivacy")}>
                                                <option value={""} selected={!fromHome} disabled>{t('select')}</option>
                                                <option value={EPrivacy.EVERY_ONE}>{t('everyone')}</option>
                                                <option value={EPrivacy.ONLY_ME}>{t('onlyMe')}</option>
                                            </select>
                                        </div>
                                    </li>
                                    <small id="emailHelp" className="form-text text-muted">
                                        {errors.emailPrivacy?.message}
                                    </small>
                                    <li>
                                        <span>{t('dateOfBirth')}</span>
                                        <div className="up-select">
                                            <select {...register("dobPrivacy")}>
                                                <option value={""} selected={!fromHome} disabled>{t('select')}</option>
                                                <option value={EPrivacy.EVERY_ONE}>{t('everyone')}</option>
                                                <option value={EPrivacy.ONLY_ME}>{t('onlyMe')}</option>
                                            </select>
                                        </div>
                                    </li>
                                    <small id="emailHelp" className="form-text text-muted">
                                        {errors.dobPrivacy?.message}
                                    </small>
                                    <li>
                                        <span>{t('address')}</span>
                                        <div className="up-select">
                                            <select {...register("addressPrivacy")}>
                                                <option value={""} selected={!fromHome} disabled>{t('select')}</option>
                                                <option value={EPrivacy.EVERY_ONE}>{t('everyone')}</option>
                                                <option value={EPrivacy.ONLY_ME}>{t('onlyMe')}</option>
                                            </select>
                                        </div>
                                    </li>
                                    <small id="emailHelp" className="form-text text-muted">
                                        {errors.addressPrivacy?.message}
                                    </small>
                                </ul>
                            </div>
                            <div className="up-privacysetting">
                                <div className="up-privacysettingtitle">
                                    <h4>{t('communication')}</h4>
                                </div>
                                <ul>
                                    <li>
                                        <span>{t('allowUsers')}</span>
                                        <div className="up-select">
                                            <select {...register("userCanChat")}>
                                                <option value={""} selected={!fromHome} disabled>{t('select')}</option>
                                                <option value={EPrivacy.EVERY_ONE}>{t('everyone')}</option>
                                                <option value={EPrivacy.ONLY_ME}>{t('onlyMe')}</option>
                                            </select>
                                        </div>
                                    </li>
                                    <small id="emailHelp" className="form-text text-muted">
                                        {errors.userCanChat?.message}
                                    </small>
                                    <li>
                                        <span>{t('allowGroupUsers')}</span>
                                        <div className="up-select">
                                            <select {...register("groupUserCanChat")}>
                                                <option value={""} selected={!fromHome} disabled>{t('select')}</option>
                                                <option value={EBoolean.YES}>{t('yes')}</option>
                                                <option value={EBoolean.NO}>{t('no')}</option>
                                            </select>
                                        </div>
                                    </li>
                                    <small id="emailHelp" className="form-text text-muted">
                                        {errors.groupUserCanChat?.message}
                                    </small>
                                </ul>
                            </div>
                        </div>
                        <div className="up-formbuttons">
                            <button type="submit" className="up-btn up-btn-lg up-btnwithloader"> {fromHome ? t('update') : t('next')} {isLoading && <div className="lds-dual-ring"></div>}</button>
                            {!fromHome && <a href="/follow-organizations" className="up-btnskip">{t('skip')}</a>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};
