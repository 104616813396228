import { IUser } from "../features/auth/pages/login/interface/user.interface";
import { EBoolean } from "../features/auth/pages/signup/interfaces/drop-down.enums";
import { IChat } from "../features/chatmessages/interfaces/chat.interface";
import { IMessage } from "../features/chatmessages/interfaces/message.interface";
import { ICreateEvent } from "../features/organisations/createorganisation/interfaces/create-event.interface";
import { IGroup } from "../features/organisations/createorganisation/interfaces/group.interface";
import { IResponseInterface } from "../interfaces/api-response.interface";
import { IPagination } from "../interfaces/pagination-info.interface";
import { IQuery } from "../interfaces/query.interface";
import { HttpService } from "./base.service";

class DashboardService extends HttpService {
  private readonly dashboardStatsPrefix: string = "admin-dashboard-stats";

  getDashboardStats = (id: string): Promise<IResponseInterface<{}>> => this.get(`${this.dashboardStatsPrefix}/${id}`);
  
  getDashboardEvents = (id: string, params: string): Promise<IResponseInterface<{}>> => this.get(`admin-dashboard-event/${id}${params}`);
  
  getDashboardEventAttendees = (id: string): Promise<IResponseInterface<{}>> => this.get(`admin-dashboard-event/${id}/avg-attendees`);

  getDashboardMemberVisitStats = (id: string, page: number, limit: number, query: string): Promise<IResponseInterface<{}>> => this.get(`admin-dashboard-member/${id}/visits?page=${page}&limit=${limit}&query=${query}`);

  getDashboardMemberByGroups = (groupid: string, page: number, query: string): Promise<IResponseInterface<{}>> => this.get(`admin-dashboard-member/by-group/${groupid}?page=${page}&limit=15&query=${query}`);

  getDashboardMemberRecent = (id: string, page: number, query: string): Promise<IResponseInterface<{}>> => this.get(`admin-dashboard-member/${id}/recent?page=${page}&limit=10&query=${query}`);

  getDashboardMemberTop = (id: string, page: number, query: string): Promise<IResponseInterface<{}>> => this.get(`admin-dashboard-member/${id}/top?page=${page}&limit=10&query=${query}`);

  getDashboardTopAdmins = (id: string, page: number, query: string): Promise<IResponseInterface<{}>> => this.get(`admin-dashboard-member/${id}/top-admin?page=${page}&limit=5&query=${query}`);

  getDashboardMemberPostCount = (id: string, page: number): Promise<IResponseInterface<{}>> => this.get(`admin-dashboard-member/${id}/post-count?page=${page}&limit=10`);

  getDashboardPosts = (id: string, params: string): Promise<IResponseInterface<{}>> => this.get(`admin-dashboard-post/${id}/top${params}`);

  getDashboardPostCount = (id: string, params: string): Promise<IResponseInterface<{}>> => this.get(`admin-dashboard-post/${id}/count${params}`);
  
  getDashboardViewCount = (id: string): Promise<IResponseInterface<{}>> => this.get(`admin-dashboard-post/${id}/avg-views`);

 }
export const dashboardService = new DashboardService();