import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";
import {images} from "../../../assets/images";
import {AppLayout} from "../../../components/layouts/AppLayout";
import {routes} from "../../../router/routes";
import {_io} from "../../../services/socket.service";
import {IUser} from "../../auth/pages/login/interface/user.interface";
import {getUser} from "../../auth/slice/user.selector";
import {MessageComponent} from "../components/Message";
import {EChatType} from "../enum/type.enum";
import {IChat} from "../interfaces/chat.interface";
import {
  getActiveHead,
  getActiveType,
  getGroupChatHeads,
  getHeadsLoading,
  getIndividualChatHeads,
  getIsLoading,
} from "../slice/chat.selector";
import {
  addMessage,
  handleSocketReaction,
  changeActiveTab,
  updateActiveHead,
  updateLastMessageInChatHead, updateLastSentMessage, addNewChatHead,
} from "../slice/chat.slice";
import {GetGroupChatHeads, GetIndividualChatHeads, GetMedias, GetMembers,} from "../slice/chat.thunks";
import {chatService} from "../../../services/chat.service";
import {useTranslation} from "react-i18next";

export const AllChatPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const groupChatHeads = useSelector(getGroupChatHeads);
  const individualChatHeads = useSelector(getIndividualChatHeads);
  const headsLoading = useSelector(getHeadsLoading);
  const activeType = useSelector(getActiveType);
  const activeHead = useSelector(getActiveHead);
  const location = useLocation();
  const history = useHistory();
  const authUser = useSelector(getUser);
  const { t } = useTranslation('', { keyPrefix: 'AllChatPage' });
  const [updateTab, setUpdateTab] = useState(false);
  useEffect(() => {
    // if (activeType == EChatType.GROUP) {
    messageSocketHandler();
    newChatSocketHandler();
    // } else {
    // individualSocketHandler()
    // }

    return () => {
      const query = new URLSearchParams(location.search);
      let chatId ;
      if (activeType === EChatType.ONE_TO_ONE) {
        chatId = query.get("active")
      } else {
        chatId = query.get("groupId")
      }
      _io.off(`new-message-${chatId}`);
      _io.off(`message-reaction-${chatId}`);
      _io.off(`new-message-notification-${authUser._id}`);
    };
  }, [activeHead, activeType]);

  const messageSocketHandler = () => {
    const query = new URLSearchParams(location.search);
    let chatId ;
    if (activeType === EChatType.ONE_TO_ONE) {
      chatId = query.get("active")
    } else {
      chatId = query.get("groupId")
    }
    _io.on(`new-message-${chatId}`, (data: any) => {
      if (data.message.sender._id != authUser._id) {
        dispatch(addMessage(data.message));
        chatService.readMessage(data.message._id)
      } else if (data.message.sender._id == authUser._id) {
        dispatch(updateLastSentMessage(data.message))
      }
    });
    _io.on(`message-reaction-${chatId}`, (data: any) => {
      dispatch(handleSocketReaction(data));
    });
    _io.on(`new-message-notification-${authUser._id}`, (data: any) => {
      handleSocketResponse(data)
    });
  };
  const newChatSocketHandler = () => {
    _io.on(`new-message-notification-${authUser._id}`, (data: any) => {
      handleNewChatSocketResponse(data)
    });
  };
  useEffect(() => {
    if (location.search) {
      const query = new URLSearchParams(location.search);
      if (updateTab){
        if (query.get("tab")) {
          dispatch(changeActiveTab(query.get("tab")));
        }
        if (query.get("active")) {
          dispatch(updateActiveHead(query.get("active")));
          dispatch(GetMedias(query.get("active")!));
          if (query.get("tab") == EChatType.GROUP)
            dispatch(GetMembers(query.get("groupId")!));
        }
      }else{
        setUpdateTab(true)
      }
    }
    //groupChatHeads
  }, [location, groupChatHeads, individualChatHeads]);

  useEffect(() => {
    if (activeType == EChatType.ONE_TO_ONE) {
      dispatch(GetIndividualChatHeads({ page: 1, limit: 1000 }));
    } else {
      dispatch(GetGroupChatHeads({ page: 1, limit: 1000 }));
    }
  }, [activeType]);
  const handleSocketResponse = (data: any) => {
    if (data.type === "MESSAGE" && activeHead?._id !== data?.chat?._id) {
        setUpdateTab(false)
        dispatch(updateLastMessageInChatHead(data))
    }
  }
  const handleNewChatSocketResponse = (data: any) => {
    if (data.type === "MESSAGE" && activeHead?._id !== data?.chat?._id) {
        dispatch(addNewChatHead(data))
    }
  }
  const renderChatHeaders = () => {
    return (
      <ul>
        {activeType == EChatType.ONE_TO_ONE
          ? renderIndividualChatHeads(individualChatHeads)
          : renderGroupChatHeads(groupChatHeads)}
      </ul>
    );
  };

  const renderGroupChatHeads = (chats: IChat[]) => {
    return (
      <ul>
        {chats.map((chat) => (
          <li
            key={chat._id}
            onClick={() =>
              // dispatch(updateActiveHead(chat))
              {
                if(activeHead?._id == chat._id) return;
              history.push(
                `${routes.allChatPage}?tab=${EChatType.GROUP}&active=${chat?._id}&groupId=${chat.group?._id}`
              )}
            }
            className={activeHead?._id == chat._id ? "up-active" : ""}
          >
            <div className="up-chatuser">
              <figure className="up-chatusersimg">
                {/* <span className="up-onlinedot up-onlinecolor"></span> */}
                <img
                  src={chat.group?.avatar ?? images.RestPlaceholder}
                  alt="User Image"
                />
              </figure>
              <div className="up-chatusercontent">
                <div className="up-chatusercontenthead">
                  <h3>{chat.group?.name}</h3>
                  {/* {chat.lastMessage && ( */}
                    <time dateTime="2022-12-12">
                      {new Intl.DateTimeFormat("en-GB", {
                        dateStyle: "medium",
                        // timeStyle: "short",
                      }).format(new Date(chat?.lastMessage?.length > 0 ? chat?.lastMessage[0]!.createdAt! : chat.createdAt!))}
                    </time>
                  {chat.unreadMessageCount > 0 && <span className="up-countmessage">{chat.unreadMessageCount}</span>}
                  {/* )} */}
                </div>
                <div className="up-description">
                  {/* <p>{chat.lastMessage?.content}</p> */}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const renderIndividualChatHeads = (chats: IChat[]) => {
    return (
      <ul>
        {chats.map((chat) => {
          const receiver =
            (chat.participants![0] as IUser)._id == authUser._id
              ? (chat.participants![1] as IUser)
              : (chat.participants![0] as IUser);
          return (
            <li
              key={chat._id}
              onClick={() =>
                // dispatch(updateActiveHead(chat))
                {
                  if(activeHead?._id == chat._id) return;
                  history.push(
                  `${routes.allChatPage}?tab=${EChatType.ONE_TO_ONE}&active=${chat._id}`
                )}
              }
              className={activeHead?._id == chat._id ? "up-active" : ""}
            >
              <div className="up-chatuser">
                <figure className="up-chatusersimg">
                  {/* <span className="up-onlinedot up-onlinecolor"></span> */}
                  <img
                    src={receiver.avatar ?? images.UserPlaceholder}
                    alt="User Image"
                  />
                </figure>
                <div className="up-chatusercontent">
                  <div className="up-chatusercontenthead">
                    <h3>{receiver.username}</h3>
                    {/* {chat.lastMessage && ( */}
                      <time dateTime="2022-12-12">
                        {new Intl.DateTimeFormat("en-GB", {
                          dateStyle: "medium",
                          // timeStyle: "short",
                        }).format(new Date(chat?.lastMessage?.length > 0 ? chat?.lastMessage?.[0]!.createdAt! : chat.createdAt!))}
                      </time>
                        {chat.unreadMessageCount > 0 && <span className="up-countmessage">{chat.unreadMessageCount}</span>}
                        {/* <span  className="up-countmessage">1</span> */}
                    {/* )} */}
                  </div>
                  <div className="up-description">
                    {/* <p>{chat.lastMessage?.content}</p> */}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <AppLayout>
      <div className="up-chatepage up-mainchatpage">
        <div className="up-chatsidebararea">
          <div className="up-chatsidebarhead">
            <h3>
              {t('chats')}
              {/* <span className="up-chatcount">7</span> */}
            </h3>
            {/* <form className="up-formtheme up-formsearchchat">
                            <fieldset>
                                <div className="form-group">
                                    <i className="icon-search"></i>
                                    <input type="search" name="search-chat" className="form-control" placeholder="Search People , Group &amp; Message"/>
                                </div>
                            </fieldset>
                        </form> */}
          </div>
          <div className="up-chatusersholder">
            <ul className="up-chatbuttons">
              <li>
                <button
                  onClick={() =>
                    // dispatch(changeActiveTab(EChatType.ONE_TO_ONE))
                    history.push(
                      `${routes.allChatPage}?tab=${EChatType.ONE_TO_ONE}`
                    )
                  }
                  className={activeType == EChatType.ONE_TO_ONE ? "active" : ""}
                >
                  {t('individual')}
                </button>
              </li>
              <li>
                <button
                  onClick={() =>
                    // dispatch(changeActiveTab(EChatType.GROUP))
                    history.push(`${routes.allChatPage}?tab=${EChatType.GROUP}`)
                  }
                  className={activeType == EChatType.GROUP ? "active" : ""}
                >
                  {t('group')}
                </button>
              </li>
            </ul>
            <div className="up-chatusers">
              {/* Loader for individual and Group switching Start  for chat heads*/}
              {headsLoading && (
                <div className="up-fullloader up-smallloader">
                  <div className="lds-dual-ring"></div>
                </div>
              )}

              {/* Loader for individual and Group switching End */}
              {renderChatHeaders()}
            </div>
          </div>
        </div>
        {activeHead ? (
          <MessageComponent />
        ) : (
          <div className="up-nochatselected">
            <img src={images.MessageIcon} alt="Message Icon" />
            <h3>{t('noChatSelected')}</h3>
          </div>
        )}
      </div>
    </AppLayout>
  );
};
