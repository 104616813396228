import * as yup from "yup";

export const organisationSetupSchema = yup.object().shape({
    avatar: yup.string(),
    email: yup.string().email().required('Email is required'),
    country: yup.string().required('Country is required'),
    city: yup.string().required('City is required'),
    address: yup.string().required('Address is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    about: yup.string(),
    website: yup.string(),
    socialLinks: yup.string(),
}).required();