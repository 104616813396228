import { IForgotPasswordInterface } from "../features/auth/pages/forgotpassword/interfaces/forgot-password.interface";
import { IResetPasswordInterface } from "../features/auth/pages/forgotpassword/interfaces/reset-password.interface";
import { IResponseInterface } from "../interfaces/api-response.interface";
import { ILogInInterface } from "../features/auth/pages/login/interface/login.interface";
import { IProfileSetupInterface } from "../features/auth/pages/signup/interfaces/profile-setup.interface";
import { ISignUpInterface } from "../features/auth/pages/signup/interfaces/sign-up.interface";
import { HttpService } from "./base.service";
import { IToken } from "../features/auth/pages/login/interface/token.interface";
import { IUser } from "../features/auth/pages/login/interface/user.interface";
import { IOrganization } from "../features/organisations/createorganisation/interfaces/organisation.interface";
import { IOrganizationSubscription } from "../features/organisations/createorganisation/interfaces/organisation-subscription.interface";
class AdminService extends HttpService {
  

  getOrganizations = (data: {page: number, limit: number}): Promise<IResponseInterface<{organizations:IOrganization}>> => this.get(`organization/all`, data);

  subscription = (data: any): Promise<IResponseInterface<{subscription:IOrganizationSubscription}>> => this.post(`organization-subscription`, data);
}
export const adminService = new AdminService();
