import {Modal} from "react-bootstrap";
import {images} from "../../../assets/images";
import NoDataFound from "../../../components/NoData/NoDataFound";
import {useTranslation} from "react-i18next";
import {IReaction} from "../interfaces/message.interface";
import {useSelector} from "react-redux";
import {getUser} from "../../auth/slice/user.selector";
import {useState} from "react";
import {DynamicLoader} from "../../../utils/DynamicLoader";
import {messageService} from "../../../services/message.service";


interface IReactionsDetailModalProps {
    reactions: IReaction[];
    closeModal: Function
}

export const ReactionsDetailModal: React.FC<IReactionsDetailModalProps> = (props) => {
    const [selectedReactions, setSelectedReactions] = useState<IReaction[]>(JSON.parse(JSON.stringify(props.reactions)));
    const [isLoading, setIsloading] = useState(false);
    const {t} = useTranslation('', {keyPrefix: 'ReactionsDetailModal'});
    const currentUser = useSelector(getUser);

    const closeDisplayToModal = () => {
        props.closeModal()
    }

    const removeReaction = async (option: IReaction, index: number) => {
        setIsloading(true);
        const res = await messageService.removeReaction(option._id)
        setIsloading(false);
        if (res.statusCode === 200) {
            selectedReactions.splice(index,1)
            setSelectedReactions([...selectedReactions])
            // props.closeModal()
        }
    }

    return <Modal className="up-thememodal up-invitemembersmodal" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('title')}</Modal.Title>
        </Modal.Header>
        {isLoading && <DynamicLoader/>}
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <div></div>
                <div className="up-userstable">
                    <div className="up-usertableholder">
                        <table className="up-tabletheme">
                            <thead>
                            <tr>
                                <th>{t('user')}</th>
                                <th>{t('reaction')}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedReactions.map((option, index) => (<tr>
                                <td>
                                    <div className="up-usertabledetail" style={{marginBottom: '12px'}}>
                                        <figure>
                                            <img src={option.sender.avatar || images.UserPlaceholder} alt="user image"/>
                                        </figure>
                                        <h4>{option.sender.name}</h4>
                                    </div>
                                </td>
                                <td>
                                    {option.reaction}
                                </td>
                                {currentUser._id == option.sender._id && <td>
                                    <button className='up-btn' onClick={() => removeReaction(option, index)}>Remove
                                    </button>
                                </td>}
                            </tr>))}
                            </tbody>
                        </table>
                        {selectedReactions && selectedReactions.length === 0 && (
                            <NoDataFound/>
                        )}
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}