import {useEffect, useState} from "react";
import {postService} from "../../services/post.service";
import {Modal} from "react-bootstrap";
import {DynamicLoader} from "../../utils/DynamicLoader";
import NoDataFound from "../NoData/NoDataFound";
import {useTranslation} from "react-i18next";

export const DisplayToDetailModal: React.FC<any> = (props: any) => {

    const [isLoading, setIsloading] = useState(false);
    const [displayToData, setDisplayToData] = useState<any[]>([]);
    const { t } = useTranslation('', { keyPrefix: 'DisplayToDetailModal' });
    useEffect(() => {
        getDisplayToDetails();
    }, [])
    const closeDisplayToModal = () => {
        props.closeModal(false)
    }
    const getDisplayToDetails = async () => {
        setIsloading(true);
        const res: any = await postService.getDisplayToDetails(props.id)
        setIsloading(false);
        if (res.statusCode === 200) {
            setDisplayToData(res.payload.displayTo)
        }
    }

    return <Modal className="up-thememodal up-invitemembersmodal" show={true} onHide={closeDisplayToModal}>
        <Modal.Header className="up-modalheader" closeButton>
            <Modal.Title>{t('DisplayToDetailModal')}</Modal.Title>
        </Modal.Header>
        {isLoading && <DynamicLoader/>}
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <div></div>
                <div className="up-userstable">
                    <div className="up-usertableholder">
                        {displayToData && displayToData.length > 0 && (
                            <table className="up-tabletheme">
                                <thead>
                                <tr>
                                    <th>{props.title}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {displayToData.map((option, index) => (<tr>
                                    <td>
                                        <div className="up-usertabledetail" style={{marginBottom: '12px'}}>
                                            <h4>{option?.name ?? option?.role}</h4>
                                        </div>
                                    </td>
                                </tr>))}
                                </tbody>
                            </table>
                        )}
                        {displayToData && displayToData.length === 0 && (
                            <NoDataFound/>
                        )}
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}
