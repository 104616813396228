import {yupResolver} from '@hookform/resolvers/yup';
import {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {useForm} from 'react-hook-form';
import * as yup from "yup";
import {Toast} from "../../../utils/Toast";
import {requestedPostService} from "../../../services/requested-post.service";
import {useTranslation} from "react-i18next";

const rejectReasonSchema = yup.object().shape({
    reason: yup.string()
}).required();

export const RejectReasonModal: React.FC<any> = (props) => {

    const [isLoading, setIsloading] = useState(false);
    const { t } = useTranslation('', { keyPrefix: 'RejectReasonModal' });

    const {register, handleSubmit, formState: {errors}, setValue} = useForm({
        resolver: yupResolver(rejectReasonSchema)
    });
    const handleCloseeight = () => {
        props.closeModal(false)
    }

    const cancelRequest = async (data: any) => {
        setIsloading(true)
        const params = {...props.post, ...data}
        const res: any = await requestedPostService.updateRequestedPost(props.post?._id!, params)
        setIsloading(false)
        if (res.statusCode == 200) {
            Toast.fire({
                icon: 'success',
                title: t('incomingUpdateRejected')
            })
            props.closeModal(true)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    return <Modal className="up-thememodal up-modalcreategroup up-modalcreateevents" show={true}
                  onHide={handleCloseeight}>
        <Modal.Body className="up-modalbody">
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formcreateevent" onSubmit={handleSubmit(cancelRequest)}>
                    <fieldset>
                        <div className="form-group">
                            <label>{t('reasonForRejection')}</label>
                            <input disabled={props.isEdit} {...register("reason")} type="text" name="reason"
                                   className="form-control" placeholder={t('enterReasonForRejection')}/>
                            <small id="content" className="form-text text-muted">
                                {errors.reason?.message}
                            </small>
                        </div>
                        {!props.isEdit &&
                            <button disabled={isLoading} type="submit" className="up-btn">{t('reject')} {isLoading &&
                                <div className="lds-dual-ring"></div>}</button>}
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
}